/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../../../../../../common/styles/commandWidgetStyle.css";
import { toast } from "react-toastify";
import TruncateText from "../../../../../../common/utils/truncateText";
import { isEmpty } from "../../../../../../common/utils/isEmpty";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const CommandWidget = ({ chart, handleSave }) => {
  const { config } = useMenu();
  const serverEndpoint = config?.REACT_APP_POLLING_ENDPOINT;
  const [isChecked, setIsChecked] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [pointValue, setPointValue] = useState();
  const [pointData, setPointData] = useState({});
  const [deviceName, setDeviceName] = useState("");
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);

  const fetchPoint = async () => {
    try {
      await api
        .get(`${dbUrl}/points/${chart?.data?.points[0].value}`)
        .then(async (res) => {
          setPointData(res.data.data);
          await api
            .get(`${dbUrl}/devices/${res.data.data.pointDeviceInstance}`, {
              headers: {
                Authorization: `Basic ${base64Credentials}`,
              },
            })
            .then((res) => {
              setDeviceName(res.data.data.deviceName);
            })
            .catch((error) => {
              console.error(`Unexpected Error Occurred :${error}`);
            });
        })
        .catch((error) => {
          console.error(`Unexpected Error Occurred :${error}`);
        });
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };

  useEffect(() => {
    fetchPoint();
    if (chart?.data?.points[0].value) {
      const fetchDataInterval = setInterval(() => {
        fetchPoint();
      }, 5000);
      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart?.data]);

  const handleWrite = async (data) => {
    try {
      if (!isEmpty(pointValue)) {
        // setIsLoading(true);
        const updatePoint = await api.post(
          `${serverEndpoint}/write/${data._id}`,
          {
            value: parseFloat(pointValue),
            priority: 10,
            topic: data?.networkTopic,
          }
        );
        setPointValue();

        if (updatePoint.status === 200) {
          toast.success("Point Written Successfully");
        } else {
          toast.error(
            `Failed to Update, Possible Cause: ${updatePoint.message}`
          );
          // setIsLoading(false);
        }
      } else {
        toast.warning("Cannot Write Empty Value to a Point");
      }
    } catch (error) {
      toast.error(`Failed to Update, Possible Cause: ${error}`);
      setPointValue(pointValue);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleWrite(pointData);
    }
  };

  return (
    <div className="commandWidget">
      <div className="pointInfos">
        <div className="pointInfo">
          Device :{" "}
          {deviceName
            ? `${TruncateText(deviceName, 10)}-${pointData.pointDeviceInstance}`
            : ""}
        </div>
        <div className="pointInfo">
          Point : {pointData ? pointData?.pointName : ""}
        </div>
        <div className="pointInfo">
          PresentValue : {pointData ? pointData?.pointPresentValue : ""}
        </div>
      </div>
      <div className="pointWriteInfo">
        <span>Write Point</span>
        <input
          type="checkbox"
          checked={chart?.data?.pointEnable}
          onChange={(e) => setIsChecked(e.target.checked)}
        />
        {isChecked && (
          <input
            className="commandWidgetInput"
            type="number"
            value={pointValue}
            onBlur={() => handleWrite(pointData)}
            onChange={(e) => setPointValue(e.target.value)}
            onKeyPress={handleKeyPress} // Add the onKeyPress handler
          />
        )}
      </div>
    </div>
  );
};

export default CommandWidget;
