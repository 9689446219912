/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Slide from "@mui/material/Slide";
import CustomButton from "../../../../reusableComponents/customButton";
import "../../../../../common/styles/operatorNodeFormStyle.css";

import CustomFormSelect from "../../../../reusableComponents/customFormSelect";
import { toast } from "react-toastify";

const OperatorNodeForm = ({
  setOperatorNodeFormOpen,
  operatorNodeFormOpen,
  operatorNode,
  addNodeValues,
}) => {
  const [kp, setKp] = useState(parseFloat(operatorNode.data.kp, 10));
  const [kd, setKd] = useState(parseFloat(operatorNode.data.kd, 10));
  const [ki, setKi] = useState(parseFloat(operatorNode.data.ki, 10));
  const [min, setMin] = useState(parseFloat(operatorNode.data.min, 10));
  const [max, setMax] = useState(parseFloat(operatorNode.data.max, 10));
  const [ticks, setTicks] = useState(operatorNode.data.ticks);
  const [rampPeriod, setRampPeriod] = useState(operatorNode.data.rampPeriod);

  const [reset, setReset] = useState(operatorNode.data.reset);
  const [direction, setDirection] = useState(
    operatorNode.data.direction ? operatorNode.data.direction : 0
  );
  const [highLimit, setHighLimit] = useState(
    parseFloat(operatorNode.data.highLimit, 10)
  );
  const [lowLimit, setLowLimit] = useState(
    parseFloat(operatorNode.data.lowLimit, 10)
  );
  const [startsAt, setStartsAt] = useState(
    parseFloat(operatorNode.data.startsAt, 10)
  );
  const [selectorValue, setSelectorValue] = useState(
    parseFloat(operatorNode.data.selector, 10)
  );

  const handleSave = () => {
    let data;
    if (operatorNode.data.label === "PID") {
      data = {
        ki: parseFloat(ki ? ki : operatorNode.data.ki, 10),
        kd: parseFloat(kd ? kd : operatorNode.data.kd, 10),
        kp: parseFloat(kp ? kp : operatorNode.data.kp, 10),
        min: parseFloat(min ? min : operatorNode.data.min, 10),
        max: parseFloat(max ? max : operatorNode.data.max, 10),
      };
    } else if (
      operatorNode.data.label === "ADEMUX2" ||
      operatorNode.data.label === "ASW" ||
      operatorNode.data.label === "BSW"
    ) {
      data = {
        selector: selectorValue
          ? selectorValue
          : operatorNode.data.selectorValue,
      };
    } else if (operatorNode.data.label === "ASW4") {
      data = {
        selectorValue: selectorValue
          ? selectorValue
          : operatorNode.data.selectorValue,
        startsAt: startsAt ? startsAt : operatorNode.data.startsAt,
      };
    } else if (operatorNode.data.label === "Limiter") {
      data = {
        highLimit: highLimit ? highLimit : operatorNode.data.highLimit,
        lowLimit: lowLimit ? lowLimit : operatorNode.data.lowLimit,
      };
    } else if (operatorNode.data.label === "SRLatch") {
      data = {
        reset: reset ? reset : operatorNode.data.reset,
      };
    } else if (operatorNode.data.label === "Count") {
      data = {
        direction: direction ? direction : operatorNode.data.direction,
        reset: reset ? reset : operatorNode.data.reset,
      };
    } else if (operatorNode.data.label === "Ramp") {
      data = {
        min: min ? min : operatorNode.data.min,
        max: max ? max : operatorNode.data.max,
        rampPeriod: rampPeriod ? rampPeriod : operatorNode.data.rampPeriod,
      };
    } else if (operatorNode.data.label === "TickTock") {
      if (ticks > 2) {
        toast.warning("Maximum 2 Ticks are only allowed");
        return;
      } else if (ticks < 1) {
        toast.warning("Minimum 1 Ticks are only allowed");
        return;
      } else {
        data = {
          ticks: ticks ? ticks : operatorNode.data.ticks,
        };
      }
    }
    // console.log(data);
    addNodeValues(operatorNode, data);
    setOperatorNodeFormOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOperatorNodeFormOpen((prev) => !prev);
  };

  return (
    <Slide
      direction={"left"}
      in={operatorNodeFormOpen}
      mountOnEnter
      unmountOnExit
      onExited={() => {
        setOperatorNodeFormOpen(false);
      }}
    >
      <div className={`operatorNodeForm `}>
        <div className="operatorNodeFormHeading">
          {operatorNode.data.label === "PID"
            ? "PID Configuration"
            : operatorNode.data.label === "ADEMUX2"
            ? "ADEMUX2 Configuration"
            : operatorNode.data.label === "ASW"
            ? "ASW Configuration"
            : operatorNode.data.label === "BSW"
            ? "BSW Configuration"
            : operatorNode.data.label === "ASW4"
            ? "ASW4 Configuration"
            : operatorNode.data.label === "Limiter"
            ? "Limiter Configuration"
            : operatorNode.data.label === "SRLatch"
            ? "SR Latch Configuration"
            : operatorNode.data.label === "Count"
            ? "Count Configuration"
            : operatorNode.data.label === "Ramp"
            ? "Ramp Configuration"
            : operatorNode.data.label === "TickTock"
            ? "Tick Tock Configuration"
            : ""}
        </div>

        <div className="operatorNodeFormContents">
          {operatorNode.data.label === "PID" ? (
            <>
              <label>Kp</label>
              <input
                type="number"
                placeholder="0"
                value={kp}
                onChange={(e) => setKp(e.target.value)}
              />
              <label>Ki</label>
              <input
                type="number"
                placeholder="0"
                value={ki}
                onChange={(e) => setKi(e.target.value)}
              />
              <label>kd</label>
              <input
                type="number"
                placeholder="0"
                value={kd}
                onChange={(e) => setKd(e.target.value)}
              />
              <label>Min</label>
              <input
                type="number"
                placeholder="0"
                value={min}
                onChange={(e) => setMin(e.target.value)}
              />
              <label>Max</label>
              <input
                type="number"
                placeholder="100"
                value={max}
                onChange={(e) => setMax(e.target.value)}
              />
            </>
          ) : operatorNode.data.label === "SRLatch" ? (
            <>
              <label>Reset Value</label>
              <input
                type="number"
                placeholder="0"
                value={reset}
                onChange={(e) => setReset(e.target.value)}
              />
            </>
          ) : operatorNode.data.label === "Limiter" ? (
            <>
              <label>High Limit</label>
              <input
                type="number"
                placeholder="0"
                value={highLimit}
                onChange={(e) => setHighLimit(e.target.value)}
              />
              <label>Low Limit</label>
              <input
                type="number"
                placeholder="0"
                value={lowLimit}
                onChange={(e) => setLowLimit(e.target.value)}
              />
            </>
          ) : operatorNode.data.label === "ADEMUX2" ||
            operatorNode.data.label === "ASW" ||
            operatorNode.data.label === "BSW" ? (
            <>
              <label>Selector Value</label>
              <input
                type="number"
                placeholder="0"
                value={selectorValue}
                onChange={(e) => setSelectorValue(e.target.value)}
              />
            </>
          ) : operatorNode.data.label === "ASW4" ? (
            <>
              <label>Selector Value</label>
              <input
                type="number"
                placeholder="0"
                value={selectorValue}
                onChange={(e) => setSelectorValue(e.target.value)}
              />
              <label>Starts At</label>
              <input
                type="number"
                placeholder="0"
                value={startsAt}
                onChange={(e) => setStartsAt(e.target.value)}
              />
            </>
          ) : operatorNode.data.label === "Count" ? (
            <>
              <label>Count Direction</label>
              <CustomFormSelect
                id="countDirection"
                options={[
                  {
                    label: "0 to 1",
                    value: 1,
                  },
                  {
                    label: "1 to 0",
                    value: 0,
                  },
                ]}
                value={direction}
                onChange={(e) => setDirection(e.target.value)}
              />
              <label>Reset Value</label>
              <input
                type="number"
                placeholder="0"
                value={reset}
                onChange={(e) => setReset(e.target.value)}
              />
            </>
          ) : operatorNode.data.label === "Ramp" ? (
            <>
              <label>Minimum Value</label>
              <input
                type="number"
                placeholder="0"
                value={min}
                onChange={(e) => setMin(e.target.value)}
              />
              <label>Maximum Value</label>
              <input
                type="number"
                placeholder="100"
                value={max}
                onChange={(e) => setMax(e.target.value)}
              />
              <label>Ramp Period (in Seconds)</label>
              <input
                type="number"
                placeholder="5"
                value={rampPeriod}
                onChange={(e) => setRampPeriod(e.target.value)}
              />
            </>
          ) : operatorNode.data.label === "TickTock" ? (
            <>
              <label>Ticks Per Second</label>
              <input
                type="number"
                placeholder="0"
                value={ticks}
                onChange={(e) => setTicks(e.target.value)}
              />
            </>
          ) : null}

          <div className="operatorNodeFormContentsBtns">
            <CustomButton
              buttonName="Save"
              className="operatorNodeFormContentsSaveBtn"
              handleClick={handleSave}
            />
            <CustomButton
              buttonName="Cancel"
              className="operatorNodeFormContentsCancelBtn"
              handleClick={handleClose}
            />
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default OperatorNodeForm;
