/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import "../../../../../common/styles/pointDetailsTrendsStyle.css";
import { toast } from "react-toastify";
import PreLoader from "../../../../reusableComponents/preLoader";
import RequiredIndicator from "../../../../reusableComponents/requiredIndicator";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";
import getUser from "../../../../../configuration/localStorage/getUser";

const PointDetailsTrends = ({ pointProps, permissions }) => {
  const { config } = useMenu();
  const [trendEnable, setTrendEnable] = useState();
  const [recordCount, setRecordCount] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");
  const [trendInterval, setTrendInterval] = useState(0);
  const serverEndpoint = config?.REACT_APP_TREND_ENDPOINT;
  const [isLoading, setIsLoading] = useState(false);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [trendCalender, setTrendCalender] = useState();
  const user = getUser();
  const [daysState, setDaysState] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  const fetchTrendsFromDB = async () => {
    // setIsLoading(true);
    try {
      const fetch = await api.get(`${dbUrl}/points/${pointProps?._id}`);
      const trendEnable = fetch?.data.data["trendEnable"];
      const trendInterval = fetch?.data.data["trendInterval"];
      const recordCount = fetch?.data.data["recordCount"];
      const trendCalenderEnable =
        fetch.data.data["trendCalenderData"]["trendCalenderEnable"];

      setDaysState({
        sunday: fetch?.data.data["trendCalenderData"]["sunday"],
        monday: fetch?.data.data["trendCalenderData"]["monday"],
        tuesday: fetch?.data.data["trendCalenderData"]["tuesday"],
        wednesday: fetch?.data.data["trendCalenderData"]["wednesday"],
        thursday: fetch?.data.data["trendCalenderData"]["thursday"],
        friday: fetch?.data.data["trendCalenderData"]["friday"],
        saturday: fetch?.data.data["trendCalenderData"]["saturday"],
      });

      setStartTime(fetch?.data.data["trendCalenderData"]["startTime"]);
      setEndTime(fetch?.data.data["trendCalenderData"]["endTime"]);
      setTrendCalender(trendCalenderEnable);
      const convertSecondsToTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const remainingSecondsAfterHours = totalSeconds % 3600;
        const minutes = Math.floor(remainingSecondsAfterHours / 60);
        const seconds = remainingSecondsAfterHours % 60;

        setHours(String(hours).padStart(2, "0"));
        setMinutes(String(minutes).padStart(2, "0"));
        setSeconds(String(seconds).padStart(2, "0"));
      };
      convertSecondsToTime(trendInterval);

      setTrendEnable(trendEnable);
      setRecordCount(recordCount);

      // if (trendEnable === true) {
      //   const enableTrend = await api.post(`${serverEndpoint}/enable_trend`, {
      //     macAddress: pointProps?.pointDeviceMac,
      //     deviceInstance: pointProps?.pointDeviceInstance,
      //     pointType: pointProps?.pointType,
      //     pointInstance: pointProps?.pointInstance,
      //     trendInterval: trendInterval,
      //     trendStatus: pointProps?.pointStatus,
      //     recordCount: recordCount,
      //   });

      //   if (enableTrend.status === 200) {
      //     toast.success(`Trend Enabled for Point : ${pointProps?._id}`);
      //   }
      // }
    } catch (error) {
      console.error(`Failed to Retrieve Trend Data: ${error}`);
    }
    // setIsLoading(false);
  };

  useEffect(() => {
    fetchTrendsFromDB();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    newValue = value.slice(0, 2);
    newValue = isNaN(parseInt(newValue)) ? "" : newValue;

    switch (name) {
      case "hours":
        setHours(newValue);
        break;
      case "minutes":
        setMinutes(newValue);
        break;
      case "seconds":
        setSeconds(newValue);
        break;
      default:
        break;
    }

    const totalSeconds = calculateSeconds(hours, minutes, seconds);
    setTrendInterval(totalSeconds);
  };

  const calculateSeconds = (h, m, s) => {
    const totalHours = parseInt(h, 10) || 0;
    const totalMinutes = parseInt(m, 10) || 0;
    const totalSeconds = parseInt(s, 10) || 0;

    return totalHours * 3600 + totalMinutes * 60 + totalSeconds;
  };
  useEffect(() => {
    const totalSeconds = calculateSeconds(hours, minutes, seconds);
    setTrendInterval(totalSeconds);
  }, [hours, minutes, seconds]);

  const enableTrend = async () => {
    // if (recordCount !== "" && trendInterval !== 0) {
    if (trendInterval !== 0) {
      console.log(trendInterval);
      if (trendInterval < 5) {
        toast.warning("The minimum Trend Interval is 5 Seconds");
      } else {
        const trendCalenderData = {
          ...daysState,
          startTime,
          endTime,
          trendCalenderEnable: trendCalender,
        };
        if (trendEnable === true) {
          setIsLoading(true);
          const getPoint = await api.get(`${dbUrl}/points/${pointProps?._id}`);
          const trendData = {
            _id: `trend-${pointProps?._id}`,
            trendId: pointProps?._id,
            trendEnable: trendEnable,
            trendInterval: trendInterval,
            recordCount: recordCount ? recordCount : 500,
            trendStatus: pointProps?.pointStatus,
            networkInstance: pointProps?.networkInstance,
            networkType: pointProps?.networkType,
            deviceInstance: pointProps?.pointDeviceInstance,
            pointName: pointProps?.pointName,
            pointType: pointProps?.pointType,
            pointInstance: pointProps?.pointInstance,
            macAddress: pointProps?.pointDeviceMac,
            networkAddress: pointProps?.networkAddress,
            networkPort: pointProps?.networkPort,
            trendCalenderData,
          };
          const pointTrendData = {
            ...getPoint.data.data,
            trendEnable: trendEnable,
            trendInterval: trendInterval,
            recordCount: recordCount,
            trendStatus: pointProps?.pointStatus,
            trendCalenderData,
          };
          await api.put(
            `${dbUrl}/points/${getPoint.data.data._id}`,
            pointTrendData
          );

          try {
            const updateTrend = await api.post(`${dbUrl}/trends`, trendData);

            if (updateTrend.status === 200) {
              const enableTrend = await api.post(
                `${serverEndpoint}/enable_trend`,
                {
                  pointId: pointProps?._id,
                  trendInterval: trendInterval,
                  trendStatus: pointProps?.pointStatus,
                  recordCount: recordCount ? recordCount : 500,
                  trendCalenderData,
                  orgId: user.orgId,
                }
              );
              if (enableTrend.status === 200) {
                toast.success(
                  `Trend Enabled for Point : ${pointProps?.pointName}`
                );
                setIsLoading(false);
              }
            }
          } catch (error) {
            await api.put(
              `${dbUrl}/trends/trend-${pointProps?._id}`,
              trendData
            );
            const enableTrend = await api.post(
              `${serverEndpoint}/enable_trend`,
              {
                pointId: pointProps?._id,
                trendInterval: trendInterval,
                trendStatus: pointProps?.pointStatus,
                recordCount: recordCount,
                trendCalenderData,
                orgId: user.orgId,
              }
            );
            if (enableTrend.status === 200) {
              toast.success(
                `Trend Enabled for Point : ${pointProps?.pointName}`
              );
              setIsLoading(false);
            }
          }
        } else {
          const pointTrendData = {
            trendEnable: trendEnable,
            trendInterval: trendInterval,
            recordCount: recordCount,
            trendStatus: pointProps?.pointStatus,
            trendCalenderData,
          };
          await api.put(`${dbUrl}/points/${pointProps?._id}`, pointTrendData);
          toast.success(`Trend Disabled for Point : ${pointProps?._id}`);
          setIsLoading(false);
          await api.post(`${serverEndpoint}/disable_trend`, {
            trendId: `trend-${pointProps?._id}`,
          });
        }
      }
    } else {
      toast.warning("Please Fill the Required Fields");
    }
  };
  const handleDayChange = (e) => {
    const { name, checked } = e.target;
    setDaysState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const enableTrendCalender = (e) => {
    const newCheckedState = e.target.checked;

    if (newCheckedState && trendEnable) {
      setTrendCalender(newCheckedState);
    } else {
      setTrendCalender(newCheckedState);
    }
  };

  return (
    <div className={`pointDetailsTrendsContent `}>
      {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : ""}
      <div className="pointDetailsTrendsMainContent">
        <div className="trendCheckbox">
          <div className="custom-input-label">
            Enable Trend
            <RequiredIndicator /> :
          </div>
          <input
            type="checkbox"
            name="trendCalender"
            checked={trendEnable}
            onChange={
              permissions.devices.write
                ? (e) => setTrendEnable(e.target.checked)
                : undefined
            }
            disabled={permissions?.devices?.write ? false : true}
          />
        </div>
        <div className="pointDetailsTrendsGroup ">
          <div className="custom-input-label ">
            Trend Interval
            <RequiredIndicator /> :
          </div>
          <div className="pointDetailsTrendsDataTimeGroup">
            <input
              className="pointDetailsTrendsDataTime"
              type="number"
              name="hours"
              value={hours}
              onChange={handleInputChange}
              placeholder="00"
              min="0"
              max="99"
            />
            <span>H</span>
            <input
              className="pointDetailsTrendsDataTime"
              type="number"
              name="minutes"
              value={minutes}
              onChange={handleInputChange}
              placeholder="00"
              min="0"
              max="59"
            />
            <span>M</span>
            <input
              className="pointDetailsTrendsDataTime"
              type="number"
              name="seconds"
              value={seconds}
              onChange={handleInputChange}
              placeholder="00"
              min="0"
              max="59"
            />
            <span>S</span>
          </div>
        </div>
        <div className="trendCheckbox">
          <div className="custom-input-label">
            Enable Trend Active Calendar:
          </div>
          <input
            type="checkbox"
            name="trendCalender"
            checked={trendCalender}
            onChange={(e) => enableTrendCalender(e)}
            disabled={permissions?.devices?.write ? false : true}
          />
        </div>
        <div className="pointDetailsTrendsGroup ">
          <div className="custom-input-label">Days of Week:</div>
          <div className="pointDetailsTrendsDays">
            <div className="checkboxes">
              <label>
                <input
                  type="checkbox"
                  name="sunday"
                  checked={daysState.sunday}
                  onChange={handleDayChange}
                />
                Sun
              </label>
              <label>
                <input
                  type="checkbox"
                  name="monday"
                  checked={daysState.monday}
                  onChange={handleDayChange}
                />
                Mon
              </label>
              <label>
                <input
                  type="checkbox"
                  name="tuesday"
                  checked={daysState.tuesday}
                  onChange={handleDayChange}
                />
                Tue
              </label>
              <label>
                <input
                  type="checkbox"
                  name="wednesday"
                  checked={daysState.wednesday}
                  onChange={handleDayChange}
                />
                Wed
              </label>
              <label>
                <input
                  type="checkbox"
                  name="thursday"
                  checked={daysState.thursday}
                  onChange={handleDayChange}
                />
                Thu
              </label>
              <label>
                <input
                  type="checkbox"
                  name="friday"
                  checked={daysState.friday}
                  onChange={handleDayChange}
                />
                Fri
              </label>
              <label>
                <input
                  type="checkbox"
                  name="saturday"
                  checked={daysState.saturday}
                  onChange={handleDayChange}
                />
                Sat
              </label>
            </div>
          </div>
        </div>
        <div className="pointDetailsTrendsGroup ">
          <div className="custom-input-label">Time Range :</div>
          <div className="pointDetailsTrendsTimeRange">
            <input
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
            <p>To</p>
            <input
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </div>
        </div>
      </div>

      {permissions.devices.write && (
        <div className="pointDetailsBtnContainer">
          <span className="filled-btn-style" onClick={enableTrend}>
            Save
          </span>
        </div>
      )}
    </div>
  );
};

export default PointDetailsTrends;
