import React, { useState } from "react";
import "../../../../../common/styles/deviceNetworkStyle.css";
import DeviceNetworkProperties from "./deviceNetworkProperties.jsx";
import DeviceNetworkTable from "./deviceNetworkTable.jsx";
import PreLoader from "../../../../reusableComponents/preLoader";
import "../../../../../common/styles/customHeaderStyle.css";
import CustomHeader from "../../../../reusableComponents/customHeader.jsx";

const DeviceNetwork = ({
  handleOpen,
  fetchData,
  filteredNetworks,
  loading,
  permissions,
  networkProps,
  setSelectedNetwork,
  search,
  setSearch,
}) => {
  const [deviceNetworkProperty, setDeviceNetworkProperty] = useState(false);

  const networkProperties = (row) => {
    setSelectedNetwork(row);
    setDeviceNetworkProperty(true);
  };

  return deviceNetworkProperty ? (
    <DeviceNetworkProperties
      networkProps={networkProps}
      setDeviceNetworkProperty={setDeviceNetworkProperty}
      permissions={permissions}
      setSelectedNetwork={setSelectedNetwork}
    />
  ) : (
    <div className={`deviceNetwork `}>
      {loading ? <PreLoader size={80} color="var(--primary-color)" /> : ""}
      <CustomHeader
        showIcons={true}
        plusToolTipLabel={"Create Network"}
        plusFunc={handleOpen}
        showSearch={true}
        heading={"Networks"}
        search={search}
        setSearch={setSearch}
      />
      <DeviceNetworkTable
        fetchData={fetchData}
        filteredNetworks={filteredNetworks}
        networkProperties={networkProperties}
      />
    </div>
  );
};

export default DeviceNetwork;
