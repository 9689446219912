import React from "react";

import { useState, useEffect } from "react";
import CustomButton from "../../../../reusableComponents/customButton";
import "../../../../../common/styles/trendCanvasNavigatorStyle.css";

const TrendCanvasNavigator = ({ onOptionClick, selectedOption }) => {
  const [activeTab, setActiveTab] = useState("Table");

  const handleButtonClick = (buttonName) => {
    setActiveTab(buttonName);
    onOptionClick(buttonName);
  };

  useEffect(() => {
    setActiveTab(selectedOption || "Table");
  }, [selectedOption]);
  return (
    <div className={`custom-tabs`}>
      <CustomButton
        className={`${
          selectedOption === "Table" ? "custom-tab-selected" : "custom-tab"
        }`}
        buttonName="Table"
        handleClick={() => handleButtonClick("Table")}
      />
      <CustomButton
        className={` ${
          selectedOption === "Chart" ? "custom-tab-selected" : "custom-tab"
        }`}
        buttonName="Chart"
        handleClick={() => handleButtonClick("Chart")}
      />
    </div>
  );
};

export default TrendCanvasNavigator;
