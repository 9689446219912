/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../../../../../common/styles/tableWidgetStyle.css";
import { useState } from "react";

import { useEffect } from "react";
import { toast } from "react-toastify";
import { useMemo } from "react";
import CustomFormSelect from "../../../../../reusableComponents/customFormSelect";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const TableWidget = ({ chart, selectedDevice }) => {
  const { config } = useMenu();
  // const [isLoading, setIsLoading] = useState(false);
  const serverEndpoint = config?.REACT_APP_SERVER_ENDPOINT;
  const [pointData, setPointData] = useState([]);
  const [pointValues, setPointValues] = useState(
    Array(pointData.length).fill("")
  );
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  useEffect(() => {
    console.log(selectedDevice);
  }, [selectedDevice]);

  const fetchPoints = async () => {
    try {
      const parentDeviceResponse = await api.get(
        `${dbUrl}/devices/${selectedDevice.parentId}`,
        {
          params: {
            points: true,
          },
        }
      );
      const parentDevice = parentDeviceResponse.data.data;

      if (!parentDevice) {
        console.log("Parent device not found");
        setPointData([]);
        // setIsLoading(false);
        return;
      }

      const pointNames = chart?.data?.points || [];
      const points = [];

      pointNames.forEach((pointName) => {
        const point = parentDevice.points.find((point) =>
          point.objectName.toLowerCase().includes(pointName.toLowerCase())
        );
        if (point) {
          points.push(point.pointId);
        } else {
          console.log(`Point "${pointName}" not found in parent device`);
        }
      });

      if (points.length === 0) {
        console.log("No matching points found in parent device");
        setPointData([]);
        // setIsLoading(false);
        return;
      }

      const promises = points.map(async (point) => {
        try {
          const response = await api.get(`${dbUrl}/points/${point}`);
          return response.data.data;
        } catch (error) {
          console.error(`Error fetching data for point ${point}: `, error);
          throw error;
        }
      });
      const pointDataArray = await Promise.all(promises);
      setPointData(pointDataArray);
      // setIsLoading(false);
    } catch (error) {
      console.error("Unable to fetch data from parent device:", error.message);
      setPointData([]);
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    const intervalInSeconds = 5;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchPoints();
    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchPoints();
      }, intervalInMilliseconds);
      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart]);

  useMemo(() => fetchPoints(), []);

  const handleWrite = async (data, index, newValue) => {
    try {
      const getPoint = await api.get(`${dbUrl}/points/${data._id}`);
      const updatePoint = await api.post(
        `${serverEndpoint}/write/${data._id}`,
        {
          value: parseFloat(
            pointValues[index]
              ? pointValues[index]
              : newValue
              ? newValue
              : getPoint.data.pointPresentValue
          ),
          priority: 10,
          topic: getPoint?.data?.data?.networkTopic,
        }
      );
      setPointValues((prevValues) => {
        const newPointValues = [...prevValues];
        newPointValues[index] = null;
        return newPointValues;
      });

      if (updatePoint.status === 200) {
        toast.success("Point Written Successfully");
      } else {
        toast.error(`Failed to Update, Possible Cause: ${updatePoint.message}`);
      }
      setPointValues(Array(pointData.length).fill(""));
    } catch (error) {
      console.error(error);
      toast.error(`Failed to Update, Possible Cause: ${error}`);
      setPointValues((prevValues) => {
        const newPointValues = [...prevValues];
        newPointValues[index] = null;
        return newPointValues;
      });
    }
  };

  return (
    <div className="tableWidget">
      {/* {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null} */}
      <span className="chartLabel">
        {chart?.data?.title ? chart?.data?.title : "Point Details"}
      </span>

      <div className="tableWidgetContent">
        <table className="dataTable">
          <thead>
            <tr>
              <th>Device ID</th>
              <th>Point Name</th>
              <th>Point Present Value</th>
              <th>Point Unit</th>
              <th>Write Point</th>
            </tr>
          </thead>
          <tbody>
            {pointData.map((point, index) => (
              <tr key={index}>
                <td key={point?._id}>{point?.pointDeviceInstance}</td>
                <td>{point?.pointName}</td>
                <td
                  className={`${
                    parseFloat(point?.pointPresentValue) >=
                      parseFloat(point?.alarmHighLimit) &&
                    point?.alarmHighLimitEnable === true
                      ? "red"
                      : parseFloat(point?.pointPresentValue) <=
                          parseFloat(point?.alarmLowLimit) &&
                        point?.alarmLowLimitEnable === true
                      ? "yellow"
                      : ""
                  } `}
                >
                  {(() => {
                    const isBinaryOrDigital = [
                      "binaryValue",
                      "digitalValue",
                      "digitalOutput",
                      "binaryOutput",
                      "binaryInput",
                      "digitalInput",
                      "CoilStatus",
                      "InputStatus",
                    ].some((type) => point.pointType?.includes(type));
                    const isFalse =
                      point?.pointPresentValue === 0 ||
                      point?.pointPresentValue === 0.0;
                    const isTrue =
                      point?.pointPresentValue === 1 ||
                      point?.pointPresentValue === 1.0;

                    if (isBinaryOrDigital && isFalse) return "False";
                    if (isBinaryOrDigital && isTrue) return "True";
                    return point?.pointPresentValue;
                  })()}
                  {point?.pointUnitSymbol ? point?.pointUnitSymbol : ""}
                </td>
                <td>{point.pointUnits}</td>
                <td>
                  {point?.pointType.includes("Holding") ||
                  point?.pointType.includes("Value") ||
                  point?.pointType.includes("Output") ||
                  point?.pointType.includes("CoilStatus")
                    ? (() => {
                        const isBinaryOrDigital = [
                          "binaryValue",
                          "digitalValue",
                          "digitalOutput",
                          "binaryOutput",
                          "binaryInput",
                          "digitalInput",
                          "CoilStatus",
                        ].some((type) => point.pointType?.includes(type));
                        const isFalse =
                          point.pointPresentValue === 0 ||
                          point.pointPresentValue === 0.0;
                        const isTrue =
                          point.pointPresentValue === 1 ||
                          point.pointPresentValue === 1.0;

                        if (isBinaryOrDigital && isFalse)
                          return (
                            <CustomFormSelect
                              id={point?._id}
                              className="writableInput"
                              value={point.pointPresentValue === 0 ? 0 : 1}
                              onChange={(e) => {
                                const newPointValues = [...pointValues];
                                newPointValues[index] = e.target.value;
                                setPointValues(newPointValues);
                                handleWrite(point, index, e.target.value);
                              }}
                              options={[
                                {
                                  label: `${
                                    point?.statusTextZero
                                      ? point?.statusTextZero
                                      : "False"
                                  }`,
                                  value: 0,
                                },
                                {
                                  label: `${
                                    point?.statusTextOne
                                      ? point?.statusTextOne
                                      : "True"
                                  }`,
                                  value: 1,
                                },
                              ]}
                            />
                          );
                        if (isBinaryOrDigital && isTrue)
                          return (
                            <CustomFormSelect
                              id={point?._id}
                              className="writableInput"
                              value={point.pointPresentValue === 0 ? 0 : 1}
                              onChange={(e) => {
                                const newPointValues = [...pointValues];
                                newPointValues[index] = e.target.value;
                                setPointValues(newPointValues);
                                handleWrite(point, index, e.target.value);
                              }}
                              options={[
                                {
                                  label: `${
                                    point?.statusTextZero
                                      ? point?.statusTextZero
                                      : "False"
                                  }`,
                                  value: 0,
                                },
                                {
                                  label: `${
                                    point?.statusTextOne
                                      ? point?.statusTextOne
                                      : "True"
                                  }`,
                                  value: 1,
                                },
                              ]}
                            />
                          );
                        return (
                          <input
                            id={point?._id}
                            className="writableInput"
                            type="text"
                            placeholder="Enter Value"
                            value={pointValues[index]}
                            onBlur={() => handleWrite(point, index)}
                            onChange={(e) => {
                              const newPointValues = [...pointValues];
                              newPointValues[index] = e.target.value;
                              setPointValues(newPointValues);
                            }}
                          />
                        );
                      })()
                    : "Not Writable"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableWidget;
