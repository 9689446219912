import React from "react";
import "../../../../../common/styles/commonNodeStyle.css";
import { Handle, Position } from "reactflow";

const CustomOutputNode = ({ data, isConnectable }) => {
  return (
    <div className="customNodeContainer">
      <div className="customNodeHeading">{data.label}</div>
      <div className={`customNodeContent `}>
        {data?.value?.toFixed(2) ? data?.value?.toFixed(2) : 0.0}
      </div>

      <Handle
        type="target"
        position={Position.Left}
        id="out"
        isConnectable={isConnectable}
        className="customHandle"
      />
    </div>
  );
};

export default CustomOutputNode;
