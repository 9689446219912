import { useEffect, useState } from "react";
import "../../../../../common/styles/configurationSettingsMailStyle.css";
import CustomButton from "../../../../reusableComponents/customButton";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import { toast } from "react-toastify";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";

const ConfigurationSettingsSMS = () => {
  const [smsData, setSmsData] = useState({
    _id: "sms",
    numbers: "",
    port: "",
  });
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  useEffect(() => {
    const getSMSData = async () => {
      try {
        const smsData = await api.get(`${dbUrl}/smsconfigs/sms`);
        setSmsData({
          _id: "sms",
          apiKey: smsData?.data?.data?.apiKey
            ? smsData?.data?.data?.apiKey
            : "",
          numbers: smsData?.data?.data?.numbers
            ? smsData?.data?.data?.numbers
            : "",
          port: smsData?.data?.data?.port ? smsData?.data?.data?.port : "",
        });
      } catch (error) {
        toast.error(`Error Fetching SMS Data: ${error}`);
        setSmsData({
          _id: "sms",
          numbers: "",
          port: "",
        });
      }
    };
    getSMSData();
  }, [dbUrl]);

  const saveSmsData = async () => {
    if (smsData.numbers === "" || smsData.port === "") {
      toast.warning("Please Enter All Required Fields");
    } else {
      toast.success("SMS Settings Saved Successfully");
      try {
        await api.post(`${dbUrl}/smsconfigs`, smsData);
      } catch (error) {
        await api.put(`${dbUrl}/smsconfigs/sms`, smsData);
      }
    }
  };
  return (
    <div className="configurationSettingsMail">
      <div className="configurationSettingsSMSContent">
        <CustomFormInput
          id="numbers"
          type={"text"}
          required
          label="Phone Numbers"
          placeholder={"Enter Phone Numbers with Comma Seperated"}
          onChange={(e) => setSmsData({ ...smsData, numbers: e.target.value })}
          value={smsData?.numbers}
        />
        <CustomFormInput
          id="port"
          type={"text"}
          required
          label="Port"
          placeholder={"Enter Port"}
          onChange={(e) => setSmsData({ ...smsData, port: e.target.value })}
          value={smsData?.port}
        />
      </div>
      <div className="configurationSettingsSMSBtns">
        <CustomButton
          buttonName={"Save"}
          handleClick={saveSmsData}
          className={"filled-btn-style"}
        />
      </div>
    </div>
  );
};

export default ConfigurationSettingsSMS;
