import React, { useState, useEffect } from "react";
import "../../../../../common/styles/commonNodeStyle.css";
import { Handle, Position } from "reactflow";
import TruncateText from "../../../../../common/utils/truncateText";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";

const CustomNode = ({ data, isConnectable }) => {
  const [presentValue, setPresentValue] = useState(data.value);
  const { config } = useMenu();
  const [updatedPointName, setUpdatedPointName] = useState(data.pointName);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);

  useEffect(() => {
    const fetchAndSetData = async () => {
      try {
        const response = await api.get(`${dbUrl}/points/${data.objectName}`, {
          headers: {
            Authorization: `Basic ${base64Credentials}`,
          },
        });

        const updatedValue = response.data.data.pointPresentValue;
        const updatedPointName = response.data.data.pointName;
        setPresentValue(updatedValue.toFixed(2));
        setUpdatedPointName(updatedPointName);
      } catch (error) {
        if (error.response) {
          console.error("Server Error:", error.response.status);
          setPresentValue(NaN);
          console.error("Error Data:", error.response.data);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Request error:", error.message);
        }
      }
    };

    fetchAndSetData();

    const intervalId = setInterval(() => {
      fetchAndSetData();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [base64Credentials, data.objectName, dbUrl]);

  return (
    <div className="customNodeContainer">
      <div className="customNodeHeading">
        {TruncateText(updatedPointName, 20)}
      </div>
      <div className={`customInputNodeContent `}>
        <b>Present Value:</b> {presentValue}
      </div>
      {data.label.includes("Value") ||
      data.label.includes("Output") ||
      data.label.includes("Holding") ||
      data.label.includes("Status") ? (
        <>
          <Handle
            type="target"
            position={Position.Left}
            id="out"
            className="customHandle"
            isConnectable={data.isConnectable}
          />
          <Handle
            type="source"
            position={Position.Right}
            id="in"
            className="customHandle"
            isConnectable={data.isConnectable}
          />
        </>
      ) : (
        <Handle
          type="source"
          position={Position.Right}
          id="in"
          className="customHandle"
          isConnectable={data.isConnectable}
        />
      )}
    </div>
  );
};

export default CustomNode;
