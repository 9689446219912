/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../../../../common/styles/devicesStyle.css";

import DeviceBrowser from "./components/deviceBrowser.jsx";
import DeviceDetails from "./components/deviceDetails.jsx";
import DeviceNetwork from "./components/deviceNetwork.jsx";
import DeviceNetworkProperties from "./components/deviceNetworkProperties";
import PointDetails from "./components/pointDetails.jsx";
import DeviceNetworkForm from "./components/deviceNetworkForm";
import { useMenu } from "../../../../common/utils/menuContext";
import getPermissions from "../../../../configuration/localStorage/getPermissions";
import { useParams } from "react-router-dom";
import api from "../../../../common/utils/axiosRequest.js";

const Devices = () => {
  const { networkId, pointId, deviceId } = useParams();
  const [permissions] = useState(getPermissions);
  const { setReloadData, config } = useMenu();
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState([]);
  const [pointDetails, setPointDetails] = useState(false);
  const [networkDetails, setNetworkDetails] = useState(false);
  const dbUrl = config?.REACT_APP_POLLING_ENDPOINT;
  const [rows, setRows] = useState([]);
  const [filteredNetworks, setFilteredNetworks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [alarmHigh, setAlarmHigh] = useState(false);
  const [alarmLow, setAlarmLow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const getRouteData = async () => {
      if (networkId) {
        const network = {
          _id: networkId,
        };
        handleSelectedNetwork(network);
      } else if (deviceId) {
        const updatedDevice = {
          deviceDetails: {
            _id: deviceId,
          },
        };

        handleSelectedDevice(updatedDevice);
      } else if (pointId) {
        handleSelectedPoint({ pointId: pointId });
      }
    };
    getRouteData();
  }, [dbUrl, deviceId, networkId, pointId]);

  const fetchData = async () => {
    try {
      const database = "networks";
      const networkResponse = await api.get(`${dbUrl}/${database}/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      const networks = networkResponse.data.data.rows.map((row) => row.doc);
      setRows(networks);
      setFilteredNetworks(networks);
    } catch (error) {
      console.log("Error while fetching data from API:", error);
    }
  };
  const onSearch = async () => {
    if (search) {
      const networks = rows.filter((network) =>
        network.networkName.toLowerCase().includes(search.toLowerCase())
      );
      console.log(networks, search);
      setFilteredNetworks(networks);
    } else {
      fetchData();
    }
  };

  useEffect(() => {
    onSearch();
  }, [search]);

  const handleSelectedNetwork = async (network) => {
    try {
      const getNetwork = await api.get(`${dbUrl}/networks/${network._id}`);
      setSelectedNetwork(getNetwork.data.data);
      setNetworkDetails(true);
      setDeviceDetails(false);
      setPointDetails(false);
    } catch (error) {
      toast.error(`An error occurred while fetching network details: ${error}`);
    }
  };
  const handleSelectedDevice = async (device) => {
    setDeviceDetails(true);
    setNetworkDetails(false);
    setPointDetails(false);

    const documentId = device?.deviceDetails?._id;

    try {
      const getDevice = await api.get(`${dbUrl}/devices/${documentId}`);

      try {
        setSelectedDevice(getDevice.data.data);
      } catch (error) {
        console.error(`Unexpected Error Occurred: ${error}`);
      }
      setReloadData(true);
    } catch (error) {
      // toast.error(`Unexpected Error Occurred: ${error}`);
      console.error(`Unexpected Error Occurred: ${error}`);
      setReloadData(true);
    }
  };
  const handleSelectedPoint = async (point) => {
    setDeviceDetails(false);
    setNetworkDetails(false);
    setPointDetails(true);

    try {
      const pointResponse = await api.get(`${dbUrl}/points/${point.pointId}`);
      setSelectedPoint(pointResponse.data.data);
      const liveVal = pointResponse.data.data.pointPresentValue;
      if (
        liveVal >= parseInt(pointResponse?.data?.data?.alarmHighLimit) &&
        pointResponse?.data?.data?.alarmHighLimitEnable
      ) {
        setAlarmHigh(true);
        setAlarmLow(false);
      } else if (
        liveVal <= parseInt(pointResponse?.data?.data?.alarmLowLimit) &&
        pointResponse?.data?.data?.alarmLowLimitEnable
      ) {
        setAlarmLow(true);
        setAlarmHigh(false);
      } else {
        setAlarmHigh(false);
        setAlarmLow(false);
      }
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  const addNetwork = async (data) => {
    setLoading(true);
    try {
      if (data.networkType === "BacnetTCP") {
        const connect = await api.post(`${dbUrl}/configure_bacnet_tcp`, data);
        if (connect.status === 500) {
          toast.warning(connect.data.data.message);
          data["networkEnable"] = false;
          data["lastPollStatus"] = "Failed";
          data["networkStatus"] = "Inactive";
          try {
            const createNetwork = await api.post(`${dbUrl}/networks`, data);
            if (createNetwork.status === 200) {
              toast.success("Network Created Successfully");
              fetchData();
              setLoading(false);
              setReloadData(true);
            } else {
              setLoading(false);
              toast.error(createNetwork.data.data);
              fetchData();
              setLoading(false);
              setReloadData(true);
            }
          } catch (error) {
            setLoading(false);
            toast.error(`Error Occured : ${error}`);
          }
          setLoading(false);
        } else {
          try {
            const createNetwork = await api.post(`${dbUrl}/networks`, data);
            if (createNetwork.status === 200) {
              toast.success("Network Created Successfully");
              fetchData();
              setLoading(false);
              setReloadData(true);
            } else {
              setLoading(false);
              toast.error(createNetwork.data);
              fetchData();
              setLoading(false);
              setReloadData(true);
            }
          } catch (error) {
            setLoading(false);
            toast.error(`Error Occured : ${error}`);
          }
        }
      } else {
        const createNetwork = await api.post(`${dbUrl}/networks`, data);
        if (createNetwork.status === 200) {
          await api.post(`${dbUrl}/subscribe`, {
            topic: `${data.topic}/#`,
          });
          toast.success("Network Created Successfully");
          fetchData();
          setLoading(false);
          setReloadData(true);
        } else {
          setLoading(false);
          toast.error(createNetwork.data);
          fetchData();
          setLoading(false);
          setReloadData(true);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Error Occured : ${error}`);
    }
  };
  const handleOpen = () => {
    setIsFormOpen(true);
    setIsOverlayOpen(true);
  };
  const handleClose = () => {
    setIsFormOpen(false);
    setIsOverlayOpen(false);
  };
  return (
    <div className={`devices `}>
      <DeviceBrowser
        handleSelectedNetwork={handleSelectedNetwork}
        handleSelectedDevice={handleSelectedDevice}
        handleSelectedPoint={handleSelectedPoint}
        handleOpen={handleOpen}
        permissions={permissions}
        setIsOverlayOpen={setIsOverlayOpen}
        deviceProps={selectedDevice}
        setPointDetails={setPointDetails}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
      {networkDetails ? (
        <DeviceNetworkProperties
          networkProps={selectedNetwork}
          setSelectedNetwork={setSelectedNetwork}
          setDeviceNetworkProperty={setNetworkDetails}
          permissions={permissions}
        />
      ) : deviceDetails ? (
        <DeviceDetails
          networkProps={selectedNetwork}
          setSelectedDevice={setSelectedDevice}
          setDeviceDetails={setDeviceDetails}
          deviceProps={selectedDevice}
          permissions={permissions}
          setSelectedItem={setSelectedItem}
        />
      ) : pointDetails ? (
        <PointDetails
          networkProps={selectedNetwork}
          setPointDetails={setPointDetails}
          pointDetails={pointDetails}
          pointProps={selectedPoint}
          setSelectedPoint={setSelectedPoint}
          option={"Summary"}
          permissions={permissions}
          deviceProps={selectedDevice}
          alarmHigh={alarmHigh}
          alarmLow={alarmLow}
          setAlarmHigh={setAlarmHigh}
          setAlarmLow={setAlarmLow}
          setSelectedItem={setSelectedItem}
        />
      ) : (
        <DeviceNetwork
          handleOpen={handleOpen}
          loading={loading}
          fetchData={fetchData}
          filteredNetworks={filteredNetworks}
          permissions={permissions}
          setSelectedNetwork={setSelectedNetwork}
          networkProps={selectedNetwork}
          search={search}
          setSearch={setSearch}
        />
      )}
      {isOverlayOpen && (
        <div className="overlay" onClick={() => setIsOverlayOpen(false)}>
          {isFormOpen && (
            <DeviceNetworkForm
              isFormOpen={isFormOpen}
              setIsFormOpen={handleClose}
              addNetwork={addNetwork}
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Devices;
