import React from "react";
import "../../common/styles/customConfirmDeleteStyle.css";

const CustomConfirmDelete = ({
  handleDelete,
  message,
  note,
  setConfirmDelete,
}) => {
  return (
    <div className="preLoaderBackground">
      <div className="custom-dialog-style">
        <div className="customConfirmDeleteDialogContent">
          <p>{message}</p>
          <div className="customConfirmDeleteDialogContentNote">{note}</div>
        </div>
        <div className="customConfirmDeleteDialogBtns">
          <div
            className="outline-btn-style"
            onClick={() => setConfirmDelete(false)}
          >
            Cancel
          </div>
          <div className="filled-btn-style" onClick={handleDelete}>
            Confirm
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomConfirmDelete;
