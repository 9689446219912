import React from "react";
import "../../../../../common/styles/widgetsStyle.css";
import { useMenu } from "../../../../../common/utils/menuContext";
import { SlEnergy } from "react-icons/sl";

const WidgetsLibrary = ({ handleDragStartWidgetContainer }) => {
  const { widgetLibraryOpen, isMobile } = useMenu();

  const Dashboardwidgets = [
    {
      id: "energyTemplate",
      icon: SlEnergy,
      label: "Energy Template",
      category: "template",
      data: [],
    },
  ];

  return widgetLibraryOpen ? (
    <div className={`widgets container-style widgetsLibrary`}>
      <span className={`widgetsHeader `}>Templates Library</span>

      <div className="widgetsContent widgetsLibraryContent">
        {Dashboardwidgets?.map((widget, index) => (
          <div
            className={`widget widgetsLibraryWidget`}
            key={index}
            draggable
            onDragStart={(e) => handleDragStartWidgetContainer(e, widget)}
          >
            {React.createElement(widget.icon, {
              className: `widgetIcon`,
              size: isMobile ? 30 : 30,
              color: "var(--font-color)",
            })}
            <p>{widget.label}</p>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default WidgetsLibrary;
