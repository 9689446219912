import "../../../../../common/styles/trendComparatorConfigFormStyle.css";
import CustomButton from "../../../../reusableComponents/customButton";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import CustomFormSelect from "../../../../reusableComponents/customFormSelect";
import CustomFormMultiSelect from "../../../../reusableComponents/customFormMultiSelect";

const TrendComparatorConfigForm = ({
  options,
  handleSelectChange,
  selectedOptions,
  generateChart,
  valuesToShow,
  handleValuesToShowChange,
  trendName,
  handleComparatorSave,
  setTrendName,
  permissions,
  handleExportImage,
}) => {
  return (
    <div className="trendComparatorConfigForm">
      <div className="trendComparatorConfigFormGroup">
        <div className="trendComparatorConfigFormInputGroup">
          <CustomFormInput
            label="Trend Name"
            type="text"
            id="trendName"
            name="trendName"
            value={trendName}
            onChange={(e) => setTrendName(e.target.value)}
            placeholder={"Enter the Comparasion Name to Save"}
          />

          {/* <label className="trendComparatorConfigFormInputLabel">
            Select Points
          </label>
          <Select
            closeMenuOnSelect={selectedOptions.length === 4 ? true : false}
            components={animatedComponents}
            isMulti
            options={options}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            value={selectedOptions}
            placeholder="Select Max 10 Points"
            onChange={handleSelectChange}
            styles={{
              multiValue: (provided) => ({
                ...provided,
                maxWidth: "200px",
              }),
              multiValueLabel: (provided) => ({
                ...provided,
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }),
            }}
          /> */}
          <CustomFormMultiSelect
            id={"select multiple points"}
            label={"Select Points"}
            options={options}
            placeholder="Select Max 10 Points"
            selectedValues={selectedOptions}
            onChange={handleSelectChange}
            max={10}
          />
          <CustomFormSelect
            id="valuesToShow"
            name="valuesToShow"
            label={"Values To Show"}
            value={valuesToShow}
            onChange={handleValuesToShowChange}
            options={[
              {
                value: "today",
                label: "Today",
              },
              {
                value: "yesterday",
                label: "Yesterday",
              },
              {
                value: "thisWeek",
                label: "This Week",
              },
              {
                value: "lastWeek",
                label: "Last Week",
              },
              {
                value: "thisMonth",
                label: "This Month",
              },
              {
                value: "lastMonth",
                label: "Last Month",
              },
            ]}
          />
        </div>
      </div>
      <div className="trendComparatorConfigFormBtns">
        {permissions.trends.write && (
          <CustomButton
            className="filled-btn-style"
            buttonName="Save"
            handleClick={handleComparatorSave}
          />
        )}
        <CustomButton
          className="filled-btn-style"
          buttonName="Generate"
          handleClick={generateChart}
        />
        <CustomButton
          className="filled-btn-style"
          buttonName="Export Chart"
          handleClick={handleExportImage}
        />
      </div>
    </div>
  );
};

export default TrendComparatorConfigForm;
