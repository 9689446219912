import Cookies from "js-cookie";

function getPermissions() {
  let permissions = Cookies.get("permissions");
  if (permissions) {
    permissions = JSON.parse(permissions);
  } else {
    permissions = null;
  }
  return permissions;
}


export default getPermissions;