import React from "react";
import "../../common/styles/customFormInputStyle.css";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useState } from "react";

const CustomFormInput = ({
  type,
  id,
  label,
  placeholder,
  required,
  value,
  onChange,
  checked,
  disabled = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="customFormInput ">
      <label className="customFormLabel" htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div
        className={`customFormInputField input-style ${
          type === "checkbox" ? "checkbox" : ""
        }${isFocused && type !== "checkbox" ? "focused" : ""}`}
      >
        <input
          className={`customFormInputBox `}
          type={showPassword ? "text" : type ? type : "password"}
          name={id}
          value={value}
          onChange={onChange}
          id={id}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          checked={checked}
          style={{
            color:
              value === "Failed" || value === "Inactive"
                ? "red"
                : "var(--font-color)",
          }}
        />
        {type === "password" && (
          <div
            className="passwordToggle"
            onClick={() => {
              togglePasswordVisibility();
              setIsFocused(true);
            }}
          >
            {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomFormInput;
