import React, { useEffect, useState } from "react";

import "../../../../../common/styles/licenseSettingsStyle.css";
import { GoDotFill } from "react-icons/go";
import CustomHeader from "../../../../reusableComponents/customHeader";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";

const LicenseSettings = () => {
  const [mac, setMac] = useState("");
  const [license, setLicense] = useState();
  const { config } = useMenu();
  const serverEndpoint = config?.REACT_APP_USER_ENDPOINT;
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);

  useEffect(() => {
    const getMac = async () => {
      try {
        const response = await api.get(`${serverEndpoint}/get_mac`);
        const mac = await response.data.mac;
        const getLicense = await api.get(`${dbUrl}/license/license`);
        const license = getLicense.data.data.licenseKey;
        setLicense(license);
        setMac(mac);
      } catch (error) {
        console.error("Error fetching Mac Address:", error);
      }
    };

    getMac();
  }, [base64Credentials, dbUrl, serverEndpoint]);

  return (
    <div className={`licenseSettings `}>
      <CustomHeader heading={"License"} />
      <div className="licenseSettingContent container-style">
        <div className="licenseSettingsForm">
          <div className="licenseSettingsGroup">
            <p className="label">Instance ID :</p>
            <p className="data">{mac}</p>
          </div>
          <div className="licenseSettingsGroup">
            <p className="label">License Status :</p>
            <p className="data">
              <GoDotFill className="licenseSettingsStatusDot" size="20" />
              Active
            </p>
          </div>
          {/* <div className="licenseSettingsGroup">
            <p className="label">License Type :</p>
            <p className="data">Perpetual</p>
          </div> */}
          <div className="licenseSettingsGroup">
            <p className="label">License Validity :</p>
            <p className="data">3 Years</p>
          </div>
          <div className="licenseSettingsGroup">
            <p className="label">Maximum Edges :</p>
            <p className="data">5</p>
          </div>
          <div className="licenseSettingsGroup">
            <p className="label">License Key :</p>
            {license ? (
              <textarea
                className={`data licenseSettingsDetails `}
                name="licenseKey"
                id="licenseKey"
                cols="50"
                rows="10"
                disabled
              >
                {license}
              </textarea>
            ) : (
              <p className={`data licenseSettingsDetails `}>No License</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseSettings;
