import React, { useEffect, useState } from "react";
import "../../../../common/styles/reportsStyle.css";
import ReportsBrowser from "./components/reportsBrowser";
import ReportsCanvas from "./components/reportsCanvas";
import { useMenu } from "../../../../common/utils/menuContext";
import api from "../../../../common/utils/axiosRequest";

const Reports = () => {
  const { config } = useMenu();
  const [selectedReportTemplate, setSelectedReportTemplate] = useState(
    "Standard Alarm Report"
  );
  const [networks, setNetworks] = useState([]);
  const [devices, setDevices] = useState([]);
  const [points, setPoints] = useState([]);
  const [selectedReport, setSelectedReport] = useState();
  const [reportData, setReportData] = useState({
    _id: "",
    reportName: "",
    reportDesc: "",
    tags: "",
    interval: "1hour",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    filterEnable: false,
    reportTemplate: selectedReportTemplate,
    selectedNetworks: [],
    selectedPoints: [],
    selectedDevices: [],
    periodicReport: false,
    period: "Daily",
    timeOfReport: "",
    dayOfReport: "",
    weekOfReport: "1",
    monthOfReport: "jan",
    generatedReport: {},
  });
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  useEffect(() => {
    const fetchData = async () => {
      const networks = await api.get(`${dbUrl}/networks/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      const networkOptions = networks.data.data.rows.map((network) => ({
        value: network.doc._id,
        label: network.doc.networkName,
      }));
      setNetworks(networkOptions);

      const devices = await api.get(`${dbUrl}/devices/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      const deviceOptions = devices.data.data.rows.map((device) => ({
        value: `${device.doc.deviceInstance}`,
        label: `${device.doc.deviceName}-${device.doc.deviceInstance}`,
      }));
      setDevices(deviceOptions);

      const points = await api.get(`${dbUrl}/points/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      const pointOptions = points.data.data.rows.map((point) => ({
        value: point.doc._id,
        label: `${point.doc.pointName}-${point.doc.pointDeviceInstance}`,
      }));
      setPoints(pointOptions);
    };
    fetchData();
  }, [dbUrl]);

  const selectAllOption = { label: "Select All", value: "select_all" };

  const networkOptions = [
    selectAllOption,
    ...networks.map((network) => {
      return { label: network.label, value: network.value };
    }),
  ];
  const handleSelectChange = (selected, setSelectedKey, options) => {
    if (selected && selected.some((option) => option.value === "select_all")) {
      // Check if all options are already selected
      if (selected.length === options.length) {
        // Deselect all
        setReportData({
          ...reportData,
          [setSelectedKey]: [],
        });
      } else {
        // Select all
        setReportData({
          ...reportData,
          [setSelectedKey]: "select_all",
        });
      }
    } else {
      setReportData({
        ...reportData,
        [setSelectedKey]: selected
          ? selected.map((option) => option.value)
          : [],
      });
    }
  };

  const pointOptions = [
    selectAllOption,
    ...points.map((point) => {
      return { label: point.label, value: point.value };
    }),
  ];

  const deviceOptions = [
    selectAllOption,
    ...devices.map((device) => {
      return { label: device.label, value: device.value };
    }),
  ];

  return (
    <div className="reports">
      <ReportsBrowser
        selectedReportTemplate={selectedReportTemplate}
        setSelectedReportTemplate={setSelectedReportTemplate}
        setReportData={setReportData}
        reportData={reportData}
        selectedReport={selectedReport}
        setSelectedReport={setSelectedReport}
      />
      <div className="reportContent">
        <ReportsCanvas
          selectedReportTemplate={selectedReportTemplate}
          setSelectedReport={setSelectedReport}
          selectedReport={selectedReport}
          setReportData={setReportData}
          reportData={reportData}
          networkOptions={networkOptions}
          deviceOptions={deviceOptions}
          pointOptions={pointOptions}
          handleSelectChange={handleSelectChange}
        />
      </div>
    </div>
  );
};

export default Reports;
