/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload.map((entry) => (
      <div key={entry.dataKey}>
        <strong>{entry.name}:</strong> {entry.value}
      </div>
    ));

    return (
      <div
        style={{
          backgroundColor: "var(--tooltip-background-color)",
          border: "var(--container-border)",
          borderRadius: "15px",
          padding: "10px",
          color: "black",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <p
          style={{
            fontSize: "var(--heading-font-size)",
            color: "black",
            fontWeight: "bold",
          }}
        >
          {label}
        </p>
        {data}
      </div>
    );
  }

  return null;
};

const Composedchart = ({ chart }) => {
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [chartData, setChartData] = useState([]);
  // const [loading, setLoading] = useState(true);

  // LTTB Decimation Algorithm
  const lttb = (data, numPoints) => {
    const result = [];
    let a = 0;
    let b = data.length - 1;

    // Always include the first and last point
    result.push(data[a]);
    result.push(data[b]);

    for (let i = 1; i < numPoints - 1; i++) {
      const segmentStart = a;
      const segmentEnd = b;
      let maxArea = 0;
      let maxAreaPoint = a + 1;

      // Check the area of the triangle formed by each point and the endpoints
      for (let j = segmentStart + 1; j < segmentEnd; j++) {
        const area = Math.abs(
          (data[segmentStart].timestamp - data[segmentEnd].timestamp) *
            (data[j].presentValue - data[segmentStart].presentValue) -
            (data[segmentStart].timestamp - data[j].timestamp) *
              (data[segmentEnd].presentValue - data[segmentStart].presentValue)
        );
        if (area > maxArea) {
          maxArea = area;
          maxAreaPoint = j;
        }
      }

      // Add the point with the largest area to the result
      result.push(data[maxAreaPoint]);

      // Move to the next segment
      a = maxAreaPoint;
    }

    return result;
  };

  // Function to fetch data for devices and fill missing timestamps
  const fetchLiveDeviceData = async () => {
    try {
      const couchdbUrl = dbUrl;

      // Fetching the data for each point
      const fetchedData = await Promise.all(
        chart.data.points.map(async (point) => {
          const response = await api.get(`${couchdbUrl}/trends/${point.value}`);
          return response.data.data.trends;
        })
      );

      const processedData = [];
      const numPoints = 20; // Set the number of points for decimation
      const decimatedData = fetchedData.map((data) => lttb(data, numPoints)); // Apply LTTB to each dataset in fetchedData

      // Prepare the data in the desired format after decimation
      decimatedData[0].forEach((point, i) => {
        const timestampData = {
          name: point.timestamp || `Timestamp ${i + 1}`,
        };

        chart.data.points.forEach((chartPoint, index) => {
          const trend = decimatedData[index].find(
            (trendData) => trendData.timestamp === point.timestamp
          );
          timestampData[chartPoint?.label] = trend?.presentValue || 0;
        });

        processedData.push(timestampData);
      });

      setChartData(processedData);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchLiveDeviceData();
  }, [chart]);

  return (
    <div
      id="chartContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
        height: "92%",
        overflow: "scroll",
        margin: "auto",
      }}
    >
      <span className="chartLabel">
        {chart?.data?.title
          ? chart?.data?.title
          : chart?.data?.pointType === "trendVal"
          ? chart?.data?.points[0]?.label
          : "Label"}
      </span>

      {chartData.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={chartData}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" scale="band" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} /> {/* Use Custom Tooltip */}
            <Legend />
            <Area
              type="monotone"
              dataKey={chart?.data?.points[0]?.label}
              fill="var(--line-chart-fill-color)"
              stroke="var(--line-chart-stroke-color)"
            />
            <Bar
              dataKey={chart?.data?.points[1]?.label}
              barSize={15}
              fill="var(--primary-color)"
              radius={[3, 3, 3, 3]}
            />
            <Line
              type="monotone"
              dataKey={chart?.data?.points[2]?.label}
              stroke="var(--line-chart-stroke-color)"
            />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <p className="noData">No Data Available</p>
      )}
    </div>
  );
};

export default Composedchart;
