/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../../../../../common/styles/numericChartStyles.css";
import { useState } from "react";
import { useEffect } from "react";
import PreLoader from "../../../../../reusableComponents/preLoader";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const StateChart = ({ chart }) => {
  const { config } = useMenu();
  const [isLoading, setIsLoading] = useState(true);
  const [presentValue, setPresentValue] = useState();
  const [pointInfo, setPointInfo] = useState({});
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const fetchLiveDeviceData = async () => {
    setIsLoading(false);
    try {
      const database = chart.data.points[0].value;
      const getResponse = await api.get(`${dbUrl}/points/${database}`);
      const liveVal = getResponse.data.data.pointPresentValue;
      setPresentValue(liveVal?.toFixed(2));
      setPointInfo(getResponse.data.data);
    } catch (error) {
      console.error("Error fetching last uploaded record:", error.message);
    }
  };

  useEffect(() => {
    const intervalInSeconds = chart?.data?.pollFrequency;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchLiveDeviceData();
    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      setIsLoading(false);
    }
  }, [chart?.data]);
  return (
    <div className="numericWidget">
      {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null}
      <p className="numericWidgetTitle">
        {chart?.data?.title
          ? chart?.data?.title
          : pointInfo.pointName
          ? `${pointInfo.pointDeviceInstance}-${pointInfo.pointName}`
          : "Analog/Binary Point"}
      </p>
      <p>
        {(() => {
          const isBinaryOrDigital = [
            "binaryValue",
            "digitalValue",
            "digitalOutput",
            "binaryOutput",
            "binaryInput",
            "digitalInput",
            "CoilStatus",
            "InputStatus",
          ].some((type) => pointInfo.pointType?.includes(type));
          const isFalse = presentValue == 0 || presentValue == 0.0;
          const isTrue = presentValue == 1 || presentValue == 1.0;

          if (isBinaryOrDigital && isFalse) return "OFF";
          if (isBinaryOrDigital && isTrue) return "ON";
          return presentValue;
        })()}
        {pointInfo?.pointUnitSymbol ? pointInfo?.pointUnitSymbol : ""}
      </p>
    </div>
  );
};

export default StateChart;
