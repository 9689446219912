import React from "react";

import { useMenu } from "../../../../../common/utils/menuContext";
import "../../../../../common/styles/functionBlockStyle.css";

const FunctionBlock = ({ onDragOperatorStart }) => {
  const { functionBlockOpen } = useMenu();

  return functionBlockOpen ? (
    <div className={`functionBlock `}>
      <div className={`functionBlockHeader `}>Function Blocks</div>
      <div className="functionBlockContent">
        <div className="functionBlocks">
          <div
            className="functionBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "PID")}
          >
            PID
          </div>
          <div
            className="functionBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Compare")}
          >
            Compare
          </div>
          <div
            className="functionBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Count")}
          >
            Count
          </div>
          <div
            className="functionBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Limiter")}
          >
            Limiter
          </div>
          {/* <div
            className="functionBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Linearize")}
          >
            Linearize
          </div> */}
          <div
            className="functionBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Ramp")}
          >
            Ramp
          </div>
          <div
            className="functionBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "SRLatch")}
          >
            SRLatch
          </div>
          <div
            className="functionBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "TickTock")}
          >
            TickTock
          </div>
          {/* <div
            className="functionBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "UpDn")}
          >
            UpDn
          </div> */}
        </div>
      </div>
    </div>
  ) : null;
};

export default FunctionBlock;
