/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import MapComponent from "../../../prebuiltDisplay/presentation/components/charts/map";
import ImageWidget from "../../../display/presentation/components/charts/imageWidget";
import TextWidget from "../../../prebuiltDisplay/presentation/components/charts/textWidget";
import CommandWidget from "../../../prebuiltDisplay/presentation/components/charts/commandWidget";
import ButtonWidget from "../../../prebuiltDisplay/presentation/components/charts/buttonWidget";
import TableWidget from "../../../prebuiltDisplay/presentation/components/charts/tableWidget";
import GuageChart from "../../../prebuiltDisplay/presentation/components/charts/guageChart";
import BarCharts from "../../../prebuiltDisplay/presentation/components/charts/barChart";
import LineCharts from "../../../prebuiltDisplay/presentation/components/charts/lineChart";
import KPIChart from "../../../prebuiltDisplay/presentation/components/charts/kpiChart";
import GridLayout from "react-grid-layout";
import { v4 as uuidv4 } from "uuid";
import PieCharts from "../../../prebuiltDisplay/presentation/components/charts/pieChart";
import StateChart from "../../../prebuiltDisplay/presentation/components/charts/numericChart";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";

const DeviceTemplate = ({ deviceProps }) => {
  const { config } = useMenu();
  const [displayTemplates, setDisplayTemplates] = useState([]);
  const [selectedDisplay, setSelectedDisplay] = useState({});
  const [itemProperties, setItemProperties] = useState({});
  const [layout, setLayout] = useState([]);
  const [droppedItems, setDroppedItems] = useState([]);
  const selectedDevice = {
    id: deviceProps?.deviceInstance,
    name: deviceProps?.deviceName,
    parentId: deviceProps?._id,
  };
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [hovered, setHovered] = useState(false);
  const [hoveredPin, setHoveredPin] = useState({});
  const [draggingWidget, setDraggingWidget] = useState(null);
  const [draggedInsideImg, setDraggedInsideImg] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [selectedFloorWidget, setSelectedFloorWidget] = useState({});

  useEffect(() => {
    const getAllDisplayTemplates = async () => {
      try {
        const allDisplayTemplates = await api.get(
          `${dbUrl}/prebuiltdisplays/_all_docs`,
          {
            params: {
              include_docs: true,
            },
          }
        );
        const allDisplays = allDisplayTemplates.data.data.rows.map(
          (row) => row.doc
        );
        console.log(allDisplays);

        const matchingDisplays = allDisplays.filter((display) => {
          return display.devices.some(
            (device) => device.parentId === deviceProps?._id
          );
        });

        setDisplayTemplates(matchingDisplays);
        setSelectedDisplay(matchingDisplays[0]);
        handleLoadDashboard(matchingDisplays[0]);
      } catch (error) {
        console.log(error);
      }
    };
    getAllDisplayTemplates();
  }, [dbUrl, deviceProps?._id]);

  const handleLoadDashboard = async (dashboard) => {
    try {
      const getDashboard = await api.get(
        `${dbUrl}/prebuiltdisplays/${dashboard._id}`
      );

      const updatedItemProperties = {};
      getDashboard?.data.data?.layout?.forEach((item) => {
        updatedItemProperties[item.i] = {
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
        };
      });

      setItemProperties(updatedItemProperties);
      setLayout(getDashboard.data.data.layout);
      setDroppedItems(getDashboard.data.data.widgets);
      setWidgets(getDashboard.data.data.floorWidgets);
    } catch (error) {
      console.log(`Unexpected Error Occurred: ${error}`);
    }
  };
  const dimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  let cols = 12;
  let rowHeight = 35;

  if (dimensions.width > 1200) {
    cols = 16;
  } else if (dimensions.width > 768) {
    cols = 12;
  } else {
    cols = 8;
  }

  if (dimensions.height < 800) {
    rowHeight = 30;
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const widgetType = e.dataTransfer.getData("widgetType");
    if (!widgetType) return;

    const imageWidgetId = e.target.closest(".imageWidget").id;
    if (!imageWidgetId) return;

    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const id = `${widgetType}-${uuidv4()}`;

    setWidgets((prevWidgets) => {
      const newWidget = {
        id: id,
        type: widgetType,
        x,
        y,
        selectedPoint: "",
        pointName: "",
        pointValue: "",
        pointDescription: "",
        pointActive: "",
        color: e.dataTransfer.getData("color"),
        parentId: imageWidgetId,
      };

      return {
        ...prevWidgets,
        [imageWidgetId]: prevWidgets[imageWidgetId]
          ? [...prevWidgets[imageWidgetId], newWidget]
          : [newWidget],
      };
    });
  };

  const handleDragWidgetInsideCanvasStart = (e, widgetId, parentId) => {
    e.stopPropagation();
    setDraggedInsideImg(true);

    const parentWidgets = widgets[parentId];
    const widget = parentWidgets.find((w) => w.id === widgetId);

    setDraggingWidget({
      ...widget,
      startX: e.clientX,
      startY: e.clientY,
      parentId,
    });
  };

  const handleDragInsideCanvas = (e) => {
    if (!draggingWidget) return;
    const deltaX = e.clientX - draggingWidget.startX;
    const deltaY = e.clientY - draggingWidget.startY;
    setDraggingWidget({
      ...draggingWidget,
      x: draggingWidget.x + deltaX,
      y: draggingWidget.y + deltaY,
      startX: e.clientX,
      startY: e.clientY,
    });
  };

  const handleDragInsideCanvasEnd = (e) => {
    if (!draggingWidget) return;

    const { parentId, id, x, y } = draggingWidget;

    setWidgets((prevWidgets) => ({
      ...prevWidgets,
      [parentId]: prevWidgets[parentId].map((widget) =>
        widget.id === id ? { ...widget, x, y } : widget
      ),
    }));

    setDraggingWidget(null);
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Delete" && selectedFloorWidget) {
        const { id, parentId } = selectedFloorWidget;

        setWidgets((prevWidgets) => {
          const updatedWidgets = { ...prevWidgets };

          if (updatedWidgets[parentId]) {
            updatedWidgets[parentId] = updatedWidgets[parentId].filter(
              (widget) => widget.id !== id
            );
            if (updatedWidgets[parentId].length === 0) {
              delete updatedWidgets[parentId];
            }
          }
          return updatedWidgets;
        });

        setSelectedFloorWidget(null);
      }
    },
    [selectedFloorWidget]
  );

  const deleteWidget = (widget) => {
    if (widget) {
      const { id, parentId } = widget;
      setWidgets((prevWidgets) => {
        const updatedWidgets = { ...prevWidgets };

        if (updatedWidgets[parentId]) {
          updatedWidgets[parentId] = updatedWidgets[parentId].filter(
            (widget) => widget.id !== id
          );
          if (updatedWidgets[parentId].length === 0) {
            delete updatedWidgets[parentId];
          }
        }
        return updatedWidgets;
      });
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const handlePinHover = (e, selectedPin) => {
    e.preventDefault();
    setHovered(true);
    setHoveredPin(selectedPin);
  };
  return (
    <div className="deviceTemplate">
      <div class="custom-tabs">
        {displayTemplates.map((display, index) => (
          <span
            className={`${
              selectedDisplay._id === display._id
                ? "custom-tab-selected"
                : "custom-tab"
            }`}
            onClick={() => {
              setSelectedDisplay(display);
              handleLoadDashboard(display);
            }}
            key={display._id}
          >
            {display.name}
          </span>
        ))}
      </div>

      {selectedDisplay && (
        <div className="gridLayout">
          <GridLayout
            className="layout"
            layout={layout}
            cols={cols}
            rowHeight={rowHeight}
            width={1300}
            autoSize={true}
            style={{ width: "100%" }}
            isDraggable={false}
            isResizable={false}
          >
            {droppedItems.map((chart, index) => (
              <div
                key={chart.id}
                data-grid={{
                  x: itemProperties[chart.id]?.x || 0,
                  y: itemProperties[chart.id]?.y || 0,
                  w: itemProperties[chart.id]?.w || 7,
                  h: itemProperties[chart.id]?.h || 8,
                }}
                className={`droppedItem`}
              >
                {chart.id.includes("kpi") ? (
                  <KPIChart chart={chart} selectedDevice={selectedDevice} />
                ) : chart.id.includes("line") ? (
                  <LineCharts chart={chart} selectedDevice={selectedDevice} />
                ) : chart.id.includes("bar") ? (
                  <BarCharts chart={chart} selectedDevice={selectedDevice} />
                ) : chart.id.includes("gauge") ? (
                  <GuageChart chart={chart} selectedDevice={selectedDevice} />
                ) : chart.id.includes("pie") ? (
                  <PieCharts chart={chart} selectedDevice={selectedDevice} />
                ) : chart.id.includes("table") ? (
                  <TableWidget chart={chart} selectedDevice={selectedDevice} />
                ) : chart.id.includes("button") ? (
                  <ButtonWidget
                    chart={chart}
                    handleLoadDashboard={handleLoadDashboard}
                  />
                ) : chart.id.includes("numeric") ? (
                  <StateChart chart={chart} selectedDevice={selectedDevice} />
                ) : chart.id.includes("command") ? (
                  <CommandWidget
                    chart={chart}
                    selectedDevice={selectedDevice}
                  />
                ) : chart.id.includes("label") ? (
                  <TextWidget chart={chart} />
                ) : chart.id.includes("image") ? (
                  <ImageWidget
                    chart={chart}
                    handleDrop={handleDrop}
                    widgets={widgets}
                    hovered={hovered}
                    hoveredPin={hoveredPin}
                    onDrag={handleDragInsideCanvas}
                    onDragEnd={handleDragInsideCanvasEnd}
                    handleDragWidgetInsideCanvasStart={
                      handleDragWidgetInsideCanvasStart
                    }
                    deleteWidget={deleteWidget}
                    handleLoadDashboard={handleLoadDashboard}
                    handleDragOver={handleDragOver}
                    handleDragInsideCanvasEnd={handleDragInsideCanvasEnd}
                    handleDragInsideCanvas={handleDragInsideCanvas}
                    handlePinHover={handlePinHover}
                    setHovered={setHovered}
                    setHoveredPin={setHoveredPin}
                    setSelectedFloorWidget={setSelectedFloorWidget}
                    selectedFloorWidget={selectedFloorWidget}
                    view={"view"}
                  />
                ) : chart.id.includes("map") ? (
                  <MapComponent />
                ) : null}
              </div>
            ))}
          </GridLayout>
        </div>
      )}
    </div>
  );
};

export default DeviceTemplate;
