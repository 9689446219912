/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../../../../../common/styles/kpiChartStyle.css";
import { useState } from "react";
import { useEffect } from "react";
import PreLoader from "../../../../../reusableComponents/preLoader";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";
const KPIChart = ({ chart, selectedDevice }) => {
  // const [isLoading, setIsLoading] = useState(true);
  const [value1, setValue1] = useState([]);
  const [average, setAverage] = useState([]);
  const { config } = useMenu();
  const [errorValues, setErrorValues] = useState([]);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const fetchLiveDeviceData = async () => {
    try {
      const pointName = chart?.data?.points;
      const deviceId = selectedDevice?.id ? selectedDevice?.id : "";
      let pointId = "";
      const couchdbUrl = dbUrl;

      // Fetch the parent device data
      const parentDeviceResponse = await api.get(
        `${couchdbUrl}/devices/${selectedDevice.parentId}`
      );
      const parentDevice = parentDeviceResponse.data.data.devices.find(
        (deviceInstance) => deviceInstance.deviceInstance === parseInt(deviceId)
      );

      if (!parentDevice) {
        console.log("Device not found in parent device data");
        // setIsLoading(false);
        setValue1([]);
        return;
      }

      // Find the point ID
      const point = parentDevice.points.find((point) =>
        point.objectName.toLowerCase().includes(pointName.toLowerCase())
      );

      if (!point) {
        console.log("Point not found");
        // setIsLoading(false);
        setValue1([]);
        return;
      }

      pointId = point.pointId;

      // Fetch trend data
      const response = await api.get(`${couchdbUrl}/trends/trend-${pointId}`);
      if (response.data.status === 404) {
        // setIsLoading(false);
        setValue1([]);
        setErrorValues([]);
        return;
      }

      const allData = response.data.data.trends;
      let filteredData = [];

      if (chart?.data?.kpi === "1Day") {
        const currentDate = new Date().toISOString().split("T")[0];
        filteredData = allData.filter((doc) =>
          doc.timestamp.startsWith(currentDate)
        );
      } else if (chart?.data?.kpi === "1Week") {
        const endDate = new Date().toISOString().split("T")[0];
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        const updatedStartDate = startDate.toISOString().split("T")[0];
        filteredData = allData.filter(
          (doc) => doc.timestamp >= updatedStartDate && doc.timestamp <= endDate
        );
      } else if (chart?.data?.kpi === "custom") {
        filteredData = allData.slice(-100);
      }

      const getTimeStamps = filteredData.map((doc) => doc.timestamp);
      const presentValues = filteredData.map((doc) =>
        parseFloat(doc.presentValue.toFixed(2))
      );

      if (chart?.data?.kpi === "custom") {
        const errorValues = presentValues.map(
          (value) => chart?.data?.customKpiValue - value
        );
        setErrorValues(errorValues);
        setAverage(
          new Array(getTimeStamps.length).fill(chart?.data?.customKpiValue)
        );
      } else {
        const sum = presentValues.reduce((acc, value) => acc + value, 0);
        const average = sum / presentValues.length;
        const errorValues = presentValues.map((value) => average - value);
        setErrorValues(errorValues);
        setAverage(new Array(getTimeStamps.length).fill(average));
      }

      setValue1(presentValues);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setValue1([]);
      setErrorValues([]);
    }
    // setIsLoading(false); // Set loading to false at the end
  };

  useEffect(() => {
    const intervalInSeconds = 5;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchLiveDeviceData();

    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart.data]);
  const kpiValue = average[0] ? average[0] : 0;
  const formattedKpiValue = Number.isInteger(parseFloat(kpiValue))
    ? kpiValue
    : kpiValue.toFixed(2);
  return (
    <>
      {/* {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null} */}
      <div className="kpiChart">
        <div className="kpiChartHeading">
          {chart?.data?.title ? chart?.data?.title : "KPI Chart"}
        </div>
        <div className="kpiChartContent">
          <div className="kpiChartPresentValue">
            {value1[0] ? value1[0] : 0}
          </div>
          <div className="kpiChartKpiValue">KPI Value: {formattedKpiValue}</div>
          <div className="kpiChartErrorValue">
            Error Value: {errorValues[0] ? errorValues[0].toFixed(2) : 0}
          </div>
        </div>
      </div>
    </>
  );
};

export default KPIChart;
