/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import RequiredIndicator from "../../../../reusableComponents/requiredIndicator";

const ScheduleForm = ({
  setShowModal,
  newEventTitle,
  createNewEvent,
  setNewEventTitle,
  selectedTask,
  setSelectedTask,
  id,
  setSelectedDays,
  selectedDays,
  handleUpdateSchedule,
  setEventTimes,
  eventTimes,
}) => {
  const handleDayCheckboxChange = (day) => {
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      [day]: !prevSelectedDays[day],
    }));
  };
  const daysOfWeek = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ];
  useEffect(() => {
    if (!newEventTitle) {
      generateRandomId();
    }
    console.log(eventTimes);
  }, []);

  const generateRandomId = (length = 8) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";
    for (let i = 0; i < length; i++) {
      randomId += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setNewEventTitle(randomId);
  };
  function isoToHHMM(isoTime) {
    const date = new Date(isoTime);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  function constructDateTime(date, timeString) {
    const hours = parseInt(timeString.split(":")[0], 10);
    const minutes = parseInt(timeString.split(":")[1], 10);
    const newDate = new Date(date);
    newDate.setHours(hours, minutes);
    return newDate;
  }
  return (
    <div className="preLoaderBackground">
      <div className="custom-dialog-style">
        <div className="scheduleFormContent">
          <div className="scheduleFormContentGroup">
            <div className="scheduleFormContentLabelsGroup">
              <label className="custom-input-label">
                Select Backup Type <RequiredIndicator />
              </label>
              <label className="custom-input-label">Start Time</label>
              <label className="custom-input-label">End Time</label>
            </div>
            <div className="scheduleFormContentInputsGroup">
              <div className="input-style">
                <select
                  name="points"
                  id="points"
                  value={selectedTask}
                  onChange={(e) => setSelectedTask(e.target.value)}
                >
                  <option key="select" value="">
                    Select a Backup
                  </option>
                  <option key={"configDBBackup"} value={"configDbBackup"}>
                    Configuration & Data Backup
                  </option>
                  <option key={"configBackup"} value={"configBackup"}>
                    Configuration Backup
                  </option>
                  <option key={"DBBackup"} value={"dbBackup"}>
                    DB Backup
                  </option>
                </select>
                {/* <FaSearch
                  onClick={() => setSearch(true)}
                  className="scheduleFormSearchBtn"
                /> */}
              </div>
              <div className="input-style" style={{ width: "100%" }}>
                <input
                  type="time"
                  value={isoToHHMM(eventTimes.start)}
                  style={{ width: "95%" }}
                  onChange={(e) =>
                    setEventTimes({
                      ...eventTimes,
                      start: constructDateTime(
                        eventTimes.start,
                        e.target.value
                      ),
                    })
                  }
                />
              </div>
              <div className="input-style" style={{ width: "100%" }}>
                <input
                  style={{ width: "95%" }}
                  type="time"
                  value={isoToHHMM(eventTimes.end)}
                  onChange={(e) =>
                    setEventTimes({
                      ...eventTimes,
                      end: constructDateTime(eventTimes.start, e.target.value),
                    })
                  }
                />
              </div>
            </div>
          </div>
          {id.includes(newEventTitle) ? (
            <></>
          ) : (
            <div className="scheduleFormRepeatedEvent">
              <label className="custom-input-label">
                Repeat Events <RequiredIndicator />
              </label>
              <div className="dayCheckboxes">
                {daysOfWeek.map((day) => (
                  <label key={day.value} className="dayCheckbox">
                    <input
                      type="checkbox"
                      checked={selectedDays[day.value]}
                      onChange={() => handleDayCheckboxChange(day.value)}
                    />
                    {day.label}
                  </label>
                ))}
              </div>
            </div>
          )}

          <div className="scheduleFormContentGroupBtns">
            <div
              className="outline-btn-style"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </div>
            {id.includes(newEventTitle) ? (
              <div
                onClick={() => handleUpdateSchedule(newEventTitle)}
                className="filled-btn-style"
              >
                Update
              </div>
            ) : (
              <div
                onClick={() => createNewEvent()}
                className="filled-btn-style"
              >
                Create
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleForm;
