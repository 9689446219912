import React, { useState } from "react";
import CustomButton from "../../../../reusableComponents/customButton";
import { LuRefreshCw } from "react-icons/lu";
import { Tooltip } from "react-tooltip";

export default function AlarmTableFilter({
  source,
  setFilters,
  debouncedSetFilters,
  filters,
  fetchAlarms,
  ackBy,
}) {
  const [priority, setPriority] = useState("");
  const [ackStatus, setAckStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [acksBy, setAcksBy] = useState("");

  const handleReset = () => {
    setFilters({});
    setPriority("");
    setAckStatus("");
    setStartDate("");
    setEndDate("");
    setSelectedSource("");
    setAcksBy("");
  };

  return (
    <div className="alarmTableFilters">
      <div className="input-style">
        <select
          type="text"
          value={priority}
          onChange={(e) => {
            setPriority(e.target.value);
            setFilters({ ...filters, priority: e.target.value });
          }}
        >
          <option value="">Priority Filter</option>
          <option value="Critical">Critical</option>
          <option value="High">High</option>
          <option value="Low">Low</option>
        </select>
      </div>
      <div className="input-style">
        <select
          type="text"
          value={ackStatus}
          onChange={(e) => {
            setAckStatus(e.target.value);
            setFilters({ ...filters, ackStatus: e.target.value });
          }}
        >
          <option value="">Ack Status Filter</option>
          <option value="Acknowledged">Acknowledged</option>
          <option value="Unacknowledged">Unacknowledged</option>
        </select>
      </div>
      <span className="custom-input-label">From:</span>
      <div className="input-style">
        <input
          type="date"
          value={startDate}
          style={{ width: "95%" }}
          onChange={(e) => {
            setStartDate(e.target.value);
            setFilters({ ...filters, startDate: e.target.value });
          }}
        />
      </div>
      <span className="custom-input-label">To:</span>
      <div className="input-style">
        <input
          type="date"
          style={{ width: "95%" }}
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value);
            setFilters({ ...filters, endDate: e.target.value });
          }}
        />
      </div>
      <div className="input-style">
        <input
          type="text"
          placeholder="Search and Select Source..."
          value={selectedSource}
          style={{ width: "95%" }}
          onChange={(e) => {
            const inputText = e.target.value;
            setSelectedSource(inputText);
            const filteredOptions = Array.from(new Set(source)).filter(
              (sourceOption) =>
                sourceOption.toLowerCase().includes(inputText.toLowerCase())
            );
            if (filteredOptions.length === 1) {
              setSelectedSource(filteredOptions[0]);
              debouncedSetFilters(filteredOptions[0], "");
            }
          }}
          list="sourceOptions"
        />
        <datalist id="sourceOptions">
          {Array.from(new Set(source)).map((sourceOption, index) => (
            <option key={index} value={sourceOption} />
          ))}
        </datalist>
      </div>
      <div className="input-style">
        <input
          type="text"
          placeholder="Select Users"
          value={acksBy}
          onChange={(e) => {
            const inputText = e.target.value;
            setAcksBy(inputText);
            const filteredOptions = Array.from(new Set(ackBy)).filter(
              (option) =>
                option?.toLowerCase()?.includes(inputText.toLowerCase())
            );
            if (filteredOptions.length === 1) {
              setAcksBy(filteredOptions[0]);
              debouncedSetFilters("", filteredOptions[0]);
            }
          }}
          list="ackByOptions"
        />
        <datalist id="ackByOptions">
          {Array.from(new Set(ackBy)).map((sourceOption, index) => (
            <option key={index} value={sourceOption} />
          ))}
        </datalist>
      </div>
      <CustomButton
        buttonName="Reset"
        className="filled-btn-style"
        handleClick={handleReset}
      />
      <div
        className="outline-btn-style"
        onClick={() => fetchAlarms()}
        data-tooltip-id="alarmRefresh"
        data-tooltip-content="Refresh"
        style={{
          display: "flex",
          margin: "auto",
        }}
      >
        <LuRefreshCw size={15} />
      </div>
      <Tooltip place="bottom" className="tooltipStyle" id="alarmRefresh" />
    </div>
  );
}
