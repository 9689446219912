import React from "react";
import { FaSave } from "react-icons/fa";
import { TbGitBranchDeleted } from "react-icons/tb";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  Panel,
  ReactFlowProvider,
} from "reactflow";
import "reactflow/dist/style.css";
import "../../../../../common/styles/ruleCanvasStyle.css";

import ContextMenu from "../nodes/contextMenu";
import TruncateText from "../../../../../common/utils/truncateText";
import { Tooltip } from "react-tooltip";
import { FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const RuleCanvas = ({
  nodes,
  edges,
  edgeOptions,
  onNodesChange,
  onEdgesChange,
  onConnect,
  onInit,
  nodeTypes,
  onDrop,
  onDragOver,
  connectionLineStyle,
  openSavePopup,
  clearNodes,
  onPaneClick,
  onNodeContextMenu,
  reactFlowWrapper,
  contextMenu,
  nodeCount,
  onNodeDoubleClick,
  closeContextMenu,
  permissions,
  saveValue,
  setSaveValue,
  selectedRule,
  onEdgeContextMenu,
  onSave,
  logicsOnOff,
  ruleState,
}) => {
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 52,
    height: 34,
    padding: 7,
    marginLeft: 0,
    marginRight: 0,
    "& .MuiSwitch-switchBase": {
      marginTop: 5,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb": {
          backgroundColor: "var(--light-green-color)", // Green color when on
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "white",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "red",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "white",
      borderRadius: 20 / 2,
    },
  }));

  return (
    <div
      className={`ruleCanvas `}
      ref={reactFlowWrapper}
      onDrop={onDrop}
      onDragOver={onDragOver}
    >
      <div className="ruleCanvasTopOptions">
        <div className="ruleCanvasRuleName">
          {permissions.rules.write ? (
            <input
              type="text"
              value={TruncateText(saveValue, 50)}
              placeholder="Untitled Rule"
              onChange={(e) => setSaveValue(e.target.value)}
              onBlur={onSave}
              data-tooltip-id="ruleToolTip"
              data-tooltip-content="Rename"
            />
          ) : (
            <p>{TruncateText(saveValue ? saveValue : "Untitled Rule", 50)}</p>
          )}
        </div>

        {selectedRule && permissions.rules.write && (
          <div className="ruleCanvasSwitch">
            <p>
              OFF
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    sx={{ m: 0 }}
                    checked={ruleState ? true : false}
                    onChange={(e) => logicsOnOff(e.target.checked)}
                  />
                }
              />
              ON
            </p>
          </div>
        )}
      </div>
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          defaultEdgeOptions={edgeOptions}
          onNodesChange={permissions.rules.write ? onNodesChange : null}
          onEdgesChange={permissions.rules.write ? onEdgesChange : null}
          onConnect={onConnect}
          onInit={onInit}
          nodeTypes={nodeTypes}
          onDrop={onDrop}
          onDragOver={onDragOver}
          connectionLineStyle={connectionLineStyle}
          // connectionLineType="smoothstep"
          minZoom={0.5}
          maxZoom={1.3}
          onPaneClick={onPaneClick}
          onNodeContextMenu={onNodeContextMenu}
          onEdgeContextMenu={onEdgeContextMenu}
          onNodeDoubleClick={onNodeDoubleClick}
          fitView
          panningEnabled={false}
          deleteKeyCode="Delete"
        >
          <MiniMap zoomable pannable position="top-right" className="minimap" />
          <Panel>
            {permissions.rules.write && (
              <>
                <div
                  className={`ruleCanvasSaveBtn `}
                  data-tooltip-id="ruleCanvasToolTip"
                  data-tooltip-content="Save Rule"
                  onClick={(e) => {
                    saveValue || selectedRule ? onSave() : openSavePopup();
                  }}
                >
                  <FaSave />
                </div>
                <div
                  className={`ruleCanvasClearNodesBtn `}
                  data-tooltip-id="ruleCanvasToolTip"
                  data-tooltip-content="Clear All Nodes"
                  onClick={(e) => {
                    clearNodes();
                  }}
                >
                  <TbGitBranchDeleted />
                </div>
              </>
            )}
          </Panel>
          <Controls
            position="top-left"
            className="ruleCanvasControls"
          ></Controls>
          <Background
            variant="line"
            gap={20}
            size={1}
            style={{ opacity: "0.1" }}
            color={`"var(--dark-secondary-color)"}`}
          />
          {contextMenu && (
            <ContextMenu
              id={contextMenu.id}
              name={contextMenu.name}
              top={contextMenu.top}
              left={contextMenu.left}
              nodeCount={nodeCount}
              onClick={closeContextMenu}
            />
          )}
        </ReactFlow>
      </ReactFlowProvider>
      <Tooltip place="right" className="tooltipStyle" id="ruleCanvasToolTip" />
      <Tooltip place="bottom" className="tooltipStyle" id="ruleToolTip" />
    </div>
  );
};

export default RuleCanvas;
