import React from "react";
import "../../../../../../common/styles/textWidgetStyle.css";

const TextWidget = ({ chart }) => {
  return (
    <div className="textWidget">
      {chart?.data?.title ? chart?.data?.title : "Label"}
    </div>
  );
};

export default TextWidget;
