/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import "../../../../common/styles/edgeStatisticsStyle.css";
import CPU from "../../../../common/svg/cpu-util.svg";
import RAM from "../../../../common/svg/memory-util.svg";
import DISK from "../../../../common/svg/disk-util.svg";
import TruncateText from "../../../../common/utils/truncateText";
import { Link } from "react-router-dom";
import MapComponent from "../../display/presentation/components/charts/map";
import { useMenu } from "../../../../common/utils/menuContext";
import api from "../../../../common/utils/axiosRequest";

const EdgeStatistics = () => {
  const { config } = useMenu();
  const serverEndpoint = config?.REACT_APP_SERVER_ENDPOINT;
  const [data, setData] = useState([]);
  const [activeNetworkCount, setActiveNetworkCount] = useState(0);
  const [inactiveNetworkCount, setInactiveNetworkCount] = useState(0);
  const [activeDeviceCount, setActiveDeviceCount] = useState(0);
  const [inactiveDeviceCount, setInactiveDeviceCount] = useState(0);
  const [activeDevicePercent, setActiveDevicePercent] = useState();
  const [count, setCount] = useState({
    points: 0,
    trends: 0,
    alarms: 0,
    networks: 0,
    devices: 0,
    schedules: 0,
    displays: 0,
    reports: 0,
  });
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const fetchStatistics = async () => {
    try {
      const data = await api.get(`${serverEndpoint}/fetch_hardware_statistics`);
      setData(data.data);
    } catch (error) {
      console.error("Error fetching hardware statistics:", error);
    }
  };
  const fetchParameters = async () => {
    try {
      const dbs = [
        "points",
        "trends",
        "alarms",
        "networks",
        "devices",
        "schedules",
        "displays",
        "reports",
      ];
      for (const db of dbs) {
        const fetchCount = await api.get(`${dbUrl}/${db}/_all_docs`, {
          params: {
            include_docs: true,
          },
        });
        setCount((prev) => ({
          ...prev,
          [db]: fetchCount.data.data.rows.length,
        }));
      }
    } catch (error) {
      console.error("Error fetching parameters:", error);
    }
  };

  useMemo(() => fetchParameters(), []);
  useEffect(() => {
    const networks = async () => {
      try {
        const fetchNetworks = await api.get(`${dbUrl}/networks/_all_docs`, {
          params: {
            include_docs: true,
          },
        });

        const networks = fetchNetworks.data.data.rows.map((row) => row.doc);
        const activeNetworks = networks.filter(
          (network) => network.networkStatus === "Active"
        );

        setActiveNetworkCount(activeNetworks.length);
        setInactiveNetworkCount(networks.length - activeNetworks.length);
      } catch (error) {
        console.error(`Unexpected Error Occurred: ${error}`);
      }
    };
    const devices = async () => {
      try {
        const fetchDevices = await api.get(`${dbUrl}/devices/_all_docs`, {
          params: {
            include_docs: true,
          },
        });

        const devices = fetchDevices.data.data.rows.map((row) => row.doc);
        const activeDevices = devices.filter(
          (device) => device.deviceStatus === "Active"
        );

        setActiveDeviceCount(activeDevices.length);
        setInactiveDeviceCount(devices.length - activeDevices.length);

        setActiveDevicePercent((activeDevices.length / devices.length) * 100);
      } catch (error) {
        console.error(`Unexpected Error Occurred: ${error}`);
      }
    };

    networks();
    devices();
    fetchStatistics();
  }, [dbUrl]);
  return (
    <div className="edgeStatistics">
      <div className="edgeStatisticsLeft">
        <div className="statisticsContainer">
          <span className="statisticsHeading">
            Device & Hardware Statistics
          </span>
          <div className="topStatisticsContainer">
            <div className="deviceStatistics">
              <div className="deviceStatisticsContainer">
                <div className="deviceStatisticsCard container-style">
                  <div className="deviceStatisticsCardLeftContent">
                    <span className="deviceStatisticsCardHeading">
                      Device Status
                    </span>
                    <div className="deviceStatisticsCardsStatusContainer">
                      <div className="deviceStatisticsStatus">
                        <span className="deviceStatisticsStatusDot active"></span>
                        <span>
                          {activeDeviceCount ? activeDeviceCount : 0} Active
                        </span>
                      </div>
                      <div className="deviceStatisticsStatus">
                        <span className="deviceStatisticsStatusDot inactive"></span>
                        <span>
                          {inactiveDeviceCount ? inactiveDeviceCount : 0}{" "}
                          Inactive
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="deviceStatisticsCardRightContent">
                    <span className="deviceStatusPercentage">
                      {isNaN(activeDevicePercent)
                        ? 0
                        : Math.round(activeDevicePercent)}
                      %
                    </span>
                    <span className="deviceStatusText">Active</span>
                  </div>
                </div>
                <div className="deviceStatisticsCard container-style">
                  <div className="deviceStatisticsCardLeftContent">
                    <span className="deviceStatisticsCardHeading">
                      Network Status
                    </span>
                    <div className="deviceStatisticsCardsStatusContainer">
                      <div className="deviceStatisticsStatus">
                        <span className="deviceStatisticsStatusDot active"></span>
                        <span>
                          {activeNetworkCount ? activeNetworkCount : 0} Active
                        </span>
                      </div>
                      <div className="deviceStatisticsStatus">
                        <span className="deviceStatisticsStatusDot inactive"></span>
                        <span style={{ whiteSpace: "nowrap" }}>
                          {inactiveNetworkCount ? inactiveNetworkCount : 0}{" "}
                          Inactive
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="deviceStatisticsCardRightContent">
                    <span className="deviceStatusPercentage">
                      {isNaN(activeDevicePercent)
                        ? 0
                        : Math.round(activeDevicePercent)}
                      %
                    </span>

                    <span className="deviceStatusText">Active</span>
                  </div>
                </div>
              </div>
              <div className="configurationStatisticsContainer">
                <div className="configurationStatisticsCards">
                  <div className="divider">
                    <Link className="edgeLink" to="/alarms">
                      <div className="configurationStatisticsCard container-style">
                        <div className="configurationContent">
                          <span className="configurationHeading">Alarms</span>
                          <span className="configurationCount">
                            {count?.alarms}
                          </span>
                        </div>
                      </div>
                    </Link>
                    <Link className="edgeLink" to="/trends">
                      <div className="configurationStatisticsCard container-style">
                        <div className="configurationContent">
                          <span className="configurationHeading">Trends</span>
                          <span className="configurationCount">
                            {count?.trends}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="divider">
                    <Link className="edgeLink" to="/schedule">
                      <div className="configurationStatisticsCard container-style">
                        <div className="configurationContent">
                          <span className="configurationHeading">
                            Schedules
                          </span>
                          <span className="configurationCount">
                            {count?.schedules}
                          </span>
                        </div>
                      </div>
                    </Link>
                    <Link className="edgeLink" to="/reports">
                      <div className="configurationStatisticsCard container-style">
                        <div className="configurationContent">
                          <span className="configurationHeading">Reports</span>
                          <span className="configurationCount">
                            {count?.reports}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hardwareStatisticsContainer">
          {/* <span className="statisticsHeading">Hardware Statistics</span> */}
          <div className="hardwareStatisticsCardsContainer">
            <div className="hardwareStatisticsCard container-style">
              <div className="hardwareCardHeading">
                <span className="hardwareCardHeadingIcon">
                  <img src={CPU} alt="cpu" />
                </span>
                <span className="hardwareCardHeadingText">CPU Utilization</span>
              </div>
              <div className="hardwareCardContent">
                <div
                  className="hardwareContentPercentage"
                  style={{ height: `${data?.cpu_usage}%` }}
                >
                  <div className="utilizationPercentage">
                    {data?.cpu_usage}%
                  </div>
                  <span className="utilizationText">
                    OS: {TruncateText(data.os_info, 10)}
                  </span>
                </div>
              </div>
            </div>

            <div className="hardwareStatisticsCard container-style">
              <div className="hardwareCardHeading">
                <span className="hardwareCardHeadingIcon">
                  <img src={RAM} alt="ram" />
                </span>
                <span className="hardwareCardHeadingText">Memory Usage</span>
              </div>
              <div className="hardwareCardContent memory">
                <div
                  className="hardwareContentPercentage memory"
                  style={{ height: `${data?.memory_usage}%` }}
                >
                  <div className="utilizationPercentage">
                    {data?.memory_usage}%
                  </div>
                  <span className="utilizationText">
                    {data?.running_processes} Running Processes
                  </span>
                </div>
              </div>
            </div>

            <div className="hardwareStatisticsCard container-style">
              <div className="hardwareCardHeading">
                <span className="hardwareCardHeadingIcon">
                  <img src={DISK} alt="disk" />
                </span>
                <span className="hardwareCardHeadingText">Disk Usage</span>
              </div>
              <div className="hardwareCardContent">
                <div
                  className="hardwareContentPercentage zero"
                  style={{ height: `${data?.disk_usage}%` }}
                >
                  <div className="utilizationPercentage">
                    {data?.disk_usage}%
                  </div>
                  <span className="utilizationText">
                    CPU Arch {data.cpu_arch}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="edgeStatisticsRight">
        <div className="mapcomponent">
          <MapComponent />
        </div>
      </div>
    </div>
  );
};

export default EdgeStatistics;
