import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TruncateText from "../../../../../common/utils/truncateText";

export default function Report({ rows, columns }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer style={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow style={{ position: "sticky", top: 0 }}>
              {columns.map((column) => (
                <TableCell
                key={column.id}
                align="center"
                style={{
                  minWidth: column.minWidth,
                  backgroundColor: "var(--background-color-violet)",
                  color: "var(--white-color)",
                  whiteSpace: "nowrap",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "sticky", // Use sticky instead of fixed
                    left: "1px",
                    top: 0, // Adjust this to make it stick at a specific scroll position
                  }}
                >
                  {column.label}
                </div>
              </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
  {rows
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row, rowIndex) => (
      <TableRow key={rowIndex} hover>
        {columns.map((column) => {
          let value;
          if (column.id === "message") {
            value = row[column.id] ? row[column.id] : "";
          } else if (column.id === "eventDescription") {
            value = row["highLimitMessage"]
              ? row["highLimitMessage"]
              : row["lowLimitMessage"];
          } else if (!row.hasOwnProperty(column.id)) {
            value = "Pending";
          } else {
            value = row[column.id];
          }

          return (
            <TableCell
              key={column.id}
              align={column.id === "eventDescription" ? "left" : "center"}
            >
              {value !== undefined ? TruncateText(value,20) : "N/A"}
            </TableCell>
          );
        })}
      </TableRow>
    ))}
</TableBody>

        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
