/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import PreLoader from "../../../../../reusableComponents/preLoader";
import "../../../../../../common/styles/lineChartStyle.css";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const LineCharts = ({ chart, selectedDevice }) => {
  const { config } = useMenu();
  // const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [yAxisDomain, setYAxisDomain] = useState([0, 0]);

  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const fetchLiveDeviceData = async () => {
    // setIsLoading(true);
    try {
      const couchdbUrl = dbUrl;
      const pointName = chart?.data?.points;

      // Fetch parent device data
      const parentDeviceResponse = await api.get(
        `${couchdbUrl}/devices/${selectedDevice.parentId}`,
        {
          params: {
            points: true,
          },
        }
      );

      const point = parentDeviceResponse.data.data.points.find((point) =>
        point.objectName.toLowerCase().includes(pointName.toLowerCase())
      );

      if (!point) {
        console.log("Point not found");
        // setIsLoading(false);
        setChartData([]);
        return;
      }

      const pointId = point.pointId;

      if (chart?.data?.pointType === "currentVal") {
        const response = await api.get(`${couchdbUrl}/points/${pointId}`);
        const data = {
          name: response.data.data.pointName,
          value: response.data.data.pointPresentValue,
        };
        setChartData([data]);
        setYAxisDomain([Math.min(data.value) - 5, Math.max(data.value) + 5]);
      } else if (chart?.data?.pointType === "trendVal") {
        let filteredData = [];
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const response = await api.get(`${couchdbUrl}/trends/trend-${pointId}`);

        if (response.data.status === 404) {
          setChartData([]);
        } else {
          if (chart?.data?.dateRange === "1Day") {
            const currentDateString = new Date().toISOString().split("T")[0];
            filteredData = response.data.data.trends.filter((doc) =>
              doc.timestamp.startsWith(currentDateString)
            );
          } else if (chart?.data?.dateRange === "2Day") {
            const twoDaysAgo = new Date(currentDate);
            twoDaysAgo.setDate(currentDate.getDate() - 2);
            filteredData = response.data.data.trends.filter(
              (doc) => new Date(doc.timestamp) >= twoDaysAgo
            );
          } else if (chart?.data?.dateRange === "custom") {
            const startDate = new Date(chart.data.min);
            const endDate = new Date(chart.data.max);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            filteredData = response.data.data.trends.filter((doc) => {
              const timestamp = new Date(doc.timestamp);
              return timestamp >= startDate && timestamp <= endDate;
            });
          }
          filteredData = filteredData.slice(-100);

          const data = filteredData.map((doc) => ({
            name: doc.timestamp,
            value: parseFloat(doc.presentValue.toFixed(2)),
          }));

          setChartData(data);
          setYAxisDomain([
            Math.min(...data.map((d) => d.value)),
            Math.max(...data.map((d) => d.value)) + 0.5,
          ]);
        }
      }

      // setIsLoading(false);
    } catch (error) {
      setChartData([]);
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    const intervalInSeconds = 5;
    const intervalInMilliseconds = intervalInSeconds * 1000;

    fetchLiveDeviceData();

    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => clearInterval(fetchDataInterval);
    }
  }, [chart.data]);

  return (
    <>
      {/* {isLoading && <PreLoader size={80} color="var(--primary-color)" />} */}
      <div
        id="chartContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
          overflow: "scroll",
          height: "92%",
          margin: "auto",
        }}
      >
        <span className="chartLabel">
          {chart?.data?.title
            ? chart?.data?.title
            : chart?.data?.pointType === "trendVal"
            ? chart?.data?.points[0]?.label
            : "Label"}
        </span>
        {chartData.length > 0 ? (
          <ResponsiveContainer width="100%" height={"100%"}>
            <AreaChart
              width={600}
              height={300}
              data={chartData}
              className="lineChart"
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Area
                type="monotone"
                dataKey="value"
                stroke="var(--line-chart-stroke-color)"
                strokeWidth={2}
                fill="var(--line-chart-fill-color)"
              />

              <XAxis
                dataKey="name"
                tickFormatter={(name) =>
                  name.length > 10 ? `${name.substring(0, 10)}...` : name
                }
                stroke="#8884d8"
                tickLine={false}
                axisLine={{ stroke: "var(--font-color)", strokeWidth: 1.5 }}
                tick={{ fontSize: 12, fill: "var(--font-color)" }}
              />
              <YAxis
                domain={yAxisDomain}
                stroke="#8884d8"
                tickLine={false}
                axisLine={{ stroke: "var(--font-color)", strokeWidth: 1.5 }}
                tick={{ fontSize: 12, fill: "var(--font-color)" }}
              />

              <Tooltip
                contentStyle={{
                  backgroundColor: "var(--tooltip-background-color)",
                  border: "none",
                  borderRadius: "10px",
                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                }}
                labelStyle={{
                  fontSize: "var(--heading-font-size)",
                  color: "var(--black-color)",
                  fontWeight: "bold",
                }}
                itemStyle={{
                  color: "var(--black-color)",
                  fontWeight: "bold",
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <p className="noData">No Data Available</p>
        )}
      </div>
    </>
  );
};

export default LineCharts;
