import React from "react";
import "../../common/styles/customFormInputStyle.css";

const CustomFormSelect = ({
  id,
  label,
  required,
  value,
  onChange,
  options,
  disabled = false,
}) => {
  return (
    <div className="customFormInput">
      <label className="customFormLabel" htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div className="customFormInputField input-style">
        <select
          className="customFormInputBox"
          name={id}
          id={id}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CustomFormSelect;
