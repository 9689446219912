/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import CustomButton from "../../../../reusableComponents/customButton";
import formatCurrentDateTime from "../../../../../common/utils/formattedDateTime";
import "../../../../../common/styles/deviceNetworkFormStyle.css";
import { toast } from "react-toastify";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import CustomFormSelect from "../../../../reusableComponents/customFormSelect";
import { isEmpty } from "../../../../../common/utils/isEmpty";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";

const DeviceNetworkForm = ({
  isFormOpen,
  setIsFormOpen,
  addNetwork,
  onClick,
}) => {
  const { config } = useMenu();
  const [networkType, setNetworkType] = useState("");

  const [networkName, setNetworkName] = useState("Bacsys Server");

  const [bacnetNetworkName, setBacnetNetworkName] = useState("Bacnet Network");

  const [networkDesc, setNetworkDesc] = useState("This is a MQTT Network");

  const [networkPort, setNetworkPort] = useState("8883");

  const [bacnetNetworkPort, setBacnetNetworkPort] = useState("47808");

  const [bacnetNetworkDesc, setBacnetNetworkDesc] = useState(
    "This is a Bacnet Network"
  );

  const [networkInstance, setNetworkInstance] = useState("1");

  const [networkAddress, setNetworkAddress] = useState("");

  const [networkSubnet, setNetworkSubnet] = useState("");

  const [networkGateway, setNetworkGateway] = useState("");

  const [networkApdu, setNetworkApdu] = useState("6000");
  const [networkTimeOut, setNetworkTimeOut] = useState("3000");
  const [networkRetry, setNetworkRetry] = useState("3");
  const [selectedOption, setSelectedOption] = useState("Apex");

  const [topics, setTopics] = useState([]);
  const [topic, setTopic] = useState();
  const [_id, setId] = useState("NET0");

  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const serverEndpoint = config?.REACT_APP_POLLING_ENDPOINT;

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setIsFormOpen(false);
    }
  };
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await api.get(`${dbUrl}/topics/topic`);
        if (response.status === 200 || response.status === 201) {
          const topics = response.data.data.availableTopics;
          const topicNames = topics.map((topic) => topic);
          setTopics(topicNames);
          setTopic(topicNames[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (networkType === "MQTT") {
      fetchTopics();
    }
  }, [networkType]);

  const fetchNetworkId = async () => {
    try {
      const networks = await api.get(`${dbUrl}/networks/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      let newNetworkId;
      console.log(
        networks.data.data.rows[networks.data.data.rows.length - 1].doc._id
      );
      if (networks.data.data.rows.length === 0) {
        newNetworkId = "NET0";
      } else {
        const lastNetworkId =
          networks.data.data.rows[networks.data.data.rows.length - 1].doc._id;
        const lastIdNumber = parseInt(lastNetworkId.slice(3));
        if (isNaN(lastIdNumber)) {
          newNetworkId = "NET0";
        } else {
          newNetworkId = `NET${lastIdNumber + 1}`;
        }
      }
      setId(newNetworkId);
    } catch (error) {
      console.error("Error fetching network IDs:", error);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    try {
      api
        .get(`${serverEndpoint}/local-ip`)
        .then((response) => {
          const ip = response.data.local_ip;
          const subnet_mask = response.data.subnet_mask;
          const gateway = response.data.gateway;
          setNetworkAddress(ip);
          setNetworkSubnet(subnet_mask);
          setNetworkGateway(gateway);
        })
        .catch((error) => {
          console.error("Error fetching local IP:", error);
        });
      fetchNetworkId();
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  }, [setIsFormOpen, networkType]);

  const handleSave = () => {
    if (networkType === "MQTT") {
      if (
        !isEmpty(
          networkName,
          networkAddress,
          networkInstance,
          networkType,
          networkPort,
          topic
        )
      ) {
        const newNetwork = {
          _id,
          networkName,
          networkPort,
          networkDesc,
          networkStatus: "Active",
          networkEnable: true,
          lastPollStatus: "Success",
          lastPollTime: formatCurrentDateTime(),
          networkProtocol: "Message Queuing Telemetry Transport",
          networkType,
          networkInstance,
          networkAddress,
          networkSubnet,
          topic,
          isVirtual: selectedOption === "Apex" ? false : true,
        };
        addNetwork(newNetwork);
        setIsFormOpen((prev) => !prev);
      } else {
        toast.warning("Please fill the mandatory Fields");
      }
    } else if (networkType === "BacnetTCP") {
      if (
        !isEmpty(
          bacnetNetworkName,
          networkAddress,
          networkApdu,
          networkInstance,
          networkType,
          bacnetNetworkPort,
          networkTimeOut,
          networkRetry
        )
      ) {
        const newNetwork = {
          _id,
          networkName: bacnetNetworkName,
          networkPort: bacnetNetworkPort,
          networkDesc: bacnetNetworkDesc,
          networkStatus: "Active",
          networkEnable: true,
          lastPollStatus: "Success",
          lastPollTime: formatCurrentDateTime(),
          networkProtocol: "Bacnet",
          networkType,
          networkInstance,
          networkAddress,
          networkSubnet,
          networkGateway,
          networkApdu,
          networkTimeOut,
          networkRetry,
        };
        addNetwork(newNetwork);
        setIsFormOpen((prev) => !prev);
      } else {
        toast.warning("Please fill the mandatory Fields");
      }
    }
  };

  const handleClose = () => {
    setIsFormOpen((prev) => !prev);
  };
  const networkOptions = [
    {
      value: "",
      label: "Select a Network",
    },
    {
      value: "MQTT",
      label: "MQTT",
    },
    // {
    //   value: "BacnetTCP",
    //   label: "Bacnet TCP/IP",
    // },
  ];
  return (
    <Slide
      direction={"left"}
      in={isFormOpen}
      mountOnEnter
      unmountOnExit
      onExited={() => {
        setIsFormOpen(false);
      }}
    >
      <div className={`deviceNetworkForm container-style`} onClick={onClick}>
        <div className="deviceNetworkFormHeading">Add a new Network</div>

        <div className="deviceNetworkFormContents">
          <CustomFormSelect
            id="selectNetwork"
            label="Choose a Network"
            onChange={(e) => setNetworkType(e.target.value)}
            value={networkType}
            options={networkOptions}
            required={true}
          />
          {networkType === "MQTT" ? (
            <>
              <CustomFormInput
                id="networkName"
                label="Network Name"
                onChange={(e) => setNetworkName(e.target.value)}
                value={networkName}
                placeholder="Name of the Network"
                required={true}
                type="text"
              />
              <CustomFormInput
                id="networkDesc"
                label="Network Description"
                onChange={(e) => setNetworkDesc(e.target.value)}
                value={networkDesc}
                placeholder="Network Description"
                type="text"
              />

              {/* <label htmlFor="networkPollFrequency">
                Network Poll Frequency
              </label>
              <input
                type="number"
                placeholder="Network Poll Frequency (in minutes)"
                value={networkPollFrequency}
                onChange={(e) => setNetworkPollFrequency(e.target.value)}
              /> */}
              {/* <label htmlFor="networkProtocol">Network Protocol</label>
              <input
                type="text"
                placeholder="Network Protocol"
                value={networkProtocol}
                onChange={(e) => setNetworkProtocol(e.target.value)}
              /> */}
              {/* <CustomFormInput
                id="networkInstance"
                label="Network Instance"
                onChange={(e) => setNetworkInstance(e.target.value)}
                value={networkInstance}
                placeholder="Network Instance"
                required={true}
                type="number"
              />
              <CustomFormInput
                id="networkAddress"
                label="Network Address"
                onChange={(e) => setNetworkAddress(e.target.value)}
                value={networkAddress}
                placeholder="Network Address"
                required={true}
                type="text"
              /> */}
              <CustomFormInput
                id="networkPort"
                label="Network Port"
                onChange={(e) => setNetworkPort(e.target.value)}
                value={networkPort}
                placeholder="Network Port"
                required={true}
                type="number"
              />
              {/* <CustomFormInput
                id="networkSubnet"
                label="Network Subnet"
                onChange={(e) => setNetworkSubnet(e.target.value)}
                value={networkSubnet}
                placeholder="Network Subnet"
                type="text"
              /> */}
              <div className="custom-tabs">
                <CustomButton
                  className={`  ${
                    selectedOption === "Apex"
                      ? "custom-tab-selected "
                      : "custom-tab"
                  }`}
                  buttonName="Apex"
                  handleClick={() => setSelectedOption("Apex")}
                />
                <CustomButton
                  className={`  ${
                    selectedOption === "3rdParty"
                      ? "custom-tab-selected"
                      : "custom-tab"
                  }`}
                  buttonName="Other Publisher"
                  handleClick={() => setSelectedOption("3rdParty")}
                />
              </div>
              {selectedOption === "Apex" ? (
                <CustomFormSelect
                  id={"topic"}
                  label={"Apex Name"}
                  onChange={(e) => setTopic(e.target.value)}
                  value={topic}
                  options={topics.map((topic) => ({
                    value: topic,
                    label: topic,
                  }))}
                  required={true}
                />
              ) : (
                <>
                  <CustomFormSelect
                    id={"topic"}
                    label={"Apex Name"}
                    onChange={(e) => setTopic(e.target.value)}
                    value={topic}
                    options={topics.map((topic) => ({
                      value: topic,
                      label: topic,
                    }))}
                    required={true}
                  />
                </>
              )}
            </>
          ) : networkType === "BacnetTCP" ? (
            <>
              <CustomFormInput
                id="networkName"
                label="Network Name"
                onChange={(e) => setBacnetNetworkName(e.target.value)}
                value={bacnetNetworkName}
                placeholder="Name of the Network"
                required={true}
                type="text"
              />
              <CustomFormInput
                id="networkDesc"
                label="Network Description"
                onChange={(e) => setBacnetNetworkDesc(e.target.value)}
                value={bacnetNetworkDesc}
                placeholder="Network Description"
                type="text"
              />

              {/* <label htmlFor="networkPollFrequency">
                Network Poll Frequency
              </label>
              <input
                type="number"
                placeholder="Network Poll Frequency (in minutes)"
                value={networkPollFrequency}
                onChange={(e) => setNetworkPollFrequency(e.target.value)}
              /> */}
              {/* <label htmlFor="networkProtocol">Network Protocol</label>
              <input
                type="text"
                placeholder="Network Protocol"
                value={networkProtocol}
                onChange={(e) => setNetworkProtocol(e.target.value)}
              /> */}
              <CustomFormInput
                id="networkInstance"
                label="Network Instance"
                onChange={(e) => setNetworkInstance(e.target.value)}
                value={networkInstance}
                placeholder="Network Instance"
                required={true}
                type="number"
              />
              <CustomFormInput
                id="networkAddress"
                label="Network Address"
                onChange={(e) => setNetworkAddress(e.target.value)}
                value={networkAddress}
                placeholder="Network Address"
                required={true}
                type="text"
              />
              <CustomFormInput
                id="networkPort"
                label="Network Port"
                onChange={(e) => setBacnetNetworkPort(e.target.value)}
                value={bacnetNetworkPort}
                placeholder="Network Port"
                required={true}
                type="number"
              />
              <CustomFormInput
                id="networkSubnet"
                label="Network Subnet"
                onChange={(e) => setNetworkSubnet(e.target.value)}
                value={networkSubnet}
                placeholder="Network Subnet"
                type="text"
              />
              <CustomFormInput
                id="networkGateway"
                label="Network Gateway"
                onChange={(e) => setNetworkGateway(e.target.value)}
                value={networkGateway}
                placeholder="Network Gateway"
                type="text"
              />
              <CustomFormInput
                id="networkApdu"
                label="Network APDU"
                onChange={(e) => setNetworkApdu(e.target.value)}
                value={networkApdu}
                placeholder="Network APDU"
                required={true}
                type="number"
              />
              <CustomFormInput
                id="networkTimeOut"
                label="Network Timeout"
                onChange={(e) => setNetworkTimeOut(e.target.value)}
                value={networkTimeOut}
                required={true}
                placeholder="Network Timeout"
                type="number"
              />
              <CustomFormInput
                id="networkRetry"
                label="Network Retry"
                onChange={(e) => setNetworkRetry(e.target.value)}
                value={networkRetry}
                required={true}
                placeholder="Network Retry"
                type="number"
              />
            </>
          ) : (
            <></>
          )}

          <div className="deviceNetworkFormContentsBtns">
            <CustomButton
              buttonName="Cancel"
              className="outline-btn-style"
              handleClick={handleClose}
            />
            <CustomButton
              buttonName="Save"
              className="filled-btn-style"
              handleClick={handleSave}
            />
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default DeviceNetworkForm;
