import React, { createContext, useContext, useState } from "react";
import getPermissions from "../../configuration/localStorage/getPermissions";

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [theme, setTheme] = useState("dark");
  const [permissions] = useState(getPermissions);
  const [open, setOpen] = useState(true);
  const [reloadData, setReloadData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [widgetOpen, setWidgetOpen] = useState(
    permissions?.displays.write ? true : false
  );
  const [floorLayout, setFloorLayout] = useState(false);
  const [preBuiltDashboard, setPreBuiltDashboard] = useState(
    permissions?.displays.write ? false : true
  );
  const [preBuiltDashboardEnable, setPreBuiltDashboardEnable] = useState(
    permissions?.displays.write ? false : true
  );
  const [mathBlockOpen, setMathBlockOpen] = useState(false);
  const [logicBlockOpen, setLogicBlockOpen] = useState(false);
  const [functionBlockOpen, setFunctionBlockOpen] = useState(false);
  const [constantsBlockOpen, setConstantsBlockOpen] = useState(false);
  const [reloadUser, setReloadUser] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [config, setConfig] = useState(null);
  const [configError, setConfigError] = useState("");
  const [currentUser, setCurrentUser] = useState();
  const [widgetLibraryOpen, setWidgetLibraryOpen] = useState(false);
  return (
    <MenuContext.Provider
      value={{
        open,
        setOpen,
        widgetOpen,
        setWidgetOpen,
        mathBlockOpen,
        setMathBlockOpen,
        functionBlockOpen,
        setFunctionBlockOpen,
        logicBlockOpen,
        setLogicBlockOpen,
        constantsBlockOpen,
        setConstantsBlockOpen,
        setPreBuiltDashboard,
        preBuiltDashboard,
        preBuiltDashboardEnable,
        setPreBuiltDashboardEnable,
        reloadData,
        setReloadData,
        setReloadUser,
        reloadUser,
        setLoading,
        loading,
        setIsMobile,
        isMobile,
        setOpenMenu,
        openMenu,
        floorLayout,
        theme,
        setTheme,
        setFloorLayout,
        setIsTemplate,
        isTemplate,
        config,
        setConfig,
        setConfigError,
        configError,
        setCurrentUser,
        currentUser,
        setWidgetLibraryOpen,
        widgetLibraryOpen,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
export const useMenu = () => {
  return useContext(MenuContext);
};
