import React, { useState, useEffect, useRef } from "react";
import "../../common/styles/customFormInputStyle.css";

const CustomFormMultiSelect = ({
  id,
  label,
  required,
  options,
  selectedValues,
  onChange,
  placeholder,
  max,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    if (!isOpen && selectedValues?.length < max) setIsOpen(true);
  };

  const handleSelect = (option) => {
    if (
      !selectedValues?.some((item) => item.value === option.value) &&
      selectedValues?.length < max
    ) {
      onChange([...selectedValues, option]);
      if (selectedValues?.length + 1 === max) {
        setIsOpen(false);
      }
    }
  };

  const handleRemove = (option) => {
    onChange(selectedValues.filter((item) => item.value !== option.value));
    if (selectedValues?.length === max) {
      setIsOpen(true);
    }
  };

  const clearAll = (e) => {
    e.stopPropagation();
    onChange([]);
    setIsOpen(false);
  };

  const normalizeString = (str) => str?.toLowerCase().replace(/[^a-z0-9]/g, "");

  const filteredOptions = options.filter(
    (option) =>
      normalizeString(option.label).includes(normalizeString(searchTerm)) &&
      !selectedValues?.some((item) => item.value === option.value)
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="customFormInput" ref={dropdownRef}>
      <label className="customFormLabel" htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div
        className="customFormInputField input-style"
        onClick={handleToggleDropdown}
      >
        <div className="customFormInputBox">
          {selectedValues?.length > 0 ? (
            <div className="selected-items">
              {selectedValues.map((option) => (
                <div key={option.value} className="selected-item">
                  {option.label}
                  <button
                    className="remove-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemove(option);
                    }}
                  >
                    x
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <span className="placeholder">{placeholder}</span>
          )}
          {selectedValues?.length > 0 && (
            <span className="clear-all-btn" onClick={clearAll}>
              X
            </span>
          )}
        </div>
        {isOpen && (
          <div className="dropdown">
            <div className="input-style" style={{ width: "100%" }}>
              <input
                type="text"
                placeholder="Search & Select..."
                className="search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <div className="dropdown-options">
              {filteredOptions?.length > 0 ? (
                filteredOptions?.map((option) => (
                  <div
                    key={option.value}
                    className="dropdown-option"
                    onClick={() => handleSelect(option)}
                  >
                    {option.label}
                  </div>
                ))
              ) : (
                <span>No Options</span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomFormMultiSelect;
