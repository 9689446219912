/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../../../../../common/styles/kpiChartStyle.css";
import { useState } from "react";
import { useEffect } from "react";
import PreLoader from "../../../../../reusableComponents/preLoader";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";
const KPIChart = ({ chart }) => {
  const { config } = useMenu();
  // const [isLoading, setIsLoading] = useState(true);
  const [value1, setValue1] = useState([]);
  const [average, setAverage] = useState([]);

  const [errorValues, setErrorValues] = useState([]);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const fetchLiveDeviceData = async () => {
    // setIsLoading(false);
    try {
      const username = "admin";
      const password = "root";
      const base64Credentials = btoa(`${username}:${password}`);
      const couchdbUrl = dbUrl;
      // const database = chart?.data?.points;
      const database = chart?.data?.points[0].value;
      // const database = point.value;

      if (chart?.data?.kpi === "1Day") {
        const currentDate = new Date().toISOString().split("T")[0];

        try {
          const response = await api.post(
            `${couchdbUrl}/${database}/_find`,
            {
              selector: {
                _id: { $regex: `^${currentDate}` },
              },
              fields: ["_id", "timestamp", "trendId", "presentValue"],
              execution_stats: true,
            },
            {
              headers: {
                Authorization: `Basic ${base64Credentials}`,
              },
            }
          );

          const getTimeStamps = response.data.docs.map((doc) => doc.timestamp);
          const presentvalues = response.data.docs.map((doc) =>
            doc.presentValue.toFixed(2)
          );
          let sum = 0;
          presentvalues.forEach((value) => {
            sum += parseFloat(value);
          });
          const average = sum / presentvalues.length;

          const errorValues = presentvalues.map((value) => average - value);

          setAverage(new Array(getTimeStamps.length).fill(average));
          setValue1(presentvalues);
          setErrorValues(errorValues);
        } catch (error) {
          console.error("Error fetching data for the day:", error.message);
        }
      } else if (chart?.data?.kpi === "1Week") {
        try {
          const endDate = new Date().toISOString().split("T")[0];
          const startDate = new Date();
          startDate.setDate(startDate.getDate() - 7);
          const updatedStartDate = startDate.toISOString().split("T")[0];

          console.log(endDate, updatedStartDate);
          const response = await api.post(
            `${couchdbUrl}/${database}/_find`,
            {
              selector: {
                timestamp: {
                  $gte: `${updatedStartDate}`,
                  $lte: `${endDate}`,
                },
              },
              fields: ["_id", "timestamp", "trendId", "presentValue"],
              execution_stats: true,
            },
            {
              headers: {
                Authorization: `Basic ${base64Credentials}`,
              },
            }
          );

          const getTimeStamps = response.data.docs.map((doc) => doc.timestamp);
          const presentvalues = response.data.docs.map((doc) =>
            parseFloat(doc.presentValue.toFixed(2))
          );
          const sum = presentvalues.reduce((acc, value) => acc + value, 0);
          const average = sum / presentvalues.length;
          const errorValues = presentvalues.map((value) => average - value);
          setAverage(new Array(getTimeStamps.length).fill(average));
          setValue1(presentvalues);
          setErrorValues(errorValues);
        } catch (error) {
          console.error("Error fetching data for the week:", error.message);
        }
      } else if (chart?.data?.kpi === "custom") {
        try {
          const response = await api.post(
            `${couchdbUrl}/${database}/_find`,
            {
              selector: {},
              fields: ["_id", "timestamp", "trendId", "presentValue"],
              execution_stats: true,
              limit: 20,
            },
            {
              headers: {
                Authorization: `Basic ${base64Credentials}`,
              },
            }
          );

          const getTimeStamps = response.data.docs.map((doc) => doc.timestamp);
          const presentvalues = response.data.docs.map((doc) =>
            parseFloat(doc.presentValue.toFixed(2))
          );
          const errorValues = presentvalues.map(
            (value) => chart?.data?.customKpiValue - value
          );
          setValue1(presentvalues);
          setErrorValues(errorValues);

          setAverage(
            new Array(getTimeStamps.length).fill(chart?.data?.customKpiValue)
          );
          console.log(
            new Array(getTimeStamps.length).fill(chart?.data?.customKpiValue)
          );
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching last uploaded record:", error.message);
    }
  };

  useEffect(() => {
    const intervalInSeconds = chart?.data?.pollFrequency;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart.data]);
  const kpiValue = average[0] ? average[0] : 0;
  const formattedKpiValue = Number.isInteger(parseFloat(kpiValue))
    ? kpiValue
    : kpiValue.toFixed(2);
  return (
    <>
      {/* {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null} */}
      <div className="kpiChart">
        <div className="kpiChartHeading">
          {chart?.data?.title ? chart?.data?.title : "KPI Chart"}
        </div>
        <div className="kpiChartContent">
          <div className="kpiChartPresentValue">
            {value1[0] ? value1[0] : 0}
          </div>
          <div className="kpiChartKpiValue">KPI Value: {formattedKpiValue}</div>
          <div className="kpiChartErrorValue">
            Error Value: {errorValues[0] ? errorValues[0].toFixed(2) : 0}
          </div>
        </div>
      </div>
    </>
  );
};

export default KPIChart;
