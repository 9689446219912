import React from "react";

import "../../../../../common/styles/deviceDetailsPropertiesStyle.css";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import CustomConfirmDelete from "../../../../reusableComponents/customConfirmDelete";
import { useMenu } from "../../../../../common/utils/menuContext";
import { isEmpty } from "../../../../../common/utils/isEmpty";
import CustomButton from "../../../../reusableComponents/customButton";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import api from "../../../../../common/utils/axiosRequest";

const DeviceDetailsProperties = ({
  deviceProps,
  setSelectedDevice,
  networkProps,
  permissions,
  handleOpen,
  setConfirmDelete,
}) => {
  const { setReloadData, config } = useMenu();
  const [deviceName, setDeviceName] = useState();
  const [deviceDescription, setDeviceDescription] = useState();
  const [deviceInstance, setDeviceInstance] = useState();
  const [deviceEnable, setDeviceEnable] = useState();
  const [pollFrequency, setPollFrequency] = useState();
  const serverEndpoint = config?.REACT_APP_POLLING_ENDPOINT;
  const dbUrl = config?.REACT_APP_POLLING_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const [loading, setLoading] = useState(false);
  const base64Credentials = btoa(`${username}:${password}`);
  const [updateWarning, setUpdateWarning] = useState(false);

  useEffect(() => {
    setDeviceEnable(deviceProps?.deviceEnable);
    setDeviceName(deviceProps?.deviceName);
    setDeviceDescription(deviceProps?.deviceDetails);
    setPollFrequency(deviceProps?.devicePollFrequency);
    setDeviceInstance(deviceProps?.deviceInstance);
  }, [deviceProps]);

  // const handleCheckBoxChange = async (event) => {
  //   const newCheckedState = event.target.checked;
  //   const getDevice = await api.get(`${dbUrl}/devices/${deviceProps?._id}`);
  //   try {
  //     if (newCheckedState) {
  //       if (deviceProps?.deviceType === "BacnetTCP") {
  //         const checkDevice = await api.post(`${serverEndpoint}/check_device`, {
  //           deviceInstance: getDevice.data.data.deviceInstance,
  //           deviceMac: getDevice.data.data.deviceMacAddress,
  //           networkAddress: getDevice.data.data.networkAddress,
  //           networkPort: getDevice.data.data.networkPort,
  //         });
  //         if (!checkDevice.data.error) {
  //           setDeviceEnable(newCheckedState);
  //           const enableDevice = {
  //             ...getDevice.data.data,
  //             deviceEnable: true,
  //             deviceStatus: "Active",
  //             lastPollStatus: "Success",
  //           };
  //           getDevice.data.data.devices[0].points.forEach(async (point) => {
  //             try {
  //               const getPoint = await api.get(
  //                 `${dbUrl}/points/${point.pointId}`,
  //                 {
  //                   headers: {
  //                     Authorization: `Basic ${base64Credentials}`,
  //                   },
  //                 }
  //               );
  //               const enablePoint = {
  //                 ...getPoint.data.data,
  //                 pointEnable: true,
  //                 pointStatus: "Active",
  //                 pointLastPollStatus: "Success",
  //               };

  //               await api.put(
  //                 `${dbUrl}/points/${getPoint.data.data._id}`,
  //                 enablePoint,
  //                 {
  //                   headers: {
  //                     Authorization: `Basic ${base64Credentials}`,
  //                     "Content-Type": "application/json",
  //                   },
  //                 }
  //               );
  //             } catch (error) {
  //               console.error("Error fetching or updating point:", error);
  //             }
  //           });

  //           const enableDeviceResponse = await api.put(
  //             `${dbUrl}/devices/${getDevice.data.data._id}`,
  //             enableDevice
  //           );
  //           if (enableDeviceResponse.status === 200) {
  //             setSelectedDevice(enableDevice);
  //             if (deviceProps?.deviceType === "BacnetTCP") {
  //               startPolling();
  //             } else {
  //               subscribe();
  //             }
  //           }
  //         } else {
  //           toast.error("Either Network or Device is not Responding !");
  //         }
  //       } else {
  //         setDeviceEnable(newCheckedState);
  //         const enableDevice = {
  //           ...getDevice.data.data,
  //           deviceEnable: true,
  //           deviceStatus: "Active",
  //           lastPollStatus: "Success",
  //         };
  //         getDevice.data.data.devices[0].points.forEach(async (point) => {
  //           try {
  //             const getPoint = await api.get(
  //               `${dbUrl}/points/${point.pointId}`,
  //               {
  //                 headers: {
  //                   Authorization: `Basic ${base64Credentials}`,
  //                 },
  //               }
  //             );
  //             const enablePoint = {
  //               ...getPoint.data.data,
  //               pointEnable: true,
  //               pointStatus: "Active",
  //               pointLastPollStatus: "Success",
  //             };

  //             await api.put(
  //               `${dbUrl}/points/${getPoint.data.data._id}`,
  //               enablePoint,
  //               {
  //                 headers: {
  //                   Authorization: `Basic ${base64Credentials}`,
  //                   "Content-Type": "application/json",
  //                 },
  //               }
  //             );
  //           } catch (error) {
  //             console.error("Error fetching or updating point:", error);
  //           }
  //         });

  //         const enableDeviceResponse = await api.put(
  //           `${dbUrl}/devices/${getDevice.data.data._id}`,
  //           enableDevice
  //         );
  //         if (enableDeviceResponse.status === 200) {
  //           setSelectedDevice(enableDevice);
  //           if (deviceProps?.deviceType === "BacnetTCP") {
  //             startPolling();
  //           } else {
  //             subscribe();
  //           }
  //         }
  //       }
  //     } else if (deviceProps?.deviceStatus === "Active") {
  //       setDeviceEnable(newCheckedState);
  //       const disableDevice = {
  //         ...getDevice.data.data,
  //         deviceEnable: false,
  //         deviceStatus: "Inactive",
  //         lastPollStatus: "Failed",
  //       };
  //       getDevice.data.data.devices[0].points.forEach(async (point) => {
  //         try {
  //           const getPoint = await api.get(`${dbUrl}/points/${point.pointId}`);
  //           const enablePoint = {
  //             ...getPoint.data.data,
  //             pointEnable: false,
  //             pointStatus: "Inactive",
  //             pointLastPollStatus: "Failed",
  //           };

  //           await api.put(
  //             `${dbUrl}/points/${getPoint.data.data._id}`,
  //             enablePoint
  //           );
  //         } catch (error) {
  //           if (error.response && error.response.status === 404) {
  //             console.error(`Point with ID ${point.pointId} not found.`);
  //           } else {
  //             console.error("Error fetching or updating point:", error);
  //           }
  //         }
  //       });
  //       const disableDeviceResponse = await api.put(
  //         `${dbUrl}/devices/${getDevice.data.data._id}`,
  //         disableDevice
  //       );
  //       if (disableDeviceResponse.status === 200) {
  //         setSelectedDevice(disableDevice);
  //         if (deviceProps?.deviceType === "BacnetTCP") {
  //           stopPolling();
  //         } else {
  //           unsubscribe();
  //         }
  //       }
  //     } else {
  //       toast.warning("Device not found in the Network");
  //     }
  //   } catch (error) {
  //     toast.warning("Device not found in the Network");
  //   }
  // };
  const saveDevice = async () => {
    if (!isEmpty(deviceName)) {
      const getDevice = await api.get(`${dbUrl}/devices/${deviceProps?._id}`, {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });
      if (deviceInstance === getDevice.data.data.deviceInstance) {
        const updatedData = {
          ...getDevice.data.data,
          deviceName: deviceName,
          deviceDetails: deviceDescription,
          devicePollFrequency: pollFrequency,
        };
        const updateDataToDb = await api.put(
          `${dbUrl}/devices/${deviceProps?._id}`,
          updatedData
        );
        if (updateDataToDb.status === 200) {
          toast.success("Updated Device");
          setReloadData(true);
        }
      } else {
        setUpdateWarning(true);
      }
    } else {
      toast.warning("Please Fill the Required Fields");
    }
  };
  const createDevice = async () => {
    setUpdateWarning(false);
    const getDevice = await api.get(`${dbUrl}/devices/${deviceProps?._id}`, {
      headers: {
        Authorization: `Basic ${base64Credentials}`,
      },
    });
    const existingDevice = getDevice.data.data;
    const { _id, _rev, points, ...deviceData } = getDevice.data.data;
    const deleteDevice = await api.delete(
      `${dbUrl}/devices/${existingDevice._id}`,
      {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      }
    );
    if (deleteDevice.status === 200) {
      existingDevice.points.forEach(async (point) => {
        const getPoint = await api.get(`${dbUrl}/points/${point.pointId}`, {
          headers: {
            Authorization: `Basic ${base64Credentials}`,
          },
        });
        const pointData = getPoint.data.data;
        await api.delete(`${dbUrl}/points/${pointData._id}`, {
          headers: {
            Authorization: `Basic ${base64Credentials}`,
          },
        });
      });
      const newDevice = {
        ...deviceData,
        _id: deviceInstance,
        deviceId: parseInt(deviceInstance),
        deviceInstance: parseInt(deviceInstance),
        deviceName: deviceName,
        deviceDetails: deviceDescription,
        devicePollFrequency: pollFrequency,
        points: [],
      };
      const createDocumentResponse = await api.post(
        `${dbUrl}/devices`,
        newDevice,
        {
          headers: {
            Authorization: `Basic ${base64Credentials}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        createDocumentResponse.status === 201 ||
        createDocumentResponse.status === 200
      ) {
        const getNetworkResponse = await api.get(
          `${dbUrl}/networks/${existingDevice.networkId}`,
          {
            headers: {
              Authorization: `Basic ${base64Credentials}`,
            },
          }
        );

        const networkData = getNetworkResponse.data.data;

        const existingNetworkIndex = networkData.devices.findIndex(
          (device) => device.deviceInstance === existingDevice.deviceInstance
        );
        if (existingNetworkIndex !== -1) {
          const updatedDeviceData = {
            deviceID: parseInt(deviceInstance),
            deviceInstance: parseInt(deviceInstance),
          };
          networkData.devices[existingNetworkIndex] = updatedDeviceData;
          const updateNetworkResponse = await api.put(
            `${dbUrl}/networks/${networkData._id}`,
            networkData,
            {
              headers: {
                Authorization: `Basic ${base64Credentials}`,
                "Content-Type": "application/json",
              },
            }
          );
          setReloadData(true);
          const getUpdatedDevice = await api.get(
            `${dbUrl}/devices/${deviceInstance}`,
            {
              headers: {
                Authorization: `Basic ${base64Credentials}`,
              },
            }
          );
          setSelectedDevice(getUpdatedDevice.data.data);
          if (updateNetworkResponse.status === 200) {
            toast.success("Updated Device");
          }
        } else {
          console.error(
            "Existing Device not found in the network's devices array"
          );
        }
      }
    }
  };
  const subscribe = async () => {
    setLoading(true);
    try {
      const subscribe = await api.post(`${serverEndpoint}/subscribe`, {
        topic: `${deviceProps?.topicName}`,
      });
      if (subscribe.status === 200) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setReloadData(true);
          toast.success(subscribe.data.message);
        }, 5000);

        setReloadData(true);
      } else if (subscribe.status === 400) {
        toast.error(subscribe.data.message);
        setReloadData(true);
      }
      const getDevice = await api.get(`${dbUrl}/devices/${deviceProps?._id}`, {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });
      const updatedData = {
        ...getDevice.data.data,
        isSubscribed: true,
      };
      setSelectedDevice(updatedData);
      await api.put(
        `${dbUrl}/devices/${deviceProps?._id}?rev=${getDevice.data._rev}`,
        updatedData
      );
    } catch (error) {
      console.error(`Unexpected Error Occurred :${error}`);
    }
  };
  const unsubscribe = async () => {
    setLoading(true);
    try {
      const unsubscribe = await api.post(`${serverEndpoint}/unsubscribe`, {
        topic: `${deviceProps?.topicName}`,
      });
      if (unsubscribe.status === 200) {
        toast.success(unsubscribe.data.message);
        setReloadData(true);
      } else if (unsubscribe.status === 400) {
        toast.error(unsubscribe.data.message);
        setReloadData(true);
      }
      const getDevice = await api.get(`${dbUrl}/devices/${deviceProps?._id}`, {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });
      const updatedData = {
        ...getDevice.data.data,
        isSubscribed: false,
      };
      setSelectedDevice(updatedData);
      await api.put(`${dbUrl}/devices/${deviceProps?._id}`, updatedData);
    } catch (error) {
      console.error(`Unexpected Error Occurred :${error}`);
    }
  };

  const startPolling = async () => {
    setLoading(true);
    try {
      const getDevice = await api.get(`${dbUrl}/devices/${deviceProps?._id}`);
      const points = getDevice.data.data.devices[0].points;
      const extractedObjects = points.map((point) => ({
        objectType: point.objectType,
        objectInstance: point.objectInstance,
      }));
      if (extractedObjects.length === 0) {
        toast.warning("No points to poll. Please add points to the device.");
        setLoading(false);
        return;
      }

      const startPoll = await api.post(`${serverEndpoint}/start_polling`, {
        deviceInstance: deviceProps?.deviceInstance,
        deviceMac: deviceProps?.deviceMacAddress,
        networkAddress: deviceProps?.networkAddress,
        networkPort: deviceProps?.networkPort,
        networkType: deviceProps?.deviceType,
        pollFrequency: deviceProps?.pollFrequency
          ? deviceProps?.pollFrequency
          : 5,
        points: extractedObjects,
      });
      if (startPoll.data.error) {
        toast.error(startPoll.data.error);
        setLoading(false);
      }
      toast.success("Polling Started!");
      setLoading(false);
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };

  const stopPolling = async () => {
    try {
      const thread = {
        threadId: `${deviceProps?.deviceInstance}_${deviceProps?.deviceMacAddress}`,
      };
      await api.post(`${serverEndpoint}/stop_polling`, thread);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="deviceDetailsProperties">
        <div className={`deviceDetailsPropertiesContent `}>
          <div className="deviceDetailsPropertiesContentInputs">
            <div className="deviceDetailsPropertiesContentPartInputs">
              <div className="deviceDetailsPropertiesGroup">
                <CustomFormInput
                  required={true}
                  id={"deviceName"}
                  label={"Device Name"}
                  type={"text"}
                  value={deviceName}
                  onChange={(e) => setDeviceName(e.target.value)}
                />
              </div>
              <div className="deviceDetailsPropertiesGroup">
                <CustomFormInput
                  required={true}
                  id={"deviceDesc"}
                  label={"Device Description"}
                  type={"text"}
                  value={deviceDescription}
                  onChange={(e) => setDeviceDescription(e.target.value)}
                />
              </div>
              <div className="deviceDetailsPropertiesGroup">
                <CustomFormInput
                  required={true}
                  id={"deviceInstance"}
                  label={"Device Instance"}
                  type={"text"}
                  value={deviceInstance}
                  disabled={true}
                />
              </div>

              <div className="deviceDetailsPropertiesGroup">
                <CustomFormInput
                  id={"ipAddress"}
                  label={"IP Address"}
                  type={"text"}
                  value={deviceProps?.networkAddress}
                  disabled={true}
                />
              </div>
            </div>
            <div className="deviceDetailsPropertiesContentPartInputs">
              <div className="deviceDetailsPropertiesGroup">
                <CustomFormInput
                  id={"deviceNetwork"}
                  label={"Device Network"}
                  type={"text"}
                  value={deviceProps?.deviceNetwork}
                  disabled={true}
                />
              </div>
              <div className="deviceDetailsPropertiesGroup">
                <CustomFormInput
                  id={"deviceType"}
                  label={"Device Type"}
                  type={"text"}
                  value={deviceProps?.deviceType}
                  disabled={true}
                />
              </div>
              <div className="deviceDetailsPropertiesGroup">
                <CustomFormInput
                  id={"deviceStatus"}
                  label={"Device Status"}
                  type={"text"}
                  value={deviceProps?.deviceStatus}
                  disabled={true}
                />
              </div>
              <div className="deviceDetailsPropertiesGroup">
                {deviceProps?.deviceType === "BacnetTCP" ? (
                  <CustomFormInput
                    id={"lastPolled"}
                    label={"Created At"}
                    type={"text"}
                    value={deviceProps?.lastPollTime}
                    disabled={true}
                  />
                ) : (
                  <CustomFormInput
                    id={"lastSubscribed"}
                    label={"Created At"}
                    type={"text"}
                    value={deviceProps?.lastPollTime}
                    disabled={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {permissions.devices.write && (
          <>
            <div className="deviceNetworkPropertiesBtnsGroup ">
              <div className="filled-btn-style" onClick={saveDevice}>
                Save
              </div>
              <CustomButton
                className={`filled-btn-style`}
                buttonName="Delete Device"
                handleClick={() => setConfirmDelete(true)}
              />

              <CustomButton
                handleClick={() => handleOpen()}
                className={`filled-btn-style `}
                buttonName="Add Points"
              />
            </div>
          </>
        )}
      </div>
      {updateWarning ? (
        <CustomConfirmDelete
          setConfirmDelete={setUpdateWarning}
          handleDelete={createDevice}
          message="Are you sure you want to change the Device ID ?"
          note="All the Points associated with the device will be deleted."
        />
      ) : null}
    </>
  );
};

export default DeviceDetailsProperties;
