import React from "react";

const MatrixReportChart = ({ rows, columns }) => {
  return (
    <div className="matrixReportTable">
      <table>
        <thead>
          <tr>
            <th>Points</th>
            {columns?.map((column) => (
              <th key={column.id}>{column.label}</th>
            ))}
          </tr>
          <tr>
            <th>Devices</th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((row) => (
            <tr key={row.deviceId}>
              <td>{row.deviceId}</td>
              {columns.map((column) => (
                <td key={column.id}>
                  {row[column.id] !== undefined || row[column.id] === ""
                    ? row[column.id]
                    : "N/A"}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MatrixReportChart;
