import React from "react";

const CustomNavigator = ({ children, className }) => {
  return (
    <div
      className={`container-style ${className ? className : "customNavigator"}`}
    >
      {children}
    </div>
  );
};

export default CustomNavigator;
