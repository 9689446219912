import React, { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import Alarms from "../features/pages/alarms/presentation/alarms";
import Devices from "../features/pages/devices/presentation/devices";
import Display from "../features/pages/display/presentation/display";
import EdgeStatistics from "../features/pages/edgeStatistics/presentation/edgeStatistics";
import Rules from "../features/pages/ruleEditor/presentation/rules";
import Schedules from "../features/pages/schedules/presentation/schedules";
import Settings from "../features/pages/settings/presentation/settings";
import Trends from "../features/pages/trends/presentation/trends";
import getPermissions from "../configuration/localStorage/getPermissions";
import UserProfile from "../features/pages/userProfile/presentation/userProfile";
import Reports from "../features/pages/reports/presentation/reports.jsx";
import PrebuiltDisplay from "../features/pages/prebuiltDisplay/presentation/prebuiltDisplay.jsx";
import getUser from "../configuration/localStorage/getUser.js";
import InviteUser from "../features/pages/register/presentation/inviteUser.jsx";

const ProtectedRoute = ({ permission, element }) => {
  return permission ? element : null;
};

const AdminRoute = ({ user, element }) => {
  return user?.role === "Admin" ? element : null;
};

const AppRoutes = () => {
  const permissions = useMemo(() => getPermissions(), []);
  const user = useMemo(() => getUser(), []);

  const routes = [
    { path: "/home", element: <EdgeStatistics /> },
    { path: "*", element: <EdgeStatistics /> },
    {
      path: "/displays",
      element: <Display />,
      permission: permissions?.displays?.read,
    },
    {
      path: "/prebuiltdisplays",
      element: <PrebuiltDisplay />,
      permission: permissions?.displays?.read,
    },
    {
      path: "/prebuiltdisplays/:displayId",
      element: <PrebuiltDisplay />,
      permission: permissions?.displays?.read,
    },

    {
      path: "/displays/:displayId",
      element: <Display />,
      permission: permissions?.displays?.read,
    },
    {
      path: "/devices",
      element: <Devices />,
      permission: permissions?.devices?.read,
    },
    {
      path: "/devices/network/:networkId",
      element: <Devices />,
      permission: permissions?.devices?.read,
    },
    {
      path: "/devices/device/:deviceId",
      element: <Devices />,
      permission: permissions?.devices?.read,
    },
    {
      path: "/devices/point/:pointId",
      element: <Devices />,
      permission: permissions?.devices?.read,
    },
    {
      path: "/alarms",
      element: <Alarms />,
      permission: permissions?.alarms?.read,
    },
    {
      path: "/trends",
      element: <Trends />,
      permission: permissions?.trends?.read,
    },
    {
      path: "/trends/:selectedTrendId",
      element: <Trends />,
      permission: permissions?.trends?.read,
    },
    {
      path: "/trends/trendComparator/:trendComparatorId",
      element: <Trends />,
      permission: permissions?.trends?.read,
    },
    {
      path: "/rules",
      element: <Rules />,
      permission: permissions?.rules?.read,
    },
    {
      path: "/rules/:ruleId",
      element: <Rules />,
      permission: permissions?.rules?.read,
    },
    {
      path: "/schedule",
      element: <Schedules />,
      permission: permissions?.schedules?.read,
    },
    {
      path: "/schedule/:scheduleId",
      element: <Schedules />,
      permission: permissions?.schedules?.read,
    },
    {
      path: "/reports",
      element: <Reports />,
      permission: permissions?.reports?.read,
    },
    { path: "/settings/users", element: <Settings />, admin: true },
    { path: "/settings/roles", element: <Settings />, admin: true },
    { path: "/settings/configuration", element: <Settings />, admin: true },
    { path: "/settings/backupRestore", element: <Settings />, admin: true },
    { path: "/settings/logs", element: <Settings />, admin: true },
    { path: "/settings/license", element: <Settings />, admin: true },
    { path: "/profile", element: <UserProfile /> },
    { path: "/invite", element: <InviteUser /> },
    { path: "/invite/:inviteId", element: <InviteUser /> },
  ];

  return (
    <Routes>
      {routes.map(({ path, element, permission, admin }) => (
        <Route
          key={path}
          path={path}
          element={
            admin ? (
              <AdminRoute user={user} element={element} />
            ) : (
              <ProtectedRoute
                permission={permission !== undefined ? permission : true}
                element={element}
              />
            )
          }
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
