import React from "react";
import StandardAlarmReport from "./standardAlarmReport.jsx";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useMenu } from "../../../../../common/utils/menuContext.js";
import MatrixReport from "./matrixReport.jsx";
import api from "../../../../../common/utils/axiosRequest.js";

const ReportsCanvas = ({
  selectedReportTemplate,
  selectedReport,
  setSelectedReport,
  setReportData,
  reportData,
  networkOptions,
  pointOptions,
  deviceOptions,
  handleSelectChange,
}) => {
  const { setReloadData, config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const handleSaveReport = async () => {
    const { _id, ...rest } = reportData;
    const data = {
      _id: _id ? _id : uuidv4(),
      ...rest,
    };
    try {
      const pushToDb = await api.post(`${dbUrl}/reports`, data);

      if (pushToDb.status === 200) {
        toast.success("Report Saved Successfully");
        setSelectedReport(data._id);
        setReloadData(true);
      }
    } catch (error) {
      const updatedData = {
        ...reportData,
      };
      try {
        const updateData = await api.put(
          `${dbUrl}/reports/${_id}`,
          updatedData
        );
        if (updateData.status === 200) {
          toast.success("Report Updated Successfully");
          setSelectedReport(_id);
          setReloadData(true);
        }
      } catch (error) {
        console.error("Failed to update report:", error);
        toast.error("Failed to update report");
      }
    }
  };

  return (
    <div className="reportCanvas">
      <div className="reportCanvasContent">
        {selectedReportTemplate === "Standard Alarm Report" ? (
          <StandardAlarmReport
            setReportData={setReportData}
            reportData={reportData}
            handleSaveReport={handleSaveReport}
            networkOptions={networkOptions}
            pointOptions={pointOptions}
            deviceOptions={deviceOptions}
            handleSelectChange={handleSelectChange}
          />
        ) : selectedReportTemplate === "Matrix Report" ? (
          <MatrixReport
            setReportData={setReportData}
            reportData={reportData}
            handleSaveReport={handleSaveReport}
            networkOptions={networkOptions}
            pointOptions={pointOptions}
            deviceOptions={deviceOptions}
            handleSelectChange={handleSelectChange}
            selectedReportTemplate={selectedReportTemplate}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ReportsCanvas;
