import React from "react";
import "../../../../../common/styles/ruleListStyle.css";

import { MdDelete } from "react-icons/md";
import CustomConfirmDelete from "../../../../reusableComponents/customConfirmDelete";
import TruncateText from "../../../../../common/utils/truncateText";
import { Tooltip } from "react-tooltip";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import { useState } from "react";

const RuleList = ({
  ruleList,
  onRuleClick,
  deleteRule,
  confirmDelete,
  setConfirmDelete,
  permissions,
  createNewRule,
  selectedRule,
}) => {
  const [toBeDeleted, setToBeDeleted] = useState();

  return (
    <div className={`ruleList `}>
      <div className={`ruleListHeader `}>Rules</div>
      <div className="createNewRuleBtn" onClick={() => createNewRule()}>
        <VscGitPullRequestCreate style={{ margin: "auto" }} />
        Create New Rule
      </div>
      <div className="ruleListContent">
        {ruleList?.map((rule) => (
          <div
            className={`rule ${
              selectedRule === rule.name ? "selectedRule" : ""
            }`}
            key={rule.name}
          >
            <li
              onClick={() => {
                onRuleClick(rule);
              }}
              data-tooltip-id="ruleListToolTip"
              data-tooltip-content={rule.name}
            >
              {TruncateText(rule.name, 11)}
            </li>
            <Tooltip
              place="right"
              className="tooltipStyle"
              id="ruleListToolTip"
            />
            {permissions.rules.write && (
              <MdDelete
                className="ruleListDeleteBtn"
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmDelete(true);
                  setToBeDeleted(rule._id);
                }}
              />
            )}
          </div>
        ))}
        {confirmDelete && (
          <CustomConfirmDelete
            setConfirmDelete={setConfirmDelete}
            handleDelete={() => deleteRule(toBeDeleted)}
            message="Are you sure you want to Delete this Rule ?"
            note="Points Associated with this Rule will be Deleted"
          />
        )}
      </div>
    </div>
  );
};

export default RuleList;
