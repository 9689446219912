/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../../../../common/styles/displayNavigatorStyle.css";
import { useMenu } from "../../../../../common/utils/menuContext";
import { useEffect } from "react";
import { MdDelete } from "react-icons/md";
import CustomConfirmDelete from "../../../../reusableComponents/customConfirmDelete";
import TruncateText from "../../../../../common/utils/truncateText";
import { Tooltip } from "react-tooltip";
import { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomNavigator from "../../../../reusableComponents/customNavigator";
import CustomSwitch from "../../../../reusableComponents/customSwitch";

const DisplayNavigator = ({
  loadDashboards,
  dashboards,
  handleNewDashboard,
  handleLoadDashboard,
  handleDeleteDashboard,
  confirmDelete,
  setConfirmDelete,
  permissions,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { open, isTemplate, setIsTemplate } = useMenu();
  const [toBeDeleted, setToBeDeleted] = useState();

  useEffect(() => {
    if (
      location.pathname.startsWith("/displays") ||
      location.pathname.startsWith("/displays/")
    ) {
      console.log("not a template");
      setIsTemplate(false);
    } else if (
      location.pathname.startsWith("/prebuiltdisplays") ||
      location.pathname.startsWith("/prebuiltdisplays/")
    ) {
      setIsTemplate(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    loadDashboards();
  }, []);

  const switchDisplay = (checked) => {
    setIsTemplate(checked);
    if (checked) {
      navigate("/prebuiltdisplays");
    } else {
      navigate("/displays");
    }
  };

  return open ? (
    <>
      <CustomNavigator>
        <div className={`displayNavigator `}>
          <div className={`displayNavigatorHeader`}>
            <span>Display Navigator</span>
            <CustomSwitch
              onText={"D"}
              offText={"DT"}
              checked={isTemplate}
              onChange={(e) => switchDisplay(e.target.checked)}
            />
            {permissions?.displays?.write && (
              <FiPlus
                className="addNetworksBtn"
                data-tooltip-id="displayNavigatorToolTip"
                data-tooltip-content="New Display"
                onClick={handleNewDashboard}
                size={20}
              />
            )}
          </div>

          <div className="displayNavigatorContent">
            {dashboards.map((dashboard) => (
              <div
                key={`${dashboard._id}`}
                className="displayNavigatorContentLists"
              >
                <li
                  className="displayNavigatorContentList"
                  id={`${dashboard._id}`}
                >
                  <Link
                    to={`/displays/${dashboard._id}`}
                    className="link settings"
                  >
                    <span
                      onClick={() => {
                        handleLoadDashboard(dashboard);
                      }}
                      data-tooltip-id="displayNavigatorToolTip"
                      data-tooltip-content={dashboard.name}
                    >
                      {TruncateText(dashboard.name, 16)}
                    </span>
                  </Link>
                  {permissions.displays.write && (
                    <MdDelete
                      onClick={() => {
                        setConfirmDelete(true);
                        setToBeDeleted(dashboard._id);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "var(--white-color)",
                        margin: "auto",
                        padding: "3px",
                        borderRadius: "5px",
                        backgroundColor: "var(--light-red-color)",
                      }}
                    />
                  )}
                </li>
              </div>
            ))}
          </div>
        </div>
      </CustomNavigator>
      {confirmDelete && (
        <CustomConfirmDelete
          setConfirmDelete={setConfirmDelete}
          handleDelete={() => handleDeleteDashboard(toBeDeleted)}
          message="Are you sure you want to Delete this Display?"
          note="Charts Associated with this Display will be Deleted."
        />
      )}
      <Tooltip
        place="right"
        className="tooltipStyle"
        id="displayNavigatorToolTip"
      />
    </>
  ) : null;
};
export default DisplayNavigator;
