import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import axios from "axios";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async ({ userCredentials, config }) => {
    const serverEndpoint = config?.REACT_APP_USER_ENDPOINT;
    const dbUrl = config?.REACT_APP_DB_ENDPOINT;

    try {
      const response = await axios.post(
        `${serverEndpoint}/login`,
        userCredentials
      );
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.success === false) {
          toast.error("Access Denied! Invalid Credentials");
        } else {
          const getRolePermission = await axios.get(
            `${dbUrl}/roles/${responseData.data.role}`,
            {
              headers: {
                "x-org-id": responseData.data.orgId,
              },
            }
          );

          const rolePermissions = getRolePermission.data;
          toast.success("Logged In Successfully");
          const expirationTime = new Date();
          expirationTime.setDate(expirationTime.getDate() + 7);
          Cookies.set("user", JSON.stringify(responseData.data), {
            expires: expirationTime,
          });
          Cookies.set("permissions", JSON.stringify(rolePermissions.data), {
            expires: expirationTime,
          });
          return responseData.data;
        }
      } else {
        throw new Error("Access Denied! Invalid Credentials");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const logoutUser = (setIsLoggedIn) => (dispatch) => {
  Cookies.remove("user");
  setIsLoggedIn(false);
  toast.success("Logged Out Successfully");
  dispatch({ type: "LOGOUT" });
};
const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    user: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        console.log(action.error.message);
        if (action.error.message === "Request failed with status code 401") {
          state.error = "Access Denied! Invalid Credentials";
        } else {
          state.error = action.error.message;
        }
      });
  },
});

export default userSlice.reducer;
