import React, { useState, useEffect, useRef } from "react";
import "../../common/styles/customFormInputStyle.css";

const CustomFormMultiSelect = ({
  id,
  label,
  required,
  options,
  selectedValue,
  onChange,
  placeholder,
  disabled = false,
  setSelectedValue = () => {},
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    if (!isOpen && disabled === false) setIsOpen(true);
  };

  const handleSelect = (option) => {
    onChange(option);
    if (selectedValue) {
      setIsOpen(false);
    }
  };
  // const handleRemove = (option) => {
  //   onChange(selectedValue.filter((item) => item._id !== option._id));
  //   if (selectedValue) {
  //     setIsOpen(true);
  //   }
  // };

  const normalizeString = (str) => str?.toLowerCase().replace(/[^a-z0-9]/g, "");

  const filteredOptions = options.filter(
    (option) =>
      normalizeString(option._id).includes(normalizeString(searchTerm))
    //  &&
    // !selectedValue === option._id
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="customFormInput" ref={dropdownRef}>
      <label className="customFormLabel" htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div
        className="customFormInputField input-style"
        onClick={handleToggleDropdown}
      >
        <div className="customFormInputBox">
          <span className="placeholder">
            {selectedValue ? selectedValue : placeholder}
          </span>
        </div>
        {isOpen && (
          <div className="dropdown">
            <div className="input-style" style={{ width: "100%" }}>
              <input
                type="text"
                placeholder="Search & Select..."
                className="search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <div className="dropdown-options">
              {filteredOptions?.length > 0 ? (
                filteredOptions?.map((option) => (
                  <div
                    key={option._id}
                    className="dropdown-option"
                    onClick={() => handleSelect(option)}
                  >
                    {option._id} ({option.symbol})
                  </div>
                ))
              ) : (
                <span>No Options</span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomFormMultiSelect;
