/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "../../../../common/styles/loginStyle.css";
import CustomButton from "../../../reusableComponents/customButton";
import { useNavigate } from "react-router-dom";
import PreLoader from "../../../reusableComponents/preLoader";
import Logo from "../../../../common/svg/bacsys-logo-black.svg";
import CustomFormInput from "../../../reusableComponents/customFormInput";
import CryptoJS from "crypto-js";
import { useMenu } from "../../../../common/utils/menuContext";
import { validateInputs } from "../../../../common/utils/validateInputs";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import axios from "axios";
import { IoIosCloseCircle } from "react-icons/io";
import LandingImage from "../../../../assets/landing.png";
import ReCAPTCHA from "react-google-recaptcha";
import { encryptEmail } from "../../../../common/utils/tokenGenerator";

const Register = () => {
  const captchaRef = useRef(null);
  const [orgPage, setOrgPage] = useState(false);
  const siteKey = process.env.REACT_APP_SITE_KEY;
  const [joinOrg, setJoinOrg] = useState(false);
  const [inviteEmails, setInviteEmails] = useState();
  const [userDetails, setUserDetails] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    orgName: "",
  });
  const navigate = useNavigate();
  const [loginPopup, setLoginPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inviteUser, setInviteUser] = useState(false);
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_USER_ENDPOINT;
  const host = config?.REACT_APP_HOST;
  const goToSignIn = () => {
    navigate("/login");
  };
  const getHashedPassword = () => {
    const secretKey = "supersecretpassw";
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const hashedPass = CryptoJS.AES.encrypt(userDetails?.password, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return hashedPass;
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const token = await captchaRef?.current?.executeAsync();
      if (!token) {
        throw new Error("Captcha verification failed");
      }
      captchaRef.current.reset();
      if (
        !userDetails.firstName ||
        !userDetails?.email ||
        !userDetails?.password ||
        !userDetails?.confirmPassword
      ) {
        throw new Error("Please fill in the required details");
      }
      if (!validateInputs(userDetails.email, userDetails?.password)) {
        throw new Error("Invalid input");
      }
      if (userDetails?.password !== userDetails?.confirmPassword) {
        throw new Error("Passwords do not match");
      }
      const pass = getHashedPassword();
      setUserDetails({ ...userDetails, password: pass, _id: uuid() });
      const verify_email = await axios.post(`${dbUrl}/verify_email`, {
        email: userDetails?.email,
      });
      if (verify_email?.data?.status === 400) {
        throw new Error(verify_email?.data?.message);
      } else if (verify_email?.data?.status === 409) {
        setLoginPopup(true);
        throw new Error(verify_email?.data?.message);
      } else if (verify_email?.data?.status === 200) {
        setOrgPage(true);
      }
    } catch (error) {
      toast.warning(error.message || "An unexpected error occurred");
    } finally {
    }
  };

  const handleCaptchaExpired = () => {
    toast.warning("Captcha expired, please try again.");
  };

  const handleCaptchaError = () => {
    toast.error("An error occurred with Captcha. Please try again.");
  };
  const createOrg = async () => {
    setLoading(true);
    if (!userDetails?.orgName) {
      toast.warning("Organization Name is Required");
    } else {
      const createOrg = await axios.post(`${dbUrl}/create_org`, userDetails);
      if (createOrg?.data?.status === 400) {
        setLoading(false);
        toast.warning(createOrg?.data?.message);
        return;
      } else if (createOrg?.data?.status === 409) {
        setLoading(false);
        toast.warning(createOrg?.data?.message);
        return;
      } else if (createOrg?.data?.status === 200) {
        setLoading(false);
        setInviteUser(true);
        toast.success(createOrg?.data?.message);
      }
    }
  };

  const joinOrganization = async () => {
    setLoading(true);
    if (!userDetails?.orgName) {
      toast.warning("Organization Name is Required");
      setLoading(true);
      return;
    } else {
      const createOrg = await axios.post(
        `${dbUrl}/add_new_user_to_org`,
        userDetails
      );
      if (createOrg?.data?.status === 400) {
        setLoading(false);
        toast.warning(createOrg?.data?.message);
        return;
      } else if (createOrg?.data?.status === 404) {
        setLoading(false);
        toast.warning(createOrg?.data?.message);
        return;
      } else if (createOrg?.data?.status === 409) {
        setLoading(false);
        toast.warning(createOrg?.data?.message);
        return;
      } else if (createOrg?.data?.status === 200) {
        setLoading(false);
        toast.success(createOrg?.data?.message);
        navigate("/login");
      }
    }
  };

  const sendInviteEmails = async () => {
    if (!inviteEmails || inviteEmails.trim() === "") {
      toast.warning("Emails are Required to Invite Users");
      return;
    }

    const emailArray = inviteEmails.split(",").map((email) => email.trim());
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const invalidEmails = emailArray.filter((email) => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      toast.warning(`Invalid email(s): ${invalidEmails.join(", ")}`);
      return;
    }
    const orgId = userDetails?._id;
    const orgName = userDetails?.orgName;
    emailArray.forEach(async (email) => {
      const payload = `${email}|${orgId}|${orgName}`;
      const inviteToken = encryptEmail(payload);
      const verifyEmail = await axios.post(`${dbUrl}/verify_email`, {
        email: email,
      });
      if (verifyEmail?.data?.status === 400) {
        toast.warning(verifyEmail?.data?.message);
      } else if (verifyEmail?.data?.status === 409) {
        toast.warning(verifyEmail?.data?.message);
      } else if (verifyEmail?.data?.status === 200) {
        const sendInvite = await axios.post(`${dbUrl}/send_invite`, {
          email: email,
          inviteUrl: `http://${host}/invite/${encodeURIComponent(inviteToken)}`,
        });
        if (sendInvite?.data?.status === 200) {
          setInviteUser(false);
          toast.success(
            "Invite Sent Successfully to the respective Email Addresses"
          );
          navigate("/login");
        } else if (sendInvite?.data?.status === 500) {
          toast.error(sendInvite?.data?.message);
        }
        console.log(
          `Invite URL for ${email}: http://localhost:3000/invite/${encodeURIComponent(
            inviteToken
          )}`
        );
      }
    });
  };

  return !orgPage ? (
    <div className="register">
      <div className="registerForm">
        <div className="registerLoginlogo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="registerContent">
          <div className="registerFormContent">
            <div className="signUpText">Create your account</div>
            <form onSubmit={handleSignIn} className="registerContentForm">
              <CustomFormInput
                id="firstName"
                label="First Name"
                type="text"
                value={userDetails?.firstName}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, firstName: e.target.value })
                }
                required
                placeholder="Enter your First Name"
              />
              <CustomFormInput
                id="lastName"
                label="Last Name"
                type="text"
                value={userDetails?.lastName}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, lastName: e.target.value })
                }
                // required
                placeholder="Enter your Last Name"
              />
              <CustomFormInput
                id="email"
                label="Email Address"
                type="email"
                value={userDetails?.email}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, email: e.target.value })
                }
                required
                placeholder="Enter your e-mail address"
              />
              <CustomFormInput
                id="password"
                label="Password"
                type="password"
                value={userDetails?.password}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, password: e.target.value })
                }
                required
                placeholder="Enter your Password"
              />
              <CustomFormInput
                id="confirmPassword"
                label="Confirm Password"
                type="password"
                value={userDetails?.confirmPassword}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    confirmPassword: e.target.value,
                  })
                }
                required
                placeholder="Enter your Password Again"
              />
              <CustomFormInput
                id="companyName"
                label="Company Name"
                type="text"
                value={userDetails?.companyName}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    companyName: e.target.value,
                  })
                }
                placeholder="Enter your Company Name "
              />
            </form>
            <div className="dontHaveAnAccount">
              Have an Account ?
              <span className="link signUpLink" onClick={goToSignIn}>
                Sign In
              </span>
            </div>
            <div className="loginBtnContainer">
              <CustomButton
                type="submit"
                buttonName="Sign Up"
                className="orgBtnStyle"
                handleClick={handleSignIn}
              />
            </div>
            {loading ? (
              <PreLoader size={80} color="var(--primary-color)" />
            ) : (
              ""
            )}
          </div>
          <div className="registerLandingImage">
            <img src={LandingImage} alt="landingImage" />
          </div>
        </div>
      </div>
      {loginPopup === true && (
        <div className="overlay">
          <div className="loginPopupContent">
            <div className="orgForm">
              <IoIosCloseCircle
                className="closeIcon"
                onClick={() => setLoginPopup(false)}
              />
              <div className="orgHeading">
                <span>Would you like to Sign-In ?</span>
              </div>
              <CustomButton
                buttonName={"Go to Sign-in"}
                className={"orgBtnStyle"}
                key={"createOrg"}
                handleClick={() => goToSignIn()}
              />
            </div>
          </div>
        </div>
      )}
      <ReCAPTCHA
        sitekey={siteKey}
        size="invisible"
        ref={captchaRef}
        onExpired={handleCaptchaExpired}
        onError={handleCaptchaError}
      />
    </div>
  ) : (
    <>
      {inviteUser ? (
        <div className="org">
          <div className="orgContent">
            <div className="orgHeading">
              <span>Invite Users</span>
            </div>
            <span className="inviteSubHeading">
              An organization is a workspace where teams collect, manage and
              control IOT Data
            </span>
            <div className="orgForm">
              <div className="orgFormContent">
                <CustomFormInput
                  id="orgName"
                  label="Invite Emails"
                  type="text"
                  value={inviteEmails}
                  onChange={(e) => setInviteEmails(e.target.value)}
                  required
                  placeholder="Enter Email Addresses Seperated with a comma (,)"
                />
                <div className="orgBtns">
                  <CustomButton
                    buttonName={"Invite Members"}
                    className={"orgBtnStyle"}
                    key={"createOrg"}
                    handleClick={sendInviteEmails}
                  />
                </div>
                <div className="skipInvite">
                  <span className="link signUpLink" onClick={goToSignIn}>
                    <u>Skip Invite</u>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="org">
          <div className="orgContent">
            <div className="orgHeading">
              <IoArrowBackCircleSharp
                className="orgBackBtn"
                onClick={() => setOrgPage(false)}
              />
              <span>
                {joinOrg ? "Join an organization" : "Create a new organization"}
              </span>
            </div>
            <span className="orgSubHeading">
              An organization is a workspace where teams collect, manage and
              control IOT Data
            </span>
            <div className="orgForm">
              <div className="orgFormContent">
                <CustomFormInput
                  id="orgName"
                  label="Organization Name"
                  type="text"
                  value={userDetails?.orgName}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, orgName: e.target.value })
                  }
                  required
                  placeholder="Enter Organization Name"
                />
                <div className="orgBtns">
                  <CustomButton
                    buttonName={
                      joinOrg ? "Join Organization" : "Create Organization"
                    }
                    className={"orgBtnStyle"}
                    key={"createOrg"}
                    handleClick={joinOrg ? joinOrganization : createOrg}
                  />
                </div>
              </div>
            </div>
            <div
              className="orgJoinContent"
              onClick={() => {
                joinOrg ? setJoinOrg(false) : setJoinOrg(true);
              }}
            >
              {joinOrg
                ? "Create a New Organization instead"
                : "Join existing organization instead"}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
