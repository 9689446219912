/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import "../../../../../common/styles/rolesSettingsStyle.css";
import Role from "./role.jsx";
import { useState } from "react";
import { useEffect } from "react";
import { IoMdSave } from "react-icons/io";
import { toast } from "react-toastify";
import CustomHeader from "../../../../reusableComponents/customHeader.jsx";
import { useMenu } from "../../../../../common/utils/menuContext.js";
import api from "../../../../../common/utils/axiosRequest.js";

const RolesSettings = () => {
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);
  const [roles, setRoles] = useState([]);
  const [roleName, setRoleName] = useState();
  const [addRolePopup, setAddRolePopup] = useState(false);

  const fetchRoles = async () => {
    try {
      const getAllRoles = await api.get(`${dbUrl}/roles/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      setRoles(getAllRoles.data.data.rows.map((role) => role.doc));
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  useEffect(() => {
    fetchRoles();
    console.log(roles);
  }, []);

  const handleWritePermissionChange = (roleName, updatedRole) => {
    const updatedRoles = roles.map((role) =>
      role._id === roleName ? updatedRole : role
    );
    console.log(updatedRoles);
    setRoles(updatedRoles);
  };

  const handleSaveRoles = async () => {
    try {
      for (const role of roles) {
        await api.put(`${dbUrl}/roles/${role._id}?rev=${role._rev}`, role, {
          headers: {
            Authorization: `Basic ${base64Credentials}`,
          },
        });
      }
      toast.success("Roles Updated");
      fetchRoles();
    } catch (error) {
      toast.error(error);
      fetchRoles();
    }
  };
  const createRole = async () => {
    const data = {
      _id: roleName,
      displays: {
        read: false,
        write: false,
      },
      devices: {
        read: false,
        write: false,
      },
      alarms: {
        read: false,
        write: false,
      },
      trends: {
        read: false,
        write: false,
      },
      settings: {
        read: false,
        write: false,
      },
      rules: {
        read: false,
        write: false,
      },
      schedules: {
        read: false,
        write: false,
      },
    };
    try {
      const createRoleInDB = await api.post(`${dbUrl}/roles`, data, {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });
      if (createRoleInDB.status === 200 || createRoleInDB.status === 201) {
        toast.success("Role Created Successfully");
        fetchRoles();
        setAddRolePopup(false);
      }
    } catch (error) {
      toast.error(error);
      fetchRoles();
      setAddRolePopup(false);
    }
  };
  return (
    <div className={`rolesSettings `}>
      <CustomHeader heading={"Roles"} />
      <div className="rolesSettingsContents">
        <div className="rolesSettingsContent">
          <div className="rolesSettingsRolesHeading">
            <p className="rolesSettingsRolesHeadingRoleName">Role Name</p>
            <div className="rolesSettingsRoles">
              <p>Display</p>
              <p>Devices</p>
              <p>Alarms</p>
              <p style={{ position: "relative", left: "5px" }}>Trends</p>
              <p style={{ position: "relative", left: "15px" }}>Rules</p>
              <p style={{ position: "relative", left: "13px" }}>Schedules</p>
              <p style={{ position: "relative", left: "13px" }}>Reports</p>
            </div>
          </div>
          <div className={`rolesSettingsRolesReadWrite `}>
            <p>RW</p>
            <p>RW</p>
            <p>RW</p>
            <p>RW</p>
            <p>RW</p>
            <p>RW</p>
            <p>RW</p>
          </div>
          {roles.map((role) => (
            <Role
              roleName={role._id}
              roles={roles}
              handleWritePermissionChange={handleWritePermissionChange}
            />
          ))}
        </div>
        <div className="rolesSettingsBtns">
          <div
            className="roleSettingsSaveBtn filled-btn-style"
            onClick={handleSaveRoles}
          >
            <IoMdSave className="roleSettingsSaveBtnIcon" />
            Save Roles
          </div>
        </div>
        {addRolePopup && (
          <div className="preLoaderBackground">
            <div className="addRoleForm">
              <div className="addRoleHeading">Create Role</div>
              <div className="addRoleFormInputs">
                <label htmlFor="roleName">Role Name</label>
                <input
                  type="text"
                  placeholder="Enter Role Name"
                  value={roleName}
                  onChange={(e) => setRoleName(e.target.value)}
                />
              </div>
              <div className="addRoleFormBtns">
                <div className="addRoleFormBtn" onClick={createRole}>
                  Create
                </div>
                <div
                  className="addRoleFormBtn cancel"
                  onClick={() => setAddRolePopup(false)}
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RolesSettings;
