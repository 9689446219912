import React, { useEffect, useState } from "react";
import "../../../../../common/styles/widgetsStyle.css";

import { useMenu } from "../../../../../common/utils/menuContext";
import { MdOutlineDeviceThermostat } from "react-icons/md";
import CustomFormSelect from "../../../../reusableComponents/customFormSelect";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import { toast } from "react-toastify";
import ColorPicker from "../../../../../common/utils/colorPicker";
import CustomFormMultiSelect from "../../../../reusableComponents/customFormMultiSelect";
import api from "../../../../../common/utils/axiosRequest";

const FloorLayoutWidgets = ({ selectedFloorWidget, widgets, setWidgets }) => {
  const { floorLayout, theme, config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const [colors, setColors] = useState({
    edgeColor: "",
    pointColor: "",
  });
  const [points, setPoints] = useState([]);
  const [displays, setDisplays] = useState([]);

  const [currentSelectedPin, setCurrentSelectedPin] = useState({
    id: "",
    type: "",
    color: "",
    pointName: "",
    pointValue: "",
    pointDescription: "",
    selectedPoint: "",
    deviceId: "",
    pointActive: "",
    parentId: "",
    selectedDisplay: "",
    selectedDisplayName: "",
  });

  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const pointsDatabase = "points";
        const pointResponse = await api.get(
          `${dbUrl}/${pointsDatabase}/_all_docs`,
          {
            params: {
              include_docs: true,
            },
          }
        );
        setPoints(pointResponse.data.data.rows.map((row) => row.doc));
      } catch (error) {
        console.log(
          `Error Occurred While fetching Output/Value Points: ${error}`
        );
      }
    };
    const fetchDisplays = async () => {
      try {
        const getDashboards = await api.get(
          `${dbUrl}/displays?selector=_id,name`
        );
        if (getDashboards.status === 200) {
          setDisplays(getDashboards.data.data.rows);
        }
      } catch (error) {}
    };
    fetchDisplays();
    fetchPoints();
  }, [dbUrl]);

  const handleUpdate = () => {
    if (!currentSelectedPin.selectedPoint || !currentSelectedPin.pointName) {
      toast.warning("Please fill all the required fields");
      return;
    }

    const { parentId, id } = currentSelectedPin;

    setWidgets((prevWidgets) => {
      return {
        ...prevWidgets,
        [parentId]: prevWidgets[parentId].map((widget) =>
          widget.id === id ? { ...widget, ...currentSelectedPin } : widget
        ),
      };
    });
    toast.success("Pin Updated successfully");
  };

  useEffect(() => {
    setCurrentSelectedPin({
      id: selectedFloorWidget?.id === "" ? "" : selectedFloorWidget?.id,
      type: selectedFloorWidget?.type === "" ? "" : selectedFloorWidget?.type,
      color:
        selectedFloorWidget?.color === "" ? "#000" : selectedFloorWidget?.color,
      selectedPoint:
        selectedFloorWidget?.selectedPoint === ""
          ? ""
          : selectedFloorWidget?.selectedPoint,
      pointName:
        selectedFloorWidget?.pointName === ""
          ? ""
          : selectedFloorWidget?.pointName,
      pointValue:
        selectedFloorWidget?.pointValue === ""
          ? ""
          : selectedFloorWidget?.pointValue,
      pointDescription:
        selectedFloorWidget?.pointDescription === ""
          ? ""
          : selectedFloorWidget?.pointDescription,
      pointActive:
        selectedFloorWidget?.pointActive === ""
          ? ""
          : selectedFloorWidget?.pointActive,
      parentId:
        selectedFloorWidget?.parentId === ""
          ? ""
          : selectedFloorWidget?.parentId,
      selectedDisplay:
        selectedFloorWidget?.selectedDisplay === ""
          ? ""
          : selectedFloorWidget?.selectedDisplay,
      deviceId:
        selectedFloorWidget?.deviceId === ""
          ? ""
          : selectedFloorWidget?.deviceId,
    });
  }, [selectedFloorWidget]);

  const handleDragStart = (e, type, color) => {
    e.dataTransfer.setData("widgetType", type);
    e.dataTransfer.setData("color", color);
  };

  function getContrastingColor(hexColor) {
    hexColor = hexColor.replace("#", "");
    let r = parseInt(hexColor.substring(0, 2), 16);
    let g = parseInt(hexColor.substring(2, 4), 16);
    let b = parseInt(hexColor.substring(4, 6), 16);
    let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? "black" : "white";
  }

  return floorLayout ? (
    <div className={`floorWidgets container-style`}>
      <div className="floorWidgetsContent">
        <div className={`floorWidgetsHeader`}>Floor Layout Widgets</div>
        <div className="floorLayoutWidget">
          <span className="floorWidgetsSubHeader">Draggable Widgets</span>
          <div className="floorWidgetGroup">
            <div className="draggableItemsContainer">
              <span
                draggable
                onDragStart={(e) =>
                  handleDragStart(e, "point", colors.pointColor)
                }
              >
                <MdOutlineDeviceThermostat
                  size={25}
                  color={
                    colors.pointColor
                      ? colors.pointColor
                      : theme === "dark"
                      ? "white"
                      : "black"
                  }
                  style={{ cursor: "grab" }}
                />
              </span>
              <span
                draggable
                onDragStart={(e) =>
                  handleDragStart(e, "button", colors?.pointColor)
                }
              >
                <button
                  style={{
                    backgroundColor: colors?.pointColor
                      ? colors?.pointColor
                      : theme === "dark"
                      ? "white"
                      : "black",
                    cursor: "grab",
                    padding: "8px",
                    borderRadius: "10px",
                    color: getContrastingColor(
                      colors?.pointColor
                        ? colors?.pointColor
                        : theme === "dark"
                        ? "#FFFFFF"
                        : "#000000"
                    ),
                  }}
                >
                  Button
                </button>
              </span>
            </div>
            <ColorPicker
              initialColor={colors?.pointColor}
              onColorChange={(color) =>
                setColors({ ...colors, pointColor: color })
              }
              label={false}
            />
          </div>
        </div>
      </div>
      {selectedFloorWidget?.type === "point" ? (
        <div className="floorWidgetsContent2">
          <div className="floorWidgetsSubHeader">Sensor Details</div>
          <div className="floorWidgetsFormContent">
            <CustomFormMultiSelect
              id={"selectPoint"}
              label={"Map a Point"}
              max={1}
              onChange={(selectedOptions) => {
                const selectedPoint = points.find(
                  (point) => point._id === selectedOptions[0]?.value
                );

                setCurrentSelectedPin({
                  ...currentSelectedPin,
                  pointValue: selectedPoint
                    ? selectedPoint.pointPresentValue
                    : "",
                  pointName: selectedPoint ? selectedPoint.pointName : "",
                  pointDescription: selectedPoint
                    ? selectedPoint.pointDetails
                    : "",
                  selectedPoint: selectedOptions[0]?.value || "",
                  pointActive: selectedPoint
                    ? selectedPoint.pointLastPollStatus
                    : "",
                  deviceId: selectedPoint
                    ? selectedPoint?.pointDeviceInstance
                    : "",
                });
              }}
              placeholder={"Select Max 1 Point"}
              selectedValues={
                currentSelectedPin?.selectedPoint === ""
                  ? []
                  : [
                      {
                        value: currentSelectedPin.selectedPoint,
                        label: `${currentSelectedPin?.deviceId}-${currentSelectedPin?.pointName}`,
                      },
                    ]
              }
              required={true}
              options={[
                ...points?.map((point) => ({
                  label: `${point?.pointDeviceInstance}-${point?.pointName}`,
                  value: point?._id,
                })),
              ]}
            />

            <CustomFormInput
              id={"sensorName"}
              label={"Sensor Name"}
              type={"text"}
              onChange={(e) =>
                setCurrentSelectedPin({
                  ...currentSelectedPin,
                  pointName: e.target.value,
                })
              }
              value={currentSelectedPin.pointName}
              required={true}
            />
            <CustomFormInput
              id={"sensorDesc"}
              label={"Sensor Description"}
              type={"text"}
              onChange={(e) =>
                setCurrentSelectedPin({
                  ...currentSelectedPin,
                  pointDescription: e.target.value,
                })
              }
              value={currentSelectedPin.pointDescription}
            />
            <div className="floorWidgetsFormContentGroup">
              <label htmlFor="color">Change Color</label>

              <ColorPicker
                initialColor={currentSelectedPin.color}
                onColorChange={(color) =>
                  setCurrentSelectedPin({
                    ...currentSelectedPin,
                    color: color,
                  })
                }
                label={false}
              />
            </div>
          </div>
          <div className="updateBtn">
            <div className="filled-btn-style" onClick={() => handleUpdate()}>
              Update
            </div>
          </div>
        </div>
      ) : (
        <div className="floorWidgetsContent2" style={{ marginTop: "5px" }}>
          <div
            className="floorWidgetsSubHeader"
            style={{ marginBottom: "20px" }}
          >
            Link to Dislpay
          </div>
          <div className="floorWidgetsFormContent">
            <CustomFormSelect
              id={"selectPoint"}
              label={"Select a Display"}
              options={[
                { value: "", label: "Select Display" },
                ...displays?.map((display) => ({
                  label: `${display?.name}`,
                  value: display?._id,
                })),
              ]}
              onChange={(e) => {
                const selectedDisplay = displays.find(
                  (display) => display._id === e.target.value
                );
                setCurrentSelectedPin({
                  ...currentSelectedPin,
                  selectedDisplay: e.target.value,
                  selectedDisplayName: selectedDisplay.name,
                  selectedPoint: e.target.value,
                  pointName: selectedDisplay.name,
                });
              }}
              value={currentSelectedPin.selectedDisplay}
              required={true}
            />
            <CustomFormInput
              id={"sensorName"}
              label={"Button Name"}
              type={"text"}
              onChange={(e) =>
                setCurrentSelectedPin({
                  ...currentSelectedPin,
                  pointName: e.target.value,
                })
              }
              value={currentSelectedPin.pointName}
              required={true}
            />
            <div className="floorWidgetsFormContentGroup">
              <label>Change Button Color</label>
              <ColorPicker
                initialColor={currentSelectedPin.color}
                onColorChange={(color) =>
                  setCurrentSelectedPin({
                    ...currentSelectedPin,
                    color: color,
                  })
                }
                label={false}
              />
            </div>
          </div>
          <div className="updateBtn">
            <div className="filled-btn-style" onClick={() => handleUpdate()}>
              Update
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default FloorLayoutWidgets;
