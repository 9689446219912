import React, { useEffect, useState } from "react";
import "../../../../../common/styles/alarmTableStyle.css";

import { debounce } from "lodash";
import AlarmTableFilter from "./alarmTableFilter.jsx";
import CustomButton from "../../../../reusableComponents/customButton.jsx";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";

import TruncateText from "../../../../../common/utils/truncateText";
import CustomTable from "../../../../reusableComponents/customTable.jsx";
export default function AlarmTable({
  _id,
  priority,
  dateTime,
  source,
  sourceState,
  ackStatus,
  eventDescription,
  message,
  fetchAlarms,
  selectedAlarms,
  setSelectedAlarms,
  setConfirmDelete,
  acknowledgeAllAlarms,
  permissions,
  disableBtns,
  acknowledgeAlarms,
  convertToCSV,
  setIsLoading,
  ackBy,
}) {
  const [filters, setFilters] = useState({
    priority: "",
    ackStatus: "",
    source: "",
    startDate: "",
    endDate: "",
    ackBy: "",
  });

  const rows = createData(
    _id,
    priority,
    dateTime,
    source,
    sourceState,
    ackStatus,
    eventDescription,
    message,
    ackBy
  );
  // setAlarmData(rows)

  function createData(
    _id,
    priority,
    dateTime,
    source,
    sourceState,
    ackStatus,
    eventDescription,
    message,
    ackBy
  ) {
    const data = [];
    for (let i = 0; i < priority?.length; i++) {
      const row = {
        _id: _id[i],
        alarmPriority: priority[i],
        alarmStartTime: dateTime[i],
        source: source[i],
        sourceState: sourceState[i],
        ackStatus: ackStatus[i],
        eventDescription: eventDescription[i],
        message: message[i],
        ackBy: ackBy[i],
      };

      const startDate = new Date(filters.startDate);
      const endDate = new Date(filters.endDate);
      const itemDate = new Date(dateTime[i]);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      if (
        (!filters.priority || row.alarmPriority === filters.priority) &&
        (!filters.ackStatus ||
          (filters.ackStatus === "Acknowledged" &&
            row.ackStatus === "Acknowledged") ||
          (filters.ackStatus === "Unacknowledged" &&
            row.ackStatus !== "Acknowledged")) &&
        (!filters.source || row.source === filters.source) &&
        (!filters.ackBy || row.ackBy === filters.ackBy) &&
        (!filters.startDate || itemDate >= startDate) &&
        (!filters.endDate || itemDate <= endDate)
      ) {
        data.push(row);
      }
    }
    return data;
  }
  const debouncedSetFilters = debounce((source, ackBy) => {
    setFilters({ ...filters, source: source, ackBy: ackBy });
  }, 300);

  const handleExportCSV = () => {
    setIsLoading(true);
    const csvData = convertToCSV(rows);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "alarms_data.csv");
    setIsLoading(false);
  };

  const handleExportPDF = () => {
    setIsLoading(true);

    const data = rows.map((alarm) => ({
      Priority: alarm.alarmPriority || "",
      "Date Time": alarm.alarmStartTime || "",
      Source: alarm.source || "",
      "Source State": alarm.sourceState || "",
      "Ack Status": alarm.ackStatus || "Unacknowledged",
      "Event Description": TruncateText(
        alarm.highLimitMessage || alarm.lowLimitMessage || "",
        10
      ),
      Message: alarm.message || "",
      "Acknowledged By": alarm.ackBy || "",
    }));

    const columns = [
      { key: "Priority", label: "Priority" },
      { key: "Date Time", label: "Date Time" },
      { key: "Source", label: "Source" },
      { key: "Source State", label: "Alarm State" },
      { key: "Ack Status", label: "Ack Status" },
      { key: "Event Description", label: "Event Description" },
      { key: "Message", label: "Message" },
      { key: "Acknowledged By", label: "Acknowledged By" },
    ];

    const pdf = new jsPDF();
    pdf.autoTable({
      theme: "grid",
      head: [columns.map((column) => column.label)],
      body: data.map((row) => columns.map((column) => row[column.key])),
    });

    pdf.save("alarms_data.pdf");

    setIsLoading(false);
  };
  const headings = [
    { id: "alarmPriority", label: "Priority" },
    { id: "alarmStartTime", label: "Date Time" },
    {
      id: "source",
      label: "Source",
    },
    {
      id: "sourceState",
      label: "Alarm State",
    },
    {
      id: "ackStatus",
      label: "Ack Status",
    },
    {
      id: "eventDescription",
      label: "Event Description",
    },
    {
      id: "message",
      label: "Message",
    },
    {
      id: "ackBy",
      label: "Acknowledged By",
    },
  ];

  return (
    <>
      <AlarmTableFilter
        source={source}
        setFilters={setFilters}
        ackBy={ackBy}
        debouncedSetFilters={debouncedSetFilters}
        filters={filters}
        fetchAlarms={fetchAlarms}
      />
      <CustomTable
        data={rows}
        headings={headings}
        key={"alarmTable"}
        totalRows={rows.length}
        totalPlaceholder={"Total Alarms"}
        dateTimeHeading={"alarmStartTime"}
        checkBoxEnable={true}
        onRowSelect={setSelectedAlarms}
      />
      <div className="alarmBtns">
        <CustomButton
          className={`filled-btn-style`}
          buttonName="Acknowledge Alarms"
          handleClick={acknowledgeAlarms}
        />
        {/* <CustomButton
          className={`filled-btn-style`}
          buttonName="Acknowledge All Alarms"
          handleClick={acknowledgeAllAlarms}
        /> */}
        {permissions.alarms.write && (
          <>
            <CustomButton
              className={`filled-btn-style `}
              buttonName="Clear All Alarms"
              handleClick={() => setConfirmDelete(true)}
              disabled={disableBtns}
            />
          </>
        )}

        <CustomButton
          className={`outline-btn-style `}
          buttonName="Export CSV"
          handleClick={handleExportCSV}
        />
        <CustomButton
          className={`outline-btn-style `}
          buttonName="Export PDF"
          handleClick={handleExportPDF}
        />
      </div>
    </>
  );
}
