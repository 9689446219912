import React from "react";
import { useState } from "react";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import CustomButton from "../../../../reusableComponents/customButton";
import { toast } from "react-toastify";
import PreLoader from "../../../../reusableComponents/preLoader";
import { useMenu } from "../../../../../common/utils/menuContext";
import formatCurrentDateTime from "../../../../../common/utils/formattedDateTime";
import { isEmpty } from "../../../../../common/utils/isEmpty";
import api from "../../../../../common/utils/axiosRequest";

const AddDeviceManualForm = ({ handleClose, networkProps }) => {
  const { setReloadData, config } = useMenu();
  const [deviceInstance, setDeviceInstance] = useState();
  const [deviceName, setDeviceName] = useState("");
  const [deviceDesc, setDeviceDesc] = useState("");
  const [deviceMac, setDeviceMac] = useState("");
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [isLoading, setIsLoading] = useState(false);
  async function addDevice() {
    try {
      if (!isEmpty(deviceName, deviceInstance, deviceMac)) {
        const documentId = networkProps?._id;
        const getResponse = await api.get(`${dbUrl}/networks/${documentId}`);
        const existingDocument = getResponse.data.data;
        const updatedDevices = {
          deviceID: deviceMac,
          deviceInstance: parseInt(deviceInstance, 10),
        };
        console.log(updatedDevices);
        for (let i = 0; i < existingDocument.devices.length; i++) {
          const device = existingDocument.devices[i];
          if (
            updatedDevices.deviceID === device.deviceID &&
            updatedDevices.deviceInstance === device.deviceInstance
          ) {
            device.someField = updatedDevices.someField;
            delete updatedDevices.deviceID;
            delete updatedDevices.deviceInstance;
            break;
          }
        }
        if (Object.keys(updatedDevices).length !== 0) {
          console.log(updatedDevices);
          existingDocument.devices.push(updatedDevices);
          const putResponse = await api.put(
            `${dbUrl}/networks/${documentId}`,
            existingDocument
          );

          if (putResponse.status === 200) {
            const newDevice = {
              _id: deviceInstance.toString(),
              deviceId: parseInt(deviceInstance, 10),
              deviceName: deviceName,
              deviceDetails: deviceDesc,
              deviceNetwork: networkProps?.networkInstance,
              deviceType: "BacnetTCP",
              deviceStatus: "Active",
              deviceEnable: true,
              lastPollStatus: "Success",
              lastPollTime: formatCurrentDateTime(),
              // devicePollFrequency: pollFrequency,
              deviceInstance: parseInt(deviceInstance, 10),
              deviceMacAddress: deviceMac,
              networkAddress: networkProps?.networkAddress,
              networkPort: networkProps?.networkPort,
              deviceSubnet: networkProps?.networkSubnet,
              deviceGateway: networkProps?.networkGateway,
              networkId: networkProps?._id,
              points: [],
            };

            try {
              const createDeviceResponse = await api.post(
                `${dbUrl}/devices`,
                newDevice
              );

              if (createDeviceResponse.status === 200) {
                setReloadData(true);
                toast.success("Devices Imported Successfully");
                setIsLoading(false);
                handleClose();
              } else {
                console.error(
                  "Device creation failed:",
                  createDeviceResponse.status
                );
                toast.error("Device creation failed");
              }
            } catch (error) {
              console.error("Error creating device:", error);
              toast.error("Error creating device");
            }
          }
        } else {
          toast.error(
            "Error Importing Devices: Device Already Exists with the same Address"
          );
          setIsLoading(false);
          handleClose();
        }
      } else {
        toast.warning("Please Fill the Required Fields");
      }
      // const checkDevice = await api.post(`${serverEndpoint}/ccheck_device`, {
      //   deviceId: parseInt(deviceAddress, 10),
      // });
    } catch (error) {
      toast.error(
        "Error Importing Devices: Device Already Exists with the same Address"
      );
      setIsLoading(false);
      handleClose();
      console.log(error);
    }
  }

  return (
    <div className="addDeviceManualForm">
      {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null}
      <div className="addDeviceManualFormContents">
        <div className="addDeviceManualFormContentsForm">
          <CustomFormInput
            id="deviceName"
            label="Device Name"
            onChange={(e) => setDeviceName(e.target.value)}
            value={deviceName}
            placeholder="Enter Device Name"
            required={true}
            type="text"
          />

          <CustomFormInput
            id="deviceDesc"
            label="Device Description"
            onChange={(e) => setDeviceDesc(e.target.value)}
            value={deviceDesc}
            placeholder="Enter Device Description"
            type="text"
          />

          <CustomFormInput
            id="deviceInstance"
            label="Device Instance"
            onChange={(e) => setDeviceInstance(e.target.value)}
            value={deviceInstance}
            placeholder="Enter Device Instance"
            type="number"
            required
          />

          <CustomFormInput
            id="deviceMac"
            label="Device MacAddress"
            onChange={(e) => setDeviceMac(e.target.value)}
            value={deviceMac}
            placeholder="Enter Device Mac Address"
            type="text"
            required
          />
        </div>
        <div className="addDeviceManualFormContentsBtn">
          <CustomButton
            buttonName="Add"
            className="addDeviceFormContentsSaveBtn"
            handleClick={addDevice}
          />
          <CustomButton
            buttonName="Cancel"
            className="addDeviceFormContentsCancelBtn"
            handleClick={() => handleClose()}
          />
        </div>
      </div>
    </div>
  );
};

export default AddDeviceManualForm;
