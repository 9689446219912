import React from "react";
import "../../../../common/styles/userProfileStyle.css";
import { CgProfile } from "react-icons/cg";
import { useState } from "react";
import CustomPhoneInput from "../../../reusableComponents/customPhoneInput";
import getUser from "../../../../configuration/localStorage/getUser";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { validateInputs } from "../../../../common/utils/validateInputs";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../../configuration/reducers/userReducer";
import { useAuth } from "../../../../common/utils/authContext";
import { isEmpty } from "../../../../common/utils/isEmpty";
import { useMenu } from "../../../../common/utils/menuContext";
import CustomFormInput from "../../../reusableComponents/customFormInput";
import api from "../../../../common/utils/axiosRequest";

const UserProfile = () => {
  const [user] = useState(getUser);
  const { setReloadUser, config } = useMenu();
  const [users, setUsers] = useState([]);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [companyName, setCompanyName] = useState(user.companyName);
  const [location, setLocation] = useState(user.location);
  const [emailAddress, setEmailAddress] = useState(user.emailAddress);
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);
  const [isLoading, setIsLoading] = useState(false);
  const { setIsLoggedIn } = useAuth();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser(setIsLoggedIn));
    setIsEmailChanged(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(`${dbUrl}/users/_all_docs`, {
          params: {
            include_docs: true,
          },
        });
        const userData = response.data.data.rows.map((user) => user.doc);
        setIsLoading(false);
        setUsers(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, [base64Credentials, dbUrl]);

  const updateUser = async () => {
    if (resetPassword) {
      if (!validateInputs(emailAddress, newPassword)) {
        return;
      }
    }

    const emailExists = users.some(
      (user) => user.emailAddress === emailAddress
    );
    if (emailExists && emailAddress !== user.emailAddress) {
      toast.warning(
        "Email address is already taken. Please use another email address."
      );
      return;
    }
    if (emailAddress !== user.emailAddress) {
      setIsEmailChanged(true);
    }
    try {
      let data;
      if (resetPassword) {
        const hashedPassword = await api.post(`${dbUrl}/reset_password`, {
          _id: user._id,
          newPassword: newPassword,
        });
        data = {
          username: emailAddress.split("@")[0],
          firstName: firstName,
          lastName: lastName,
          companyName: companyName,
          location: location,
          hashedPassword: hashedPassword?.data?.data,
          emailAddress: emailAddress,
          phoneNumber: phoneNumber,
        };
      } else {
        data = {
          username: emailAddress.split("@")[0],
          firstName: firstName,
          lastName: lastName,
          companyName: companyName,
          location: location,
          emailAddress: emailAddress,
          phoneNumber: phoneNumber,
        };
      }

      const updateData = await api.put(`${dbUrl}/users/${user._id}`, data);
      if (updateData.status === 200) {
        toast.success("User Updated Successfully");
        const updatedUser = { ...user, ...data };
        setReloadUser(true);
        Cookies.set("user", JSON.stringify(updatedUser));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleResetPassword = async () => {
    if (!isEmpty(oldPassword, newPassword, confirmPassword)) {
      if (newPassword !== confirmPassword) {
        toast.warning("New Password and Confirm Password do not match");
        return;
      }

      try {
        const isValid = await api.post(`${dbUrl}/verify_password`, {
          _id: user._id,
          password: oldPassword,
        });

        console.log(isValid);
        if (isValid.data.success === false || isValid.status === 404) {
          toast.warning("Old Password is incorrect");
        } else {
          updateUser();
          setResetPassword(false);
          setIsEmailChanged(true);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again.");
      }
    } else {
      toast.warning("Please Fill the Required Fields");
    }
  };

  return (
    <div className="userProfile">
      <div className="userProfileHeading">
        <CgProfile className="userProfileHeadingIcon" />
        My Profile
      </div>
      <div className="userProfileContent">
        <div className="userProfileContentInputs">
          <div className="userSettingsCreateUserFormLeft left">
            <CustomFormInput
              id="firstName"
              label="First Name"
              placeholder={"Enter your First Name"}
              required={true}
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <CustomFormInput
              id="lastName"
              label="Last Name"
              placeholder={"Enter your Last Name"}
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <CustomFormInput
              id="companyName"
              label="Company Name"
              placeholder={"Enter your Company Name"}
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <CustomFormInput
              id="location"
              label="Location"
              placeholder={"Enter your Location"}
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>

          <div className="userSettingsCreateUserFormRight right">
            <CustomFormInput
              id="emailAddress"
              label="Email Address"
              placeholder={"Enter your e-mail"}
              type="email"
              required={true}
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            <CustomPhoneInput
              id="phoneNumber"
              label="Phone Number"
              value={phoneNumber}
              onChange={setPhoneNumber}
              placeholder="Enter your Phone number"
            />
          </div>
        </div>
        <div className="userProfileBtns">
          <div
            className="userProfileUpdateBtn filled-btn-style"
            onClick={updateUser}
          >
            Save
          </div>
          <div
            className="userProfileUpdateBtn outline-btn-style"
            onClick={() => setResetPassword(true)}
          >
            Reset Password
          </div>
        </div>
      </div>
      {isEmailChanged ? (
        <div className="overlay">
          <div className="userProfileEmailChangedPopup">
            <div className="userProfileEmailChangedPopupContent">
              Email is Changed! You will need to Login Again
            </div>
            <div
              className="userProfileOkPopupBtn filled-btn-style"
              onClick={handleLogout}
            >
              Ok
            </div>
          </div>
        </div>
      ) : null}
      {resetPassword ? (
        <div className="overlay">
          <div className="userProfileEmailChangedPopup">
            <div className="userProfileEmailChangedPopupContent">
              <div className="userProfileEmailChangedPopupContentHeading">
                Reset Password
              </div>
              <div className="userProfileEmailChangedPopupContentForm">
                <CustomFormInput
                  id="oldPassword"
                  label="Old Password"
                  placeholder={"Enter Old Password"}
                  type="password"
                  required={true}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <CustomFormInput
                  id="newPassword"
                  label="New Password"
                  placeholder={"Enter New Password"}
                  type="password"
                  required={true}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <CustomFormInput
                id={"confirmPassword"}
                label={"Confirm Password"}
                placeholder={"Confirm New Password"}
                type={"password"}
                required={true}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="userProfilePopupBtns">
              <div
                className=" filled-btn-style"
                onClick={() => handleResetPassword()}
              >
                Reset
              </div>
              <div
                className=" outline-btn-style"
                onClick={() => setResetPassword(false)}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UserProfile;
