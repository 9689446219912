import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../common/styles/toastMessageStyle.css"

function CustomAlert() {
  const customToastOptions = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  return (
    <div>
      <ToastContainer {...customToastOptions} />
    </div>
  );
}

export default CustomAlert;
