import React, { useState } from "react";
import LightThemeLogo from "../../../common/svg/lightThemeLogo.svg";
import DarkThemeLogo from "../../../common/svg/darkThemeLogo.svg";
import "../../../common/styles/headerStyles.css";
import { FiSun, FiMoon } from "react-icons/fi";
import { logoutUser } from "../../../configuration/reducers/userReducer";
import { useMenu } from "../../../common/utils/menuContext";
import { RiHistoryLine } from "react-icons/ri";
import { HiOutlineBell } from "react-icons/hi2";
import { TbSettings } from "react-icons/tb";
import { IoIosLogOut } from "react-icons/io";
import { RxAvatar } from "react-icons/rx";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import getPermissions from "../../../configuration/localStorage/getPermissions";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../common/utils/authContext";

const Header = () => {
  const { setTheme, theme } = useMenu();
  const navigate = useNavigate();
  const [permissions] = useState(getPermissions);
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useAuth();

  const handleIconClick = (path) => {
    navigate(path);
  };
  const handleLogout = () => {
    dispatch(logoutUser(setIsLoggedIn));
  };
  return (
    <div className="header container-style">
      <div className="header-logo">
        <img
          src={theme === "light" ? LightThemeLogo : DarkThemeLogo}
          alt="bacsys.ai"
        />
      </div>
      <div className="header-content">
        {theme === "light" ? (
          <FiMoon
            onClick={() => setTheme("dark")}
            size={20}
            style={{ cursor: "pointer", outline: "none" }}
            data-tooltip-id="headerToolTip"
            data-tooltip-content="Dark Mode"
          />
        ) : (
          <FiSun
            onClick={() => setTheme("light")}
            size={20}
            style={{ cursor: "pointer", outline: "none" }}
            data-tooltip-id="headerToolTip"
            data-tooltip-content="Light Mode"
          />
        )}
        <RiHistoryLine
          size={20}
          data-tooltip-id="headerToolTip"
          data-tooltip-content="Recent"
          style={{ cursor: "pointer", outline: "none" }}
        />
        <HiOutlineBell
          size={20}
          data-tooltip-id="headerToolTip"
          data-tooltip-content="Alarms"
          style={{ cursor: "pointer", outline: "none" }}
          onClick={() => handleIconClick("/alarms")}
        />
        {permissions.settings.write && (
          <TbSettings
            size={20}
            data-tooltip-id="headerToolTip"
            data-tooltip-content="Settings"
            style={{ cursor: "pointer", outline: "none" }}
            onClick={() => handleIconClick("/settings/users")}
          />
        )}
        <RxAvatar
          onClick={() => handleIconClick("/profile")}
          size={20}
          data-tooltip-id="headerToolTip"
          data-tooltip-content="Profile"
          style={{ cursor: "pointer", outline: "none" }}
        />
        <IoIosLogOut
          onClick={handleLogout}
          size={20}
          data-tooltip-id="headerToolTip"
          data-tooltip-content="Logout"
          style={{ cursor: "pointer", outline: "none" }}
        />
      </div>
      <Tooltip place="bottom" className="tooltipStyle" id="headerToolTip" />
    </div>
  );
};

export default Header;
