/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import "../../../../../common/styles/backupRestoreSettingsStyle.css";
import BackupRestore from "./backupRestore.jsx";
import { GoDotFill } from "react-icons/go";
import formatCurrentDateTime from "../../../../../common/utils/formattedDateTime";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import CustomConfirmDelete from "../../../../reusableComponents/customConfirmDelete";
import CustomHeader from "../../../../reusableComponents/customHeader.jsx";
import { useMenu } from "../../../../../common/utils/menuContext.js";
import api from "../../../../../common/utils/axiosRequest.js";

const BackupRestoreSettings = () => {
  const { config } = useMenu();
  const fileInputRef = useRef(null);
  const serverEndpoint = config?.REACT_APP_USER_ENDPOINT;
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);
  const [localConfig, setLocalConfig] = useState({});
  const [localData, setLocalData] = useState({});
  const [confirmDataReset, setConfirmDataReset] = useState(false);
  const [confirmConfigReset, setConfirmConfigReset] = useState(false);

  const fetchBackupInfo = async () => {
    try {
      const existingBackup = await api.get(`${dbUrl}/backups/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      const data = existingBackup?.data.data?.rows?.map((data) => data.doc);
      setLocalConfig(
        data ? data.find((data) => data?._id === "local_config") : null
      );
      setLocalData(
        data ? data.find((data) => data?._id === "local_data") : null
      );
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  useEffect(() => {
    fetchBackupInfo();
    console.log(localData, localConfig);
  }, []);

  const handleSaveBackup = async (type) => {
    const data = {
      _id: `local_${type}`,
      createdAt: formatCurrentDateTime(),
      status: "success",
    };

    let pushToDb;
    try {
      pushToDb = await api.post(`${dbUrl}/backups`, data, {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });
      if (pushToDb.status === 200) {
        toast.success("Backup Created Successfully");
      }
    } catch (e) {
      console.error(e);
      try {
        pushToDb = await api.put(`${dbUrl}/backups/local_${type}`, data);
        if (pushToDb.status === 200) {
          toast.success("Backup Created Successfully");
        }
      } catch (error) {
        console.error(`Unexpected Error Occurred: ${error}`);
      }
    }
  };
  const handleConfigBackup = async () => {
    try {
      const response = await toast.promise(
        api.get(`${serverEndpoint}/backup_configs`, {
          responseType: "blob",
        }),
        {
          pending:
            "Please Wait..., Backup Process may depend upon the size of data.",
          error: "Error Creating backup",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      const timestamp = new Date().toISOString().replace(/:/g, "-");
      link.setAttribute(
        "download",
        `${timestamp}_bacsys_encrypted_config_backup`
      );
      link.setAttribute("readonly", "");
      document.body.appendChild(link);
      link.click();
      handleSaveBackup("config");
      fetchBackupInfo();
    } catch (error) {
      toast.error(`Error Creating Config backup: ${error}`);
    }
  };

  const handleDataBackup = async () => {
    try {
      const response = await toast.promise(
        api.get(`${serverEndpoint}/backup_data`, {
          responseType: "blob",
        }),
        {
          pending:
            "Please Wait..., Backup Process may depend upon the size of data.",
          success: "Backup Created successfully",
          error: "Error Creating backup",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      const timestamp = new Date().toISOString().replace(/:/g, "-");
      link.setAttribute(
        "download",
        `${timestamp}_bacsys_encrypted_data_backup`
      );
      link.setAttribute("readonly", "");
      document.body.appendChild(link);
      link.click();

      handleSaveBackup("data");
      fetchBackupInfo();
    } catch (error) {
      toast.error(`Error Creating Data backup: ${error}`);
    }
  };

  const handleResetData = async () => {
    setConfirmDataReset(false);
    try {
      const response = await api.get(`${dbUrl}/topics/topic`);
      const topicsArray = response.data.data.availableTopics;

      await toast.promise(
        api.get(`${serverEndpoint}/reset_data?topics=${topicsArray}`),
        {
          pending: "Please Wait..., Resetting may take some time..",
          success: "Data Reset was successfully",
          error: "Error Resetting Data",
        }
      );
    } catch (error) {
      toast.error(`Error Resetting Data: ${error}`);
    }
  };

  const handleResetConfigs = async () => {
    setConfirmConfigReset(false);
    try {
      const response = await api.get(`${dbUrl}/topics/topic`);
      const topicsArray = response.data.data.availableTopics;

      await toast.promise(
        api.get(`${serverEndpoint}/reset_configs?topics=${topicsArray}`),
        {
          pending: "Please Wait..., Resetting may take some time..",
          success: "Config Reset was successfully",
          error: "Error Resetting Data",
        }
      );
    } catch (error) {
      toast.error(`Error Resetting Data: ${error}`);
    }
  };
  const handleRestore = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("configFile", file);

      await toast.promise(
        api.post(`${serverEndpoint}/restore_info`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }),
        {
          pending: "Please Wait..., Restoring may take some time..",
          success: "Config Restored successfully",
          error: "Error Restoring Configurations",
        }
      );
      event.target.value = null;
    } catch (error) {
      console.error("Error restoring configurations:", error);
      toast.error(`Error restoring configurations: ${error.message}`);
      event.target.value = null;
    }
  };

  return (
    <div className={`backupRestoreSettings `}>
      {/* <CustomHeader
        showIcons={false}
        showSearch={false}
        heading={"Backup & Restore Configurations"}
      /> */}
      <CustomHeader heading={"Backup & Restore"} />
      <div className="backupRestoreSettingsContents">
        <div className="backupRestoreSettingsContent">
          <div className={`backupRestoreSettingsData`}>
            <BackupRestore
              configType="Local Config Backup"
              handleBackupClick={() => handleConfigBackup()}
              handleRestoreClick={handleRestore}
            />
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={(event) => handleFileChange(event)}
            />
            {/* <BackupRestore
            configType="Cloud Config Backup"
            handleBackupClick={() => {}}
            handleRestoreClick={() => {}}
          /> */}
            <div className="backupRestoreLastBackupTime">
              <p>Last Backup Time</p>
              <p>
                {localConfig ? (
                  <React.Fragment key={localConfig._id}>
                    {localConfig ? localConfig.createdAt : "N/A"}
                  </React.Fragment>
                ) : (
                  "N/A"
                )}
              </p>
            </div>
            <div className="backupRestoreLastBackupStatus">
              <p className="backupRestoreStatusLabel">Last Backup Status</p>
              {localConfig ? (
                <React.Fragment key={localConfig?._id}>
                  <p>
                    <GoDotFill size="20" className="backupRestoreStatusDot" />
                  </p>
                  <p>Success</p>
                  <p>Local</p>
                </React.Fragment>
              ) : (
                <React.Fragment key={localConfig?._id}>
                  <p>
                    <GoDotFill
                      size="20"
                      color="red"
                      className="backupRestoreStatusDot"
                    />
                  </p>
                  <p>N/A</p>
                  <p>N/A</p>
                </React.Fragment>
              )}
            </div>
            <div
              className="backupRestoreResetBtn"
              onClick={() => setConfirmConfigReset(true)}
            >
              Reset Configurations
            </div>
          </div>
          <div className={`backupRestoreSettingsData `}>
            <BackupRestore
              configType="Local Data Backup"
              handleBackupClick={() => handleDataBackup()}
              handleRestoreClick={handleRestore}
            />
            {/* <BackupRestore
            configType="Cloud Data Backup"
            handleBackupClick={() => {}}
            handleRestoreClick={() => {}}
          /> */}
            <div className="backupRestoreLastBackupTime">
              <p>Last Backup Time</p>
              <p>
                {localData ? (
                  <React.Fragment key={localData?._id}>
                    {localData?.createdAt}
                  </React.Fragment>
                ) : (
                  "N/A"
                )}
              </p>
            </div>
            <div className="backupRestoreLastBackupStatus">
              <p className="backupRestoreStatusLabel">Last Backup Status</p>
              {localData ? (
                <React.Fragment key={localData._id}>
                  <p>
                    <GoDotFill size="20" className="backupRestoreStatusDot" />
                  </p>
                  <p>Success</p>
                  <p>Local</p>
                </React.Fragment>
              ) : (
                <React.Fragment key={localData?._id}>
                  <p>
                    <GoDotFill
                      size="20"
                      color="red"
                      className="backupRestoreStatusDot"
                    />
                  </p>
                  <p>N/A</p>
                  <p>N/A</p>
                </React.Fragment>
              )}
            </div>
            <div
              className="backupRestoreResetBtn"
              onClick={() => setConfirmDataReset(true)}
            >
              Reset Data
            </div>
          </div>
        </div>
        {confirmDataReset ? (
          <CustomConfirmDelete
            setConfirmDelete={setConfirmDataReset}
            handleDelete={handleResetData}
            message="Are you sure you want to reset Z-EDGE's Data ?"
            note="All the Data Associated with this Machine will be reset and erased."
          />
        ) : null}
        {confirmConfigReset ? (
          <CustomConfirmDelete
            setConfirmDelete={setConfirmConfigReset}
            handleDelete={handleResetConfigs}
            message="Are you sure you want to reset Z-EDGE's Configurations ?"
            note="All the Configurations Associated with this Machine will be reset and erased."
          />
        ) : null}
      </div>
    </div>
  );
};

export default BackupRestoreSettings;
