/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "../../../../../common/styles/pointDetailsConfigStyle.css";

import PointDetailsConfigNavigator from "./pointDetailsConfigNavigator";
import PointDetailsAlarms from "./pointDetailsAlarms";
import PointDetailsTrends from "./pointDetailsTrends";
import PointDetailsTags from "./pointDetailsTags";

const PointDetailsConfig = ({ permissions, pointProps }) => {
  const [configOptions, setConfigOptions] = useState("alarms");

  return (
    <div className="pointDetailsConfig">
      <PointDetailsConfigNavigator
        configOptions={configOptions}
        pointProps={pointProps}
        setConfigOptions={setConfigOptions}
      />
      {configOptions === "alarms" && (
        <PointDetailsAlarms pointProps={pointProps} permissions={permissions} />
      )}
      {configOptions === "trends" && (
        <PointDetailsTrends pointProps={pointProps} permissions={permissions} />
      )}
      {configOptions === "tags" && (
        <PointDetailsTags pointProps={pointProps} permissions={permissions} />
      )}
    </div>
  );
};

export default PointDetailsConfig;
