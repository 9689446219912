import React, { useState } from "react";
import "../../../../../common/styles/deviceDetailsStyle.css";

import DeviceDetailsProperties from "./deviceDetailsProperties.jsx";
import AddPointsForm from "./addPointsForm.jsx";
import { useMenu } from "../../../../../common/utils/menuContext";
import { toast } from "react-toastify";
import CustomConfirmDelete from "../../../../reusableComponents/customConfirmDelete";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DeviceTemplate from "./deviceTemplate.jsx";
import CustomHeader from "../../../../reusableComponents/customHeader.jsx";
import api from "../../../../../common/utils/axiosRequest.js";

const DeviceDetails = ({
  setDeviceDetails,
  deviceProps,
  networkProps,
  setSelectedDevice,
  permissions,
  setSelectedItem,
}) => {
  const navigate = useNavigate();

  const { setReloadData, config } = useMenu();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedDeviceTab, setSelectedDeviceTab] = useState("properties");

  const handleOpen = () => {
    setIsFormOpen(true);
    setIsOverlayOpen(true);
  };
  const goBack = () => {
    setDeviceDetails(false);
    setSelectedItem(false);
    navigate("/devices");
  };
  const handleClose = () => {
    setIsFormOpen(false);
    setIsOverlayOpen(false);
  };
  const deleteDevice = async () => {
    setConfirmDelete(false);
    try {
      const deleteDeviceResponse = await api.delete(
        `${dbUrl}/devices/${deviceProps?._id}`,
        {
          params: {
            points: true,
            reference: "deviceId",
          },
        }
      );

      if (deleteDeviceResponse.status === 200) {
        await api.post(`${dbUrl}/remove_id_from_mqtt`, {
          ids: [deviceProps?._id],
        });
        setReloadData(true);
        goBack();
        toast.success(`Device-${deviceProps?._id} Deleted Successfully`);
        navigate("/devices");
      }
    } catch (error) {
      setReloadData(true);
      console.log("Error deleting device:", error.message);
      toast.error(`Error deleting device:${error}`);
    }
  };

  return (
    <div className="deviceDetails">
      <CustomHeader
        heading={
          deviceProps?.deviceName
            ? `${deviceProps?.deviceName}-${deviceProps?.deviceId}`
            : deviceProps?.deviceId
        }
      />
      <div className={`deviceDetails container-style`}>
        <div className="deviceDetailsHeaderContainerName">
          <div className="headerDeviceGroup">
            <div className="headerGroup filled-btn-style" onClick={goBack}>
              <FaChevronLeft />
              <span>Networks</span>
            </div>
          </div>
        </div>
        <div class="custom-tabs">
          <span
            className={`${
              selectedDeviceTab === "properties"
                ? "custom-tab-selected"
                : "custom-tab"
            }`}
            onClick={() => setSelectedDeviceTab("properties")}
          >
            Properties
          </span>
          <span
            className={`${
              selectedDeviceTab === "template"
                ? "custom-tab-selected"
                : "custom-tab"
            }`}
            onClick={() => setSelectedDeviceTab("template")}
          >
            Device Display Template
          </span>
        </div>

        <div className="deviceDetailsContent">
          {selectedDeviceTab === "properties" ? (
            <DeviceDetailsProperties
              deviceProps={deviceProps}
              networkProps={networkProps}
              setSelectedDevice={setSelectedDevice}
              permissions={permissions}
              setConfirmDelete={setConfirmDelete}
              handleOpen={handleOpen}
            />
          ) : (
            <DeviceTemplate deviceProps={deviceProps} />
          )}
          {confirmDelete && (
            <CustomConfirmDelete
              setConfirmDelete={setConfirmDelete}
              handleDelete={deleteDevice}
              message="Are you sure you want to Delete this Device?"
              note="Points Associated with this Devices will be Deleted."
            />
          )}
        </div>
        {isFormOpen && (
          <AddPointsForm
            isFormOpen={isFormOpen}
            networkProps={networkProps}
            deviceProps={deviceProps}
            setIsFormOpen={handleClose}
          />
        )}
      </div>
    </div>
  );
};

export default DeviceDetails;
