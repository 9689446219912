/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "../../../../common/styles/schedulesStyle.css";
import { useEffect } from "react";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import ScheduleForm from "./components/scheduleForm";
import { toast } from "react-toastify";
import ScheduleBrowser from "./components/scheduleBrowser";
import { IoMdSave } from "react-icons/io";

import getPermissions from "../../../../configuration/localStorage/getPermissions";
import { Tooltip } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import CustomHeader from "../../../reusableComponents/customHeader";
import PreLoader from "../../../reusableComponents/preLoader";
import { useParams, useNavigate } from "react-router-dom";
import { useMenu } from "../../../../common/utils/menuContext";
import api from "../../../../common/utils/axiosRequest";

const Schedules = () => {
  const navigate = useNavigate();
  const { scheduleId } = useParams();
  const [permissions] = useState(getPermissions);
  const { config } = useMenu();

  const [showModal, setShowModal] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState("");
  const [day, setDay] = useState();
  const [events, setEvents] = useState([]);
  const [calendarKey, setCalendarKey] = useState(Date.now());
  const [selectedTask, setSelectedTask] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [value, setValue] = useState();
  const [id, setId] = useState([]);
  const [scheduleTitle, setScheduleTitle] = useState();
  const [selectedSchedule, setSelectedSchedule] = useState();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);
  const [search, setSearch] = useState(false);
  const [selectedDays, setSelectedDays] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });

  const [loading, setLoading] = useState(false);
  const serverEndpoint = config?.REACT_APP_SCHEDULE_ENDPOINT;

  const startDate = "2023-12-31";
  const endDate = "2024-01-07";

  const [eventTimes, setEventTimes] = useState({
    start: null,
    end: null,
  });

  useEffect(() => {
    const loadSchedule = async () => {
      if (scheduleId) {
        const getSchedule = await api.get(`${dbUrl}/schedules/${scheduleId}`);
        handleLoadSchedule(
          getSchedule.data.data._id,
          getSchedule.data.data.scheduleTitle
        );
      }
    };
    loadSchedule();
  }, [scheduleId]);

  const fetchSchedules = async () => {
    // setLoading(true);
    try {
      const schedules = await api.get(`${dbUrl}/schedules/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      setSchedules(schedules.data.data.rows.map((row) => row.doc));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchedules();
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setShowModal(false);
      }
    };
    if (showModal) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [base64Credentials, dbUrl, showModal, events]);

  const handleNewSchedule = async () => {
    setEvents([]);
    setSelectedTask();
    setScheduleTitle("");
    setValue();
    setSelectedDays({
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
    });
    setSelectedSchedule();
    navigate("/schedule");
  };
  const handleUpdateScheduletoDb = async () => {
    try {
      const getSchedule = await api.get(
        `${dbUrl}/schedules/${selectedSchedule}`,
        {
          headers: {
            Authorization: `Basic ${base64Credentials}`,
          },
        }
      );
      const updatedData = {
        ...getSchedule.data.data,
        scheduleTitle: scheduleTitle ? scheduleTitle : "New Schedule",
        events: events,
      };

      const updateToDb = await api.put(
        `${dbUrl}/schedules/${getSchedule.data.data._id}`,
        updatedData
      );

      if (updateToDb.status === 200) {
        toast.success(`Updated Schedule: ${selectedSchedule}`);
        fetchSchedules();
        for (const event of events) {
          const scheduleData = {
            selectedTask: event.selectedTask,
            scheduleName: event._id,
            start_time: event.start_time,
            end_time: event.end_time,
            day: event.day,
          };

          try {
            const startSchedule = await api.post(
              `${serverEndpoint}/start_scheduler`,
              scheduleData
            );

            if (startSchedule.status === 200) {
              console.log("Schedule Started Successfully");
            }
          } catch (error) {
            console.error("Error Starting schedule:", error);
          }
        }
        toast.success("Schedule Updated and Restarted Successfully");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSaveSchedule = async () => {
    const scheduleData = {
      _id: uuidv4(),
      scheduleTitle: scheduleTitle ? scheduleTitle : "New Schedule",
      events: events,
    };
    try {
      const pushToDb = await api.post(`${dbUrl}/schedules`, scheduleData);

      if (pushToDb.status === 200 || pushToDb.status === 201) {
        toast.success("Saved Schedule Successfully");
        setSelectedSchedule(scheduleData._id);
        fetchSchedules();
        for (const event of events) {
          const scheduleData = {
            selectedTask: event.selectedTask,
            scheduleName: event._id,
            start_time: event.start_time,
            end_time: event.end_time,
            day: event.day,
          };

          try {
            const startSchedule = await api.post(
              `${serverEndpoint}/start_scheduler`,
              scheduleData
            );

            if (startSchedule.status === 200) {
              console.log("Schedule Started Successfully");
            }
          } catch (error) {
            console.error("Error Starting schedule:", error);
          }
        }
        toast.success("Schedule Started Successfully");
        navigate(`/schedule/${scheduleData._id}`, {
          state: {
            selectedSchedule: scheduleData._id,
          },
          replace: true,
        });
      }
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  const handleLoadSchedule = (scheduleId, scheduleTitle) => {
    setSelectedSchedule(scheduleId);
    setScheduleTitle(scheduleTitle);
    const selectedSchedule = schedules.find(
      (schedule) => schedule._id === scheduleId
    );

    if (selectedSchedule) {
      const eventsFromSelectedSchedule = selectedSchedule.events.map(
        (event) => {
          return event;
        }
      );
      setEvents(eventsFromSelectedSchedule);
      setId(eventsFromSelectedSchedule.map((event) => event._id));
    } else {
      console.log(`Schedule with ID ${scheduleId} not found.`);
    }
  };
  const handleDeleteSchedule = async (scheduleId) => {
    try {
      const getSchedule = await api.get(`${dbUrl}/schedules/${scheduleId}`, {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });
      const deleteSchedule = await api.delete(
        `${dbUrl}/schedules/${scheduleId}`
      );
      if (deleteSchedule.status === 200 || deleteSchedule.status === 201) {
        toast.success(`Successfully Deleted Schedule : ${scheduleId}`);
        setSelectedSchedule();
        fetchSchedules();
        for (const event of events) {
          const stop_schedule = await api.post(
            `${serverEndpoint}/stop_scheduler`,
            {
              threadId: event._id,
            }
          );
          if (stop_schedule.status === 200) {
            console.log("Stopped the Running  Schedule");
          }
        }
        toast.success("Schedule Stopped ");
        setEvents([]);
        setSelectedTask();
        setScheduleTitle("");
        setValue();
        setSelectedDays({
          Sunday: false,
          Monday: false,
          Tuesday: false,
          Wednesday: false,
          Thursday: false,
          Friday: false,
          Saturday: false,
        });
      }
      navigate("/schedule");
    } catch (error) {
      console.error(error);
    }
  };
  const handleEventDrop = async (info) => {
    setSelectedTask();
    setValue();
    setSelectedDays({
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
    });
    const updatedEvent = {
      _id: info.event.extendedProps._id,
      value: info.event.extendedProps.value,
      selectedTask: info.event.extendedProps.selectedTask,
      allDay: info.event._def.allDay,
      title: info.event._def.title,
      start: info.event._instance.range.start,
      end: info.event._instance.range.end,
      day: info.event.start.toLocaleString("en-US", { weekday: "long" }),
      editable: true,
    };
    try {
      const newUpdateEvent = {
        _id: updatedEvent._id,
        title: updatedEvent.title,
        start: updatedEvent.start.toISOString().slice(0, -5),
        end: updatedEvent.end.toISOString().slice(0, -5),
        day: updatedEvent.day,
        allDay: updatedEvent.allDay,
        editable: updatedEvent.editable,
        value: updatedEvent.value,
        selectedTask: updatedEvent.selectedTask,
        start_time: getFormattedTime(updatedEvent.start),
        end_time: getFormattedTime(updatedEvent.end),
      };
      const updatedEvents = events.map((event) =>
        event._id === updatedEvent._id ? { ...newUpdateEvent } : event
      );
      setEvents(updatedEvents);
    } catch (error) {
      toast.error(`Error updating event: ${error}`);
    }
  };

  const handleEventDelete = async (info) => {
    const eventToDelete = info.event;
    const updatedID = id.filter(
      (eventId) => eventId !== eventToDelete._def.extendedProps._id
    );
    console.log(updatedID);
    setId(updatedID);
    const updatedEvents = events.filter(
      (event) => event.title !== eventToDelete.title
    );
    setEvents(updatedEvents);
  };
  const handleEditEvent = (info) => {
    console.log(info);
    setShowModal(true);
    setEventTimes({
      start: info.event.start,
      end: info.event.end,
    });
    // setId(info.event._def.extendedProps._id);
    setDay(info.event._def.extendedProps.day);
    setNewEventTitle(info.event._def.extendedProps._id);
    setSelectedTask(info.event._def.extendedProps.selectedTask);
    setValue(info.event._def.extendedProps.value);
  };

  const eventContent = (info) => {
    return (
      <div className="schedulesEvent">
        <div className="schedulesEventHeading">
          <span className="schedulesEventTime">{info.timeText}</span>
          {permissions.schedules.write && (
            <div className="schedulesFunctionBtns">
              <MdDelete
                onClick={() => handleEventDelete(info)}
                className="schedulesFunctionBtn"
              />
              <MdEdit
                onClick={() => handleEditEvent(info)}
                className="schedulesFunctionBtn"
              />
            </div>
          )}
        </div>
        <p className="scheduleInfo">
          {info.event._def.extendedProps.selectedTask === "configDbBackup"
            ? "Config & DB Backup"
            : info.event._def.extendedProps.selectedTask === "dbBackup"
            ? "DB Backup"
            : info.event._def.extendedProps.selectedTask === "configBackup"
            ? "Config Backup"
            : ""}
        </p>
      </div>
    );
  };
  const handleSelect = (selectInfo) => {
    console.log("Selection Info:", selectInfo);

    const start = new Date(selectInfo.startStr);
    const end = new Date(selectInfo.endStr);
    setShowModal(true);
    setNewEventTitle("");
    setEventTimes({ start, end });
    setSelectedTask();
    setValue();
    setSelectedDays({
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
    });
  };
  const getFormattedTime = (date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const createNewEvent = async () => {
    try {
      const newEvent = {
        _id: newEventTitle === "" ? "NewEvent" : newEventTitle,
        title: newEventTitle === "" ? "NewEvent" : newEventTitle,
        start: eventTimes.start,
        end: eventTimes.end,
        day: eventTimes.start.toLocaleString("en-US", { weekday: "long" }),
        allDay: false,
        editable: true,
        value: value ? value : 0,
        selectedTask: selectedTask ? selectedTask : null,
        scheduleName: newEventTitle,
        start_time: getFormattedTime(eventTimes.start),
        end_time: getFormattedTime(eventTimes.end),
      };
      const selectedDaysOfWeek = Object.keys(selectedDays).filter(
        (day) => selectedDays[day]
      );

      if (selectedDaysOfWeek.length === 0) {
        // No specific days selected, add event directly
        const updatedEvents = [...events];
        updatedEvents.push(newEvent);
        setEvents(updatedEvents);
        setId((prevIds) => [...prevIds, newEvent._id]);
        toast.success("Event Created Successfully");
      } else {
        // Specific days selected, create repeated events
        const daysOfWeek = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        const updatedEvents = [...events];

        for (const day of selectedDaysOfWeek) {
          const dayStart = new Date(eventTimes.start);
          dayStart.setDate(
            dayStart.getDate() +
              ((daysOfWeek.indexOf(day) + 1 - dayStart.getDay()) % 7)
          );

          const dayEnd = new Date(eventTimes.end);
          dayEnd.setDate(
            dayEnd.getDate() +
              ((daysOfWeek.indexOf(day) + 1 - dayEnd.getDay()) % 7)
          );
          const repeatedEvent = {
            ...newEvent,
            _id: `${newEventTitle} - ${day}`,
            title: `${newEventTitle} - ${day}`,
            start: dayStart,
            end: dayEnd,
            day: day,
          };

          updatedEvents.push(repeatedEvent);
          setId((prevIds) => [...prevIds, repeatedEvent._id]);
        }
        toast.success("Events Created Successfully");
        setEvents(updatedEvents);
      }

      setCalendarKey(Date.now());
      setShowModal(false);
      setNewEventTitle("");
      setValue("");
      setEventTimes({ start: null, end: null });
      setSelectedTask("");
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
      toast.error(`Error Creating Event: ${error}`);
      setCalendarKey(Date.now());
    }
  };
  const handleUpdateSchedule = async (id) => {
    const updatedEvent = {
      _id: id,
      title: newEventTitle,
      start: new Date(eventTimes.start),
      end: new Date(eventTimes.end),
      day: day,
      allDay: false,
      editable: true,
      value: value ? value : 0,
      selectedTask: selectedTask ? selectedTask : null,
    };

    try {
      const newUpdateEvent = {
        _id: id,
        title: newEventTitle,
        start: eventTimes.start,
        end: eventTimes.end,
        day: day,
        allDay: false,
        editable: true,
        value: value ? value : 0,
        selectedTask: selectedTask ? selectedTask : null,
      };
      console.log(newUpdateEvent);
      const updatedEvents = events.map((event) =>
        event._id === id ? { ...newUpdateEvent } : event
      );
      setEvents(updatedEvents);
      toast.success("Updated Event");
      setShowModal(false);
      setDay(null);
      setEventTimes({ start: null, end: null });
      setNewEventTitle("");
      setSelectedTask("");
      setValue("");
    } catch (error) {
      toast.error(`Error updating event: ${error}`);
    }
  };

  return (
    <div className="schedules">
      {loading ? <PreLoader size={80} color="var(--primary-color)" /> : null}
      <ScheduleBrowser
        handleNewSchedule={handleNewSchedule}
        schedules={schedules}
        handleLoadSchedule={handleLoadSchedule}
        handleDeleteSchedule={handleDeleteSchedule}
        selectedSchedule={selectedSchedule}
        permissions={permissions}
      />
      <div className="scheduleContents">
        <div className="scheduleCalendar">
          <CustomHeader
            showIcons={false}
            heading="Schedules"
            showSearch={false}
            permission={permissions?.schedules?.write}
            showSave={true}
            isInput={true}
            inputToolTipLabel="Rename"
            saveFunc={() =>
              selectedSchedule
                ? handleUpdateScheduletoDb()
                : handleSaveSchedule()
            }
            inputOnBlur={() =>
              selectedSchedule
                ? handleUpdateScheduletoDb()
                : handleSaveSchedule()
            }
            inputValue={scheduleTitle ? scheduleTitle : ""}
            inputOnChange={(e) => setScheduleTitle(e.target.value)}
            inputPlaceHolder="Untitled Schedule"
          />

          <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin]}
            key={calendarKey}
            initialView="timeGridWeek"
            dayHeaderFormat={{ weekday: "short" }}
            slotDuration="01:00:00"
            allDaySlot={false}
            headerToolbar={{
              start: "",
              center: "",
              end: "",
            }}
            editable={permissions.schedules.write ? true : false}
            // unselectAuto={!showModal ? "true" : "false"}
            selectable={permissions.schedules.write ? true : false}
            selectMirror={permissions.schedules.write ? true : false}
            scrollTime="00:00:00"
            eventDrop={permissions.schedules.write ? handleEventDrop : null}
            validRange={{ start: startDate, end: endDate }}
            select={permissions.schedules.write ? handleSelect : null}
            eventContent={eventContent}
            events={events}
          />
        </div>
      </div>
      {showModal && (
        <ScheduleForm
          newEventTitle={newEventTitle}
          createNewEvent={createNewEvent}
          setNewEventTitle={setNewEventTitle}
          setShowModal={setShowModal}
          setSelectedTask={setSelectedTask}
          selectedTask={selectedTask}
          value={value}
          setSelectedDays={setSelectedDays}
          selectedDays={selectedDays}
          handleUpdateSchedule={handleUpdateSchedule}
          setValue={setValue}
          id={id}
          setSearch={setSearch}
          search={search}
          eventTimes={eventTimes}
          setEventTimes={setEventTimes}
        />
      )}
    </div>
  );
};

export default Schedules;
