import Custom10OperatorNode from "./custom10Operator";
import Custom4OperatorNode from "./custom4OperatorNode";
import CustomConstantNode from "./customConstantNode";
import CustomNode from "./customNode";
import CustomOperatorNode from "./customOperatorNode";
import CustomOutputNode from "./customOutputNode";

const nodeTypes = {
  customNode: CustomNode,
  customOperatorNode: CustomOperatorNode,
  customOutputNode: CustomOutputNode,
  customConstantNode: CustomConstantNode,
  custom4OperatorNode: Custom4OperatorNode,
  custom10OperatorNode: Custom10OperatorNode,
};

export default nodeTypes;
