import React, { useState } from "react";
import CustomButton from "../../../../reusableComponents/customButton";

const ConfigurationSettingsNavigator = ({ onOptionClick }) => {
  const [selectedOption, setSelectedOption] = useState("MQTT Certificate");

  const handleButtonClick = (buttonName) => {
    setSelectedOption(buttonName);
    onOptionClick(buttonName);
  };
  return (
    <div className="configurationSettingsNavigator">
      <div className="custom-tabs">
        <CustomButton
          className={`${
            selectedOption === "MQTT Certificate"
              ? "custom-tab-selected"
              : "custom-tab"
          }`}
          buttonName="MQTT Certificate Generation"
          handleClick={() => handleButtonClick("MQTT Certificate")}
        />
        {/* <CustomButton
        className={`configurationSettingsNavigatorOption  ${selectedOption === "MAIL" ? "selected" : ""}`}
        buttonName="MAIL"
        handleClick={() => handleButtonClick("MAIL")}
      /> */}
        <CustomButton
          className={` ${
            selectedOption === "SMS" ? "custom-tab-selected" : "custom-tab"
          }`}
          buttonName="SMS"
          handleClick={() => handleButtonClick("SMS")}
        />
      </div>
    </div>
  );
};

export default ConfigurationSettingsNavigator;
