// ColorPicker.js
import React, { useEffect, useState } from "react";
import { SwatchesPicker } from "react-color";
import Cookies from "js-cookie";
import "../../common/styles/colorPickerStyle.css";

const ColorPicker = ({ initialColor, onColorChange, label = true }) => {
  const [btnColor, setBtnColor] = useState(initialColor);
  const [recentColors, setRecentColors] = useState([]);
  const colorGroups = SwatchesPicker.defaultProps.colors;

  useEffect(() => {
    const storedColors = Cookies.get("recentColors");
    if (storedColors) {
      setRecentColors(JSON.parse(storedColors));
    }
  }, []);

  const handleColorChange = (color) => {
    const selectedColor = color.hex;
    setBtnColor(selectedColor);
    onColorChange(selectedColor);

    const updatedColors = recentColors.includes(selectedColor)
      ? recentColors
      : [selectedColor, ...recentColors.slice(0, 9)];

    setRecentColors(updatedColors);
    Cookies.set("recentColors", JSON.stringify(updatedColors), { expires: 30 });
  };

  return (
    <div className="colorSelectorContainer">
      {label && (
        <label className="selectPointsLabel" htmlFor="colorPicker">
          Choose a Button color
        </label>
      )}

      <label className="selectPointsLabel">Available Colors:</label>
      <div className="allColorsContainer">
        {colorGroups.map((group, groupIndex) => (
          <div key={groupIndex} className="colorGroup">
            {group.map((color, colorIndex) => (
              <div
                key={colorIndex}
                className={`colorSwatchBox ${
                  btnColor === color ? "selected" : ""
                }`}
                style={{ backgroundColor: color }}
                onClick={() => handleColorChange({ hex: color })}
              >
                {btnColor === color && <span className="checkmark">✓</span>}
              </div>
            ))}
          </div>
        ))}
      </div>

      <label className="selectPointsLabel">Recent Colors:</label>
      <div className="recentColors">
        {recentColors.map((color, index) => (
          <div
            key={index}
            className={`recentColorBox ${btnColor === color ? "selected" : ""}`}
            style={{ backgroundColor: color }}
            onClick={() => {
              setBtnColor(color);
              onColorChange(color);
            }}
          >
            {btnColor === color && <span className="checkmark">✓</span>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;
