import React from "react";
import { useState } from "react";

import CustomButton from "../../../../reusableComponents/customButton";
import { useEffect } from "react";

const PointDetailsNavigator = ({ onOptionClick, pointProps }) => {
  const [selectedOption, setSelectedOption] = useState("Properties");

  const handleButtonClick = (buttonName) => {
    setSelectedOption(buttonName);
    onOptionClick(buttonName);
  };
  useEffect(() => {
    return () => {
      setSelectedOption("Properties");
    };
  }, [pointProps]);
  return (
    <div className="custom-tabs">
      <CustomButton
        className={`  ${
          selectedOption === "Properties" ? "custom-tab-selected" : "custom-tab"
        }`}
        buttonName="Properties"
        handleClick={() => handleButtonClick("Properties")}
      />
      <CustomButton
        className={`  ${
          selectedOption === "Config" ? "custom-tab-selected " : "custom-tab"
        }`}
        buttonName="Config"
        handleClick={() => handleButtonClick("Config")}
      />
      {(pointProps?.alarmHighLimitEnable ||
        pointProps?.alarmLowLimitEnable) && (
        <CustomButton
          className={` ${
            selectedOption === "Alarms" ? "custom-tab-selected " : "custom-tab"
          }`}
          buttonName="Alarms"
          handleClick={() => handleButtonClick("Alarms")}
        />
      )}
      {pointProps?.trendEnable && (
        <CustomButton
          className={`${
            selectedOption === "Trends" ? "custom-tab-selected " : "custom-tab"
          }`}
          buttonName="Trends"
          handleClick={() => handleButtonClick("Trends")}
        />
      )}
    </div>
  );
};

export default PointDetailsNavigator;
