/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../../../../../common/styles/numericChartStyles.css";
import { useState } from "react";
import { useEffect } from "react";
import PreLoader from "../../../../../reusableComponents/preLoader";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const StateChart = ({ chart, selectedDevice }) => {
  // const [isLoading, setIsLoading] = useState(true);
  const { config } = useMenu();
  const [presentValue, setPresentValue] = useState();
  const [pointInfo, setPointInfo] = useState({});
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const fetchLiveDeviceData = async () => {
    try {
      const pointName = chart?.data?.points;
      const deviceId = selectedDevice?.id ? selectedDevice?.id : "";
      let pointId = "";
      const couchdbUrl = dbUrl;

      // Fetch the parent device data
      const parentDeviceResponse = await api.get(
        `${couchdbUrl}/devices/${selectedDevice.parentId}`
      );
      const parentDevice = parentDeviceResponse.data.data.devices.find(
        (deviceInstance) => deviceInstance.deviceInstance === parseInt(deviceId)
      );

      if (!parentDevice) {
        console.log("Device not found in parent device data");
        // setIsLoading(false);
        setPresentValue([]);
        return;
      }

      // Find the point ID
      const point = parentDevice.points.find((point) =>
        point.objectName.toLowerCase().includes(pointName.toLowerCase())
      );

      if (!point) {
        console.log("Point not found");
        // setIsLoading(false);
        setPresentValue([]);
        return;
      }

      pointId = point.pointId;
      console.log(pointId);

      // Fetch point data
      const response = await api.get(`${couchdbUrl}/points/${pointId}`);
      const pointData = response.data.data;

      setPointInfo(pointData);
      setPresentValue(pointData.pointPresentValue?.toFixed(2));
    } catch (error) {
      console.error("Error fetching last uploaded record:", error.message);
      setPresentValue([]); // Ensure to handle error state
    }
    // setIsLoading(false); // Set loading to false at the end
  };

  useEffect(() => {
    const intervalInSeconds = 5;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchLiveDeviceData();
    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart]);
  return (
    <div className="numericWidget">
      {/* {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null} */}
      <p className="numericWidgetTitle">
        {chart?.data?.title
          ? chart?.data?.title
          : pointInfo.pointName
          ? pointInfo.pointName
          : "Analog/Binary Point"}
      </p>
      <p>
        {" "}
        {(() => {
          const isBinaryOrDigital = [
            "binaryValue",
            "digitalValue",
            "digitalOutput",
            "binaryOutput",
            "binaryInput",
            "digitalInput",
            "CoilStatus",
            "InputStatus",
          ].some((type) => pointInfo.pointType?.includes(type));
          const isFalse = presentValue == 0 || presentValue == 0.0;
          const isTrue = presentValue == 1 || presentValue == 1.0;

          if (isBinaryOrDigital && isFalse) return "OFF";
          if (isBinaryOrDigital && isTrue) return "ON";
          return presentValue;
        })()}
      </p>
    </div>
  );
};

export default StateChart;
