import React from "react";
import "../../../../../common/styles/pointDetailsConfigStyle.css";

const PointDetailsConfigNavigator = ({
  configOptions,
  setConfigOptions,
  pointProps,
}) => {
  return (
    <div className="pointDetailsConfigNavigator">
      <div className="custom-tabs">
        <div
          className={` ${
            configOptions === "alarms" ? "custom-tab-selected" : "custom-tab"
          }`}
          onClick={() => setConfigOptions("alarms")}
        >
          Alarms
        </div>
        <div
          className={` ${
            configOptions === "trends" ? "custom-tab-selected" : "custom-tab"
          }`}
          onClick={() => setConfigOptions("trends")}
        >
          Trends
        </div>
        <div
          className={` ${
            configOptions === "tags" ? "custom-tab-selected" : "custom-tab"
          }`}
          onClick={() => setConfigOptions("tags")}
        >
          Tags
        </div>
      </div>
    </div>
  );
};

export default PointDetailsConfigNavigator;
