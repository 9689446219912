/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import "../../../../common/styles/loginStyle.css";
import landingLogo from "../../../../assets/landingLogo.png";
import CustomButton from "../../../reusableComponents/customButton";
import { useAuth } from "../../../../common/utils/authContext";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../../configuration/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import PreLoader from "../../../reusableComponents/preLoader";
import Logo from "../../../../common/svg/bacsys-logo-black.svg";
import CustomFormInput from "../../../reusableComponents/customFormInput";
import CryptoJS from "crypto-js";
import { useMenu } from "../../../../common/utils/menuContext";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

const Login = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const { setIsLoggedIn } = useAuth();
  const [password, setPassword] = useState("");
  const { loading, error } = useSelector((state) => state.user);
  const { config } = useMenu();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentTimestamp = Date.now();
  const currentISOTime = new Date(currentTimestamp).toISOString();
  const siteKey = process.env.REACT_APP_SITE_KEY;
  const captchaRef = useRef(null);

  const getHashedPassword = () => {
    const secretKey = "supersecretpassw";
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const hashedPass = CryptoJS.AES.encrypt(password, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return hashedPass;
  };
  const handleSignIn = async (e) => {
    e.preventDefault();
    const token = await captchaRef?.current?.executeAsync();
    if (!token) {
      toast.warning("Captcha verification failed");
      return;
    }
    captchaRef.current.reset();
    const userCredentials = {
      emailAddress: emailAddress,
      password: getHashedPassword(),
      lastLoggedIn: currentISOTime,
      captchaToken: token,
    };

    dispatch(loginUser({ userCredentials, config })).then((result) => {
      if (result.payload) {
        setEmailAddress("");
        setPassword("");
        setIsLoggedIn(true);
        navigate("/");
      }
    });
  };

  const goToSignUp = () => {
    navigate("/register");
  };

  return (
    <div className="login">
      <div className="loginBackLeft"></div>
      <div className="loginForm">
        <div className="loginImage">
          <div className="loginImg">
            <div className="Loginlogo">
              <img src={Logo} alt="logo" />
            </div>
          </div>
          <div className="landingLogo">
            <img src={landingLogo} alt="" />
          </div>
        </div>
        <div className="loginContent">
          <div className="loginFormContent">
            <form onSubmit={handleSignIn}>
              <CustomFormInput
                id="email"
                label=""
                type="email"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                // required
                placeholder="Enter your e-mail address"
              />
              <CustomFormInput
                id="password"
                // label="PASSWORD"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                // required
                placeholder="Enter your password"
              />
              <div className="dontHaveAnAccount">
                Don't Have an Account ?
                <span className="link signUpLink" onClick={goToSignUp}>
                  SignUp
                </span>
              </div>
              <div className="loginBtnContainer">
                <CustomButton
                  type="submit"
                  buttonName="Sign In"
                  className="filled-btn-style"
                />
              </div>
            </form>
            {loading ? (
              <PreLoader size={80} color="var(--primary-color)" />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <ReCAPTCHA sitekey={siteKey} size="invisible" ref={captchaRef} />
    </div>
  );
};

export default Login;
