/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { TiSpannerOutline } from "react-icons/ti";
import { LuDatabaseBackup } from "react-icons/lu";
import { RiUserSettingsLine } from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi";
import { VscDebugConsole } from "react-icons/vsc";

import "../../../../../common/styles/settingsNavigatorStyle.css";
import { useState } from "react";
import { IoKeyOutline } from "react-icons/io5";
import { useMenu } from "../../../../../common/utils/menuContext";
import { Link } from "react-router-dom";
import CustomNavigator from "../../../../reusableComponents/customNavigator";

const SettingsNavigator = ({ onOptionClick, location }) => {
  const { open } = useMenu();
  const [selectedOption, setSelectedOption] = useState("Users"); // Initialize with the default selected option

  useEffect(() => {
    if (location.pathname === "/settings/users") {
      handleOptionClick("Users");
    } else if (location.pathname === "/settings/roles") {
      handleOptionClick("Roles");
    } else if (location.pathname === "/settings/configuration") {
      handleOptionClick("Configuration");
    } else if (location.pathname === "/settings/backupRestore") {
      handleOptionClick("BackupRestore");
    } else if (location.pathname === "/settings/logs") {
      handleOptionClick("Logs");
    } else if (location.pathname === "/settings/license") {
      handleOptionClick("License");
    }
  }, [location.pathname]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onOptionClick(option);
  };

  return (
    open && (
      <CustomNavigator>
        <div className={`settingsNavigator`}>
          <div className={`settingsNavigatorHeader `}>
            <span>Settings</span>
          </div>
          <div className={`settingsNavigatorOptions  `}>
            <span
              className={`settingsNavigatorOption ${
                selectedOption === "Users" ? "selected-nav" : ""
              } `}
              onClick={() => handleOptionClick("Users")}
            >
              <Link to={"/settings/users"} className="link settings">
                <RiUserSettingsLine
                  size={20}
                  className="settingsNavigatorOptionIcon"
                />
                Users
              </Link>
            </span>
            <span
              className={`settingsNavigatorOption ${
                selectedOption === "Roles" ? "selected-nav" : ""
              } `}
              onClick={() => handleOptionClick("Roles")}
            >
              <Link to={"/settings/roles"} className="link settings">
                <HiUserGroup
                  size={20}
                  className="settingsNavigatorOptionIcon"
                />
                Roles
              </Link>
            </span>
            {/* <span
          className={`settingsNavigatorOption ${
            selectedOption === "Services" ? "selected" : ""
          } `}
          onClick={() => handleOptionClick("Services")}
        >
          <MdOutlineMiscellaneousServices
            size={20}
            className="settingsNavigatorOptionIcon"
          />
          Services
        </span> */}
            <span
              className={`settingsNavigatorOption ${
                selectedOption === "Configuration" ? "selected-nav" : ""
              } `}
              onClick={() => handleOptionClick("Configuration")}
            >
              <Link to={"/settings/configuration"} className="link settings">
                <TiSpannerOutline
                  size={20}
                  className="settingsNavigatorOptionIcon"
                />
                Configuration
              </Link>
            </span>
            <span
              className={`settingsNavigatorOption ${
                selectedOption === "BackupRestore" ? "selected-nav" : ""
              } `}
              onClick={() => handleOptionClick("BackupRestore")}
            >
              <Link to={"/settings/backupRestore"} className="link settings">
                <LuDatabaseBackup
                  size={20}
                  className="settingsNavigatorOptionIcon"
                />
                Backup & Restore
              </Link>
            </span>
            {/* <span
            className={`settingsNavigatorOption ${
              selectedOption === "OnboardingWizard" ? "selected" : ""
            } `}
            onClick={() => handleOptionClick("OnboardingWizard")}
          >
            <FaHatWizard size={20} className="settingsNavigatorOptionIcon" />
            Onboarding Wizard
          </span> */}

            <span
              className={`settingsNavigatorOption ${
                selectedOption === "Logs" ? "selected-nav" : ""
              } `}
              onClick={() => handleOptionClick("Logs")}
            >
              <Link to={"/settings/logs"} className="link settings">
                <VscDebugConsole
                  size={20}
                  className="settingsNavigatorOptionIcon"
                />
                Device Logs
              </Link>
            </span>
            <span
              className={`settingsNavigatorOption ${
                selectedOption === "License" ? "selected-nav" : ""
              } `}
              onClick={() => handleOptionClick("License")}
            >
              <Link to={"/settings/license"} className="link settings">
                <IoKeyOutline
                  size={20}
                  className="settingsNavigatorOptionIcon"
                />
                License
              </Link>
            </span>
            {/* <span
          className={`settingsNavigatorOption ${
            selectedOption === "MQTTConfiguration" ? "selected" : ""
          } `}
          onClick={() => handleOptionClick("MQTTConfiguration")}
        >
          <SiMqtt size={20} className="settingsNavigatorOptionIcon" />
          MQTT Configurations
        </span> */}
          </div>
        </div>
      </CustomNavigator>
    )
  );
};

export default SettingsNavigator;
