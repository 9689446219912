/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import PreLoader from "../../../../../reusableComponents/preLoader";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FFBA86"];

const PieCharts = ({ chart, selectedDevice }) => {
  const { config } = useMenu();
  // const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const fetchLiveDeviceData = async () => {
    try {
      const couchdbUrl = dbUrl;
      const pointName = chart?.data?.points;
      const deviceId = selectedDevice?.id ? selectedDevice?.id : "";
      let pointId = "";

      // Fetch parent device data
      const parentDeviceResponse = await api.get(
        `${couchdbUrl}/devices/${selectedDevice.parentId}`
      );
      const parentDevice = parentDeviceResponse.data.data.devices.find(
        (deviceInstance) => deviceInstance.deviceInstance === parseInt(deviceId)
      );

      if (!parentDevice) {
        console.log("Device not found in parent device data");
        // setIsLoading(false);
        setData([]);
        return;
      }

      // Find the point ID
      const point = parentDevice.points.find((point) =>
        point.objectName.toLowerCase().includes(pointName.toLowerCase())
      );

      if (!point) {
        console.log("Point not found");
        // setIsLoading(false);
        setData([]);
        return;
      }

      pointId = point.pointId;

      // Fetch data based on point type
      if (chart?.data?.pointType === "currentVal") {
        const response = await api.get(`${couchdbUrl}/points/${pointId}`);
        const data = {
          name: response.data.data.pointName,
          value: response.data.data.pointPresentValue,
        };
        setData([data]);
      } else if (chart?.data?.pointType === "trendVal") {
        let filteredData = [];
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const response = await api.get(`${couchdbUrl}/trends/trend-${pointId}`);

        if (response.data.status === 404) {
          setData([]);
          // setIsLoading(false);
          return;
        }

        if (chart?.data?.dateRange === "1Day") {
          const currentDateString = new Date().toISOString().split("T")[0];
          filteredData = response.data.data.trends.filter((doc) =>
            doc.timestamp.startsWith(currentDateString)
          );
        } else if (chart?.data?.dateRange === "2Days") {
          const twoDaysAgo = new Date(currentDate);
          twoDaysAgo.setDate(currentDate.getDate() - 2);
          filteredData = response.data.data.trends.filter(
            (doc) => new Date(doc.timestamp) >= twoDaysAgo
          );
        } else if (chart?.data?.dateRange === "custom") {
          const startDate = new Date(chart.data.min);
          const endDate = new Date(chart.data.max);
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(23, 59, 59, 999);
          filteredData = response.data.data.trends.filter((doc) => {
            const timestamp = new Date(doc.timestamp);
            return timestamp >= startDate && timestamp <= endDate;
          });
        }
        filteredData = filteredData.slice(-100);

        const trendData = filteredData.map((doc) => ({
          name: doc.timestamp,
          value: parseFloat(doc.presentValue.toFixed(2)),
        }));

        setData(trendData);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setData([]);
    }
    // setIsLoading(false);
  };

  useEffect(() => {
    const intervalInSeconds = 5;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchLiveDeviceData();

    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart]);

  return (
    <>
      {/* {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null} */}
      <div
        id="chartContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
          overflow: "scroll",
          margin: "auto",
          height: "92%",
        }}
      >
        <span className="chartLabel">
          {chart?.data?.title
            ? chart?.data?.title
            : chart?.data?.pointType === "trendVal"
            ? chart?.data?.points[0]?.label
            : "Label"}
        </span>
        {data.length > 0 ? (
          <PieChart width={200} height={200}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip
              contentStyle={{
                backgroundColor: "var(--tooltip-background-color)",
                border: "var(--container-border)",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              labelStyle={{
                fontSize: "var(--heading-font-size)",
                color: "var(--black-color)",
                fontWeight: "bold",
              }}
              itemStyle={{
                color: "var(--black-color)",
                fontWeight: "bold",
              }}
            />
            <Legend layout="vertical" align="right" verticalAlign="middle" />
          </PieChart>
        ) : (
          <p className="noData">No Data Available</p>
        )}
      </div>
    </>
  );
};

export default PieCharts;
