/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import "../../../../../../../common/styles/lineChartStyle.css";
import api from "../../../../../../../common/utils/axiosRequest";
import { useMenu } from "../../../../../../../common/utils/menuContext";

const LineTemplateChart = ({ chart }) => {
  const [chartData, setChartData] = useState([]);
  const [yAxisDomain, setYAxisDomain] = useState([0, 0]);
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const calculateYAxisDomain = (data) => {
    const values = data.map((item) => parseFloat(item.value));
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    return [minValue, maxValue];
  };

  const fetchData = async () => {
    const pointId = chart?.points[0]?.value;
    const option = chart?.option;

    const getData = await api.get(`${dbUrl}/trends/${pointId}`);
    const trendData = getData?.data?.data?.trends;
    let consumptionData = [];
    if (chart?.type === "ec") {
      const currentTime = new Date();

      if (option === "lastHour") {
        const oneHourAgo = new Date(currentTime.getTime() - 60 * 60 * 1000);
        const lastHourData = trendData.filter((item) => {
          const itemTimestamp = new Date(item.timestamp);
          return itemTimestamp >= oneHourAgo && itemTimestamp <= currentTime;
        });

        let currentInterval = new Date(oneHourAgo.getTime());

        while (currentInterval <= currentTime) {
          const nextInterval = new Date(
            currentInterval.getTime() + 15 * 60 * 1000
          );
          let intervalData = lastHourData.filter((item) => {
            const itemTimestamp = new Date(item.timestamp);
            return (
              itemTimestamp >= currentInterval && itemTimestamp < nextInterval
            );
          });

          if (intervalData.length > 1) {
            const consumption = Math.max(
              intervalData[intervalData.length - 1].presentValue -
                intervalData[0].presentValue,
              0
            );

            const formattedDate = currentInterval.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });

            consumptionData.push({
              name: formattedDate,
              value: consumption.toFixed(2),
            });
          }

          currentInterval = nextInterval;
        }
      } else if (option === "today") {
        const todayStart = new Date(currentTime);
        todayStart.setHours(0, 0, 0, 0); // Set to the start of today without modifying currentTime

        const lastHourData = trendData.filter((item) => {
          const itemTimestamp = new Date(item.timestamp);
          return itemTimestamp >= todayStart && itemTimestamp <= currentTime;
        });

        let currentInterval = new Date(todayStart.getTime());

        while (currentInterval <= currentTime) {
          const nextInterval = new Date(
            currentInterval.getTime() + 60 * 60 * 1000
          ); // 1-hour interval
          let intervalData = lastHourData.filter((item) => {
            const itemTimestamp = new Date(item.timestamp);
            return (
              itemTimestamp >= currentInterval && itemTimestamp < nextInterval
            );
          });

          if (intervalData.length > 1) {
            const consumption = Math.max(
              intervalData[intervalData.length - 1].presentValue -
                intervalData[0].presentValue,
              0
            );

            const formattedDate = currentInterval.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });

            consumptionData.push({
              name: formattedDate,
              value: consumption.toFixed(2),
            });
          }

          currentInterval = nextInterval;
        }
      } else if (option === "yesterday") {
        const yesterdayStart = new Date(currentTime);
        yesterdayStart.setDate(currentTime.getDate() - 1); // Move one day back
        yesterdayStart.setHours(0, 0, 0, 0); // Start of yesterday
        const yesterdayEnd = new Date(yesterdayStart);
        yesterdayEnd.setHours(23, 59, 59, 999); // End of yesterday

        const yesterdayData = trendData.filter((item) => {
          const itemTimestamp = new Date(item.timestamp);
          return (
            itemTimestamp >= yesterdayStart && itemTimestamp <= yesterdayEnd
          );
        });

        let currentInterval = new Date(yesterdayStart.getTime());

        while (currentInterval <= yesterdayEnd) {
          const nextInterval = new Date(
            currentInterval.getTime() + 2 * 60 * 60 * 1000
          ); // 2-hour interval
          let intervalData = yesterdayData.filter((item) => {
            const itemTimestamp = new Date(item.timestamp);
            return (
              itemTimestamp >= currentInterval && itemTimestamp < nextInterval
            );
          });

          if (intervalData.length > 1) {
            const consumption = Math.max(
              intervalData[intervalData.length - 1].presentValue -
                intervalData[0].presentValue,
              0
            );

            const formattedDate = currentInterval.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });

            consumptionData.push({
              name: formattedDate,
              value: consumption.toFixed(2),
            });
          }

          currentInterval = nextInterval;
        }
      } else if (option === "MTD") {
        const monthStart = new Date(
          currentTime.getFullYear(),
          currentTime.getMonth(),
          1
        ); // Start of the current month
        const monthEnd = new Date(
          currentTime.getFullYear(),
          currentTime.getMonth() + 1,
          0
        ); // End of the current month

        const mtdData = trendData.filter((item) => {
          const itemTimestamp = new Date(item.timestamp);
          return itemTimestamp >= monthStart && itemTimestamp <= monthEnd;
        });

        let currentInterval = new Date(monthStart.getTime());

        while (currentInterval <= monthEnd) {
          const nextInterval = new Date(
            currentInterval.getTime() + 24 * 60 * 60 * 1000
          ); // 1-day interval
          let intervalData = mtdData.filter((item) => {
            const itemTimestamp = new Date(item.timestamp);
            return (
              itemTimestamp >= currentInterval && itemTimestamp < nextInterval
            );
          });

          if (intervalData.length > 1) {
            const consumption = Math.max(
              intervalData[intervalData.length - 1].presentValue -
                intervalData[0].presentValue,
              0
            );

            const formattedDate = currentInterval.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });

            consumptionData.push({
              name: formattedDate,
              value: consumption.toFixed(2),
            });
          }

          currentInterval = nextInterval;
        }
      }
      setChartData(consumptionData);
    } else if (chart?.type === "ect") {
      consumptionData = trendData?.map((trend) => {
        const formattedDate = new Date(trend?.timestamp).toLocaleString(
          "en-US",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          }
        );

        return {
          name: formattedDate,
          value: trend?.presentValue,
        };
      });
      setChartData(consumptionData);
    }
    const yAxisDomain = calculateYAxisDomain(consumptionData);
    setYAxisDomain(yAxisDomain);
  };

  //
  useEffect(() => {
    fetchData();
  }, [chart]);

  return (
    <>
      {/* {isLoading && <PreLoader size={80} color="var(--primary-color)" />} */}
      <div
        id="chartContainer"
        className="lineTemplateChart"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "scroll",
        }}
      >
        {chartData.length > 0 ? (
          <ResponsiveContainer width="100%" height={220}>
            <AreaChart
              data={chartData}
              className="lineChart"
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Area
                type="monotone"
                dataKey="value"
                stroke="var(--line-chart-stroke-color)"
                strokeWidth={2}
                fill="var(--line-chart-fill-color)"
              />

              <XAxis
                dataKey="name"
                tickFormatter={(name) =>
                  name.length > 10 ? `${name.substring(0, 10)}...` : name
                }
                stroke="#8884d8"
                tickLine={false}
                axisLine={{ stroke: "var(--font-color)", strokeWidth: 1.5 }}
                tick={{ fontSize: 12, fill: "var(--font-color)" }}
              />
              <YAxis
                domain={yAxisDomain}
                stroke="#8884d8"
                tickLine={false}
                axisLine={{ stroke: "var(--font-color)", strokeWidth: 1.5 }}
                tick={{ fontSize: 12, fill: "var(--font-color)" }}
                label={{
                  value: "KWH ",
                  angle: -90,
                  position: "left",
                  offset: -7,
                  fill: "var(--font-color)",
                  fontWeight: "var(--heading-font-weight)",
                  fontSize: 17,
                }}
              />

              <Tooltip
                contentStyle={{
                  backgroundColor: "var(--tooltip-background-color)",
                  border: "none",
                  borderRadius: "10px",
                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                }}
                labelStyle={{
                  fontSize: "var(--heading-font-size)",
                  color: "var(--black-color)",
                  fontWeight: "bold",
                }}
                itemStyle={{
                  color: "var(--black-color)",
                  fontWeight: "bold",
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <p className="noData template">No Data Available</p>
        )}
      </div>
    </>
  );
};

export default LineTemplateChart;
