import React from "react";

const CustomButton = ({
  handleClick,
  buttonName,
  className,
  type,
  disabled,
}) => {
  return (
    <button
      className={`${className ? className : ""} `}
      onClick={handleClick}
      type={type}
      disabled={disabled}
    >
      {buttonName}
    </button>
  );
};

export default CustomButton;
