import React from "react";
import "../../../../../common/styles/commonNodeStyle.css";
import { Handle, Position } from "reactflow";

const Custom10OperatorNode = ({ data, isConnectable }) => {
  return (
    <div className="customOperatorContainer ten">
      <div className="customNodeHeading">{data.label}</div>
      <div className="customNodeContent">
        <Handle
          type="target"
          position={Position.Left}
          id="IN0"
          style={{ top: 30 }}
          isConnectable={isConnectable}
          className="customHandle"
        />
        <Handle
          type="target"
          position={Position.Left}
          id="IN1"
          style={{ top: 50 }}
          isConnectable={isConnectable}
          className="customHandle"
        />
        <Handle
          type="target"
          position={Position.Left}
          id="IN2"
          style={{ top: 70 }}
          isConnectable={isConnectable}
          className="customHandle"
        />
        <Handle
          type="target"
          position={Position.Left}
          id="IN3"
          style={{ top: 90 }}
          isConnectable={isConnectable}
          className="customHandle"
        />
        <Handle
          type="target"
          position={Position.Left}
          id="IN4"
          style={{ top: 110 }}
          isConnectable={isConnectable}
          className="customHandle"
        />
        <Handle
          type="target"
          position={Position.Left}
          id="IN5"
          style={{ top: 130 }}
          isConnectable={isConnectable}
          className="customHandle"
        />
        <Handle
          type="target"
          position={Position.Left}
          id="IN6"
          style={{ top: 150 }}
          isConnectable={isConnectable}
          className="customHandle"
        />
        <Handle
          type="target"
          position={Position.Left}
          id="IN7"
          style={{ top: 170 }}
          isConnectable={isConnectable}
          className="customHandle"
        />
        <Handle
          type="target"
          position={Position.Left}
          id="IN8"
          style={{ top: 190 }}
          isConnectable={isConnectable}
          className="customHandle"
        />
        <Handle
          type="target"
          position={Position.Left}
          id="IN9"
          style={{ top: 210 }}
          isConnectable={isConnectable}
          className="customHandle"
        />
        <Handle
          type="source"
          position={Position.Right}
          id="OUT"
          isConnectable={isConnectable}
          className="customHandle"
        />
      </div>
    </div>
  );
};

export default Custom10OperatorNode;
