import React from "react";
import "../../../../../common/styles/objectBrowserStyle.css";
import { useState } from "react";
import { useEffect } from "react";
import { useMenu } from "../../../../../common/utils/menuContext";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import TruncateText from "../../../../../common/utils/truncateText";
import { Tooltip } from "react-tooltip";
import api from "../../../../../common/utils/axiosRequest";

const ObjectBrowser = ({ onDragStart, permissions }) => {
  const { open, reloadData, setReloadData, config } = useMenu();
  const [dataBrowserData, setDataBrowserData] = useState([]);
  const [expandedNetworks, setExpandedNetworks] = useState([]);
  const [expandedDevices, setExpandedDevices] = useState([]);
  const dbUrl = config.REACT_APP_DB_ENDPOINT;

  useEffect(() => {
    const fetchNetworksAndDevices = async () => {
      try {
        const couchdbUrl = dbUrl;
        const networksDatabase = "networks";
        const devicesDatabase = "devices";
        const networksResponse = await api.get(
          `${couchdbUrl}/${networksDatabase}/_all_docs`,
          {
            params: {
              include_docs: true,
            },
          }
        );

        const networks = networksResponse.data.data.rows.map((row) => row.doc);
        const devicesResponse = await api.get(
          `${couchdbUrl}/${devicesDatabase}/_all_docs`,
          {
            params: {
              include_docs: true,
            },
          }
        );

        const devices = devicesResponse.data.data.rows.map((row) => row.doc);
        const devicesMap = {};
        devices.forEach((device) => {
          devicesMap[device.deviceInstance] = device;
        });
        networks.forEach((network) => {
          network.devices.forEach((device) => {
            const deviceInstance = device.deviceInstance;
            if (devicesMap[deviceInstance]) {
              device.points = devicesMap[deviceInstance].points;
              device.deviceName = devicesMap[deviceInstance].deviceName;
            }
          });
        });

        setDataBrowserData(networks);
        setReloadData(false);
      } catch (error) {
        console.error("Error fetching networks and devices:", error);
        setReloadData(false);
      }
    };

    if (open || reloadData) {
      fetchNetworksAndDevices();
    } else {
      setReloadData(true);
    }
  }, [dbUrl, open, reloadData, setReloadData]);

  const toggleNetwork = (network) => {
    if (expandedNetworks.includes(network._id)) {
      setExpandedNetworks((prevState) =>
        prevState.filter((id) => id !== network._id)
      );
    } else {
      setExpandedNetworks((prevState) => [...prevState, network._id]);
    }
  };
  const toggleDevice = (device) => {
    if (expandedDevices.includes(device.deviceID)) {
      setExpandedDevices((prevState) =>
        prevState.filter((id) => id !== device.deviceID)
      );
    } else {
      setExpandedDevices((prevState) => [...prevState, device.deviceID]);
    }
  };

  return (
    <div className={`objectBrowser `}>
      <div className={`objectBrowserHeader `}>Object Browser</div>
      <div className="objectBrowserContent">
        <ul className="deviceBrowserNetworks">
          {dataBrowserData.map((network) => (
            <li className="objectBrowserList" key={network._id}>
              <div className="deviceBrowserNetworkhead">
                {expandedNetworks.includes(network._id) ? (
                  <FaChevronDown
                    className="expandCollapseIcon"
                    onClick={() => toggleNetwork(network)}
                  />
                ) : (
                  <FaChevronRight
                    className="expandCollapseIcon"
                    onClick={() => toggleNetwork(network)}
                  />
                )}
                <p onClick={() => {}}>{network.networkName}</p>
              </div>
              {expandedNetworks.includes(network._id) && (
                <ul className="deviceBrowserDevices">
                  {network.devices.map((device) => (
                    <li
                      className="objectBrowserDeviceList"
                      key={device.deviceID}
                    >
                      <div className="deviceBrowserhead">
                        {expandedDevices.includes(device.deviceID) ? (
                          <FaChevronDown
                            className="expandCollapseIcon"
                            onClick={() => toggleDevice(device)}
                          />
                        ) : (
                          <FaChevronRight
                            className="expandCollapseIcon"
                            onClick={() => toggleDevice(device)}
                          />
                        )}
                        <p
                          data-tooltip-id="tooltipStyle"
                          data-tooltip-content={`${device.deviceName}-${device.deviceInstance}`}
                        >
                          {TruncateText(device.deviceName, 15)}
                        </p>
                      </div>
                      {expandedDevices.includes(device.deviceID) &&
                        device.points && (
                          <ul className="deviceBrowserPoints">
                            {device.points.map((point, index) => (
                              <li
                                className="objectBrowserPointList"
                                key={index}
                                draggable={permissions.rules.write}
                                data-tooltip-id="tooltipStyle"
                                data-tooltip-content={point.objectName}
                                onDragStart={(e) =>
                                  onDragStart(
                                    e,
                                    `${device.deviceInstance}-${point.objectType} ${point.objectInstance}`,
                                    device,
                                    point.objectName
                                  )
                                }
                              >
                                {TruncateText(point.objectName, 11)}
                              </li>
                            ))}
                          </ul>
                        )}
                      <Tooltip
                        place="right"
                        className="tooltipStyle"
                        id="tooltipStyle"
                      />
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ObjectBrowser;
