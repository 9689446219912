/* eslint-disable react-hooks/exhaustive-deps */
import React, { PureComponent, useEffect, useState } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip, // Import Tooltip component
} from "recharts";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const Radarchart = ({ chart }) => {
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [chartData, setChartData] = useState([]);
  // const [loading, setLoading] = useState(true);

  const fetchLiveDeviceData = async () => {
    try {
      const couchdbUrl = dbUrl;

      const fetchedData = await Promise.all(
        chart.data.points.map(async (point) => {
          const response = await api.get(`${couchdbUrl}/points/${point.value}`);
          return response.data.data;
        })
      );

      const data = fetchedData.map((value) => ({
        label: `${value.pointDeviceInstance}-${value.pointName}`,
        size: value.pointPresentValue,
      }));

      setChartData(data);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // setLoading(false);
    }
  };

  useEffect(() => {
    const intervalInSeconds = chart?.data?.pollFrequency;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchLiveDeviceData();
    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    }
  }, [chart]);

  return (
    <div
      id="chartContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
        height: "92%",
        overflow: "scroll",
        margin: "auto",
      }}
    >
      <span className="chartLabel">
        {chart?.data?.title
          ? chart?.data?.title
          : chart?.data?.pointType === "trendVal"
          ? chart?.data?.points[0]?.label
          : "Label"}
      </span>
      {chartData.length > 0 ? (
        <ResponsiveContainer width="92%" height="100%">
          <RadarChart cx="50%" cy="50%" outerRadius="80%" data={chartData}>
            <PolarGrid />
            <PolarAngleAxis
              dataKey="label"
              tick={{
                fill: "var(--font-color)",
                fontSize: 12,
              }}
            />
            <PolarRadiusAxis />
            <Radar
              name="Value"
              dataKey="size"
              stroke="var(--line-chart-stroke-color)"
              fill="var(--line-chart-fill-color)"
              fillOpacity={0.8}
            />

            <Tooltip
              contentStyle={{
                backgroundColor: "var(--tooltip-background-color)",
                border: "var(--container-border)",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              labelStyle={{
                fontSize: "var(--heading-font-size)",
                color: "var(--black-color)",
                fontWeight: "bold",
              }}
              itemStyle={{
                color: "var(--black-color)",
                fontWeight: "bold",
              }}
              formatter={(value, name, props) => [`${name}: ${value}`]}
              cursor={{ fill: "transparent" }}
            />
          </RadarChart>
        </ResponsiveContainer>
      ) : (
        <p className="noData">No Data Available</p>
      )}
    </div>
  );
};

export default Radarchart;
