import React, { useEffect, useState } from "react";
import CustomTable from "../../../../reusableComponents/customTable";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";
const PointDetailsAlarmsTable = ({ pointProps }) => {
  const { config } = useMenu();
  const [pointAlarms, setPointAlarms] = useState([]);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  useEffect(() => {
    const getAllAlarms = async () => {
      try {
        const getAlarms = await api.post(`${dbUrl}/find_all_data`, {
          tableName: "alarms",
          column: "source",
          valueToSearch: `${pointProps?.pointDeviceInstance}-${pointProps?.pointName}`,
        });
        if (getAlarms?.data?.data === false) {
          setPointAlarms([]);
          return;
        } else {
          console.log(getAlarms?.data?.data);
          setPointAlarms(getAlarms?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAllAlarms();

    const intervalId = setInterval(() => {
      getAllAlarms();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [
    dbUrl,
    pointProps?._id,
    pointProps?.pointDeviceInstance,
    pointProps?.pointName,
  ]);

  const headings = [
    { id: "alarmPriority", label: "Priority" },
    { id: "alarmStartTime", label: "Date Time" },
    {
      id: "source",
      label: "Source",
    },
    {
      id: "sourceState",
      label: "Alarm State",
    },
    {
      id: "ackStatus",
      label: "Ack Status",
    },
    {
      id: "eventDesc",
      label: "Event Description",
    },
    {
      id: "message",
      label: "Message",
    },
    {
      id: "ackBy",
      label: "Acknowledged By",
    },
  ];

  return (
    <CustomTable
      data={pointAlarms}
      headings={headings}
      totalPlaceholder={"Total Alarms"}
      totalRows={pointAlarms.length}
      dateTimeHeading="alarmStartTime"
      key={"pointDetailsAlarmTable"}
    />
  );
};

export default PointDetailsAlarmsTable;
