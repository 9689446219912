/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import "../../../../common/styles/display.css";

import { useMenu } from "../../../../common/utils/menuContext";
import Canvas from "./components/canvas";
import DisplayDataConfigurationForm from "./components/displayDataConfigurationForm.jsx";
import DisplayNavigator from "./components/displayNavigator";
import Widgets from "./components/widgets";
import getPermissions from "../../../../configuration/localStorage/getPermissions";
import MiniWidgets from "./components/miniWidgets.jsx";
import FloorLayoutWidgets from "./components/floorLayoutWidgets.jsx";
import CustomConfirmDelete from "../../../reusableComponents/customConfirmDelete.jsx";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../common/utils/axiosRequest.js";
import WidgetsLibrary from "./components/widgetsLibrary.jsx";

const Display = () => {
  const { displayId } = useParams();
  const navigate = useNavigate();
  const [permissions] = useState(getPermissions);

  const { setOpen, floorLayout, config, preBuiltDashboardEnable } = useMenu();

  const [droppedItems, setDroppedItems] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [newDashboardName, setNewDashboardName] = useState("");
  const [layout, setLayout] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [view, setView] = useState("Edit");
  const [itemProperties, setItemProperties] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [configure, setConfigure] = useState(false);
  const [chartId, setChartId] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [id, setId] = useState();
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [draggedInsideImg, setDraggedInsideImg] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [draggingWidget, setDraggingWidget] = useState(null);
  const [selectedFloorWidget, setSelectedFloorWidget] = useState({});
  const [hovered, setHovered] = useState(false);
  const [hoveredPin, setHoveredPin] = useState({});
  const [isTemplate, setIsTemplate] = useState(false);
  const [defaultDisplay, setDefaultDisplay] = useState({
    _id: "",
    name: "",
    isDefault: false,
  });
  const [isDifferentDisplay, setIsDifferentDisplay] = useState(false);

  const configureData = (chartId) => {
    setConfigure(true);
    setChartId(chartId);
  };

  useEffect(() => {
    const loadDashboard = async () => {
      if (displayId) {
        handleLoadDashboard({ _id: displayId });
      }
    };
    if (id === undefined) {
      loadDashboard();
    }
  }, [displayId]);

  const handleSave = (chartId, newData) => {
    setDroppedItems((prevItems) => {
      const updatedCharts = prevItems.map((chart) =>
        chart.id === chartId ? { ...chart, data: newData } : chart
      );
      setSelectedWidget(null);
      return updatedCharts;
    });
    setConfigure(false);
  };
  const handleSaveExistingDashboard = async (existingDashboard) => {
    try {
      const updatedDashboard = {
        ...existingDashboard,
        name: newDashboardName ? newDashboardName : "Untilted Display",
        widgets: droppedItems,
        layout: layout,
        floorWidgets: widgets,
      };
      const updateDashboard = await api.put(
        `${dbUrl}/displays/${existingDashboard._id}`,
        updatedDashboard
      );
      if (updateDashboard.status === 200) {
        toast.success(`Updated Dashboard - ${newDashboardName}`);
        // setView("View");
        loadDashboards();
        // setOpen(true);
      }
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  const handleSaveAsDashboard = async () => {
    setIsPopupOpen(true);
    setId();
  };

  const handleSaveDashboard = async () => {
    setIsLoading(true);
    try {
      const getDashboards = await api.get(`${dbUrl}/displays/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      const existingDashboards = getDashboards.data.data.rows.map(
        (row) => row.doc
      );
      const existingDashboard = existingDashboards.find(
        (dashboard) => dashboard._id === id
      );

      if (existingDashboard) {
        handleSaveExistingDashboard(existingDashboard);
      } else {
        const newDashboard = {
          _id: id ? id : uuidv4(),
          name: newDashboardName ? newDashboardName : "Untitled Display",
          widgets: droppedItems,
          layout: layout,
          floorWidgets: widgets,
        };
        try {
          const saveDashboardtoDB = await api.post(
            `${dbUrl}/displays`,
            newDashboard
          );
          if (saveDashboardtoDB.status === 200) {
            toast.success(`Saved Dashboard - ${newDashboard.name}`);
            navigate(`/displays/${newDashboard._id}`, {
              state: {
                view: "Edit",
                id: newDashboard._id,
                newDashboardName: newDashboard.name,
              },
              replace: true,
            });
            setId(newDashboard._id);
            setNewDashboardName(newDashboard.name);
            setIsPopupOpen(false);
          } else {
            toast.error(`${newDashboardName} Already Exists`);
          }
        } catch (error) {
          console.log(error);
        }
      }
      loadDashboards();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(`Unexpected Error Occurred: ${error}`);
    }
  };

  const loadDashboards = async () => {
    setIsLoading(true);
    try {
      const database = "displays";
      const getDashboards = await api.get(`${dbUrl}/${database}/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      setDashboards(getDashboards.data.data.rows.map((row) => row.doc));
      setIsLoading(false);
    } catch (error) {
      console.log(`Unexpected Error Occurred: ${error}`);
    }
  };

  const handleLoadDashboard = async (dashboard) => {
    setId(dashboard._id);
    try {
      const getDashboard = await api.get(`${dbUrl}/displays/${dashboard._id}`);
      setNewDashboardName(getDashboard.data.data.name);
      const updatedItemProperties = {};
      getDashboard?.data.data?.layout?.forEach((item) => {
        updatedItemProperties[item.i] = {
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
        };
      });
      setView("View");
      setItemProperties(updatedItemProperties);
      setLayout(getDashboard.data.data.layout);
      setDroppedItems(getDashboard.data.data.widgets);
      setWidgets(getDashboard.data.data.floorWidgets);
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  const handleDeleteDashboard = async (dashboardId) => {
    setConfirmDelete(false);
    try {
      setIsLoading(true);
      const deleteDashboardResponse = await api.delete(
        `${dbUrl}/displays/${dashboardId}`
      );

      if (deleteDashboardResponse.status === 200) {
        toast.success(
          `Dashboard With ID - ${dashboardId} Deleted Successfully`
        );
        loadDashboards();
        if (dashboardId === id) {
          setNewDashboardName("");
          setDroppedItems([]);
          setId();
        }
        navigate("/displays");
      }
    } catch (error) {
      console.log(`Unexpected Error Occurred: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewDashboard = () => {
    navigate("/displays");
    setId("");
    setNewDashboardName("");
    setDroppedItems([]);
    setView("Edit");
    setOpen(false);
  };
  useEffect(() => {
    loadDashboards();
    const defaultDisplay = async () => {
      try {
        const getDisplay = await api.get(
          `${dbUrl}/defaultitems/default_displays`
        );
        if (getDisplay.status === 200) {
          const defaultDisplay = getDisplay.data.data.defaultDisplay;
          setDefaultDisplay({
            _id: defaultDisplay._id,
            name: defaultDisplay.name,
            isDefault: defaultDisplay.isDefault,
          });
          if (defaultDisplay.isDefault === true) {
            handleLoadDashboard(defaultDisplay);
          }
        }
      } catch (error) {
        console.log("No Default is Yet Set");
      }
    };
    defaultDisplay();
  }, []);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && (e.key === "s" || e.key === "S")) {
        e.preventDefault();
        handleSaveDashboard();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [id, droppedItems, layout, newDashboardName]);

  const handleDragStartWidgetContainer = (e, item) => {
    setDraggedInsideImg(false);
    e.dataTransfer.setData("item", JSON.stringify(item));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const findAvailablePosition = () => {
    let maxY = 0;
    layout.forEach((item) => {
      if (item.y + item.h > maxY) {
        maxY = item.y + item.h;
      }
    });

    return { x: 0, y: maxY, w: 5, h: 6 };
  };
  const handleCanvasDrop = (e) => {
    const widgetType = e.dataTransfer?.getData("widgetType");

    if (widgetType || draggedInsideImg) return;
    else {
      toast.success("Widget Added", {
        position: "top-center",
        autoClose: 100,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          width: "fit-content",
          minHeight: "10px!important",
          fontSize: "14px",
          textAlign: "center",
          borderRadius: "10px",
        },
        // transition: "Zoom",
      });
      e.preventDefault();
      const item = JSON.parse(e.dataTransfer.getData("item"));
      const newItem = { ...item, id: `${item.id}-${uuidv4()}` };

      const newLayoutItem = findAvailablePosition();

      setLayout((prevLayout) => [
        ...prevLayout,
        { i: newItem.key, ...newLayoutItem },
      ]);
      setDroppedItems((prevItems) => [...prevItems, newItem]);
    }
  };
  const handleDelete = (index, chart) => {
    const updatedDroppedItems = [...droppedItems];
    updatedDroppedItems.splice(index, 1);
    setDroppedItems(updatedDroppedItems);
    setWidgets((prevWidgets) => {
      const updatedWidgets = { ...prevWidgets };
      delete updatedWidgets[chart.id];
      return updatedWidgets;
    });
  };

  const handleSetAsDefault = async (
    id,
    name,
    isDefault,
    isNewDisplay = false
  ) => {
    try {
      if (
        defaultDisplay._id === id ||
        !defaultDisplay.isDefault ||
        isNewDisplay
      ) {
        setDefaultDisplay({
          _id: id,
          name: name,
          isDefault: isDefault,
        });
        const updatedDisplay = {
          _id: "default_displays",
          defaultDisplay: {
            _id: id,
            name: name,
            isDefault: isDefault,
          },
        };
        await api.put(`${dbUrl}/defaultitems/default_displays`, updatedDisplay);
        setIsDifferentDisplay(false);
      } else {
        setIsDifferentDisplay(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="display">
      {preBuiltDashboardEnable ? (
        <></>
      ) : (
        <>
          {isDifferentDisplay && (
            <>
              <CustomConfirmDelete
                key={"setAsDefault"}
                message={`${defaultDisplay.name} is set as default display. Do you want to change it?`}
                note={"Changing default display will affect all users."}
                setConfirmDelete={() => setIsDifferentDisplay(false)}
                handleDelete={() => {
                  handleSetAsDefault(id, newDashboardName, true, true);
                }}
              />
            </>
          )}
          <DisplayNavigator
            loadDashboards={loadDashboards}
            dashboards={dashboards}
            handleLoadDashboard={handleLoadDashboard}
            handleDeleteDashboard={handleDeleteDashboard}
            handleNewDashboard={handleNewDashboard}
            confirmDelete={confirmDelete}
            setConfirmDelete={setConfirmDelete}
            permissions={permissions}
          />
          <div className="canvasContainer">
            <Canvas
              handleSetAsDefault={handleSetAsDefault}
              handleCanvasDrop={handleCanvasDrop}
              handleDragOver={handleDragOver}
              handleNewDashboard={handleNewDashboard}
              droppedItems={droppedItems}
              handleDelete={handleDelete}
              view={view}
              handleSave={handleSave}
              setView={setView}
              layout={layout}
              id={id}
              handleLoadDashboard={handleLoadDashboard}
              setLayout={setLayout}
              configureData={configureData}
              setItemProperties={setItemProperties}
              itemProperties={itemProperties}
              handleSaveExistingDashboard={handleSaveExistingDashboard}
              newDashboardName={newDashboardName}
              handleSaveAsDashboard={handleSaveAsDashboard}
              handleSaveDashboard={handleSaveDashboard}
              setNewDashboardName={setNewDashboardName}
              permissions={permissions}
              selectedWidget={selectedWidget}
              setSelectedWidget={setSelectedWidget}
              draggingWidget={draggingWidget}
              setDraggingWidget={setDraggingWidget}
              selectedFloorWidget={selectedFloorWidget}
              setSelectedFloorWidget={setSelectedFloorWidget}
              widgets={widgets}
              setWidgets={setWidgets}
              setDraggedInsideImg={setDraggedInsideImg}
              setHovered={setHovered}
              setHoveredPin={setHoveredPin}
              hovered={hovered}
              hoveredPin={hoveredPin}
              defaultDisplay={defaultDisplay}
              isTemplate={isTemplate}
            />
          </div>
        </>
      )}

      {view === "View" || preBuiltDashboardEnable ? null : (
        <>
          {floorLayout ? (
            <FloorLayoutWidgets
              selectedFloorWidget={selectedFloorWidget}
              widgets={widgets}
              setWidgets={setWidgets}
            />
          ) : (
            permissions.displays.write && (
              <>
                <Widgets
                  handleDragStartWidgetContainer={
                    handleDragStartWidgetContainer
                  }
                />
                <WidgetsLibrary
                  handleDragStartWidgetContainer={
                    handleDragStartWidgetContainer
                  }
                />
              </>
            )
          )}
        </>
      )}
      {view === "Edit" && <MiniWidgets />}
      {configure ? (
        <div className="overlay" onClick={() => setConfigure(false)}>
          <DisplayDataConfigurationForm
            configure={configure}
            chartId={chartId}
            droppedItems={droppedItems}
            handleSave={handleSave}
            setConfigure={setConfigure}
            setSelectedWidget={setSelectedWidget}
            setHoveredPin={setHoveredPin}
            setHovered={setHovered}
            setWidgets={setWidgets}
            setSelectedFloorWidget={setSelectedFloorWidget}
            onClick={(e) => e.stopPropagation()}
            chart
          />
        </div>
      ) : null}

      {isPopupOpen ? (
        <div className={`saveDialog `}>
          <div className="saveHeading">Save Display</div>
          <input
            type="text"
            name="saveDashboard"
            id="saveDashboard"
            placeholder="Enter Display Name"
            onChange={(e) => setNewDashboardName(e.target.value)}
          />
          <div className="saveDialogBtns">
            <div onClick={() => handleSaveDashboard()}>Save</div>
            <div onClick={() => setIsPopupOpen(false)}>Cancel</div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Display;
