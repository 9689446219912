/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CustomButton from "../../../../reusableComponents/customButton";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import PreLoader from "../../../../reusableComponents/preLoader";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";

const ConfigureNotifications = () => {
  const animatedComponents = makeAnimated();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [receiversEmail, setReceiversEmail] = useState([]);
  const { config } = useMenu();
  const serverEndpoint = config?.REACT_APP_USER_ENDPOINT;
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);
  const [isLoading, setIsLoading] = useState(false);
  const options = ["Critical", "High", "Low"];
  const [focused, setFocused] = useState(false);
  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
  };
  const alarmPriority = options.map((option) => ({
    value: option,
    label: option,
  }));

  const fetchSMTPNotoficationConfiguration = async () => {
    try {
      const response = await api.get(`${dbUrl}/connections/smtp_notification`, {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });
      const data = response.data.data;
      if (response.status === 200) {
        setReceiversEmail(data.receiversEmail);
        const alarmPrioritiesOptions = data.alarmPriorities.map((option) => ({
          value: option,
          label: option,
        }));
        setSelectedOptions(alarmPrioritiesOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSMTPNotoficationConfiguration();
  }, []);

  const handleSaveNotificationConfigurations = async () => {
    try {
      setIsLoading(true);
      const data = {
        receiversEmail: receiversEmail,
        alarmPriorities: selectedOptions.map((option) => option.value),
      };
      const configureNotifications = await api.post(
        `${serverEndpoint}/configure_smtp_notifications`,
        data
      );
      if (configureNotifications.data.error) {
        toast.error(configureNotifications.data.error);
        setIsLoading(false);
      } else {
        toast.success(configureNotifications.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error(
        `An error occurred while saving notification configurations: ${error}`
      );
      setIsLoading(false);
    }
  };

  return (
    <div className="configureNotifications">
      {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null}
      <div className="configurationNotificationsForm">
        <div className="configurationNotificationsFormContent">
          <label htmlFor="mailId">Receiver's Mail Id</label>
          <ReactMultiEmail
            className="receiverEmails"
            emails={receiversEmail}
            onChange={(emails) => {
              setReceiversEmail(emails);
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <span
                    data-tag-handle
                    onClick={(e) => {
                      e.stopPropagation();
                      removeEmail(index);
                    }}
                  >
                    ×
                  </span>
                </div>
              );
            }}
          />
          <label htmlFor="priority">Alarm Priority</label>
          <Select
            id="priority"
            className="alarmPrioritySelect"
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={alarmPriority}
            value={selectedOptions}
            onChange={handleSelectChange}
            placeholder="Select Alarm Priorities"
          />
        </div>
        <div className="configurationNotificationsFormBtns">
          <CustomButton
            className="configurationNotificationsFormBtn"
            buttonName="Save"
            handleClick={() => handleSaveNotificationConfigurations()}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigureNotifications;
