import React, { createContext, useContext } from "react";
import { useState } from "react";
import getUser from "../../configuration/localStorage/getUser";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user] = useState(getUser);
  const [isLoggedIn, setIsLoggedIn] = useState(user ? true : false);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  return useContext(AuthContext);
};
