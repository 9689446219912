import React from "react";
import { CiSearch } from "react-icons/ci";
import { FiPlus } from "react-icons/fi";
import { HiArrowsUpDown } from "react-icons/hi2";
import { IoFilter } from "react-icons/io5";
import { RxCaretLeft, RxCaretRight } from "react-icons/rx";
import { Tooltip } from "react-tooltip";
import { useMenu } from "../../common/utils/menuContext";
import { IoMdSave } from "react-icons/io";

const CustomHeader = ({
  plusFunc = {},
  showIcons = false,
  showSearch = false,
  permission = true,
  backBtn = true,
  heading,
  search,
  setSearch,
  plusToolTipLabel,
  inputToolTipLabel,
  isInput = false,
  showSave = false,
  saveFunc = {},
  inputValue = "",
  inputOnChange = () => {},
  inputOnBlur = () => {},
  inputPlaceHolder,
  fontSize = "",
}) => {
  const { open, setOpen } = useMenu();
  return (
    <>
      <div className="headerContainer container-style">
        <div className="customHeaderLeftContent">
          {backBtn &&
            (open ? (
              <RxCaretLeft
                className={"headerBackIcon"}
                onClick={() => setOpen(!open)}
                toolTipId="customHeader"
                toolTipContent="Collapse"
              />
            ) : (
              <RxCaretRight
                className={"headerBackIcon"}
                onClick={() => setOpen(!open)}
                toolTipId="customHeader"
                toolTipContent="Expand"
              />
            ))}
          {isInput ? (
            <div className="input-style headerInput">
              <input
                type="text"
                style={{ margin: "auto" }}
                placeholder={inputPlaceHolder}
                value={inputValue}
                onChange={inputOnChange}
                onBlur={inputOnBlur}
                disabled={!permission}
                data-tooltip-id="customHeader"
                data-tooltip-content={inputToolTipLabel}
              />
            </div>
          ) : (
            <span
              style={{
                fontSize: fontSize,
              }}
              className="headerHeading"
            >
              {heading}
            </span>
          )}
        </div>
        <div className="customHeaderRightContent">
          {showIcons ? (
            <div
              className="headerIconsContainer"
              style={{ display: !showIcons ? "none" : "flex" }}
            >
              {permission && (
                <FiPlus
                  className="headerIcon"
                  onClick={() => plusFunc()}
                  data-tooltip-id="customHeader"
                  data-tooltip-content={plusToolTipLabel}
                />
              )}
              <IoFilter className="headerIcon" />
              <HiArrowsUpDown className={"headerIcon"} />
            </div>
          ) : (
            <div className="headerIconsContainer"></div>
          )}
          {showSave && permission && (
            <div className="headerIconsContainer" style={{ display: "flex" }}>
              <IoMdSave
                className="headerIcon"
                onClick={saveFunc}
                data-tooltip-id="customHeader"
                data-tooltip-content="Save"
              />
            </div>
          )}

          {showSearch ? (
            <div className="headerSearch">
              <CiSearch className="headerSearchIcon" />
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </div>
          ) : (
            <div className="headerSearchNone"></div>
          )}
        </div>
      </div>
      <Tooltip place="bottom" className="tooltipStyle" id="customHeader" />
    </>
  );
};

export default CustomHeader;
