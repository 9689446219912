import React, { useEffect, useState } from "react";
import { MdOutlineDeviceThermostat } from "react-icons/md";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const PinChart = ({
  handleDragWidgetInsideCanvasStart,
  handlePinHover,
  setHoveredPin,
  setHovered,
  hovered,
  hoveredPin,
  setSelectedFloorWidget,
  widget,
  handleDragOver,
  handleDragInsideCanvasEnd,
  handleDragInsideCanvas,
  hoverPosition,
  view,
}) => {
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [liveValue, setLiveValue] = useState();
  const [isActive, setIsActive] = useState();
  const [dialogPosition, setDialogPosition] = useState({ left: 0, top: 0 });

  useEffect(() => {
    const fetchPointInfo = async () => {
      try {
        const response = await api.get(
          `${dbUrl}/points/${widget.selectedPoint}`
        );
        setLiveValue(response.data.data.pointPresentValue);
        setIsActive(response.data.data.pointLastPollStatus);
      } catch (error) {
        console.error(error);
      }
    };

    if (widget.id) {
      fetchPointInfo();
      const intervalId = setInterval(fetchPointInfo, 5000);
      return () => clearInterval(intervalId);
    }
  }, [dbUrl, widget]);

  useEffect(() => {
    const updateDialogPosition = () => {
      if (hovered && hoveredPin) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const pinLeft = hoverPosition.left;
        const pinTop = hoverPosition.top;
        const isLeftSide = pinLeft > viewportWidth / 2;
        const horizontalOffset = isLeftSide ? -120 : 60;

        const isNearTop = pinTop < viewportHeight / 2;
        const verticalOffset = isNearTop ? 60 : -60;

        setDialogPosition({
          left: pinLeft + horizontalOffset,
          top: pinTop + verticalOffset,
        });
      }
    };

    updateDialogPosition();
  }, [hovered, hoveredPin, hoverPosition]);

  return (
    <>
      <div
        className="floorImageWidgetPin"
        id={widget.id}
        key={widget.id}
        style={{
          position: "absolute",
          left: widget.x,
          top: widget.y,
          transform: "translate(-50%, -50%)",
        }}
        draggable={view === "Edit" ? true : false}
        onDragStart={(e) =>
          handleDragWidgetInsideCanvasStart(e, widget.id, widget.parentId)
        }
        onMouseEnter={(e) => handlePinHover(e, widget)}
        onMouseLeave={() => {
          setHoveredPin({});
          setHovered(false);
        }}
        onDrag={handleDragInsideCanvas}
        onDragOver={handleDragOver}
        onDragEnd={handleDragInsideCanvasEnd}
        onClick={() => {
          setSelectedFloorWidget(widget);
        }}
      >
        <MdOutlineDeviceThermostat size={25} color={widget.color} />
        <span
          className={`${
            isActive === "Failed" ? "pointPinInactive" : "pointPinActive"
          }`}
        >
          .
        </span>
        <span className="floorLayoutPinPointValue">
          {liveValue !== undefined ? liveValue : widget?.pointValue}
        </span>
      </div>
      {hovered && hoveredPin?.id === widget.id && hoveredPin?.pointName && (
        <div
          id={widget.id}
          style={{
            position: "absolute",
            left: dialogPosition.left,
            top: dialogPosition.top,
            transform: "translate(-50%, -50%)",
          }}
          className="floorImageWidgetPinDetails"
        >
          <span className="floorLayoutPointValue">
            {liveValue !== undefined ? liveValue : hoveredPin?.pointValue}
          </span>
          <span className="floorLayoutPointName">{hoveredPin?.pointName}</span>
          <span className="floorLayoutPointDesc">
            {hoveredPin?.pointDescription}
          </span>
        </div>
      )}
    </>
  );
};

export default PinChart;
