/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

const columns = [
  { id: "sno", label: "S.No" },
  { id: "networkName", label: "Name" },
  { id: "networkType", label: "Type" },
  { id: "networkStatus", label: "Status" },
  { id: "lastPollTime", label: "Created At" },
];

export default function DeviceNetworkTable({
  networkProperties,
  filteredNetworks,
  fetchData,
}) {
  useEffect(() => {
    fetchData();
  }, []);

  // return (
  //   <Paper className={`deviceNetworkTable `}>
  //     <TableContainer
  //       className="deviceNetworkTableContainer"
  //       style={{ maxHeight: 440 }}
  //     >
  //       <Table stickyHeader aria-label="sticky table">
  //         <TableHead>
  //           <TableRow>
  //             {columns.map((column) => (
  //               <TableCell
  //                 className={`deviceNetworkTableHeader `}
  //                 key={column.id}
  //                 align="left" // left align table headers
  //                 sx={{
  //                   minWidth: column.minWidth,
  //                   fontWeight: "bold",
  //                   paddingLeft: "5px",
  //                 }}
  //               >
  //                 {column.label}
  //               </TableCell>
  //             ))}
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {filteredNetworks
  //             ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  //             .map((row, index) => (
  //               <TableRow
  //                 hover
  //                 role="checkbox"
  //                 tabIndex={-1}
  //                 key={index}
  //                 onClick={() => networkProperties(row)}
  //                 style={{ cursor: "pointer" }} // Indicates clickable row
  //               >
  //                 {columns.map((column) => {
  //                   // Handle the S.No column
  //                   if (column.id === "sno") {
  //                     return (
  //                       <TableCell
  //                         className={`deviceNetworkTableData`}
  //                         key={column.id}
  //                         align="center" // left align table values
  //                       >
  //                         {page * rowsPerPage + index + 1}
  //                       </TableCell>
  //                     );
  //                   }
  //                   return (
  //                     <TableCell
  //                       className={`deviceNetworkTableData `}
  //                       key={column.id}
  //                       align="left" // left align table values
  //                     >
  //                       {row[column.id]}
  //                     </TableCell>
  //                   );
  //                 })}
  //               </TableRow>
  //             ))}
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
  //     <TablePagination
  //       className={`deviceNetworkTablePagination `}
  //       rowsPerPageOptions={[5, 10, 15]}
  //       component="div"
  //       count={filteredNetworks.length}
  //       rowsPerPage={rowsPerPage}
  //       page={page}
  //       onPageChange={handleChangePage}
  //       onRowsPerPageChange={handleChangeRowsPerPage}
  //     />
  //   </Paper>
  // );
  return (
    <div className="deviceNetworkTable container-style">
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.id}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredNetworks?.map((row, index) => (
            <tr key={index} onClick={() => networkProperties(row)}>
              {columns.map((column) =>
                column.id === "sno" ? (
                  <td key={column.id}>{index + 1}</td>
                ) : (
                  <td key={column.id}>{row[column.id]}</td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
