import React from "react";
import "../../../../../common/styles/contextMenuStyle.css"

const ContextMenu = ({ items, position }) => {
  const handleClick = (action) => {
    action();
    console.log(position);
  };

  const menuStyle = {
    position: "absolute",
    top: position.top+ 80,
    left: position.left +150,
  };

  return (
    <div className="context-menu" style={menuStyle}>
      {items.map((item, index) => (
        <button
          key={index}
          className="contextMenuItem"
          onClick={() => handleClick(item.action)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default ContextMenu;
