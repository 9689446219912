/* eslint-disable react-hooks/exhaustive-deps */
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../../../../common/styles/alamsStyle.css";

import getPermissions from "../../../../configuration/localStorage/getPermissions";
import getUser from "../../../../configuration/localStorage/getUser.js";
import CustomConfirmDelete from "../../../reusableComponents/customConfirmDelete";
import PreLoader from "../../../reusableComponents/preLoader";
import AlarmTable from "./components/alarmTable.jsx";
import { useMenu } from "../../../../common/utils/menuContext.js";
import api from "../../../../common/utils/axiosRequest.js";

const Alarms = () => {
  const [permissions] = useState(getPermissions);
  const { config } = useMenu();
  const serverEndpoint = config?.REACT_APP_ALARM_ENDPOINT;
  const [alarmData, setAlarmData] = useState([]);
  const [ackStatusMessageList, setAckStatusMessageList] = useState([]);
  const [disableBtns, setDisableBtns] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dbUrl = config?.REACT_APP_ALARM_ENDPOINT;
  const database = "alarms";
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedAlarms, setSelectedAlarms] = useState([]);
  const [user] = useState(getUser);

  useEffect(() => {
    fetchAllAlarms();
  }, []);

  const fetchAllAlarms = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `${serverEndpoint}/${database}/_all_docs`,
        {
          params: {
            include_docs: true,
          },
        }
      );
      const alarms = response.data.data.rows.map((row) => row.doc);
      setAlarmData(alarms);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching alarms:", error);
      toast.error("Unable to Fetch Alarms");
    }
  };

  const handleAckStatusMessage = (id, ackStatus, message) => {
    setAckStatusMessageList((prevList) => {
      const existingEntryIndex = prevList.findIndex((item) => item.id === id);
      if (existingEntryIndex !== -1) {
        const updatedList = [...prevList];
        updatedList[existingEntryIndex] = {
          id,
          ackStatus: ackStatus || updatedList[existingEntryIndex].ackStatus,
          message: message || updatedList[existingEntryIndex].message,
        };
        return updatedList;
      } else {
        return [...prevList, { id, ackStatus, message }];
      }
    });
  };

  const acknowledgeAlarms = async () => {
    setDisableBtns(true);
    try {
      if (selectedAlarms.length > 0) {
        await Promise.all(
          selectedAlarms.map(async (id) => {
            const alarmData = ackStatusMessageList.find(
              (item) => item.id === id
            );
            const updatedData = {
              ackStatus: alarmData?.ackStatus || "Acknowledged",
              message: alarmData?.message || "Alarm Acknowledged",
              ackBy: `${user?.firstName} ${user?.lastName}`,
            };
            await api.put(`${dbUrl}/alarms/${id}`, updatedData);
            fetchAllAlarms();
          })
        );
        setSelectedAlarms([]);
        toast.success("Alarms Acknowledged");
      } else {
        toast.warning("No Alarms to Acknowledge!");
      }
    } catch (error) {
      console.error(`Error while Acknowledging Alarms: ${error}`);
      setDisableBtns(false);
    } finally {
      setDisableBtns(false);
    }
  };

  const acknowledgeAllAlarms = async () => {
    setDisableBtns(true);
    try {
      const acknowledgePromise = api.post(`${serverEndpoint}/ack_all_alarms`, {
        ackBy: `${user?.firstName} ${user?.lastName}`,
      });

      toast.promise(acknowledgePromise, {
        pending:
          "Acknowledging Alarms in the Background, Please Wait..., It may take some time...",
        success: "Alarms Acknowledged",
        error: "Error while acknowledging alarms",
      });

      const results = await Promise.allSettled([acknowledgePromise]);

      if (results[0].status === "fulfilled") {
        fetchAllAlarms();
        setSelectedAlarms([]);
      } else {
        console.error(results[0].reason);
      }
    } catch (error) {
      console.error("An error occurred during the axios request:", error);
      toast.error(
        "An error occurred while sending the request to acknowledge alarms."
      );
    } finally {
      setDisableBtns(false);
    }
  };

  const clearAlarms = async () => {
    setConfirmDelete(false);
    try {
      setIsLoading(true);
      const deleteAlarms = await api.post(`${serverEndpoint}/clear_alarms`, {
        orgId: user?.orgId,
      });
      if (deleteAlarms.data.status === 200) {
        toast.success("All Alarms Cleared Successfully");
      } else if (deleteAlarms?.data?.status === 404) {
        toast.warning(deleteAlarms?.data?.message);
      } else {
        toast.error(`Unable to Clear Alarms: ${deleteAlarms?.data?.error}`);
      }
      fetchAllAlarms();
      setIsLoading(false);
    } catch (error) {
      toast.error(`Failed to Clear All Alarms: ${error}`);
      setIsLoading(false);
    }
  };

  const columns = [
    { id: "alarmPriority", label: "Priority" },
    { id: "alarmStartTime", label: "Date Time" },
    {
      id: "source",
      label: "Source",
    },
    {
      id: "sourceState",
      label: "Source State",
    },
    {
      id: "ackStatus",
      label: "Ack Status",
    },
    {
      id: "eventDesc",
      label: "Event Description",
    },
    {
      id: "message",
      label: "Message",
    },
  ];

  const convertToCSV = (data) => {
    const header = columns.map((column) => column.label).join(",");
    const rows = data.map((row) => {
      const rowData = columns.map((column) => {
        return row[column.id] || "";
      });
      return rowData.join(",");
    });
    return [header, ...rows].join("\n");
  };

  const _id = alarmData?.map((alarm) => alarm._id);
  const priority = alarmData?.map((alarm) => alarm.alarmPriority);
  const dateTime = alarmData?.map((alarm) => alarm.alarmStartTime);
  const source = alarmData?.map((alarm) => alarm.source);
  const sourceState = alarmData?.map((alarm) => alarm.sourceState);
  const ackBy = alarmData?.map((alarm) => alarm.ackBy);
  const eventDescription = alarmData?.map((alarm) => alarm.eventDesc);
  const ackStatus = alarmData?.map((alarm) =>
    alarm.ackStatus === "Acknowledged" ? (
      alarm.ackStatus
    ) : (
      <div className="input-style">
        <select
          name="ackStatus"
          id={alarm._id}
          onChange={(e) =>
            handleAckStatusMessage(alarm._id, e.target.value, "")
          }
        >
          <option value="Unacknowledged">Unacknowledged</option>
          <option value="Acknowledged">Acknowledged</option>
        </select>
      </div>
    )
  );
  const message = alarmData?.map((alarm) =>
    alarm.message ? (
      alarm.message
    ) : (
      <div className="input-style">
        <input
          type="text"
          id={alarm._id}
          onChange={(e) => {
            handleAckStatusMessage(alarm._id, "", e.target.value);
          }}
        />
      </div>
    )
  );

  return (
    <div className={`alarms `}>
      {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null}
      <AlarmTable
        id="alarmTable"
        _id={_id}
        priority={priority}
        dateTime={dateTime}
        source={source}
        fetchAlarms={fetchAllAlarms}
        sourceState={sourceState}
        ackStatus={ackStatus}
        eventDescription={eventDescription}
        message={message}
        ackBy={ackBy}
        isLoading={isLoading}
        selectedAlarms={selectedAlarms}
        setSelectedAlarms={setSelectedAlarms}
        setAlarmData={setAlarmData}
        acknowledgeAlarms={acknowledgeAlarms}
        disableBtns={disableBtns}
        permissions={permissions}
        acknowledgeAllAlarms={acknowledgeAllAlarms}
        setConfirmDelete={setConfirmDelete}
        convertToCSV={convertToCSV}
        setIsLoading={setIsLoading}
      />
      {confirmDelete && (
        <CustomConfirmDelete
          setConfirmDelete={setConfirmDelete}
          handleDelete={clearAlarms}
          message="Are you sure you want to Clear all the Alarms?"
          note="All the Alarms will be cleared from the database."
        />
      )}
      {/* {tagUser ? (
        <div className="overlay">
          <div className="tagUser">
            <div className="tagUserDialog">
              <div className="tagUserHeading">Select and Tag User</div>
              <div className="usersList">
                {users.map((user) => (
                  <span
                    key={user}
                    className={`${
                      taggedUser[currentAlarmId] === user ? "selectedUser" : ""
                    }`}
                    onClick={() =>
                      setTaggedUser((prev) => ({
                        ...prev,
                        [currentAlarmId]: user,
                      }))
                    }
                  >
                    {user}
                  </span>
                ))}
              </div>
              <div className="userTagBtns">
                <div className="common-btn" onClick={() => setTagUser(false)}>
                  Tag User
                </div>
                <div
                  className="common-btn cancel"
                  onClick={() => setTagUser(false)}
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

export default Alarms;
