import React from "react";
import "../../common/styles/preLoaderStyle.css";

const PreLoader = ({ size = "80", color = "#000000", loadingText = "" }) => {
  return (
    <div className="preLoaderBackground">
      <div className="logoContainer">
        <div className="svg-file z-logo">
          <svg
            width={size}
            height={size}
            viewBox="0 0 24 20"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.78124 19.1124H1.00529C0.379354 19.1124 -0.0919703 18.5394 0.0152621 17.9132L2.88809 1.70402C2.97039 1.21468 3.38934 0.857178 3.87563 0.857178H7.64908C8.27502 0.857178 8.74634 1.43019 8.63911 2.05645L5.76877 18.2681C5.68399 18.7575 5.26753 19.1124 4.77875 19.1124H4.78124Z"
              fill="url(#paint0_linear_3690_930)"
            />
            <path
              d="M16.9812 7.8246H11.0111C10.3328 7.8246 9.82659 7.19327 9.96125 6.51884L10.8041 1.73191C10.9064 1.22482 11.3453 0.857178 11.854 0.857178H18.0585C19.2182 0.857178 20.0835 1.94235 19.8416 3.09598L19.3503 5.8723C19.1134 7.01072 18.1234 7.8246 16.9812 7.8246Z"
              fill="url(#paint1_linear_3690_930)"
            />
            <path
              d="M18.4059 19.1429H9.86166C8.70336 19.1429 7.82417 18.3613 8.03001 17.5121L9.57558 11.1399C9.73606 10.4804 10.5071 10 11.4072 10H20.9213C22.8786 10 24.3369 11.3425 23.9322 12.7654L23.0914 16.2476C23.0809 16.2995 23.0705 16.354 23.053 16.4034C22.5471 18.0029 20.6248 19.1377 18.4094 19.1377L18.4059 19.1429Z"
              fill="url(#paint2_linear_3690_930)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3690_930"
                x1="1.85451"
                y1="6.56194"
                x2="12.0497"
                y2="9.46332"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#DDDDDD" />
                <stop offset="1" stop-color="#777777" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_3690_930"
                x1="12.0701"
                y1="3.0345"
                x2="19.3728"
                y2="9.29035"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#DDDDDD" />
                <stop offset="1" stop-color="#777777" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_3690_930"
                x1="11.4286"
                y1="12.8571"
                x2="21.1146"
                y2="23.0324"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#DDDDDD" />
                <stop offset="1" stop-color="#777777" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <p className="preLoaderLoadingText">{loadingText}</p>
    </div>
  );
};

export default PreLoader;
