import React from "react";
import { useMenu } from "../../../../../common/utils/menuContext";

import "../../../../../common/styles/mathBlockStyle.css";

const MathBlock = ({
  onDrag4OperatorStart,
  onDragOperatorStart,
  onDrag10OperatorStart,
}) => {
  const { mathBlockOpen } = useMenu();

  return mathBlockOpen ? (
    <div className={`mathBlock `}>
      <div className={`mathBlockHeader `}>Math Blocks</div>
      <div className="mathBlockContent">
        <div className="mathBlocks">
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Add2")}
          >
            Add2
          </div>
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Sub2")}
          >
            Sub2
          </div>
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Mul2")}
          >
            Mul2
          </div>
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Div")}
          >
            Div
          </div>
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDrag4OperatorStart(e, "Add4")}
          >
            Add4
          </div>
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDrag10OperatorStart(e, "Avg10")}
          >
            Avg10
          </div>
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Avg")}
          >
            Avg
          </div>
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "FloatOffset")}
          >
            Float Offset
          </div>
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDrag4OperatorStart(e, "Min")}
          >
            Min
          </div>
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDrag4OperatorStart(e, "Max")}
          >
            Max
          </div>
          {/* <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Min Max")}
          >
            Min Max
          </div> */}
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDrag4OperatorStart(e, "Mul4")}
          >
            Mul4
          </div>
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Neg")}
          >
            Neg
          </div>
          {/* <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "Round")}
          >
            Round
          </div> */}
          <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDrag4OperatorStart(e, "Sub4")}
          >
            Sub4
          </div>
          {/* <div
            className="mathBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "TimeAvg")}
          >
            TimeAvg
          </div> */}
        </div>
      </div>
    </div>
  ) : null;
};

export default MathBlock;
