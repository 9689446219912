import { useMenu } from "../../../../../common/utils/menuContext";

import "../../../../../common/styles/trendsComparatorStyle.css";
import { MdDelete } from "react-icons/md";
import CustomConfirmDelete from "../../../../reusableComponents/customConfirmDelete";
import TruncateText from "../../../../../common/utils/truncateText";
import { Tooltip } from "react-tooltip";
import { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import CustomNavigator from "../../../../reusableComponents/customNavigator";

const TrendsComparator = ({
  setNewTrend,
  trendComparisions,
  onTrendComparisionLoad,
  setSelectedOptions,
  setValuesToShow,
  setData1,
  setData2,
  setData3,
  setData4,
  setData5,
  setData6,
  setData7,
  setData8,
  setData9,
  setData10,
  setTrendName,
  setConfirmDelete,
  confirmDelete,
  handleDeleteComparision,
  permissions,
  setTrendId,
}) => {
  const navigate = useNavigate();

  const { open } = useMenu();
  const [toBeDeleted, setToBeDeleted] = useState();
  function newTrendComparator() {
    setNewTrend(true);
    setTrendId();
    setSelectedOptions([]);
    setValuesToShow("today");
    setTrendName("");
    setData1([]);
    setData2([]);
    setData3([]);
    setData4([]);
    setData5([]);
    setData6([]);
    setData7([]);
    setData8([]);
    setData9([]);
    setData10([]);
    navigate("/trends");
  }

  return open ? (
    <>
      <CustomNavigator className={"customTrendNavigator"}>
        <div className={`trendNavigator `}>
          <div className={`trendNavigatorHeading `}>
            <span>Trends Comparator</span>
            {permissions.trends.write && (
              <FiPlus
                className="addTrendsNetworksBtn"
                data-tooltip-id="trendsComparatorToolTip"
                data-tooltip-content="New Trend Comparision"
                onClick={newTrendComparator}
                size={20}
              />
            )}
          </div>
          <div className="trendNavigatorContent">
            {trendComparisions?.map((trendComparision) => (
              <li
                key={trendComparision._id}
                onClick={() => {
                  onTrendComparisionLoad(trendComparision._id);
                }}
              >
                <span
                  className="trendName"
                  data-tooltip-id="trendsComparatorToolTip"
                  data-tooltip-content={trendComparision.name}
                >
                  <Link
                    to={`/trends/trendComparator/${trendComparision._id}`}
                    className="link"
                  >
                    {TruncateText(trendComparision.name, 15)}
                  </Link>
                </span>

                {permissions.trends.write && (
                  <MdDelete
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the onClick for the li
                      setConfirmDelete(true);
                      setToBeDeleted(trendComparision._id);
                    }}
                    style={{
                      cursor: "pointer",
                      color: "var(--white-color)",
                      margin: "auto",
                      padding: "3px",
                      borderRadius: "5px",
                      backgroundColor: "var(--light-red-color)",
                    }}
                  />
                )}
              </li>
            ))}
          </div>
        </div>
      </CustomNavigator>
      <Tooltip
        place="right"
        className="tooltipStyle"
        id="trendsComparatorToolTip"
      />
      {confirmDelete && (
        <CustomConfirmDelete
          setConfirmDelete={setConfirmDelete}
          handleDelete={() => handleDeleteComparision(toBeDeleted)}
          message="Are you sure you want to Delete this Trend Comparision?"
          note="Comparisons Associated will be Deleted."
        />
      )}
    </>
  ) : null;
};

export default TrendsComparator;
