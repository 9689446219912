/* eslint-disable react-hooks/exhaustive-deps */
import { Slide } from "@mui/material";

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DiscoveringGif from "../../../../../assets/discovering.gif";
import "../../../../../common/styles/addPointsFormStyle.css";

import { useMenu } from "../../../../../common/utils/menuContext";
import CustomButton from "../../../../reusableComponents/customButton";
import formatCurrentDateTime from "../../../../../common/utils/formattedDateTime";
import PreLoader from "../../../../reusableComponents/preLoader";
import { IoIosSearch } from "react-icons/io";
import api from "../../../../../common/utils/axiosRequest";
import getUser from "../../../../../configuration/localStorage/getUser";

const AddPointForm = ({
  isFormOpen,
  setIsFormOpen,
  deviceProps,
  networkProps,
}) => {
  const { setReloadData, config } = useMenu();
  const user = getUser();
  const [isDiscovering, setIsDiscovering] = useState(true);
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const serverEndpoint = config?.REACT_APP_POLLING_ENDPOINT;
  const [pointsArray, setPointsArray] = useState([]);

  const [loading, setLoading] = useState(false);
  const dbUrl = config?.REACT_APP_POLLING_ENDPOINT;
  const [filteredPoints, setFilteredPoints] = useState([]);
  const [search, setSearch] = useState();

  useEffect(() => {
    const fetchMQTTPoints = async () => {
      try {
        const response = await api.post(
          `${serverEndpoint}/discover_mqtt_points`,
          {
            deviceId: deviceProps?._id,
            orgId: user?.orgId,
          }
        );
        setIsDiscovering(false);
        const updatedPointsArray = response.data.data.map((point) => ({
          ...point,
        }));
        console.log(updatedPointsArray);
        setPointsArray(updatedPointsArray);
        setFilteredPoints(updatedPointsArray);
      } catch (error) {
        console.error("Error fetching MQTT Points:", error);
      }
    };

    const fetchOtherMQTTPoints = async () => {
      try {
        const response = await api.post(
          `${serverEndpoint}/discover_other_points`,
          {
            topic: deviceProps?.networkTopic,
            orgId: user?.orgId,
          }
        );
        setIsDiscovering(false);
        const updatedPointsArray = response.data.data.map((point) => ({
          ...point,
        }));
        console.log(updatedPointsArray);
        setPointsArray(updatedPointsArray);
        setFilteredPoints(updatedPointsArray);
      } catch (error) {
        console.error("Error fetching MQTT Points:", error);
      }
    };
    if (deviceProps?.deviceType === "MQTT" && !deviceProps?.isVirtual) {
      fetchMQTTPoints();
    } else {
      fetchOtherMQTTPoints();
    }
  }, []);

  const handleToggleDevice = (device) => {
    if (selectedPoints.includes(device)) {
      setSelectedPoints((prev) => prev.filter((item) => item !== device));
    } else {
      setSelectedPoints((prev) => [...prev, device]);
    }
  };

  const handleToggleSelectAll = () => {
    if (selectAll) {
      setSelectedPoints([]);
    } else {
      setSelectedPoints(filteredPoints);
    }
    setSelectAll((prev) => !prev);
  };

  async function addPointsToDb() {
    try {
      setLoading(true);
      const database = "devices";
      const documentId = deviceProps?._id;

      // Fetch the existing document
      const getResponse = await api.get(`${dbUrl}/${database}/${documentId}`);
      const existingDocument = getResponse.data.data;

      const points = [];
      for (const point of selectedPoints) {
        points.push({
          objectType: point.objectType,
          objectInstance: point.objectInstance,
          objectName: point.objectName,
          pointId: point.pointId,
          deviceInstance: existingDocument.deviceInstance,
        });

        const response = await api.post(
          `${serverEndpoint}/get_object_details`,
          {
            deviceMac: existingDocument.deviceMacAddress,
            objectType: point.objectType,
            objectInstance: point.objectInstance,
            networkAddress: deviceProps?.networkAddress,
            networkPort: deviceProps?.networkPort,
          }
        );

        const { data } = response.data;
        const pointPollId = `${point.objectType}${point.objectInstance}${existingDocument.deviceMacAddress}`;
        const newPoint = {
          _id: `${existingDocument._id}-${point.objectType} ${point.objectInstance}`,
          pointId: `${point.objectType} ${point.objectInstance}`,
          pointPollId: pointPollId.toLowerCase(),
          pointName: data.objectName,
          pointDetails: data.description,
          pointType: point.objectType,
          pointInstance: point.objectInstance,
          pointDeviceInstance: existingDocument.deviceInstance,
          pointDeviceMac: existingDocument.deviceMacAddress,
          pointUnits: data.units,
          networkType: existingDocument.deviceType,
          networkInstance: existingDocument.deviceNetwork,
          pointPollFrequency: 5,
          pointStatus: "Active",
          pointLastPollStatus: "Success",
          networkAddress: deviceProps?.networkAddress,
          deviceName: deviceProps?.deviceName,
          networkPort: parseInt(deviceProps?.networkPort),
          pointEnable: true,
          lastPollTime: formatCurrentDateTime(),
          pointDataType: "float",
          pointPresentValue: data.presentValue,
          deviceId: existingDocument?._id,
        };

        try {
          const pushToDb = await api.post(`${dbUrl}/points`, newPoint);
          if (pushToDb.status === 200) {
            console.log("Point Created Successfully");
          } else {
            if (pushToDb.status === 409) {
              console.error("Document Already Exists");
            } else {
              console.error("Unknown Error");
            }
          }
        } catch (error) {
          console.error("Error:", error.message);
        }
      }

      if (deviceProps?.deviceType === "BacnetTCP") {
        await api.post(`${serverEndpoint}/start_polling`, {
          deviceInstance: existingDocument.deviceInstance,
          deviceMac: existingDocument.deviceMacAddress,
          networkAddress: existingDocument.networkAddress,
          networkPort: existingDocument.networkPort,
          networkType: existingDocument.deviceType,
          pollFrequency: deviceProps?.pollFrequency
            ? deviceProps?.pollFrequency
            : 5,
          points: points,
        });
      }

      setReloadData(true);
      setIsFormOpen((prev) => !prev);
      setLoading(false);
      toast.success("Points Imported Successfully");
    } catch (error) {
      console.error("Error Importing Points:", error);
      toast.error("Error Importing Points");
    }
  }

  const addMQTTPointsToDb = async () => {
    try {
      setLoading(true);
      for (const point of selectedPoints) {
        const updatedPoint = {
          ...point,
          networkTopic: deviceProps?.networkTopic
            ? deviceProps?.networkTopic
            : "",
          networkId: deviceProps?.networkId,
        };
        console.log(updatedPoint);
        const response = await api.post(`${dbUrl}/points`, updatedPoint);
        if (response.status === 200) {
          setReloadData(true);
        }
      }
      await api.post(`${dbUrl}/add_id_to_mqtt`, {
        ids: selectedPoints.map((point) => point._id),
      });
      setIsFormOpen((prev) => !prev);
      setReloadData(true);
      setLoading(false);
      toast.success("Points Created Successfully");
    } catch (error) {
      console.log(error);
      setReloadData(true);
      setLoading(false);
      setIsFormOpen((prev) => !prev);
    }
  };
  const getPointTypeCategory = (pointType) => {
    if (!pointType) return null;

    const analogTypes = ["analog", "InputRegister", "Holding"];
    const binaryTypes = ["binary", "digital", "Coil", "InputStatus"];
    const multiStateTypes = ["multiState", "multiStateInput"];

    if (analogTypes.some((type) => pointType.startsWith(type))) {
      return "analogInput";
    }
    if (binaryTypes.some((type) => pointType.startsWith(type))) {
      return "binaryInput";
    }
    if (multiStateTypes.some((type) => pointType.startsWith(type))) {
      return "multiStateInput";
    }

    return null;
  };

  const addOtherMQTTPointsToDb = async () => {
    try {
      setLoading(true);
      for (const point of selectedPoints) {
        const updatedPoint = {
          ...point,
          networkTopic: deviceProps?.networkTopic
            ? deviceProps?.networkTopic
            : "",
          networkId: deviceProps?.networkId,
          deviceId: deviceProps?._id,
          pointDeviceMac: "",
          networkType: "MQTT",
          networkInstance: 1,
          pointStatus: "Active",
          pointType: getPointTypeCategory(point?.pointType),
          pointLastPollStatus: "Success",
          networkAddress: "",
          networkPort: "",
          pointEnable: true,
          pointDeviceInstance: deviceProps?.deviceInstance,
          lastPollTime: formatCurrentDateTime(),
          pointPresentValue: point.pointValue ? point.pointValue : 0,
          pointDataType: "Float",
          pollFrequency: 5,
        };
        const response = await api.post(`${dbUrl}/points`, updatedPoint);
        if (response.status === 200) {
          setReloadData(true);
        }
      }
      await api.post(`${dbUrl}/add_id_to_mqtt`, {
        ids: selectedPoints.map((point) => point._id),
      });
      setIsFormOpen((prev) => !prev);
      setReloadData(true);
      setLoading(false);
      toast.success("Points Created Successfully");
    } catch (error) {
      console.log(error);
      setReloadData(true);
      setLoading(false);
      setIsFormOpen((prev) => !prev);
    }
  };
  const handleSave = () => {
    if (deviceProps?.deviceType === "BacnetTCP") {
      if (selectedPoints.length > 0) {
        addPointsToDb();
      } else {
        toast.warning("Please Select Points to Import");
      }
    } else if (deviceProps?.deviceType === "MQTT" && !deviceProps?.isVirtual) {
      if (selectedPoints.length > 0) {
        addMQTTPointsToDb();
      } else {
        toast.warning("Please Select Points to Import");
      }
    } else {
      if (selectedPoints.length > 0) {
        addOtherMQTTPointsToDb();
      } else {
        toast.warning("Please Select Points to Import");
      }
    }
  };

  const handleClose = () => {
    setIsFormOpen((prev) => !prev);
  };
  const normalizeString = (str) => str?.toLowerCase().replace(/[^a-z0-9]/g, "");

  const handleSearch = (event) => {
    setSearch(event.target.value);

    const filtered = pointsArray?.filter((point) =>
      normalizeString(
        `${point.poinDeviceInstance}-${point?.pointName}`
      )?.includes(normalizeString(event?.target?.value))
    );
    setFilteredPoints(filtered);
  };
  return (
    <Slide direction={"left"} in={isFormOpen} mountOnEnter unmountOnExit>
      <div className={`addPointsForm container-style `}>
        {loading ? (
          <PreLoader
            size={80}
            color="var(--primary-color)"
            loadingText="Adding Points from Device! Please Wait!"
          />
        ) : (
          ""
        )}
        <div className="addPointsFormHeading">Add Points</div>
        <div className="addPointsFormContents">
          {deviceProps?.deviceType === "BacnetTCP" ||
          deviceProps?.deviceType === "MQTT" ? (
            <>
              {isDiscovering ? (
                <div className="addPointsFormDiscoveringImg">
                  <img src={DiscoveringGif} alt="" />
                  Discovering Points
                </div>
              ) : (
                <>
                  <div className="addDeviceFormContentsDeviceList">
                    <div className="addDeviceContentsDeviceListSearch input-style">
                      <input
                        type="text"
                        placeholder="Search for Points"
                        onChange={(e) => handleSearch(e)}
                        value={search}
                      />
                      <IoIosSearch
                        size={24}
                        style={{ margin: "auto", marginRight: "10px" }}
                      />
                    </div>
                    <div className="addDeviceFormContentsDeviceListItem selectAll">
                      <input
                        type="checkbox"
                        id="select-all"
                        onChange={handleToggleSelectAll}
                        checked={selectAll}
                      />
                      <label htmlFor="select-all">Select All Points</label>
                    </div>
                    <div className="addDeviceFormContentsDeviceListContents">
                      {Array.isArray(filteredPoints) ? (
                        filteredPoints.length !== 0 ? (
                          filteredPoints.map((point, index) => (
                            <div
                              // key={`${device["objectType"]} ${device["objectInstance"]}`}
                              key={`${point["_id"]}`}
                              className="addDeviceFormContentsDeviceListItem"
                            >
                              <input
                                type="checkbox"
                                id={point["_id"]}
                                onChange={() => handleToggleDevice(point)}
                                checked={selectedPoints.includes(point)}
                              />

                              <label htmlFor={point["_id"]}>
                                {`${
                                  deviceProps?.isVirtual
                                    ? deviceProps?.deviceInstance
                                    : point["pointDeviceInstance"]
                                }-${point["pointName"]}`}
                              </label>
                            </div>
                          ))
                        ) : (
                          <div>No Points found</div>
                        )
                      ) : (
                        <div>No Points found</div>
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="addDeviceFormContentsBtns">
                <CustomButton
                  buttonName="Cancel"
                  className="outline-btn-style"
                  handleClick={handleClose}
                />
                <CustomButton
                  buttonName={"Add"}
                  className="filled-btn-style"
                  handleClick={handleSave}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Slide>
  );
};

export default AddPointForm;
