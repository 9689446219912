import React, { useEffect } from "react";
import { useMenu } from "../../../../../../common/utils/menuContext";
import TruncateText from "../../../../../../common/utils/truncateText";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";

const ButtonPinChart = ({
  handleDragWidgetInsideCanvasStart,
  setSelectedFloorWidget,
  widget,
  handleDragOver,
  handleDragInsideCanvasEnd,
  handleDragInsideCanvas,
  view,
  handleLoadDashboard,
  deleteWidget,
}) => {
  const { setFloorLayout } = useMenu();

  function getContrastingColor(hexColor) {
    hexColor = hexColor.replace("#", "");
    let r = parseInt(hexColor.substring(0, 2), 16);
    let g = parseInt(hexColor.substring(2, 4), 16);
    let b = parseInt(hexColor.substring(4, 6), 16);
    let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? "black" : "white";
  }
  return (
    <>
      <div
        className={`${
          view === "Edit" ? "floorImageWidgetPin" : "floorImageWidgetButtonPin"
        }`}
        id={widget.id}
        key={widget.id}
        style={{
          position: "absolute",
          left: widget.x,
          top: widget.y,
          transform: "translate(-50%, -50%)",
        }}
        draggable={view === "Edit" ? true : false}
        onDragStart={(e) =>
          handleDragWidgetInsideCanvasStart(e, widget.id, widget.parentId)
        }
        onDrag={handleDragInsideCanvas}
        onDragOver={handleDragOver}
        onDragEnd={handleDragInsideCanvasEnd}
      >
        <button
          style={{
            backgroundColor: widget.color ? widget.color : "var(--black-color)",
            cursor: "pointer",
            padding: "8px",
            borderRadius: "10px",
            color: getContrastingColor(
              widget.color ? widget.color : "var(--white-color)"
            ),
          }}
          onClick={() => {
            handleLoadDashboard({
              _id: widget.selectedDisplay,
              name: widget.selectedDisplayName,
            });
          }}
        >
          {widget?.pointName ? TruncateText(widget?.pointName, 15) : "Button"}
        </button>
        {view === "Edit" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "5px",
              gap: "5px",
            }}
          >
            <MdEdit
              size={15}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedFloorWidget(widget);
                setFloorLayout(true);
              }}
            />
            <MdDelete
              size={15}
              style={{ cursor: "pointer" }}
              onClick={() => {
                deleteWidget(widget);
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ButtonPinChart;
