import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { HiSaveAs } from "react-icons/hi";
import { FaImage } from "react-icons/fa6";
import { AiOutlineFilePdf } from "react-icons/ai";
import { TbFileExport } from "react-icons/tb";

const StyledSpeedDial = styled(SpeedDial)(() => ({
  position: "relative",
  height: "35px",
  width: "50px",
  "& .MuiSpeedDial-fab": {
    borderRadius: "var(--input-border-radius)!important",
    backgroundColor: "var(--dialog-bg)!important",
    boxShadow: "none",
    zIndex: "2000",
  },
  "& .MuiSpeedDialAction-fab": {
    marginTop: "1px",
    color: "var(--font-color)!important",
    borderRadius: "var(--container-border-radius)!important",
    backgroundColor: "var(--dialog-bg)!important",
    zIndex: "2000",
  },
}));

const DisplayOptions = ({
  handleExportDashboardPdf,
  handleExportDashboardImage,
  handleSaveAsDashboard,
  handleSaveDashboard,
  isMobile,
}) => {
  const [open, setOpen] = useState(false);
  const speedDialRef = useRef(null);

  const actions = [
    {
      icon: <HiSaveAs size={20} />,
      name: "Save As",
      onClick: handleSaveAsDashboard,
    },
    {
      icon: <FaImage size={20} />,
      name: "Export to Image",
      onClick: handleExportDashboardImage,
    },
    {
      icon: <AiOutlineFilePdf size={20} />,
      name: "Export to Pdf",
      onClick: handleExportDashboardPdf,
    },
  ];

  const handleClickOutside = (event) => {
    if (speedDialRef.current && !speedDialRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box sx={{ transform: "translateZ(0px)" }}>
      <Box sx={{ position: "relative", mt: 0 }} ref={speedDialRef}>
        <StyledSpeedDial
          ariaLabel="Display Options"
          icon={<TbFileExport color="var(--font-color)" size={20} />}
          open={open}
          onClick={() => setOpen((prev) => !prev)}
          direction="down"
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => {
                action.onClick();
                setOpen(false);
              }}
            />
          ))}
        </StyledSpeedDial>
      </Box>
    </Box>
  );
};

export default DisplayOptions;
