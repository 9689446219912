import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const COLORS = [
  "#8889DD",
  "#8DC77B",
  "#F8C12D",
  "#E2CF45",
  "#FF5D7E",
  "#8A59A0",
  "#FF7A5A",
  "#6B5B95",
  "#92A8D1",
  "#034F84",
];

const TrendComparatorChart = ({ data }) => {
  const [yAxisDomain, setYAxisDomain] = useState([0, 0]);
  const [selectedSeries, setSelectedSeries] = useState(
    Array(data.datasets.length).fill(true)
  );

  const handleToggleSeries = (index) => {
    const newSelectedSeries = [...selectedSeries];
    newSelectedSeries[index] = !newSelectedSeries[index];
    setSelectedSeries(newSelectedSeries);
  };

  const filteredSeries = data.datasets.filter(
    (_, index) => selectedSeries[index]
  );

  const formattedData = data.labels.map((label, index) => {
    const entry = { name: label };
    data.datasets.forEach((series, seriesIndex) => {
      entry[series.label || "No Label"] = selectedSeries[seriesIndex]
        ? series.data[index]
        : null;
    });
    return entry;
  });

  const allValues = filteredSeries.flatMap((series) => series.data);
  const minValue = Math.min(...allValues);
  const maxValue = Math.max(...allValues);

  return (
    <div
      className="trendComparatorChart"
      style={{
        height: "100%",
        minHeight: "fit-content",
        maxHeight: "fit-content",
        overflow: "scroll",
      }}
    >
      {data.labels && data.labels.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={formattedData}>
            <XAxis
              dataKey="name"
              tickFormatter={(name) =>
                name.length > 10 ? `${name.substring(0, 10)}...` : name
              }
              stroke="#8884d8"
              tickLine={false}
              axisLine={{ stroke: "var(--font-color)", strokeWidth: 1.5 }}
              tick={{ fontSize: 12, fill: "var(--font-color)" }}
            />
            <YAxis
              domain={[minValue - 2, maxValue + 2]}
              stroke="#8884d8"
              tickLine={false}
              axisLine={{ stroke: "var(--font-color)", strokeWidth: 1.5 }}
              tick={{ fontSize: 12, fill: "var(--font-color)" }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "var(--tooltip-background-color)",
                border: "none",
                borderRadius: "10px",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                textAlign: "left",
              }}
              labelStyle={{
                fontSize: "var(--heading-font-size)",
                color: "var(--black-color)",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
              itemStyle={{
                color: "var(--black-color)",
              }}
            />
            <Legend
              onClick={(e) => {
                const index = data.datasets.findIndex(
                  (dataset) => dataset.label === e.value
                );
                handleToggleSeries(index);
              }}
              formatter={(value, entry, index) => (
                <span
                  style={{
                    textDecoration: selectedSeries[index]
                      ? "none"
                      : "line-through",
                    color: COLORS[index % COLORS.length],
                    cursor: "pointer",
                  }}
                >
                  {value}
                </span>
              )}
            />
            {data.datasets.map((series, index) => (
              <Area
                key={index}
                type="monotone"
                dataKey={series.label || "No Label"}
                stroke={COLORS[index % COLORS.length]}
                fill={COLORS[index % COLORS.length]}
                strokeDasharray={!selectedSeries[index] ? "3 3" : "none"}
                opacity={selectedSeries[index] ? 1 : 0.2}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <div className="noDataAvailable">No Data Available</div>
      )}
    </div>
  );
};

export default TrendComparatorChart;
