/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../../../../../common/styles/trendCanvasStyle.css";

import PreLoader from "../../../../reusableComponents/preLoader";
import TrendCanvasChart from "./trendCanvasChart";
import TrendCanvasNavigator from "./trendCanvasNavigator.jsx";
import TrendCanvasTable from "./trendCanvasTable.jsx";
import { VscClearAll } from "react-icons/vsc";
import CustomConfirmDelete from "../../../../reusableComponents/customConfirmDelete";
import CustomButton from "../../../../reusableComponents/customButton";
import { saveAs } from "file-saver";
import { HiOutlineRefresh } from "react-icons/hi";
import html2canvas from "html2canvas";
import TruncateText from "../../../../../common/utils/truncateText";
import { Tooltip } from "react-tooltip";
import { useMenu } from "../../../../../common/utils/menuContext.js";
import api from "../../../../../common/utils/axiosRequest.js";

const TrendCanvas = ({
  selectedItem,
  setSelectedItem,
  fetchTrendPoints,
  permissions,
  valueQuality,
  setSelectedOption,
  selectedOption,
}) => {
  const [trendData, setTrendData] = useState(null);
  const { config } = useMenu();
  const serverEndpoint = config?.REACT_APP_TREND_ENDPOINT;
  const [isLoading, setIsLoading] = useState(true);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const [confirmDelete, setConfirmDelete] = useState(false);

  const fetchTrends = async () => {
    setIsLoading(true);
    try {
      const pointId = selectedItem?._id;
      try {
        const trendDataResponse = await api.get(`${dbUrl}/trends/${pointId}`);

        if (trendDataResponse.status === 200) {
          const trendData = trendDataResponse.data.data["trends"];
          setTrendData(trendData);
          setIsLoading(false);
        } else {
          console.error(`Unexpected status code: ${trendDataResponse.status}`);
          setIsLoading(false);
          setTrendData(null);
        }
      } catch (error) {
        console.error("An error occurred during the api request:", error);
        setIsLoading(false);
        setTrendData(null);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Unable to Fetch Data : ", error);
    }
  };
  useEffect(() => {
    fetchTrends();
    setSelectedOption("Table");
  }, [selectedItem]);

  const handleOptionClick = async (option) => {
    fetchTrends();
    setSelectedOption(option);
  };

  const timestamp = trendData?.map((trend) => trend.timestamp);
  const dataQuality = trendData?.map((trend) => valueQuality);
  const value = trendData?.map((trend) => trend.presentValue.toFixed(2));
  const labels = trendData?.map((trend) => trend.timestamp);

  const deleteTrend = async () => {
    setConfirmDelete(false);
    setIsLoading(true);
    try {
      const pointId = selectedItem?._id;
      const trends = {
        trends: [],
      };
      const deleteTrendPollfromDB = await api.put(
        `${serverEndpoint}/trends/${pointId}`,
        trends
      );
      if (deleteTrendPollfromDB.status === 200) {
        // setSelectedItem(null);
        // setSelectedOption(null);
        fetchTrends();
        toast.success("Trend Deleted Successfully");
      }
      setIsLoading(false);
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  const columns = [
    { id: "timestamp", label: "TimeStamp" },
    { id: "presentValue", label: "Value" },
    { id: "valueQuality", label: "ValueQuality" },
  ];
  const convertToCSV = (data) => {
    const header = columns.map((column) => column.label).join(",");
    const rows = data.map((row) => {
      console.log(row);
      const rowData = columns.map((column) => {
        console.log(column);
        if (column.id === "valueQuality") {
          return valueQuality;
        } else {
          return row[column.id] || "";
        }
      });
      return rowData.join(",");
    });
    return [header, ...rows].join("\n");
  };
  const handleExportCSV = () => {
    setIsLoading(true);
    try {
      const csvData = convertToCSV(trendData);
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      saveAs(
        blob,
        `${selectedItem?.deviceInstance}-${selectedItem?.pointName}.csv`
      );
      setIsLoading(false);
    } catch (error) {
      console.error(`Unexpected Error: ${error}`);
    }
  };
  const handleExportImage = () => {
    try {
      const gridLayoutElement = document.querySelector(".trendChart");
      html2canvas(gridLayoutElement).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = image;
        link.download = `${selectedItem?.deviceInstance}-${selectedItem?.pointName}.png`;
        link.click();
      });
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  const data = {
    labels,
    datasets: [
      {
        label: `${selectedItem?.deviceInstance}-${selectedItem?.pointName}`,
        data: trendData?.map((trend) => trend.presentValue),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
  return (
    <div className={`trendCanvas `}>
      {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null}
      <TrendCanvasNavigator
        onOptionClick={handleOptionClick}
        selectedOption={selectedOption}
      />
      <div className="trendCanvasLabel">
        <div className="trendCanvasLabelText">
          {selectedItem && (
            <>
              <span>
                {TruncateText(
                  `${selectedItem?.deviceInstance}-${selectedItem?.pointName}`,
                  30
                )}
              </span>
              <HiOutlineRefresh
                onClick={fetchTrends}
                className="filled-btn-style"
                data-tooltip-id="trendsIconExp"
                data-tooltip-content="Refresh"
              />
            </>
          )}
          {selectedItem && permissions.trends.write ? (
            <VscClearAll
              onClick={() => setConfirmDelete(true)}
              className="filled-btn-style"
              data-tooltip-id="trendsIconExp"
              data-tooltip-content="Clear Trends"
            />
          ) : null}
        </div>
        {selectedOption === "Table"
          ? selectedItem && (
              <CustomButton
                className="filled-btn-style"
                buttonName={"Export Table"}
                handleClick={handleExportCSV}
              />
            )
          : selectedItem && (
              <CustomButton
                className="filled-btn-style"
                buttonName={"Export Chart"}
                handleClick={handleExportImage}
              />
            )}
      </div>
      {confirmDelete && (
        <CustomConfirmDelete
          setConfirmDelete={setConfirmDelete}
          handleDelete={deleteTrend}
          message="Are you sure you want to Delete this Trend ?"
          note="All Trend Data Corresponding to this Trend will be Deleted!"
        />
      )}
      {selectedOption === "Table" &&
        (!value || !timestamp ? (
          "No Records Found"
        ) : (
          <TrendCanvasTable
            timestamp={timestamp}
            valueQuality={dataQuality}
            selectedItem={selectedItem}
            value={value}
          />
        ))}
      {selectedOption === "Chart" && (
        <div className="trendChart">
          <TrendCanvasChart data={data} />
        </div>
      )}
      {/* {selectedOption === "Trend Comparator" && <TrendComparator />} */}
      <Tooltip place="bottom" className="tooltipStyle" id="trendsIconExp" />
    </div>
  );
};

export default TrendCanvas;
