/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../../../../common/styles/logsStyle.css";
import { IoSearch } from "react-icons/io5";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import CustomHeader from "../../../../reusableComponents/customHeader";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";

const Logs = () => {
  const { config } = useMenu();
  const serverEndpoint = config?.REACT_APP_USER_ENDPOINT;
  const [Logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [ascendingOrder, setAscendingOrder] = useState(true);

  const collectLogs = async () => {
    try {
      let logsFromServer = await api.get(`${serverEndpoint}/fetch_logs`);
      setLogs(logsFromServer.data.logs.slice(-100));
      setFilteredLogs(logsFromServer.data.logs.slice(-100));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    collectLogs();
  }, [serverEndpoint]);

  const handleChip = (chip) => {
    if (chip === "clear") {
      collectLogs();
    } else {
      const filtered = Logs.filter((log) => {
        if (typeof log === "string") {
          return log.toLowerCase().includes(chip.toLowerCase());
        } else if (Array.isArray(log)) {
          const arrayFiltered = log.filter((subLog) =>
            subLog.toLowerCase().includes(chip.toLowerCase())
          );
          return arrayFiltered.length > 0;
        }
        return false;
      });
      setFilteredLogs(filtered);
    }
  };
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered = Logs.filter((log) => {
      if (typeof log === "string") {
        return log.toLowerCase().includes(query.toLowerCase());
      } else if (Array.isArray(log)) {
        const arrayFiltered = log.filter((subLog) =>
          subLog.toLowerCase().includes(query.toLowerCase())
        );
        return arrayFiltered.length > 0;
      }
      return false;
    });
    setFilteredLogs(filtered);
  };
  const handleSort = () => {
    try {
      const sortedLogs = [...filteredLogs].sort((a, b) => {
        let timestampA = Date.parse(a.split(" - ")[0]);
        let timestampB = Date.parse(b.split(" - ")[0]);
        if (ascendingOrder) {
          return timestampA - timestampB;
        } else {
          return timestampB - timestampA;
        }
      });
      // console.log(filteredLogs);
      setFilteredLogs(sortedLogs);
      setAscendingOrder(!ascendingOrder);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`logs `}>
      {/* <CustomHeader
        showIcons={false}
        showSearch={false}
        heading={"Device Logs"}
      /> */}
      <CustomHeader heading={"Device Logs"} />

      <div className={`logsContent container-style`}>
        <div className="logsFilterContainer">
          <div className="logsFilterOptions">
            <div
              className="filled-btn-style"
              onClick={handleSort}
              style={{
                margin: "auto",
              }}
            >
              {ascendingOrder ? (
                <FaArrowDown style={{ margin: "auto" }} />
              ) : (
                <FaArrowUp style={{ margin: "auto" }} />
              )}{" "}
              Date Time
            </div>
            <div className="logsFilterPriorityChips">
              <div
                className="logsFilterPriorityChip warning"
                onClick={() => handleChip("warning")}
              >
                WARNING
              </div>
              <div
                className="logsFilterPriorityChip error"
                onClick={() => handleChip("error")}
              >
                ERROR
              </div>
              <div
                className="logsFilterPriorityChip debug"
                onClick={() => handleChip("debug")}
              >
                DEBUG
              </div>
              <div
                className="logsFilterPriorityChip info"
                onClick={() => handleChip("info")}
              >
                INFO
              </div>
              <div
                className="logsFilterPriorityChip clear"
                onClick={() => handleChip("clear")}
              >
                CLEAR
              </div>
            </div>
            <div
              className="input-style"
              style={{
                display: "flex",
                width: "30%",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <input
                type="text"
                placeholder="Search Logs"
                value={searchQuery}
                onChange={handleSearch}
              />
              <IoSearch size={20} />
            </div>
          </div>
        </div>
        <div className="logsContentText">
          {filteredLogs?.length > 0 ? (
            filteredLogs?.map((log, index) => (
              <p className="deviceLogStatisticsContentLog" key={index}>
                {log}
              </p>
            ))
          ) : (
            <p className="deviceLogStatisticsNoContentLog">No Logs Found!</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Logs;
