/* eslint-disable react-hooks/exhaustive-deps */
import React, { PureComponent, useEffect, useState } from "react";
import { Treemap, ResponsiveContainer, Tooltip } from "recharts";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const TreeMap = ({ chart }) => {
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [chartData, setChartData] = useState([]);
  // const [loading, setLoading] = useState(true);

  const fetchLiveDeviceData = async () => {
    try {
      const couchdbUrl = dbUrl;

      const fetchedData = await Promise.all(
        chart.data.points.map(async (point) => {
          const response = await api.get(`${couchdbUrl}/points/${point.value}`);
          return response.data.data;
        })
      );

      const data = fetchedData.map((value) => ({
        label: `${value?.pointDeviceInstance}-${value?.pointName}`,
        size: value?.pointPresentValue,
      }));
      setChartData(data);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // setLoading(false);
    }
  };

  useEffect(() => {
    const intervalInSeconds = chart?.data?.pollFrequency;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchLiveDeviceData();
    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    }
  }, [chart]);

  const COLORS = [
    "#8889DD",
    "#8DC77B",
    "#F8C12D",
    "#E2CF45",
    "#FF5D7E",
    "#8A59A0",
    "#FF7A5A",
    "#6B5B95",
    "#92A8D1",
    "#034F84",
    "#DD4124",
    "#D65076",
    "#45B8AC",
    "#EFC050",
    "#5B5EA6",
    "#9B2335",
    "#BC243C",
    "#C3447A",
    "#98B4D4",
    "#C4DFE6",
  ];

  class CustomizedContent extends PureComponent {
    render() {
      const { depth, x, y, width, height, index, colors, size } = this.props;
      const gap = 0;

      return (
        // <g>
        <>
          <rect
            x={x + gap / 2}
            y={y + gap / 2}
            width={width - gap}
            height={height - gap}
            style={{
              fill: colors[index % colors.length],
              backgroundColor: "transparent",
            }}
          />
          {depth === 1 && (
            <text
              x={x + width / 2}
              y={y + height / 2}
              textAnchor="middle"
              fill="#fff"
              fontSize={14}
              fontWeight="bold"
            >
              {size}
            </text>
          )}
        </>
        // {/* </g> */}
      );
    }
  }

  return (
    <div
      id="chartContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
        height: "92%",
        overflow: "scroll",
        margin: "auto",
      }}
    >
      <span className="chartLabel">
        {chart?.data?.title
          ? chart?.data?.title
          : chart?.data?.pointType === "trendVal"
          ? chart?.data?.points[0]?.label
          : "Label"}
      </span>
      {chartData.length > 0 ? (
        <ResponsiveContainer width="100%" height="92%">
          <Treemap
            data={chartData}
            dataKey="size"
            nameKey="label"
            stroke="transparent"
            fill="transparent"
            isAnimationActive={false}
            style={{
              backgroundColor: "transparent",
            }}
            content={<CustomizedContent colors={COLORS} />}
          >
            <Tooltip
              contentStyle={{
                backgroundColor: "var(--tooltip-background-color)",
                border: "var(--container-border)",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              labelStyle={{
                fontSize: "var(--heading-font-size)",
                color: "var(--black-color)",
                fontWeight: "bold",
              }}
              itemStyle={{
                color: "var(--black-color)",
                fontWeight: "bold",
              }}
              formatter={(value, name, props) => [
                `${value}`,
                `Point Name: ${props.payload.label}`,
              ]}
              isAnimationActive={false}
              cursor={{ fill: "transparent" }}
            />
          </Treemap>
        </ResponsiveContainer>
      ) : (
        <p className="noData">No Data Available</p>
      )}
    </div>
  );
};

export default TreeMap;
