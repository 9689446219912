/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../../../../../common/styles/tableWidgetStyle.css";
import { useState } from "react";

import { useEffect } from "react";
import { toast } from "react-toastify";
import { useMemo } from "react";
import CustomFormSelect from "../../../../../reusableComponents/customFormSelect";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const TableWidget = ({ chart }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { config } = useMenu();
  const serverEndpoint = config?.REACT_APP_SERVER_ENDPOINT;
  const [pointData, setPointData] = useState([]);
  const [pointValues, setPointValues] = useState(
    Array(pointData.length).fill("")
  );
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  useEffect(() => {
    console.log(chart);
  }, [chart]);

  const fetchPoints = async () => {
    // setIsLoading(true);
    try {
      const points = chart?.data?.points || [];
      const promises = points.map(async (point) => {
        try {
          const response = await api.get(`${dbUrl}/points/${point.value}`);
          return response.data.data;
        } catch (error) {
          console.error(
            `Error fetching data for point ${point.label}: `,
            error
          );
          throw error;
        }
      });
      const pointDataArray = await Promise.all(promises);
      setPointData(pointDataArray);
    } catch (error) {
      console.error("Unable to Fetch Data: ", error.message);
    }

    // setIsLoading(false);
  };

  useEffect(() => {
    const intervalInSeconds = chart?.data?.pollFrequency;
    const intervalInMilliseconds = intervalInSeconds * 1000;

    fetchPoints();
    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchPoints();
      }, intervalInMilliseconds);
      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart?.data?.pollFrequency, chart]);

  useMemo(() => fetchPoints(), []);

  const handleWrite = async (data, index, newValue) => {
    try {
      const getPoint = await api.get(`${dbUrl}/points/${data._id}`);
      const updatePoint = await api.post(
        `${serverEndpoint}/write/${data._id}`,
        {
          value: parseFloat(
            pointValues[index]
              ? pointValues[index]
              : newValue
              ? newValue
              : getPoint.data.pointPresentValue
          ),
          priority: 10,
          topic: getPoint?.data?.data?.networkTopic,
        }
      );
      setPointValues((prevValues) => {
        const newPointValues = [...prevValues];
        newPointValues[index] = null;
        return newPointValues;
      });

      if (updatePoint.status === 200) {
        toast.success("Point Written Successfully");
      } else {
        toast.error(`Failed to Update, Possible Cause: ${updatePoint.message}`);
      }
      setPointValues(Array(pointData.length).fill(""));
    } catch (error) {
      console.error(error);
      toast.error(`Failed to Update, Possible Cause: ${error}`);
      setPointValues((prevValues) => {
        const newPointValues = [...prevValues];
        newPointValues[index] = null;
        return newPointValues;
      });
    }
  };

  const handleKeyDown = (e, point, index) => {
    if (e.key === "Enter") {
      handleWrite(point, index);
    }
  };

  return (
    <div className="tableWidget">
      {/* {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null} */}
      <span className="chartLabel">
        {chart?.data?.title ? chart?.data?.title : "Point Details"}
      </span>

      <div className="tableWidgetContent">
        <table className="dataTable">
          <thead>
            <tr>
              <th>Device ID</th>
              <th>Point Name</th>
              <th>Point Present Value</th>
              <th>Point Unit</th>
              {!chart?.data?.enableWriteField && <th>Write Value</th>}
            </tr>
          </thead>
          <tbody>
            {pointData.map((point, index) => (
              <tr key={index}>
                <td key={point?._id}>{point?.pointDeviceInstance}</td>
                <td>{point?.pointName}</td>
                <td
                  className={`${
                    parseFloat(point?.pointPresentValue) >=
                      parseFloat(point?.alarmHighLimit) &&
                    point?.alarmHighLimitEnable === true
                      ? "red"
                      : parseFloat(point?.pointPresentValue) <=
                          parseFloat(point?.alarmLowLimit) &&
                        point?.alarmLowLimitEnable === true
                      ? "yellow"
                      : ""
                  } `}
                >
                  {(() => {
                    const isBinaryOrDigital = [
                      "binaryValue",
                      "digitalValue",
                      "digitalOutput",
                      "binaryOutput",
                      "binaryInput",
                      "digitalInput",
                      "CoilStatus",
                      "InputStatus",
                    ].some((type) => point?.pointType?.includes(type));
                    const isFalse =
                      point?.pointPresentValue === 0 ||
                      point?.pointPresentValue === 0.0;
                    const isTrue =
                      point?.pointPresentValue === 1 ||
                      point?.pointPresentValue === 1.0;

                    if (isBinaryOrDigital && isFalse)
                      return point?.statusTextZero
                        ? point?.statusTextZero
                        : "False";
                    if (isBinaryOrDigital && isTrue)
                      return point?.statusTextOne
                        ? point?.statusTextOne
                        : "True";
                    return point?.pointPresentValue;
                  })()}
                  {point?.pointUnitSymbol ? point?.pointUnitSymbol : ""}
                </td>
                <td>{point?.pointUnits}</td>
                {!chart?.data?.enableWriteField && (
                  <td>
                    {point?.pointType.includes("Holding") ||
                    point?.pointType.includes("Value") ||
                    point?.pointType.includes("Output") ||
                    point?.pointType.includes("CoilStatus")
                      ? (() => {
                          const isBinaryOrDigital = [
                            "binaryValue",
                            "digitalValue",
                            "digitalOutput",
                            "binaryOutput",
                            "binaryInput",
                            "digitalInput",
                            "CoilStatus",
                          ].some((type) => point.pointType?.includes(type));
                          const isFalse =
                            point.pointPresentValue === 0 ||
                            point.pointPresentValue === 0.0;
                          const isTrue =
                            point.pointPresentValue === 1 ||
                            point.pointPresentValue === 1.0;

                          if (isBinaryOrDigital && isFalse)
                            return (
                              <CustomFormSelect
                                id={point?._id}
                                className="writableInput"
                                value={point.pointPresentValue === 0 ? 0 : 1}
                                onChange={(e) => {
                                  const newPointValues = [...pointValues];
                                  newPointValues[index] = e.target.value;
                                  setPointValues(newPointValues);
                                  handleWrite(point, index, e.target.value);
                                }}
                                options={[
                                  {
                                    label: `${
                                      point?.statusTextZero
                                        ? point?.statusTextZero
                                        : "False"
                                    }`,
                                    value: 0,
                                  },
                                  {
                                    label: `${
                                      point?.statusTextOne
                                        ? point?.statusTextOne
                                        : "True"
                                    }`,
                                    value: 1,
                                  },
                                ]}
                              />
                            );
                          if (isBinaryOrDigital && isTrue)
                            return (
                              <CustomFormSelect
                                id={point?._id}
                                className="writableInput"
                                value={point.pointPresentValue === 0 ? 0 : 1}
                                onChange={(e) => {
                                  const newPointValues = [...pointValues];
                                  newPointValues[index] = e.target.value;
                                  setPointValues(newPointValues);
                                  handleWrite(point, index, e.target.value);
                                }}
                                options={[
                                  {
                                    label: `${
                                      point?.statusTextZero
                                        ? point?.statusTextZero
                                        : "False"
                                    }`,
                                    value: 0,
                                  },
                                  {
                                    label: `${
                                      point?.statusTextOne
                                        ? point?.statusTextOne
                                        : "True"
                                    }`,
                                    value: 1,
                                  },
                                ]}
                              />
                            );
                          return (
                            <input
                              id={point?._id}
                              className="writableInput"
                              type="text"
                              placeholder="Enter Value"
                              value={pointValues[index]}
                              onBlur={() => handleWrite(point, index)}
                              onKeyDown={(e) => handleKeyDown(e, point, index)}
                              onChange={(e) => {
                                const newPointValues = [...pointValues];
                                newPointValues[index] = e.target.value;
                                setPointValues(newPointValues);
                              }}
                            />
                          );
                        })()
                      : "Not Writable"}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableWidget;
