import React, { useState } from "react";
import "../../../../../common/styles/configurationSettingsMailStyle.css";
import ConfigureNotifications from "./configureNotifications.jsx";
import ConfigureSmtp from "./configureSmtp.jsx";

const ConfigurationSettingsMAIL = () => {
  const [selectedTab, setSelectedTab] = useState("smtp");

  return (
    <div className="configurationSettingsMail">
      <div className="configurationSettingsMailHeading">
        <div className="configurationSettingsMailTabs">
          <div
            className={`configurationSettingsMailTab ${
              selectedTab === "smtp" ? "selected" : ""
            }`}
            onClick={() => setSelectedTab("smtp")}
          >
            Configure SMTP
          </div>
          <div
            onClick={() => setSelectedTab("notification")}
            className={`configurationSettingsMailTab ${
              selectedTab === "notification" ? "selected" : ""
            }`}
          >
            Configure Notifications
          </div>
        </div>
      </div>
      <div className="configurationSettingsMailContent">
        {selectedTab === "notification" ? (
          <ConfigureNotifications />
        ) : (
          <ConfigureSmtp />
        )}
      </div>
    </div>
  );
};

export default ConfigurationSettingsMAIL;
