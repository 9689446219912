/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import CustomButton from "../../../reusableComponents/customButton";
import { IoIosCloseCircle } from "react-icons/io";
import CustomFormInput from "../../../reusableComponents/customFormInput";
import LandingImage from "../../../../assets/landing.png";
import Logo from "../../../../common/svg/bacsys-logo-black.svg";
import { toast } from "react-toastify";
import { useMenu } from "../../../../common/utils/menuContext";
import { useNavigate, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { validateInputs } from "../../../../common/utils/validateInputs";
import axios from "axios";
import { extractDetailsFromToken } from "../../../../common/utils/tokenGenerator";

const InviteUser = () => {
  const siteKey = process.env.REACT_APP_SITE_KEY;
  const captchaRef = useRef(null);
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    orgName: "",
  });
  const { config } = useMenu();
  const navigate = useNavigate();
  const [loginPopup, setLoginPopup] = useState(false);
  const dbUrl = config?.REACT_APP_USER_ENDPOINT;

  useEffect(() => {
    const { email, orgId, orgName } = extractDetailsFromToken(id);
    setUserDetails({
      ...userDetails,
      email: email,
      _id: orgId,
      orgName: orgName,
    });
  }, [id]);

  const goToSignIn = () => {
    navigate("/login");
  };
  const getHashedPassword = () => {
    const secretKey = "supersecretpassw";
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const hashedPass = CryptoJS.AES.encrypt(userDetails?.password, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return hashedPass;
  };
  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const token = await captchaRef?.current?.executeAsync();
      if (!token) {
        throw new Error("Captcha verification failed");
      }
      captchaRef.current.reset();
      if (
        !userDetails.firstName ||
        !userDetails?.email ||
        !userDetails?.password ||
        !userDetails?.confirmPassword
      ) {
        throw new Error("Please fill in the required details");
      }
      if (!validateInputs(userDetails.email, userDetails?.password)) {
        throw new Error("Invalid input");
      }
      if (userDetails?.password !== userDetails?.confirmPassword) {
        throw new Error("Passwords do not match");
      }
      const pass = getHashedPassword();
      setUserDetails({ ...userDetails, password: pass });
      const verify_email = await axios.post(`${dbUrl}/verify_email`, {
        email: userDetails?.email,
      });
      if (verify_email?.data?.status === 400) {
        throw new Error(verify_email?.data?.message);
      } else if (verify_email?.data?.status === 409) {
        setLoginPopup(true);
        throw new Error(verify_email?.data?.message);
      } else if (verify_email?.data?.status === 200) {
        joinOrganization(pass);
      }
    } catch (error) {
      toast.warning(error.message || "An unexpected error occurred");
    } finally {
    }
  };

  const joinOrganization = async (pass) => {
    if (!userDetails?.orgName) {
      toast.warning("Organization Name is Required");
      return;
    } else {
      const updatedUser = {
        ...userDetails,
        password: pass,
      };
      const createOrg = await axios.post(
        `${dbUrl}/add_new_user_to_org`,
        updatedUser
      );
      if (createOrg?.data?.status === 400) {
        toast.warning(createOrg?.data?.message);
        return;
      } else if (createOrg?.data?.status === 404) {
        toast.warning(createOrg?.data?.message);
        return;
      } else if (createOrg?.data?.status === 409) {
        toast.warning(createOrg?.data?.message);
        return;
      } else if (createOrg?.data?.status === 200) {
        toast.success(createOrg?.data?.message);
        navigate("/login");
      }
    }
  };
  const handleCaptchaExpired = () => {
    toast.warning("Captcha expired, please try again.");
  };

  const handleCaptchaError = () => {
    toast.error("An error occurred with Captcha. Please try again.");
  };
  return (
    <div className="register">
      <div className="registerForm">
        <div className="registerLoginlogo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="registerContent">
          <div className="registerFormContent">
            <div className="signUpText">Continue Signup</div>
            <form onSubmit={handleSignIn} className="registerContentForm">
              <CustomFormInput
                id="firstName"
                label="First Name"
                type="text"
                value={userDetails?.firstName}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, firstName: e.target.value })
                }
                required
                placeholder="Enter your First Name"
              />
              <CustomFormInput
                id="lastName"
                label="Last Name"
                type="text"
                value={userDetails?.lastName}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, lastName: e.target.value })
                }
                placeholder="Enter your Last Name"
              />
              <CustomFormInput
                id="email"
                label="Email Address"
                type="email"
                value={userDetails?.email}
                disabled
                required
                placeholder="Enter your e-mail address"
              />
              <CustomFormInput
                id="password"
                label="Password"
                type="password"
                value={userDetails?.password}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, password: e.target.value })
                }
                required
                placeholder="Enter your Password"
              />
              <CustomFormInput
                id="confirmPassword"
                label="Confirm Password"
                type="password"
                value={userDetails?.confirmPassword}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    confirmPassword: e.target.value,
                  })
                }
                required
                placeholder="Enter your Password Again"
              />
              <CustomFormInput
                id="orgId"
                label="Org ID"
                type="text"
                value={userDetails?._id}
                disabled
                placeholder="OrgId"
              />
            </form>
            <div className="dontHaveAnAccount">
              Have an Account ?
              <span className="link signUpLink" onClick={goToSignIn}>
                Sign In
              </span>
            </div>
            <div className="loginBtnContainer">
              <CustomButton
                type="submit"
                buttonName="Sign Up"
                className="orgBtnStyle"
                handleClick={handleSignIn}
              />
            </div>
          </div>
          <div className="registerLandingImage">
            <img src={LandingImage} alt="landingImage" />
          </div>
        </div>
      </div>
      {loginPopup === true && (
        <div className="overlay">
          <div className="loginPopupContent">
            <div className="orgForm">
              <IoIosCloseCircle
                className="closeIcon"
                onClick={() => setLoginPopup(false)}
              />
              <div className="orgHeading">
                <span>Would you like to Sign-In ?</span>
              </div>
              <CustomButton
                buttonName={"Go to Sign-in"}
                className={"orgBtnStyle"}
                key={"createOrg"}
                handleClick={() => goToSignIn()}
              />
            </div>
          </div>
        </div>
      )}
      <ReCAPTCHA
        sitekey={siteKey}
        size="invisible"
        ref={captchaRef}
        onExpired={handleCaptchaExpired}
        onError={handleCaptchaError}
      />
    </div>
  );
};

export default InviteUser;
