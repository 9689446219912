import { toast } from "react-toastify";

export const validateInputs = (email, password) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+;~-])[A-Za-z\d!@#$%^&*()_+;~-]{8,}$/;

  if (!emailRegex.test(email)) {
    toast.warning("Invalid email address");
    return false;
  }
  if (!passwordRegex.test(password)) {
    toast.warning(
      "Password must contain 8 characters, at least one lowercase letter, one uppercase letter, one number, and one symbol"
    );
    return false;
  }

  return true;
};
