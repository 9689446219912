/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../../../../../../common/styles/lineChartStyle.css";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useMenu } from "../../../../../../../common/utils/menuContext";
import api from "../../../../../../../common/utils/axiosRequest";

const VerticalBarTemplateChart = ({ chart }) => {
  const { config } = useMenu();
  // const [isLoading, setIsLoading] = useState(true);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [chartData, setChartData] = useState([]);
  const [yAxisDomain, setYAxisDomain] = useState([0, 0]);
  const calculateYAxisDomain = (data) => {
    const values = data.map((item) => parseFloat(item.value));
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    return [(minValue - 0.1)?.toFixed(1), (maxValue + 0.1)?.toFixed(1)];
  };

  const fetchData = async () => {
    const pointId = chart?.points[0]?.value;
    const option = chart?.option;

    const getData = await api.get(`${dbUrl}/trends/${pointId}`);
    const trendData = getData?.data?.data?.trends;
    let consumptionData = [];
    if (chart?.type === "maxd") {
      const currentTime = new Date();

      if (option === "hourly") {
        const oneHourAgo = new Date(currentTime.getTime() - 60 * 60 * 1000);
        const lastHourData = trendData.filter((item) => {
          const itemTimestamp = new Date(item.timestamp);
          return itemTimestamp >= oneHourAgo && itemTimestamp <= currentTime;
        });

        let currentInterval = new Date(oneHourAgo.getTime());

        while (currentInterval <= currentTime) {
          const nextInterval = new Date(
            currentInterval.getTime() + 15 * 60 * 1000
          );
          let intervalData = lastHourData.filter((item) => {
            const itemTimestamp = new Date(item.timestamp);
            return (
              itemTimestamp >= currentInterval && itemTimestamp < nextInterval
            );
          });

          // Calculate average for the 15-minute interval
          if (intervalData.length > 0) {
            const average =
              intervalData.reduce((sum, item) => sum + item.presentValue, 0) /
              intervalData.length;

            const formattedDate = currentInterval.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });

            consumptionData.push({
              name: formattedDate,
              value: average.toFixed(2),
            });
          }

          currentInterval = nextInterval;
        }
      } else if (option === "daily") {
        const todayStart = new Date(currentTime);
        todayStart.setHours(0, 0, 0, 0); // Set to the start of today without modifying currentTime

        const lastDayData = trendData.filter((item) => {
          const itemTimestamp = new Date(item.timestamp);
          return itemTimestamp >= todayStart && itemTimestamp <= currentTime;
        });

        let currentInterval = new Date(todayStart.getTime());

        while (currentInterval <= currentTime) {
          const nextInterval = new Date(
            currentInterval.getTime() + 60 * 60 * 1000
          ); // 1-hour interval
          let intervalData = lastDayData.filter((item) => {
            const itemTimestamp = new Date(item.timestamp);
            return (
              itemTimestamp >= currentInterval && itemTimestamp < nextInterval
            );
          });

          // Calculate average for the 1-hour interval
          if (intervalData.length > 0) {
            const average =
              intervalData.reduce((sum, item) => sum + item.presentValue, 0) /
              intervalData.length;

            const formattedDate = currentInterval.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });

            consumptionData.push({
              name: formattedDate,
              value: average.toFixed(2),
            });
          }

          currentInterval = nextInterval;
        }
      }

      setChartData(consumptionData);
    }

    const yAxisDomain = calculateYAxisDomain(consumptionData);
    setYAxisDomain(yAxisDomain);
  };

  //
  useEffect(() => {
    fetchData();
  }, [chart]);
  return (
    <>
      <div
        id="chartContainer"
        className="lineTemplateChart"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "scroll",
        }}
      >
        {chartData.length > 0 ? (
          <ResponsiveContainer width="100%" height={220}>
            <BarChart
              layout="vertical"
              width={600}
              height={300}
              data={chartData}
              className="barChart"
              margin={{ top: 5, right: 20, bottom: 5, left: 40 }}
            >
              <XAxis
                type="number"
                domain={yAxisDomain}
                stroke="#8884d8"
                tickLine={false}
                axisLine={{ stroke: "var(--font-color)", strokeWidth: 1.5 }}
                tick={{ fontSize: 12, fill: "var(--font-color)" }}
              />
              <YAxis
                type="category"
                dataKey="name"
                tickFormatter={(name) =>
                  name.length > 10 ? `${name.substring(0, 10)}...` : name
                }
                stroke="#8884d8"
                tickLine={false}
                axisLine={{ stroke: "var(--font-color)", strokeWidth: 1.5 }}
                tick={{ fontSize: 12, fill: "var(--font-color)" }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "var(--tooltip-background-color)",
                  border: "var(--container-border)",
                  borderRadius: "15px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                labelStyle={{
                  fontSize: "var(--heading-font-size)",
                  color: "var(--black-color)",
                  fontWeight: "bold",
                }}
                itemStyle={{
                  color: "var(--black-color)",
                  fontWeight: "bold",
                }}
                cursor={{ fill: "transparent" }}
              />
              <Bar
                dataKey="value"
                fill="var(--primary-color)"
                radius={[0, 16, 16, 0]} // Rounded corners for horizontal bars
                style={{
                  filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2))",
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <p className="noData template">No Data Available</p>
        )}
      </div>
    </>
  );
};

export default VerticalBarTemplateChart;
