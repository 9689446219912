import React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const CustomPhoneInput = ({
  type,
  id,
  label,
  placeholder,
  value,
  onChange,
  required,
}) => {
  return (
    <div className="customFormInput">
      <label className="customFormLabel" htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div className="customFormInputField input-style">
        <PhoneInput
          defaultCountry="in"
          value={value}
          id={id}
          required={required}
          placeholder={placeholder}
          onChange={(phone) => onChange(phone)}
          style={{
            color: "var(--font-color)",
          }}
        />
      </div>
    </div>
  );
};

export default CustomPhoneInput;
