import React from "react";
import "../../../../../../common/styles/buttonWidgetStyle.css";
import { Link } from "react-router-dom";

const ButtonWidget = ({ chart, handleLoadDashboard }) => {
  const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, "");

    let r, g, b;
    if (hex.length === 3) {
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
      r = parseInt(hex.substr(0, 2), 16);
      g = parseInt(hex.substr(2, 2), 16);
      b = parseInt(hex.substr(4, 2), 16);
    } else {
      throw new Error("Invalid HEX color");
    }

    return { r, g, b };
  };
  const getTextColor = (backgroundColor) => {
    let r, g, b;
    if (backgroundColor.startsWith("#")) {
      const rgb = hexToRgb(backgroundColor);
      r = rgb.r;
      g = rgb.g;
      b = rgb.b;
    } else {
      const rgb = backgroundColor.match(/\d+/g);
      if (!rgb) return "black";
      r = parseInt(rgb[0]);
      g = parseInt(rgb[1]);
      b = parseInt(rgb[2]);
    }
    const luminance =
      0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);
    return luminance > 0.5 ? "black" : "white"; // Return black for light colors and white for dark colors
  };

  const backgroundColor =
    chart?.data?.buttonColor || "var(--container-background-color)";
  const textColor = getTextColor(backgroundColor);

  return chart?.data?.display ? (
    <Link
      to={`/prebuiltdisplays/${chart?.data?.display?._id}`}
      className="buttonWidget"
      style={{
        backgroundColor: chart?.data?.buttonColor
          ? chart?.data?.buttonColor
          : "var(--container-background-color)",
        color: textColor,
      }}
    >
      <div
        className="buttonWidget"
        onClick={() =>
          handleLoadDashboard(chart?.data?.display)
            ? handleLoadDashboard(chart?.data?.display)
            : null
        }
      >
        {chart?.data?.title ? chart?.data?.title : "Button Name"}
      </div>
    </Link>
  ) : (
    <div
      className="buttonWidget"
      style={{
        backgroundColor: chart?.data?.buttonColor
          ? chart?.data?.buttonColor
          : "var(--container-background-color)",
      }}
    >
      {chart?.data?.title ? chart?.data?.title : "Button Name"}
    </div>
  );
};

export default ButtonWidget;
