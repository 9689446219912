import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  ScaleControl,
  AttributionControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "../../../../../../common/styles/mapView.css";
import customIconUrl from "../../../../../../common/svg/pin-48.svg";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const MapComponent = () => {
  const [markers, setMarkers] = useState([]);
  const { config } = useMenu();
  const [alarmDetails, setAlarmDetails] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const dbUrl = config?.REACT_APP_SERVER_ENDPOINT;

  useEffect(() => {
    const fetchTopicLocations = async () => {
      try {
        const response = await api.get(`${dbUrl}/apexLocation/topic`);
        const topics = response.data.data.availableTopics;

        const topicMarkers = topics.map((topic) => ({
          name: topic.name,
          latitude: topic.location.latitude,
          longitude: topic.location.longitude,
        }));

        setMarkers(topicMarkers);
      } catch (error) {
        console.error("Error fetching topic locations:", error);
      }
    };

    fetchTopicLocations();
  }, [dbUrl]);

  const handleMarkerClick = async (markerName) => {
    setSelectedMarker(markerName);
    try {
      const deviceResponse = await api.post(`${dbUrl}/find_all_data`, {
        tableName: "devices",
        column: "networkTopic",
        valueToSearch: markerName,
      });
      const deviceInstances = deviceResponse.data.data
        .map((device) => device.deviceInstance)
        .join(", ");

      const alarmsResponse = await api.get(
        `${dbUrl}/fetch_alarms_by_deviceId?deviceId=${deviceInstances}`
      );
      setAlarmDetails(alarmsResponse.data.data.rows.map((doc) => doc));
    } catch (error) {
      console.error("Error fetching data:", error.message);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
      setAlarmDetails([]);
    }
  };

  const customIcon = new L.Icon({
    iconUrl: customIconUrl,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  return (
    <div className="mapview">
      <div className="alarmview">
        <h2>
          {selectedMarker
            ? `${selectedMarker} (Alarm Details)`
            : "Click a pin to view Alarm Details"}
        </h2>
        <MapContainer
          center={[23.077632051420586, 77.97359744085459]}
          zoom={2}
          style={{ flex: 1, width: "100%" }}
          attributionControl={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='<a href="https://bacsys.ai">Bacsys</a>'
          />
          <ScaleControl position="bottomright" />
          <AttributionControl position="bottomleft" />
          {markers.map((topic, index) => (
            <Marker
              key={index}
              position={[topic.latitude, topic.longitude]}
              icon={customIcon}
              eventHandlers={{
                click: () => handleMarkerClick(topic.name),
              }}
            >
              <Tooltip direction="top" offset={[0, -32]} opacity={1}>
                {topic.name}
              </Tooltip>
            </Marker>
          ))}
        </MapContainer>
        <div className="commonTableStyle">
          <table>
            <thead>
              <tr>
                <th>Priority</th>
                <th>Date and Time</th>
                <th>Source</th>
                <th>Event Description</th>
              </tr>
            </thead>
            <tbody>
              {alarmDetails.length > 0 ? (
                alarmDetails.map((alarm, index) => (
                  <tr key={index}>
                    <td>{alarm.alarmPriority}</td>
                    <td>{new Date(alarm.alarmStartTime).toLocaleString()}</td>
                    <td>{alarm.source}</td>
                    <td>{alarm.eventDesc}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No alarm details available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
