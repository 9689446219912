/* eslint-disable no-unused-vars */
/* eslint-disable no-eval */
import React from "react";

import TrendComparatorConfigForm from "./trendComparatorConfigForm.jsx";
import TrendComparatorChart from "./trendComparatorChart.jsx";
import "../../../../../common/styles/trendComparatorStyle.css";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useState } from "react";
import PreLoader from "../../../../reusableComponents/preLoader";
import CustomButton from "../../../../reusableComponents/customButton";
import html2canvas from "html2canvas";
import TruncateText from "../../../../../common/utils/truncateText";
import { v4 as uuidv4 } from "uuid";
import CustomHeader from "../../../../reusableComponents/customHeader.jsx";
import { useMenu } from "../../../../../common/utils/menuContext.js";
import api from "../../../../../common/utils/axiosRequest.js";

const TrendComparator = ({
  getTrendComparisions,
  setSelectedOptions,
  setValuesToShow,
  setData1,
  setData2,
  setData3,
  setData4,
  setData5,
  setData6,
  setData7,
  setData8,
  setData9,
  setData10,
  valuesToShow,
  selectedOptions,
  trendName,
  data1,
  data2,
  data3,
  data4,
  data5,
  data6,
  data7,
  data8,
  data9,
  data10,
  setTrendName,
  permissions,
  setTrendId,
  trendId,
}) => {
  const [trendData, setTrendData] = useState([]);
  const { config } = useMenu();
  const [isLoading, setIsLoading] = useState(false);

  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  useEffect(() => {
    const fetchTrendPoints = async () => {
      try {
        const couchdbUrl = dbUrl;
        const trendsDatabase = "trends";
        const networksResponse = await api.get(
          `${couchdbUrl}/${trendsDatabase}/_all_docs`,
          {
            params: {
              include_docs: true,
            },
          }
        );
        setTrendData(networksResponse.data.data.rows.map((row) => row.doc));
      } catch (error) {
        toast.warning("Enable Trend for points to view it here.");
      }
    };
    fetchTrendPoints();
  }, [base64Credentials, dbUrl]);

  const options = trendData.map((item) => ({
    value: item._id,
    label: TruncateText(`${item.deviceInstance}-${item.pointName}`, 30),
  }));

  const handleSelectChange = (selected) => {
    if (selected.length <= 10) {
      console.log(selected);
      setSelectedOptions(selected.map((option) => option));
    }
  };

  const handleValuesToShowChange = (event) => {
    const selectedValue = event.target.value;
    setValuesToShow(selectedValue);
    console.log(selectedValue);
  };

  const generateChart = async () => {
    setIsLoading(true);
    if (selectedOptions.length >= 2) {
      const fetchTrends = async (selectedOptions, setData) => {
        setData1([]);
        setData2([]);
        setData3([]);
        setData4([]);
        setData5([]);
        setData6([]);
        setData7([]);
        setData8([]);
        setData9([]);
        setData10([]);
        const foundItem = trendData.find(
          (item) => item._id === selectedOptions.value
        );
        try {
          const response = await api.get(`${dbUrl}/trends/${foundItem?._id}`);
          const trendData = response.data.data["trends"];
          if (response.status === 200) {
            const currentDate = new Date();
            let startTimestamp, endTimestamp;

            switch (valuesToShow) {
              case "today":
                startTimestamp = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  currentDate.getDate()
                ).getTime();
                endTimestamp = currentDate.getTime();
                break;
              case "yesterday":
                currentDate.setDate(currentDate.getDate() - 1);
                startTimestamp = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  currentDate.getDate()
                ).getTime();
                endTimestamp = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  currentDate.getDate() + 1
                ).getTime();
                break;
              case "thisWeek":
                startTimestamp =
                  currentDate.getTime() - currentDate.getDay() * 86400000;
                endTimestamp = currentDate.getTime();
                break;
              case "lastWeek":
                currentDate.setDate(currentDate.getDate() - 7);
                startTimestamp =
                  currentDate.getTime() - currentDate.getDay() * 86400000;
                endTimestamp = startTimestamp + 7 * 86400000;
                break;
              case "thisMonth":
                let thisMonthFirstDay = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  1
                );
                startTimestamp = thisMonthFirstDay.getTime();
                const nextMonthFirstDay = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth() + 1,
                  1
                );
                endTimestamp = nextMonthFirstDay.getTime();
                break;
              case "lastMonth":
                const lastMonthFirstDay = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth() - 1,
                  1
                );
                startTimestamp = lastMonthFirstDay.getTime();
                thisMonthFirstDay = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  1
                );
                endTimestamp = thisMonthFirstDay.getTime();
                break;
              default:
                break;
            }
            const filteredData = trendData.filter((doc) => {
              const docIdTimestamp = new Date(doc._id).getTime();
              const docTimeStamp = new Date(doc.timeStamp).getTime();

              return (
                (docIdTimestamp >= startTimestamp &&
                  docIdTimestamp <= endTimestamp) ||
                (docTimeStamp >= startTimestamp && docTimeStamp <= endTimestamp)
              );
            });
            setData(filteredData);
          }
        } catch (error) {
          console.error("Unable to Fetch Data : ", error);
        }
      };

      if (selectedOptions.length >= 1 && selectedOptions.length <= 10) {
        const fetchPromises = selectedOptions.map(async (option, index) => {
          const setData = eval(`setData${index + 1}`);
          return fetchTrends(option, setData);
        });

        await Promise.all(fetchPromises);
      }
    } else {
      toast.warning("Select atleast Two Points for Comparison");
    }

    setIsLoading(false);
  };

  const handleComparatorSave = async () => {
    if (trendName) {
      const db = "trendcomparators";
      const data = {
        _id: uuidv4(),
        name: trendName ? trendName : "New Trend Comparison",
        selectedOptions,
        valuesToShow,
        data1,
        data2,
        data3,
        data4,
        data5,
        data6,
        data7,
        data8,
        data9,
        data10,
      };
      try {
        const getExistingTrendComparision = await api.get(
          `${dbUrl}/${db}/${trendId}`
        );
        if (getExistingTrendComparision.data.status === 404) {
          try {
            const createTrend = await api.post(
              `${dbUrl}/trendcomparators`,
              data
            );
            const getTrend = await api.get(
              `${dbUrl}/trendcomparators/${data._id}`
            );
            if (getTrend.data.status === 200) {
              setTrendId(getTrend.data.data._id);
              toast.success(
                `Trend Comparision Saved With the Name: ${trendName}`
              );
            }
          } catch (error) {
            console.error(`Unexpected Error Occurred: ${error}`);
          }
        } else {
          const updatedData = {
            ...getExistingTrendComparision.data.data,
            name: trendName ? trendName : "New Trend Comparison",
            selectedOptions,
            valuesToShow,
            data1,
            data2,
            data3,
            data4,
            data5,
            data6,
            data7,
            data8,
            data9,
            data10,
          };
          await api.put(`${dbUrl}/${db}/${trendId}`, updatedData);
          toast.success(
            `Trend Comparision Updated With the Name: ${trendName}`
          );
        }
      } catch (error) {
        try {
          const createTrend = await api.post(`${dbUrl}/trendcomparators`, data);
          const getTrend = await api.get(
            `${dbUrl}/trendcomparators/${data._id}`
          );
          if (getTrend.data.status === 200) {
            setTrendId(getTrend.data.data._id);
            toast.success(
              `Trend Comparision Saved With the Name: ${trendName}`
            );
          }
        } catch (error) {
          console.error(`Unexpected Error Occurred: ${error}`);
        }
      }
    } else {
      toast.warning("Please Enter the Comparision Name to Save");
    }
    getTrendComparisions();
  };

  const labels1 = data1?.map((trend) => trend.timestamp) || [];
  const data = {
    labels: labels1,
    datasets: [
      {
        label: selectedOptions ? selectedOptions[0]?.label : "No Data",
        data: data1?.map((trend) => trend.presentValue?.toFixed(2)),
        fill: false,
        borderColor: "rgb(255, 0, 0)",
        tension: 0.1,
      },
      {
        label: selectedOptions ? selectedOptions[1]?.label : "No Data",
        data: data2?.map((trend) => trend.presentValue?.toFixed(2)),
        fill: false,
        borderColor: "rgb(0, 255, 0)",
        tension: 0.1,
      },
      {
        label: selectedOptions ? selectedOptions[2]?.label : "No Data",
        data: data3?.map((trend) => trend.presentValue?.toFixed(2)),
        fill: false,
        borderColor: "rgb(0, 0, 255)",
        tension: 0.1,
      },
      {
        label: selectedOptions ? selectedOptions[3]?.label : "No Data",
        data: data4?.map((trend) => trend.presentValue?.toFixed(2)),
        fill: false,
        borderColor: "rgb(255, 255, 0)",
        tension: 0.1,
      },
      {
        label: selectedOptions ? selectedOptions[4]?.label : "No Data",
        data: data5?.map((trend) => trend.presentValue?.toFixed(2)),
        fill: false,
        borderColor: "rgb(255, 0, 255)",
        tension: 0.1,
      },
      {
        label: selectedOptions ? selectedOptions[5]?.label : "No Data",
        data: data6?.map((trend) => trend.presentValue?.toFixed(2)),
        fill: false,
        borderColor: "rgb(0, 255, 255)",
        tension: 0.1,
      },
      {
        label: selectedOptions ? selectedOptions[6]?.label : "No Data",
        data: data7?.map((trend) => trend.presentValue?.toFixed(2)),
        fill: false,
        borderColor: "rgb(255, 204, 153)",
        tension: 0.1,
      },
      {
        label: selectedOptions ? selectedOptions[7]?.label : "No Data",
        data: data8?.map((trend) => trend.presentValue?.toFixed(2)),
        fill: false,
        borderColor: "rgb(128, 128, 0)",
        tension: 0.1,
      },
      {
        label: selectedOptions ? selectedOptions[8]?.label : "No Data",
        data: data9?.map((trend) => trend.presentValue?.toFixed(2)),
        fill: false,
        borderColor: "rgb(0, 128, 0)",
        tension: 0.1,
      },
      {
        label: selectedOptions ? selectedOptions[9]?.label : "No Data",
        data: data10?.map((trend) => trend.presentValue?.toFixed(2)),
        fill: false,
        borderColor: "rgb(255, 153, 204)",
        tension: 0.1,
      },
    ],
  };
  const handleExportImage = () => {
    try {
      const gridLayoutElement = document.querySelector(
        ".trendComparatorCharts"
      );
      html2canvas(gridLayoutElement).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = image;
        link.download = `${trendName}.png`;
        link.click();
      });
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  return (
    <div className={`trendComparator`}>
      {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null}
      <CustomHeader
        heading={"Trend Comparator"}
        showSearch={false}
        showIcons={false}
      />
      <div className="trendComparatorContent container-style">
        <div className="trendComparatorContentContainer">
          <div className="trendComparatorConfigurations">
            <TrendComparatorConfigForm
              options={options}
              handleSelectChange={handleSelectChange}
              selectedOptions={selectedOptions}
              generateChart={generateChart}
              valuesToShow={valuesToShow}
              trendName={trendName}
              setTrendName={setTrendName}
              handleComparatorSave={handleComparatorSave}
              handleValuesToShowChange={handleValuesToShowChange}
              permissions={permissions}
              handleExportImage={handleExportImage}
            />
          </div>

          <div className="trendComparatorCharts">
            <TrendComparatorChart data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendComparator;
