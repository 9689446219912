/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useMenu } from "../../../../../common/utils/menuContext";
import "../../../../../common/styles/pointDetailsTagsStyle.css";
import { IoCloseCircle } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import api from "../../../../../common/utils/axiosRequest";

const PointDetailsTags = ({ pointProps, permissions }) => {
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [allTags, setAllTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [tagWindow, setTagWindow] = useState(false);
  const inputRef = useRef(null);

  const getAddedTags = async () => {
    try {
      const addedTags = await api.get(`${dbUrl}/points/${pointProps._id}`);
      if (addedTags.status === 200) {
        setSelectedTags(addedTags.data.data.tags);
        pointProps.tags = addedTags.data.data.tags;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllTags = async () => {
    try {
      const response = await api.get(`${dbUrl}/tags/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      if (response.status === 200) {
        const fetchedTags = response.data.data.rows.map((tag) => tag.doc._id);
        setAllTags(fetchedTags);
        setTags(fetchedTags);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTags();
    getAddedTags();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setTagWindow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  const normalizeString = (str) => str?.toLowerCase().replace(/[^a-z0-9]/g, "");

  const filterTagsBasedOnSearch = (searchValue) => {
    setSearchValue(searchValue);
    const normalizedSearchValue = normalizeString(searchValue);
    if (normalizedSearchValue === "") {
      setTags(allTags);
    } else {
      setTags(
        allTags.filter((tag) =>
          normalizeString(tag)?.includes(normalizedSearchValue)
        )
      );
    }
  };

  const addTags = async (selectedTag) => {
    setTagWindow(false);
    const normalizedSelectedTag = normalizeString(selectedTag);

    if (
      pointProps?.tags?.some(
        (tag) => normalizeString(tag) === normalizedSelectedTag
      )
    ) {
      toast.info(
        `Tag ${selectedTag} is already added for this point. Please add a new Tag.`
      );
      return;
    }

    if (
      !allTags.some((tag) => normalizeString(tag) === normalizedSelectedTag)
    ) {
      try {
        const data = { _id: selectedTag };
        const addTag = await api.post(`${dbUrl}/tags`, data);

        if (addTag.status === 200) {
          const updatePointTags = await api.put(
            `${dbUrl}/points/${pointProps._id}`,
            {
              tags: [
                ...(pointProps?.tags ? pointProps?.tags : []),
                selectedTag,
              ],
            }
          );

          if (updatePointTags.status === 200) {
            setSearchValue("");
            getAddedTags();
            getAllTags();
            toast.success(`Tag ${selectedTag} Created and Added Successfully`);
          }
        }
      } catch (error) {
        if (error.response.status === 409) {
          toast.error(`Tag ${selectedTag} already exists`);
        } else {
          toast.error("Failed to create tag, please try again");
        }
      }
    } else {
      try {
        const updatePointTags = await api.put(
          `${dbUrl}/points/${pointProps._id}`,
          { tags: [...(pointProps?.tags ? pointProps?.tags : []), selectedTag] }
        );

        if (updatePointTags.status === 200) {
          setSearchValue("");
          getAddedTags();
          toast.success(`Tag ${selectedTag} Added Successfully`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const removeTags = async (selectedTag) => {
    try {
      const updatePointTags = await api.put(
        `${dbUrl}/points/${pointProps._id}`,
        { tags: pointProps?.tags?.filter((tag) => tag !== selectedTag) }
      );

      if (updatePointTags.status === 200) {
        getAddedTags();
        toast.success(`Tag ${selectedTag} Removed Successfully`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="pointDetailsTags">
      <div className="pointDetailsTagsContent">
        <div className="tagsInputGroup">
          <div className="custom-input-label">Search & Add Tags</div>
          <div className="input-style">
            <input
              ref={inputRef}
              id="tags"
              name="tags"
              type="text"
              value={searchValue}
              disabled={permissions?.devices?.write ? false : true}
              onChange={(e) => filterTagsBasedOnSearch(e.target.value)}
              placeholder="Search Tags"
              onClick={() => setTagWindow(true)}
            />
          </div>
        </div>
        {tagWindow && (
          <div className="taglist" ref={inputRef}>
            {tags?.length > 0 &&
              tags.map((tag) => (
                <span
                  key={tag}
                  onClick={() => {
                    addTags(tag);
                  }}
                >
                  {tag}
                </span>
              ))}
            {tags?.length === 0 && (
              <span
                onClick={() => {
                  addTags(searchValue);
                }}
              >
                {searchValue} (Add New Tag)
              </span>
            )}
          </div>
        )}
        <div className="tagsInputGroup">
          <div className="custom-input-label">Added Tags</div>
          <div className="selectedTags">
            {selectedTags?.length > 0 &&
              selectedTags.map((tag) => (
                <div key={tag} className="selectedTag">
                  <span>{tag}</span>
                  {permissions?.devices?.write && (
                    <IoCloseCircle
                      onClick={() => {
                        removeTags(tag);
                      }}
                      className="closeIcon"
                      data-tooltip-id={"tagTooltip"}
                      data-tooltip-content={"Remove Tag"}
                    />
                  )}

                  <Tooltip
                    place="right"
                    className="tooltipStyle"
                    id="tagTooltip"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointDetailsTags;
