/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import "../../../../../../common/styles/numericChartStyles.css";
import PreLoader from "../../../../../reusableComponents/preLoader";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const SwitchChart = ({ chart }) => {
  // const [isLoading, setIsLoading] = useState(true);
  const [presentValue, setPresentValue] = useState();
  const [pointInfo, setPointInfo] = useState({});
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const AntSwitch = styled(Switch)(({ theme, checked }) => ({
    width: 65,
    height: 35,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 28,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(30px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(30px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#4CAF50",
          border: "1px solid #FFFFFF",
        },
      },
      "&:not(.Mui-checked)": {
        transform: "translateX(0px)",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#FF5252",
          border: "1px solid #FFFFFF",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 30,
      height: 30,
      borderRadius: 15,
      backgroundColor: "#FFFFFF",
      transition: theme.transitions.create(["transform", "background-color"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 25,
      opacity: 1,
      boxSizing: "border-box",
      position: "relative",
      backgroundColor: checked ? "#4CAF50" : "#FF5252",
      transition: theme.transitions.create(["background-color"], {
        duration: 200,
      }),
      "&::before": {
        content: '"ON"',
        color: "#fff",
        fontSize: 14,
        fontWeight: "bold",
        position: "absolute",
        left: 5,
        top: "50%",
        transform: "translateY(-50%)",
      },
      "&::after": {
        content: '"O"',
        color: "#fff",
        fontSize: 14,
        fontWeight: "bold",
        position: "absolute",
        right: "12px",
        top: "50%",
        transform: "translateY(-50%)", // Center vertically
      },
    },
  }));

  const fetchLiveDeviceData = async () => {
    // setIsLoading(false);
    try {
      const database = chart.data.points[0].value;
      const getResponse = await api.get(`${dbUrl}/points/${database}`);
      const liveVal = getResponse.data.data.pointPresentValue;
      setPresentValue(liveVal?.toFixed(2));
      setPointInfo(getResponse.data.data);
    } catch (error) {
      console.error("Error fetching last uploaded record:", error.message);
    }
  };

  useEffect(() => {
    const intervalInSeconds = chart?.data?.pollFrequency
      ? chart?.data?.pollFrequency
      : 5;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchLiveDeviceData();
    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart?.data]);

  const isBinaryOrDigital = [
    "binaryValue",
    "digitalValue",
    "digitalOutput",
    "binaryOutput",
    "binaryInput",
    "digitalInput",
    "CoilStatus",
    "InputStatus",
  ].some((type) => pointInfo.pointType?.includes(type));

  const isFalse = presentValue == 0 || presentValue == 0.0;
  const isTrue = presentValue == 1 || presentValue == 1.0;

  const checkedFunc = () => {
    if (isBinaryOrDigital && isFalse) return false;
    if (isBinaryOrDigital && isTrue) return true;
    return false;
  };

  return (
    <div className="switchWidget">
      {/* {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null} */}
      <span className="switchWidgetTitle">
        {chart?.data?.title
          ? chart?.data?.title
          : pointInfo.pointName
          ? `${pointInfo.pointDeviceInstance}-${pointInfo.pointName}`
          : "Analog/Binary Point"}
      </span>
      <AntSwitch
        className="deviceBrowserBoundSwitch"
        checked={checkedFunc()}
        inputProps={{ "aria-label": "ant design" }}
      />
    </div>
  );
};

export default SwitchChart;
