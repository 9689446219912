import React from "react";
import "../../../../../common/styles/logicBlockStyle.css";

import { useMenu } from "../../../../../common/utils/menuContext";

const LogicBlock = ({ onDragOperatorStart, onDrag4OperatorStart }) => {
  const { logicBlockOpen } = useMenu();

  return logicBlockOpen ? (
    <div className={`logicBlock `}>
      <div className={`logicBlockHeader `}>Logic Blocks</div>
      <div className="logicBlockContent">
        <div className="logicBlocks">
          <div
            className="logicBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "AND2")}
          >
            AND2
          </div>
          <div
            className="logicBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "OR2")}
          >
            OR2
          </div>
          <div
            className="logicBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "XOR")}
          >
            XOR
          </div>
          <div
            className="logicBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "NOT")}
          >
            NOT
          </div>
          <div
            className="logicBlockOperator"
            draggable
            onDragStart={(e) => onDrag4OperatorStart(e, "AND4")}
          >
            AND4
          </div>
          <div
            className="logicBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "ADEMUX2")}
          >
            ADEMUX2
          </div>
          <div
            className="logicBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "ASW")}
          >
            ASW
          </div>
          <div
            className="logicBlockOperator"
            draggable
            onDragStart={(e) => onDrag4OperatorStart(e, "ASW4")}
          >
            ASW4
          </div>
          <div
            className="logicBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "B2P")}
          >
            B2P
          </div>
          <div
            className="logicBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "BSW")}
          >
            BSW
          </div>
          <div
            className="logicBlockOperator"
            draggable
            onDragStart={(e) => onDragOperatorStart(e, "DEMUXI2B4")}
          >
            DEMUXI2B4
          </div>
          <div
            className="logicBlockOperator"
            draggable
            onDragStart={(e) => onDrag4OperatorStart(e, "OR4")}
          >
            OR4
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default LogicBlock;
