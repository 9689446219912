import React, { useState } from "react";
import "../../common/styles/customTableStyle.css";
import { Tooltip } from "react-tooltip";
import TruncateText from "../../common/utils/truncateText";
import { FaBell } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";

const CustomTable = ({
  headings,
  data,
  totalRows,
  totalPlaceholder,
  dateTimeHeading = "timestamp",
  checkBoxEnable = false,
  action = false,
  onRowSelect = () => {},
  deleteFunc = () => {},
  editFunc = () => {},
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedRows, setSelectedRows] = useState([]);

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const sortedData = [...data].sort((a, b) => {
    if (sortColumn) {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];
      if (sortColumn === "dateTime") {
        return sortOrder === "asc"
          ? new Date(valueA) - new Date(valueB)
          : new Date(valueB) - new Date(valueA);
      }
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }
    return 0;
  });

  const currentData = sortedData.slice(indexOfFirstRow, indexOfLastRow);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    setSelectedRows(
      selectedRows.length ===
        currentData.filter((row) => row.ackStatus !== "Acknowledged").length
        ? []
        : currentData
            .filter((row) => row.ackStatus !== "Acknowledged")
            .map((row) => row._id)
    );
  };

  React.useEffect(() => {
    onRowSelect(selectedRows);
  }, [selectedRows, onRowSelect]);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPagination = () => {
    const pagination = [];
    const ellipsis = <span key="ellipsis">...</span>;

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pagination.push(
          <button
            key={i}
            onClick={() => paginate(i)}
            className={currentPage === i ? "activePage" : "pageNumber"}
          >
            {i}
          </button>
        );
      }
    } else {
      pagination.push(
        <button
          key={1}
          onClick={() => paginate(1)}
          className={currentPage === 1 ? "activePage" : "pageNumber"}
        >
          1
        </button>
      );

      if (currentPage > 4) pagination.push(ellipsis);

      for (
        let i = Math.max(2, currentPage - 1);
        i <= Math.min(totalPages - 1, currentPage + 1);
        i++
      ) {
        pagination.push(
          <button
            key={i}
            onClick={() => paginate(i)}
            className={currentPage === i ? "activePage" : "pageNumber"}
          >
            {i}
          </button>
        );
      }

      if (currentPage < totalPages - 3) pagination.push(ellipsis);

      pagination.push(
        <button
          key={totalPages}
          onClick={() => paginate(totalPages)}
          className={currentPage === totalPages ? "activePage" : "pageNumber"}
        >
          {totalPages}
        </button>
      );
    }

    return pagination;
  };

  return (
    <div className="customTableContent">
      <div className="customTable">
        <table>
          <thead>
            <tr key={"tableHeadings"}>
              {checkBoxEnable && (
                <th>
                  <input
                    type="checkbox"
                    checked={selectedRows.length}
                    onChange={handleSelectAll}
                  />
                </th>
              )}
              {headings.map((heading) => (
                <th key={heading.id} onClick={() => handleSort(heading.id)}>
                  {heading.label}
                  {heading.id === dateTimeHeading && (
                    <span
                      className="customTableHeader"
                      data-tooltip-id="customTableTooltip"
                      data-tooltip-content={
                        sortOrder === "asc" ? "Latest" : "Oldest"
                      }
                    >
                      {sortOrder === "asc" && sortColumn === heading.id
                        ? "↑"
                        : "↓"}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.map((row) => (
              <tr key={row._id}>
                {checkBoxEnable && row.ackStatus !== "Acknowledged" ? (
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(row._id)}
                      onChange={() => handleSelectRow(row._id)}
                    />
                  </td>
                ) : (
                  checkBoxEnable && <td></td>
                )}
                {headings.map((heading) => {
                  const cellText = row[heading.id];
                  const truncatedText = TruncateText(cellText, 25);
                  return (
                    <td
                      key={heading.id}
                      data-tooltip-id="customTableTooltip"
                      data-tooltip-content={
                        cellText?.length > 25 ? cellText : ""
                      }
                      style={{
                        color:
                          heading.id === "ackStatus" &&
                          cellText === "Acknowledged"
                            ? "var(--light-green-color)"
                            : "inherit",
                      }}
                    >
                      {heading.id !== "action" && truncatedText}
                      {heading.id === "sourceState" && cellText === "Alarm" && (
                        <FaBell
                          style={{
                            color: "red",
                            marginLeft: "5px",
                            alignItems: "center",
                          }}
                        />
                      )}
                      {action && heading.id === "action" && (
                        <div className="usersTableBtns">
                          <MdEdit
                            className="usersTableBtn"
                            onClick={() => editFunc(cellText)}
                          />

                          <MdDelete
                            className="usersTableBtn"
                            onClick={() => deleteFunc(cellText)}
                            style={{ marginLeft: "5px" }}
                          />
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="customTableFooter">
        <div className="rowCount">
          {totalRows} {totalPlaceholder}
        </div>
        <div className="pagination">
          <div
            className="filled-btn-style paginationBtn"
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &laquo; Previous
          </div>
          {renderPagination()}
          <div
            className="filled-btn-style paginationBtn"
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next &raquo;
          </div>
        </div>
        <div className="rowsPerPage">
          <label htmlFor="rowsPerPage">Rows per page:</label>
          <select
            id="rowsPerPage"
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            {[50, 100, 150, 200].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Tooltip
        place="bottom"
        className="tooltipStyle"
        id="customTableTooltip"
        noArrow={true}
        style={{ zIndex: 3000 }}
      />
    </div>
  );
};

export default CustomTable;
