import React from "react";
import "../../../../../common/styles/widgetsStyle.css";

import { useMenu } from "../../../../../common/utils/menuContext";
import { BsBarChartLineFill, BsFillImageFill, BsTable } from "react-icons/bs";
import { FaChartPie, FaGaugeHigh } from "react-icons/fa6";
import { LuLineChart } from "react-icons/lu";
import { PiGauge } from "react-icons/pi";
import { RxButton } from "react-icons/rx";
import { Tb123, TbAbc } from "react-icons/tb";
import { HiCommandLine } from "react-icons/hi2";

const Widgets = ({ handleDragStartWidgetContainer }) => {
  const { widgetOpen, isMobile } = useMenu();
  const Dashboardwidgets = [
    {
      id: "bar",
      icon: BsBarChartLineFill,
      label: "Bar",
    },
    { id: "kpi", icon: FaGaugeHigh, label: "KPI" },
    { id: "line", icon: LuLineChart, label: "Line" },
    { id: "gauge", icon: PiGauge, label: "Gauge" },
    { id: "pie", icon: FaChartPie, label: "Pie" },
    { id: "table", icon: BsTable, label: "Table" },
    {
      id: "button",
      icon: RxButton,
      label: "Button",
    },
    {
      id: "numeric",
      icon: Tb123,
      label: "Number",
    },
    {
      id: "command",
      icon: HiCommandLine,
      label: "Command",
    },
    { id: "label", icon: TbAbc, label: "Label" },
    {
      id: "image",
      icon: BsFillImageFill,
      label: "Image",
    },
    {
      id: "map",
      icon: BsFillImageFill,
      label: "Map",
    },
  ];
  return widgetOpen ? (
    <div className={`widgets container-style`}>
      <div className={`widgetsHeader `}>Display Widgets</div>
      <div className="widgetsContent">
        {Dashboardwidgets?.map((widget, index) => (
          <div
            className={`widget `}
            key={index}
            draggable
            onDragStart={(e) => handleDragStartWidgetContainer(e, widget)}
          >
            {React.createElement(widget.icon, {
              className: `widgetIcon`,
              size: isMobile ? 30 : 30,
              color: "var(--font-color)",
            })}
            <p>{widget.label}</p>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default Widgets;
