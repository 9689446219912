/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "../../../../../common/styles/configurationSettingsNetworkStyle.css";
import { useState } from "react";
import { toast } from "react-toastify";
import CustomButton from "../../../../reusableComponents/customButton.jsx";
import CustomFormInput from "../../../../reusableComponents/customFormInput.jsx";
import { useMenu } from "../../../../../common/utils/menuContext.js";
import { FaCopy } from "react-icons/fa6";
import { IoReload } from "react-icons/io5";
import api from "../../../../../common/utils/axiosRequest.js";
import getUser from "../../../../../configuration/localStorage/getUser.js";
import { v4 as uuid } from "uuid";

const MQTTCertificate = () => {
  const { config } = useMenu();
  const serverEndpoint = config?.REACT_APP_POLLING_ENDPOINT;
  const user = getUser();
  const [issuerDetails, setIssuerDetails] = useState({
    apiKey: uuid(),
    issuerKey: "",
    orgId: "",
  });
  const [apexDevices, setApexDevices] = useState([]);

  const getApexDevices = async () => {
    const getApexDevices = await api.get(
      `${serverEndpoint}/apexDevices/_all_docs?include_docs=true`
    );
    setApexDevices(
      getApexDevices?.data?.data?.rows?.map((device) => device.doc)
    );
  };
  useEffect(() => {
    getApexDevices();
    setIssuerDetails({ ...issuerDetails, orgId: user.orgId });
  }, [serverEndpoint]);

  const generateCerts = async () => {
    if (issuerDetails.apiKey === "") {
      toast.warning("API Key is required to generate Certificates");
    } else {
      try {
        const get_key = await api.post(`${serverEndpoint}/generate_mqtt_key`, {
          hostID: issuerDetails.apiKey,
        });

        if (get_key.status === 200) {
          setIssuerDetails({ ...issuerDetails, issuerKey: get_key.data.data });

          const response = await api.post(
            `${serverEndpoint}/generate_certs`,
            { hostID: issuerDetails.apiKey },
            { responseType: "blob" }
          );

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${issuerDetails.apiKey}.zip`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          toast.success("Certificates generated and downloaded successfully");
        }
      } catch (error) {
        toast.error("Failed to generate certificates");
        console.error(error);
      }
    }
  };
  const handleCopy = (text) => {
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success("Copied to clipboard");
        })
        .catch((error) => {
          toast.error("Failed to Copy");
          console.error(error);
        });
    } else {
      toast.warning("Nothing to Copy");
    }
  };
  // const handleSecretKeyCopy = (secretKey) => {
  //   if (secretKey) {
  //     navigator?.clipboard
  //       ?.writeText(secretKey)
  //       ?.then(() => {
  //         toast.success("Issuer Secret Key copied to clipboard");
  //       })
  //       ?.catch((error) => {
  //         toast.error("Failed to copy Issuer Secret Key");
  //         console.error(error);
  //       });
  //   } else {
  //     toast.warning("No Issuer Secret Key to copy");
  //   }
  // };

  return (
    <div className="mqttCertificate">
      <div className="mqttCertificateForm">
        <div className="mqttCertificateFormContent">
          <div className="secretKeyInputGroup">
            <label htmlFor="issuerHostID">API Key</label>
            <div className="copyInputField">
              <input
                type="text"
                id="issuerHostID"
                name="issuerHostID"
                disabled
                value={issuerDetails.apiKey}
                onChange={(e) =>
                  setIssuerDetails((prevDetails) => ({
                    ...prevDetails,
                    apiKey: e.target.value,
                  }))
                }
              />
              <FaCopy
                onClick={() => handleCopy(issuerDetails.apiKey)}
                size={20}
                style={{
                  marginTop: "auto",
                  height: "100%",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <div className="secretKeyInputGroup">
            <label htmlFor="orgId">Issuer Organization ID</label>
            <div className="copyInputField">
              <input type="text" value={issuerDetails.orgId} disabled={true} />
              <FaCopy
                onClick={() => handleCopy(issuerDetails.orgId)}
                size={20}
                style={{
                  marginTop: "auto",
                  height: "100%",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <div className="secretKeyInputGroup">
            <label htmlFor="secretKey">Issuer Secret Key</label>
            <div className="copyInputField">
              <input
                type="text"
                value={issuerDetails.issuerKey}
                disabled={true}
              />

              <FaCopy
                onClick={() => handleCopy(issuerDetails?.issuerKey)}
                size={20}
                style={{
                  marginTop: "auto",
                  height: "100%",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <div className="mqttCertificateBtns">
            <CustomButton
              buttonName={"Generate Certificates"}
              handleClick={generateCerts}
              className={"filled-btn-style"}
            />
          </div>
        </div>
        <div className="certificateApexDevicesContainer">
          <div className="apexDevicesHeader">
            <span>Connected APEX Edge Devices</span>{" "}
            <IoReload
              size={20}
              style={{ cursor: "pointer" }}
              onClick={getApexDevices}
            />
          </div>
          <div className="apexDeviceContainer">
            {apexDevices?.map((device) => (
              <div className="apexDeviceItem">
                <span>{device?.hostname}</span>
                <CustomFormInput
                  key={device?.secretKey}
                  id={"apexDeviceSecretKey"}
                  type={"password"}
                  disabled
                  value={device?.secret_key}
                />
                <FaCopy
                  key={device?.secretKey}
                  onClick={(e) => handleCopy(device?.secret_key)}
                  size={15}
                  style={{
                    marginTop: "auto",
                    height: "100%",
                    cursor: "pointer",
                  }}
                />
                {/* <span className="apexDeviceSecretKey">
                    {device.secret_key}
                  </span> */}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mqttCertificateGuide">
        <div className="certificateInfoContainer">
          <div className="certificateInfoHeading">
            Steps to Connect an APEX Edge to ARCOS
          </div>
          <div className="certificateInfoContent">
            <ol className="certificateInfoContentList">
              <li>Click on Generate to generate your Secret Key and Certs.</li>
              <li>
                Extract the Downloaded Zip file and keep it ready to import in
                APEX Edge.
              </li>
              <li>
                Copy the API Key, OrgID, and the secret key to a safe location,
                then Go to APEX Edge.
              </li>
              <li>Open Devices Tab on APEX Edge, then switch to NB.</li>
              <li>
                Click on New Device, then Select MQTT From the Export Protocol.
              </li>
              <li>
                Paste the copied API Key in the API Key Field and the Secret Key
                in the Secret Key Field and OrgID to it's respective field.
              </li>
              <li>
                Click on CA Certificate and locate the Downloaded and Extracted
                Zip File, then select the ca.crt file and Click on Open, do the
                same for Client Certificate and Client Key.
              </li>
              <li>
                Finally Click on Create, your Connection will be established
                with ARCOS, and your APEX Edge will be Listed in this Screen
                once it is Connected Successfully.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MQTTCertificate;
