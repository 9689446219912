/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const RadialbarChart = ({ chart }) => {
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [chartData, setChartData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const colorPalette = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#FF8C00",
    "#8A2BE2",
    "#00CED1",
    "#FFD700",
    "#ADFF2F",
    "#FF4500",
  ];
  const fetchLiveDeviceData = async () => {
    try {
      const couchdbUrl = dbUrl;
      const fetchedData = await Promise.all(
        chart.data.points.map(async (point) => {
          const response = await api.get(`${couchdbUrl}/points/${point.value}`);
          return response.data.data;
        })
      );

      const data = fetchedData.map((value, index) => ({
        name: `${value?.pointDeviceInstance}-${value?.pointName}`,
        size: value?.pointPresentValue,
        fill: colorPalette[index % colorPalette.length],
      }));

      setChartData(data); // Ensure this is set correctly
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // setLoading(false);
    }
  };

  useEffect(() => {
    const intervalInSeconds = chart?.data?.pollFrequency;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchLiveDeviceData();
    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    }
  }, [chart]);

  const style = {
    top: "50%",
    left: 0,
    transform: "translate(0, -50%)",
    lineHeight: "24px",
  };

  return (
    <div
      id="chartContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
        height: "92%",
        overflow: "scroll",
        margin: "auto",
      }}
    >
      <span className="chartLabel">
        {chart?.data?.title
          ? chart?.data?.title
          : chart?.data?.pointType === "trendVal"
          ? chart?.data?.points[0]?.label
          : "Label"}
      </span>
      {chartData.length > 0 ? (
        <ResponsiveContainer width="92%" height="100%">
          <RadialBarChart
            cx="60%"
            cy="50%"
            innerRadius="10%"
            outerRadius="80%"
            barSize={10}
            data={chartData}
            startAngle={-90}
            endAngle={270}
          >
            <RadialBar minAngle={5} clockWise dataKey="size" />
            <Legend
              layout="radial"
              verticalAlign="middle"
              wrapperStyle={style}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "var(--tooltip-background-color)",
                border: "var(--container-border)",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              labelStyle={{
                fontSize: "var(--heading-font-size)",
                color: "var(--black-color)",
                fontWeight: "bold",
              }}
              itemStyle={{
                color: "var(--black-color)",
                fontWeight: "bold",
              }}
              formatter={(value, name, props) => [
                `${props.payload.name}: ${value}`,
              ]}
              cursor={{ fill: "transparent" }}
            />
          </RadialBarChart>
        </ResponsiveContainer>
      ) : (
        <p className="noData">No Data Available</p>
      )}
    </div>
  );
};

export default RadialbarChart;
