import { AiOutlineLineChart } from "react-icons/ai";
import React from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../../common/styles/sidebarStyle.css";
import { useMenu } from "../../../common/utils/menuContext";
import getPermissions from "../../../configuration/localStorage/getPermissions";
import { TiHome } from "react-icons/ti";
import { TbLayoutDashboard } from "react-icons/tb";
import { MdOutlineOnDeviceTraining } from "react-icons/md";
import { MdAccessAlarm } from "react-icons/md";
import { LuCalendarClock } from "react-icons/lu";

const SideNav = () => {
  const location = useLocation();
  const { setOpen, setPreBuiltDashboardEnable } = useMenu();
  const [permissions] = useState(getPermissions);

  const isActiveLink = (path) => {
    return location.pathname.startsWith(path) && "selected-nav";
  };

  return (
    <div className={`sidenav container-style`}>
      <div className={`sidenavMenu`}>
        <div className="menuItems">
          <div className={`sidenavMenuItem`}>
            <Link to="/home" className={`navLink `}>
              <TiHome
                className={`sidenavMenuIcon ${isActiveLink("/home")}`}
                size={20}
              />
              <span className="sidenavMenuText">Home</span>
            </Link>
          </div>
          {permissions.displays.read && (
            <div
              className={`sidenavMenuItem `}
              onClick={() => {
                setPreBuiltDashboardEnable(false);
                setOpen(true);
              }}
            >
              <Link to="/displays" className={`navLink  `}>
                <TbLayoutDashboard
                  className={`sidenavMenuIcon ${isActiveLink(
                    "/prebuiltdisplays"
                  )} || ${isActiveLink("/displays" || "/prebuiltdisplays")}`}
                  size={20}
                />
                <span className="sidenavMenuText">Display</span>
              </Link>
            </div>
          )}
          {permissions.devices.read && (
            <div className={`sidenavMenuItem `} onClick={() => setOpen(true)}>
              <Link to="/devices" className={`navLink `}>
                <MdOutlineOnDeviceTraining
                  className={`sidenavMenuIcon ${isActiveLink("/devices")}`}
                  size={20}
                />
                <span className="sidenavMenuText">Devices</span>
              </Link>
            </div>
          )}
          {permissions.alarms.read && (
            <div className={`sidenavMenuItem `}>
              <Link to="/alarms" className={`navLink `}>
                <MdAccessAlarm
                  className={`sidenavMenuIcon ${isActiveLink("/alarms")}`}
                  size={20}
                />
                <span className="sidenavMenuText">Alarms</span>
              </Link>
            </div>
          )}
          {permissions?.trends?.read && (
            <div className={`sidenavMenuItem `} onClick={() => setOpen(true)}>
              <Link to="/trends" className={`navLink `}>
                <AiOutlineLineChart
                  className={`sidenavMenuIcon ${isActiveLink("/trends")}`}
                  size={20}
                />
                <span className="sidenavMenuText">Trends</span>
              </Link>
            </div>
          )}
          {permissions.schedules.read && (
            <div className={`sidenavMenuItem `} onClick={() => setOpen(true)}>
              <Link to="/schedule" className={`navLink `}>
                <LuCalendarClock
                  className={`sidenavMenuIcon ${isActiveLink("/schedule")}`}
                  size={20}
                />
                <span className="sidenavMenuText">Schedule</span>
              </Link>
            </div>
          )}
          {/* {permissions.trends.read && (
            <div className={`sidenavMenuItem `} onClick={() => setOpen(true)}>
              <Link to="/reports" className={`navLink `}>
                <TbReport
                  className={`sidenavMenuIcon ${isActiveLink("/reports")}`}
                  size={20}
                />
                <span className="sidenavMenuText">Reports</span>
              </Link>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default SideNav;
