/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../../../../common/styles/displayNavigatorStyle.css";
import { useMenu } from "../../../../../common/utils/menuContext";
import { MdDelete } from "react-icons/md";
import CustomConfirmDelete from "../../../../reusableComponents/customConfirmDelete";
import TruncateText from "../../../../../common/utils/truncateText";
import { Tooltip } from "react-tooltip";
import { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { FaChevronDown, FaChevronRight } from "react-icons/fa6";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomSwitch from "../../../../reusableComponents/customSwitch";
import CustomNavigator from "../../../../reusableComponents/customNavigator";

const DisplayNavigator = ({
  loadDashboards,
  dashboards,
  handleNewDashboard,
  handleLoadDashboard,
  handleDeleteDashboard,
  confirmDelete,
  setConfirmDelete,
  permissions,
  setAddDeviceTo,
  setAddDevice,
  setAddToDevicesList,
  setSelectedDevice,
  selectedDevice,
  removeDeviceFromTemplate,
  id,
}) => {
  const { displayId } = useParams();
  const navigate = useNavigate();
  const { open, isTemplate, setIsTemplate } = useMenu();
  const [toBeDeleted, setToBeDeleted] = useState();

  const [isExpanded, setIsExpanded] = useState(
    Array(dashboards.length).fill(false)
  );

  const handleExpand = (index) => {
    setIsExpanded((prevExpanded) => {
      const newExpandedState = [...prevExpanded];
      newExpandedState[index] = !newExpandedState[index];
      return newExpandedState;
    });
  };
  const switchDisplay = (checked) => {
    setIsTemplate(checked);
    if (checked) {
      navigate("/prebuiltdisplays");
    } else {
      navigate("/displays");
    }
  };

  return open ? (
    <>
      <CustomNavigator>
        <div className={`displayNavigator `}>
          <div className={`displayNavigatorHeader `}>
            <span>Display Templates</span>
            <CustomSwitch
              checked={isTemplate}
              onText={"D"}
              offText={"DT"}
              onChange={(e) => switchDisplay(e.target.checked)}
            />
            {permissions?.displays?.write && (
              <FiPlus
                className="addNetworksBtn"
                data-tooltip-id="displayNavigatorToolTip"
                data-tooltip-content="New Display"
                onClick={() => {
                  handleNewDashboard();
                  setSelectedDevice({ id: "", name: "", parentId: "" });
                }}
                size={20}
              />
            )}
          </div>

          <div className="displayNavigatorContent">
            {dashboards?.map((dashboard, index) => (
              <div key={dashboard._id} className="displayNavigatorContentLists">
                <li
                  className={`displayNavigatorContentList ${
                    id === dashboard._id ? "selected" : ""
                  }`}
                  id={dashboard._id}
                >
                  {!isExpanded[index] ? (
                    <FaChevronRight
                      className="expandCollapseIcon"
                      onClick={() => handleExpand(index)}
                    />
                  ) : (
                    <FaChevronDown
                      className="expandCollapseIcon"
                      onClick={() => handleExpand(index)}
                    />
                  )}
                  <Link
                    to={`/prebuiltdisplays/${dashboard._id}`}
                    className="link settings"
                  >
                    <span
                      onClick={() => handleLoadDashboard(dashboard)}
                      data-tooltip-id="displayNavigatorToolTip"
                      data-tooltip-content={dashboard.name}
                    >
                      {TruncateText(dashboard.name, 16)}
                    </span>
                  </Link>

                  {permissions.displays.write && (
                    <div className="displayNavigatorContentListBtns">
                      <FiPlus
                        onClick={() => {
                          setAddDevice(true);
                          setAddToDevicesList([]);
                          setAddDeviceTo({
                            id: dashboard._id,
                            name: dashboard.name,
                          });
                        }}
                        data-tooltip-id="displayNavigatorToolTip"
                        data-tooltip-content="Add Device"
                        style={{
                          cursor: "pointer",
                          color: "var(--font-color)",
                          margin: "auto",
                        }}
                      />
                      <MdDelete
                        data-tooltip-id="displayNavigatorToolTip"
                        data-tooltip-content="Delete"
                        onClick={() => {
                          setConfirmDelete(true);
                          setToBeDeleted(dashboard._id);
                        }}
                        style={{
                          cursor: "pointer",
                          color: "var(--white-color)",
                          margin: "auto",
                          padding: "3px",
                          borderRadius: "5px",
                          backgroundColor: "var(--light-red-color)",
                        }}
                      />
                    </div>
                  )}
                </li>

                {isExpanded[index] &&
                  dashboard?.devices?.map((device) => (
                    <li
                      key={device.id}
                      id={device.id}
                      className={`displayNavigatorContentDeviceList ${
                        selectedDevice?.id === device.id && id === dashboard._id
                          ? "selected"
                          : ""
                      }`}
                    >
                      <span
                        data-tooltip-id="displayNavigatorToolTip"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedDevice({
                            id: device.id,
                            name: device.name,
                            parentId: device.parentId,
                          });
                          handleLoadDashboard(dashboard);
                        }}
                        data-tooltip-content={`${device.id}-${device.name}`}
                      >
                        {TruncateText(`${device.id}-${device.name}`, 16)}
                      </span>
                      {permissions.displays.write && (
                        <MdDelete
                          data-tooltip-id="displayNavigatorToolTip"
                          data-tooltip-content="Delete"
                          onClick={() => {
                            removeDeviceFromTemplate(
                              dashboard._id,
                              dashboard.name,
                              device.id,
                              device.name
                            );
                          }}
                          style={{
                            cursor: "pointer",
                            color: "var(--light-font-color)",
                            margin: "auto",
                            padding: "3px",
                            borderRadius: "5px",
                            backgroundColor: "var(--light-red-color)",
                          }}
                        />
                      )}
                    </li>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </CustomNavigator>
      {confirmDelete && (
        <CustomConfirmDelete
          setConfirmDelete={setConfirmDelete}
          handleDelete={() => handleDeleteDashboard(toBeDeleted)}
          message="Are you sure you want to Delete this Display Template?"
          note="Charts and Devices Associated with this Display Template will be Deleted."
        />
      )}
      <Tooltip
        place="right"
        className="tooltipStyle"
        id="displayNavigatorToolTip"
      />
    </>
  ) : null;
};

export default DisplayNavigator;
