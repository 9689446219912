import React from "react";
import CustomFormInput from "../../../../reusableComponents/customFormInput";

const ScheduleReports = ({ reportData, setReportData }) => {
  return (
    <div className="scheduleReports">
      <div className="scheduleReportsContent">
        <div className="enableReportSchedule">
          <input
            type="checkbox"
            id="enableReportSchedule"
            name="enableReportSchedule"
            onChange={(e) =>
              setReportData({ ...reportData, periodicReport: e.target.checked })
            }
            checked={reportData.periodicReport}
          />
          <label htmlFor="enableReportSchedule">
            Periodic Report Generation
          </label>
          <select
            name="period"
            id="period"
            value={reportData.period}
            onChange={(e) =>
              setReportData({
                ...reportData,
                period: e.target.value,
                dayOfReport: "",
                timeOfReport: "",
                weekOfReport: "",
                monthOfReport: "",
              })
            }
          >
            <option value="Daily">Daily</option>
            <option value="Weekly">Weekly</option>
            <option value="Monthly">Monthly</option>
            <option value="Annually">Annually</option>
          </select>
        </div>
        {reportData.period === "Daily" ? (
          <div className="scheduleReportsInput">
            <CustomFormInput
              id="timeForReport"
              label="Select Time for Report"
              type="time"
              value={reportData.timeOfReport}
              onChange={(e) =>
                setReportData({
                  ...reportData,
                  timeOfReport: e.target.value,
                })
              }
            />
          </div>
        ) : reportData.period === "Weekly" ? (
          <div className="scheduleReportsInput">
            <CustomFormInput
              id="timeForReport"
              label="Select Time for Report"
              type="time"
              value={reportData.timeOfReport}
              onChange={(e) =>
                setReportData({
                  ...reportData,
                  timeOfReport: e.target.value,
                })
              }
            />
            <div className="selectDays">
              <p>Select Day</p>
              <div className="days">
                <span
                  className={`${
                    reportData.dayOfReport === "Sunday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Sunday" })
                  }
                >
                  S
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Monday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Monday" })
                  }
                >
                  M
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Tuesday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Tuesday" })
                  }
                >
                  T
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Wednesday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Wednesday" })
                  }
                >
                  W
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Thursday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Thursday" })
                  }
                >
                  T
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Friday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Friday" })
                  }
                >
                  F
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Saturday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Saturday" })
                  }
                >
                  S
                </span>
              </div>
            </div>
          </div>
        ) : reportData.period === "Monthly" ? (
          <div className="scheduleReportsInput">
            <CustomFormInput
              id="timeForReport"
              label="Select Time for Report"
              type="time"
              value={reportData.timeOfReport}
              onChange={(e) =>
                setReportData({
                  ...reportData,
                  timeOfReport: e.target.value,
                })
              }
            />
            <div className="selectWeekInput">
              <label className="scheduleReportsLabel">Select Week</label>
              <select
                name="week"
                id="week"
                className="selectWeek"
                onChange={(e) =>
                  setReportData({ ...reportData, weekOfReport: e.target.value })
                }
              >
                <option value="1">Week 1</option>
                <option value="2">Week 2</option>
                <option value="3">Week 3</option>
                <option value="4">Week 4</option>
                <option value="5">Week 5</option>
                <option value="6">Week 6</option>
              </select>
            </div>
            <div className="selectDays">
              <p>Select Day</p>
              <div className="days">
                <span
                  className={`${
                    reportData.dayOfReport === "Sunday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Sunday" })
                  }
                >
                  S
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Monday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Monday" })
                  }
                >
                  M
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Tuesday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Tuesday" })
                  }
                >
                  T
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Wednesday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Wednesday" })
                  }
                >
                  W
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Thursday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Thursday" })
                  }
                >
                  T
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Friday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Friday" })
                  }
                >
                  F
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Saturday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Saturday" })
                  }
                >
                  S
                </span>
              </div>
            </div>
          </div>
        ) : reportData.period === "Annually" ? (
          <div className="scheduleReportsInput">
            <CustomFormInput
              id="timeForReport"
              label="Select Time for Report"
              type="time"
            />
            <div className="selectWeekInput">
              <label className="scheduleReportsLabel">Select Month</label>
              <select
                name="week"
                id="week"
                className="selectWeek"
                value={reportData.monthOfReport}
                onChange={(e) =>
                  setReportData({
                    ...reportData,
                    monthOfReport: e.target.value,
                  })
                }
              >
                <option value="jan">January</option>
                <option value="feb">February</option>
                <option value="mar">March</option>
                <option value="apr">April</option>
                <option value="may">May</option>
                <option value="jun">June</option>
                <option value="jul">July</option>
                <option value="aug">August</option>
                <option value="sep">September</option>
                <option value="oct">October</option>
                <option value="nov">November</option>
                <option value="dec">December</option>
              </select>
            </div>
            <div className="selectWeekInput">
              <label className="scheduleReportsLabel">Select Week</label>
              <select
                name="week"
                id="week"
                className="selectWeek"
                onChange={(e) =>
                  setReportData({ ...reportData, weekOfReport: e.target.value })
                }
              >
                <option value="1">Week 1</option>
                <option value="2">Week 2</option>
                <option value="3">Week 3</option>
                <option value="4">Week 4</option>
                <option value="5">Week 5</option>
                <option value="6">Week 6</option>
              </select>
            </div>
            <div className="selectDays">
              <p>Select Day</p>
              <div className="days">
                <span
                  className={`${
                    reportData.dayOfReport === "Sunday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Sunday" })
                  }
                >
                  S
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Monday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Monday" })
                  }
                >
                  M
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Tuesday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Tuesday" })
                  }
                >
                  T
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Wednesday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Wednesday" })
                  }
                >
                  W
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Thursday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Thursday" })
                  }
                >
                  T
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Friday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Friday" })
                  }
                >
                  F
                </span>
                <span
                  className={`${
                    reportData.dayOfReport === "Saturday" ? "selectedDay" : ""
                  }`}
                  onClick={() =>
                    setReportData({ ...reportData, dayOfReport: "Saturday" })
                  }
                >
                  S
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ScheduleReports;
