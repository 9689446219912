/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import html2canvas from "html2canvas";
import { PiEyeFill } from "react-icons/pi";
import jsPDF from "jspdf";
import _debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import GridLayout from "react-grid-layout";
import { FaSave } from "react-icons/fa";
import "../../../../../common/styles/canvasStyle.css";

import { useMenu } from "../../../../../common/utils/menuContext";
import BarChart from "./charts/barChart";
import { v4 as uuidv4 } from "uuid";
import ButtonWidget from "./charts/buttonWidget";
import CommandWidget from "./charts/commandWidget";
import GuageChart from "./charts/guageChart";
import KPIChart from "./charts/kpiChart";
import LineChart from "./charts/lineChart";
import NumericChart from "./charts/numericChart";
import PieChart from "./charts/pieChart";
import TableWidget from "./charts/tableWidget";
import TextWidget from "./charts/textWidget";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import DisplayOptions from "./displayOptions";
import { Tooltip } from "react-tooltip";
import MapComponent from "./charts/map";
import { BiSolidEdit } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";
import { MdOutlineDragIndicator } from "react-icons/md";
import ImageWidget from "../../../display/presentation/components/charts/imageWidget";
import { RxCaretLeft, RxCaretRight } from "react-icons/rx";

const Canvas = React.memo(
  ({
    handleCanvasDrop,
    droppedItems,
    handleSave,
    view,
    setView,
    handleDelete,
    layout,
    setLayout,
    setItemProperties,
    itemProperties,
    configureData,
    handleLoadDashboard,
    handleSaveExistingDashboard,
    widgets,
    setWidgets,
    newDashboardName,
    permissions,
    handleSaveAsDashboard,
    handleSaveDashboard,
    setNewDashboardName,
    selectedWidget,
    setSelectedWidget,
    selectedDevice,
    setDraggedInsideImg,
    setDraggingWidget,
    draggingWidget,
    selectedFloorWidget,
    setSelectedFloorWidget,
    hovered,
    setHovered,
    hoveredPin,
    setHoveredPin,
  }) => {
    const { setOpen, open, isMobile } = useMenu();

    const [dimensions, setDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    useEffect(() => {
      const handleResize = () => {
        setDimensions({ width: window.innerWidth, height: window.innerHeight });
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const updateItemProperties = (itemId, properties) => {
      setItemProperties((prevProperties) => ({
        ...prevProperties,
        [itemId]: {
          ...prevProperties[itemId],
          ...properties,
        },
      }));
    };
    const handleEdit = useCallback(
      (e) => {
        e.preventDefault();
        setView("Edit");
        setOpen(false);
      },
      [setView, setOpen]
    );

    const handleView = useCallback(
      (e) => {
        e.preventDefault();
        setView("View");
        // setOpen((prevOpen) => !prevOpen);
      },
      [setView, setOpen]
    );

    const debouncedHandleResize = useCallback(
      _debounce((layout, oldItem, newItem) => {
        updateItemProperties(newItem.i, { w: newItem.w, h: newItem.h });
        const updatedLayout = layout.map((item) => {
          if (item.i === newItem.i) {
            return {
              ...item,
              w: newItem.w,
              h: newItem.h,
            };
          }
          return item;
        });

        setLayout(updatedLayout);
      }, 100),
      [layout, setItemProperties]
    );
    const debouncedHandleDrag = useCallback(
      _debounce((layout, oldItem, newItem) => {
        updateItemProperties(newItem.i, { x: newItem.x, y: newItem.y });
        const updatedLayout = layout.map((item) => {
          if (item.i === newItem.i) {
            return {
              ...item,
              x: newItem.x,
              y: newItem.y,
            };
          }
          return item;
        });

        setLayout(updatedLayout);
      }, 100),
      [layout, setItemProperties]
    );
    let cols = 12;
    let rowHeight = 35;

    if (dimensions.width > 1200) {
      cols = 16;
    } else if (dimensions.width > 768) {
      cols = 12;
    } else {
      cols = 8;
    }

    if (dimensions.height < 800) {
      rowHeight = 30;
    }

    const handleExportDashboardPdf = () => {
      const gridLayoutElement = document.querySelector(".layout");
      html2canvas(gridLayoutElement).then((canvas) => {
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        handleSaveDashboard();
        pdf.addImage(canvas, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(
          `${newDashboardName ? newDashboardName : "Untitled Display"}.pdf`
        );
      });
    };
    const handleExportDashboardImage = () => {
      const gridLayoutElement = document.querySelector(".layout");
      html2canvas(gridLayoutElement).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        handleSaveDashboard();
        link.href = image;
        link.download = `${
          newDashboardName ? newDashboardName : "Untitled Display"
        }.png`;
        link.click();
      });
    };

    const handleDrop = (e) => {
      e.preventDefault();
      const widgetType = e.dataTransfer.getData("widgetType");
      if (!widgetType) return;

      const imageWidgetId = e.target.closest(".imageWidget").id;
      if (!imageWidgetId) return;

      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const id = `${widgetType}-${uuidv4()}`;

      setWidgets((prevWidgets) => {
        const newWidget = {
          id: id,
          type: widgetType,
          x,
          y,
          selectedPoint: "",
          pointName: "",
          pointValue: "",
          pointDescription: "",
          pointActive: "",
          color: e.dataTransfer.getData("color"),
          parentId: imageWidgetId,
        };

        return {
          ...prevWidgets,
          [imageWidgetId]: prevWidgets[imageWidgetId]
            ? [...prevWidgets[imageWidgetId], newWidget]
            : [newWidget],
        };
      });
    };

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const handleDragWidgetInsideCanvasStart = (e, widgetId, parentId) => {
      e.stopPropagation();
      setDraggedInsideImg(true);

      const parentWidgets = widgets[parentId];
      const widget = parentWidgets.find((w) => w.id === widgetId);

      setDraggingWidget({
        ...widget,
        startX: e.clientX,
        startY: e.clientY,
        parentId,
      });
    };

    const handleDragInsideCanvas = (e) => {
      if (!draggingWidget) return;
      const deltaX = e.clientX - draggingWidget.startX;
      const deltaY = e.clientY - draggingWidget.startY;
      setDraggingWidget({
        ...draggingWidget,
        x: draggingWidget.x + deltaX,
        y: draggingWidget.y + deltaY,
        startX: e.clientX,
        startY: e.clientY,
      });
    };

    const handleDragInsideCanvasEnd = (e) => {
      if (!draggingWidget) return;

      const { parentId, id, x, y } = draggingWidget;

      setWidgets((prevWidgets) => ({
        ...prevWidgets,
        [parentId]: prevWidgets[parentId].map((widget) =>
          widget.id === id ? { ...widget, x, y } : widget
        ),
      }));

      setDraggingWidget(null);
    };

    const handleKeyDown = useCallback(
      (e) => {
        if (e.key === "Delete" && selectedFloorWidget) {
          const { id, parentId } = selectedFloorWidget;

          setWidgets((prevWidgets) => {
            const updatedWidgets = { ...prevWidgets };

            if (updatedWidgets[parentId]) {
              updatedWidgets[parentId] = updatedWidgets[parentId].filter(
                (widget) => widget.id !== id
              );
              if (updatedWidgets[parentId].length === 0) {
                delete updatedWidgets[parentId];
              }
            }
            return updatedWidgets;
          });

          setSelectedFloorWidget(null);
        }
      },
      [selectedFloorWidget]
    );

    const deleteWidget = (widget) => {
      if (widget) {
        const { id, parentId } = widget;
        setWidgets((prevWidgets) => {
          const updatedWidgets = { ...prevWidgets };

          if (updatedWidgets[parentId]) {
            updatedWidgets[parentId] = updatedWidgets[parentId].filter(
              (widget) => widget.id !== id
            );
            if (updatedWidgets[parentId].length === 0) {
              delete updatedWidgets[parentId];
            }
          }
          return updatedWidgets;
        });
      }
    };

    useEffect(() => {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [handleKeyDown]);

    const handlePinHover = (e, selectedPin) => {
      e.preventDefault();
      setHovered(true);
      setHoveredPin(selectedPin);
    };

    return (
      <div className={`canvas `}>
        <div
          className="canvasContent"
          onDragOver={handleDragOver}
          onDrop={handleCanvasDrop}
        >
          <div className="editNewDashboardBtns container-style">
            <div className="canvasHeaderLeft">
              {open ? (
                <RxCaretLeft
                  className={"headerBackIcon"}
                  onClick={() => setOpen(!open)}
                  toolTipId="customHeader"
                  toolTipContent="Collapse"
                />
              ) : (
                <RxCaretRight
                  className={"headerBackIcon"}
                  onClick={() => setOpen(!open)}
                  toolTipId="customHeader"
                  toolTipContent="Expand"
                />
              )}
              <div className={`canvasName input-style`}>
                {permissions.displays.write && (
                  <input
                    className="canvasDisplayName"
                    data-tooltip-id="displayCanvasToolTip"
                    data-tooltip-content="Rename"
                    value={newDashboardName}
                    disabled={view === "Edit" ? false : true}
                    onBlur={() => handleSaveDashboard()}
                    placeholder="Untitled Display"
                    onChange={(e) => setNewDashboardName(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="canvasHeaderRight">
              {permissions.displays.write && (
                <div className="canvasBtns">
                  {view === "View" ? (
                    <BiSolidEdit
                      className={`canvasEditViewBtn `}
                      onClick={handleEdit}
                      size={22}
                      color={"var(--font-color)"}
                      data-tooltip-id="displayEditToolTip"
                      data-tooltip-content="Edit"
                    />
                  ) : (
                    <>
                      {!isMobile && (
                        <div
                          className={`canvasEditViewBtn`}
                          onClick={handleSaveDashboard}
                          data-tooltip-id="displayCanvasToolTip"
                          data-tooltip-content="Save"
                        >
                          <FaSave size={19} color="var(--font-color)" />
                        </div>
                      )}

                      <div
                        className={`canvasEditViewBtn `}
                        onClick={handleView}
                        data-tooltip-id="displayPreviewToolTip"
                        data-tooltip-content="Preview"
                      >
                        <PiEyeFill size={20} color="var(--font-color)" />
                      </div>
                    </>
                  )}
                  <DisplayOptions
                    handleExportDashboardPdf={handleExportDashboardPdf}
                    handleExportDashboardImage={handleExportDashboardImage}
                    handleSaveAsDashboard={handleSaveAsDashboard}
                    handleSaveDashboard={handleSaveDashboard}
                    isMobile={isMobile}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="gridLayout">
            <GridLayout
              className="layout"
              layout={layout}
              cols={cols}
              rowHeight={rowHeight}
              width={1300}
              draggableHandle=".dragHandle"
              autoSize={true}
              style={{ width: "100%" }}
              isDraggable={view === "View" ? false : true}
              isResizable={view === "View" ? false : true}
              onResize={debouncedHandleResize}
              onDrag={debouncedHandleDrag}
            >
              {droppedItems.map((chart, index) => (
                <div
                  key={chart.id}
                  data-grid={{
                    x: itemProperties[chart.id]?.x || 0,
                    y: itemProperties[chart.id]?.y || 0,
                    w: itemProperties[chart.id]?.w || 7,
                    h: itemProperties[chart.id]?.h || 8,
                  }}
                  style={{
                    borderRadius: "8px",
                  }}
                  className={`droppedItem  
        ${selectedWidget === chart.id ? "widgetSelected" : ""}`}
                >
                  {view !== "View" && (
                    <>
                      <div className="dragHandle">
                        <MdOutlineDragIndicator
                          size={20}
                          color={`var(--font-color)`}
                          style={{
                            marginLeft: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            cursor: "move",
                            fontWeight: "var(--heading-font-weight)",
                          }}
                        />
                      </div>
                      <IoMdSettings
                        size={20}
                        color={`var(--font-color)`}
                        className="displaySettingsWidget"
                        onClick={() => {
                          configureData(chart.id);
                          setSelectedWidget(chart.id);
                        }}
                      />
                      <IoIosClose
                        size={32}
                        color="var(--font-color)"
                        className="displayRemoveWidget"
                        onClick={() => handleDelete(index, chart)}
                      />
                    </>
                  )}

                  {chart.id.includes("kpi") ? (
                    <KPIChart chart={chart} selectedDevice={selectedDevice} />
                  ) : chart.id.includes("line") ? (
                    <LineChart chart={chart} selectedDevice={selectedDevice} />
                  ) : chart.id.includes("bar") ? (
                    <BarChart chart={chart} selectedDevice={selectedDevice} />
                  ) : chart.id.includes("gauge") ? (
                    <GuageChart chart={chart} selectedDevice={selectedDevice} />
                  ) : chart.id.includes("pie") ? (
                    <PieChart chart={chart} selectedDevice={selectedDevice} />
                  ) : chart.id.includes("table") ? (
                    <TableWidget
                      chart={chart}
                      selectedDevice={selectedDevice}
                    />
                  ) : chart.id.includes("button") ? (
                    <ButtonWidget
                      chart={chart}
                      handleLoadDashboard={handleLoadDashboard}
                    />
                  ) : chart.id.includes("numeric") ? (
                    <NumericChart
                      chart={chart}
                      selectedDevice={selectedDevice}
                    />
                  ) : chart.id.includes("command") ? (
                    <CommandWidget
                      chart={chart}
                      handleSave={handleSave}
                      selectedDevice={selectedDevice}
                    />
                  ) : chart.id.includes("label") ? (
                    <TextWidget chart={chart} />
                  ) : chart.id.includes("image") ? (
                    <ImageWidget
                      chart={chart}
                      handleDrop={handleDrop}
                      widgets={widgets}
                      hovered={hovered}
                      hoveredPin={hoveredPin}
                      onDrag={handleDragInsideCanvas}
                      onDragEnd={handleDragInsideCanvasEnd}
                      handleDragWidgetInsideCanvasStart={
                        handleDragWidgetInsideCanvasStart
                      }
                      deleteWidget={deleteWidget}
                      handleLoadDashboard={handleLoadDashboard}
                      handleDragOver={handleDragOver}
                      handleDragInsideCanvasEnd={handleDragInsideCanvasEnd}
                      handleDragInsideCanvas={handleDragInsideCanvas}
                      handlePinHover={handlePinHover}
                      setHovered={setHovered}
                      setHoveredPin={setHoveredPin}
                      setSelectedFloorWidget={setSelectedFloorWidget}
                      selectedFloorWidget={selectedFloorWidget}
                      view={view}
                    />
                  ) : chart.id.includes("map") ? (
                    <MapComponent />
                  ) : null}
                </div>
              ))}
            </GridLayout>
          </div>
        </div>
        <Tooltip
          place="bottom"
          className="tooltipStyle"
          id="displayCanvasToolTip"
        />
        <Tooltip
          place="bottom"
          className="tooltipStyle"
          id="displayEditToolTip"
        />
      </div>
    );
  }
);

export default Canvas;
