import React from "react";
import "../../../../../common/styles/commonNodeStyle.css";
import { Handle, Position } from "reactflow";
import { useState } from "react";
import { useEffect } from "react";

const CustomOperatorNode = ({ data, isConnectable }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    if (data.label === "Ramp") {
      let intervalId;
      let min = parseFloat(data.min);
      let max = parseFloat(data.max);
      let rampPeriod = parseFloat(data.rampPeriod);
      console.log("Min:", min, "Max:", max, "Ramp Period:", rampPeriod);

      if (!isNaN(min) && !isNaN(max) && !isNaN(rampPeriod) && rampPeriod > 0) {
        let value = min;
        let direction = 1;

        const updateValue = () => {
          value += direction;
          if (value >= max || value <= min) {
            direction *= -1;
          }
          const roundedValue = Math.round(value * 100) / 100;
          data.value = roundedValue;
          setCurrentValue(roundedValue);
        };

        const intervalTime = rampPeriod * 1000;
        intervalId = setInterval(updateValue, intervalTime);

        return () => {
          clearInterval(intervalId);
        };
      }
    } else if (data.label === "TickTock") {
      let ticks = parseInt(data.ticks, 10);
      if (ticks === 1 || ticks === 2) {
        let intervalId;
        let value = 0;
        const updateValue = () => {
          value = (value + 1) % 2;
          data.value = value;
          setCurrentValue(value);
        };

        const intervalTime = 1000 / data.ticks;
        intervalId = setInterval(updateValue, intervalTime);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [
    data.min,
    data.max,
    data.rampPeriod,
    setCurrentValue,
    data.label,
    data.ticks,
    data,
  ]);

  return (
    <div className="customOperatorContainer">
      <div className="customNodeHeading">{data.label}</div>
      {data.label === "NOT" ||
      data.label === "Neg" ||
      data.label === "B2P" ||
      data.label === "Limiter" ||
      data.label === "SRLatch" ||
      data.label === "Count" ? (
        <div className="customNodeContent">
          <Handle
            type="target"
            position={Position.Left}
            id="IN0"
            isConnectable={isConnectable}
            className="customHandle"
          />
          <Handle
            type="source"
            position={Position.Right}
            id="OUT"
            isConnectable={isConnectable}
            className="customHandle"
          />
        </div>
      ) : data.label === "ADEMUX2" ? (
        <div className="customNodeContent">
          <Handle
            type="target"
            position={Position.Left}
            id="IN0"
            isConnectable={isConnectable}
            className="customHandle"
          />
          <Handle
            type="source"
            position={Position.Right}
            id="OUT0"
            style={{ top: 40 }}
            isConnectable={isConnectable}
            className="customHandle"
          />
          <Handle
            type="source"
            position={Position.Right}
            id="OUT1"
            style={{ top: 60 }}
            isConnectable={isConnectable}
            className="customHandle"
          />
        </div>
      ) : data.label === "Ramp" || data.label === "TickTock" ? (
        <div className="customNodeContent">
          <p>{currentValue}</p>
          <Handle
            type="source"
            position={Position.Right}
            id="IN0"
            isConnectable={isConnectable}
            className="customHandle"
          />
        </div>
      ) : (
        <div className="customNodeContent">
          <Handle
            type="target"
            position={Position.Left}
            id="IN0"
            style={{ top: 40 }}
            isConnectable={isConnectable}
            className="customHandle"
          />
          {/* <span className="handle-label">
            <b>IN0: </b>
          </span> */}
          <div className="handleWithLabel">
            <Handle
              type="target"
              position={Position.Left}
              id="IN1"
              style={{ top: 40 }}
              isConnectable={isConnectable}
              className="customHandle"
            />
            {/* <span className="handle-label">
              <b>IN1: </b>
            </span> */}
          </div>
          <Handle
            type="source"
            position={Position.Right}
            id="OUT"
            isConnectable={isConnectable}
            className="customHandle"
          />
        </div>
      )}
    </div>
  );
};

export default CustomOperatorNode;
