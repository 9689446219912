/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../../../../common/styles/userSettingsStyle.css";

import UsersTable from "./usersTable.jsx";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useState } from "react";
import CustomButton from "../../../../reusableComponents/customButton";
import CustomPhoneInput from "../../../../reusableComponents/customPhoneInput";
import { useEffect } from "react";
import PreLoader from "../../../../reusableComponents/preLoader";
import { toast } from "react-toastify";
import getUser from "../../../../../configuration/localStorage/getUser";
import formatCurrentDateTime from "../../../../../common/utils/formattedDateTime";
import { validateInputs } from "../../../../../common/utils/validateInputs";
import CustomHeader from "../../../../reusableComponents/customHeader.jsx";
import CustomFormInput from "../../../../reusableComponents/customFormInput.jsx";
import CustomFormSelect from "../../../../reusableComponents/customFormSelect.jsx";
import { useMenu } from "../../../../../common/utils/menuContext.js";
import api from "../../../../../common/utils/axiosRequest.js";
import axios from "axios";

const UserSettings = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { config } = useMenu();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = useState("");
  const [userPassword, setUserPassword] = useState();
  const [emailAddress, setEmailAddress] = useState("");
  const [lastEmailAddress, setLastEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [role, setRole] = useState("Admin");
  const [id, setId] = useState();
  const [users, setUsers] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const userNames = users.map((user) => `${user.firstName} ${user.lastName}`);
  const userId = users.map((user) => user._id);
  const roles = users.map((user) => user.role);
  const status = users.map((user) => user.status);
  const lastAccess = users.map((user) => user.lastLoggedIn);
  const [isLoading, setIsLoading] = useState(false);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [user] = useState(getUser);

  const fetchUsers = async () => {
    // setIsLoading(true);
    try {
      const response = await api.get(`${dbUrl}/users/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      const userData = response.data.data.rows.map((user) => user.doc);
      setIsLoading(false);
      setUsers(userData);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setIsLoading(false);
    }
  };
  const fetchRoles = async () => {
    try {
      const getAllRoles = await api.get(`${dbUrl}/roles/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      console.log(getAllRoles.data.data.rows.map((role) => role.doc));
      setUserRoles(getAllRoles.data.data.rows.map((role) => role.doc));
      setRole(getAllRoles.data.data.rows[0]._id);
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  const handleCreateUser = () => {
    setIsPopupOpen(true);
    setEditMode(false);
    setCompanyName("");
    setEmailAddress("");
    setFirstName("");
    setLastName("");
    setUserPassword("");
    setLocation("");
    setPhoneNumber("");
    setRole("Operator");
    setId("");
  };

  const createUser = async () => {
    if (
      !firstName === "" ||
      !emailAddress === "" ||
      !userPassword === "" ||
      !role === ""
    ) {
      toast.warning("Please fill in all required fields");
      return;
    } else if (!validateInputs(emailAddress, userPassword)) {
      return;
    }
    if (editMode) {
      return;
    } else {
      const emailExists = users.some(
        (user) => user.emailAddress === emailAddress
      );
      if (emailExists) {
        toast.warning(
          "Email address is already taken. Please use another email address."
        );
        return;
      }
    }

    // const lastUserId = users.length > 0 ? users[users.length - 1]._id : 0;
    // const newUserId = `USR${(parseInt(lastUserId.slice(3)) + 1)
    //   .toString()
    //   .padStart(3, "0")}`;
    const hashedPassword = await api.post(`${dbUrl}/get_hashed_password`, {
      password: userPassword,
    });
    const userData = {
      _id: emailAddress,
      username: emailAddress.split("@")[0],
      password: hashedPassword.data.data,
      firstName: firstName,
      lastName: lastName,
      companyName: companyName,
      location: location,
      emailAddress: emailAddress,
      createdAt: formatCurrentDateTime(),
      status: "Active",
      lastLoggedIn: "",
      phoneNumber: phoneNumber,
      role: role,
      orgId: user?.orgId,
    };
    try {
      const addUserToOrg = await axios.post(`${dbUrl}/add_user_to_org`, {
        email: userData?.emailAddress,
        orgId: userData?.orgId,
        role: userData?.role,
      });
      if (addUserToOrg?.data?.status === 409) {
        toast.warning(addUserToOrg?.data?.message);
        return;
      } else if (addUserToOrg?.data?.status === 400) {
        toast.warning(addUserToOrg?.data?.message);
        return;
      } else if (addUserToOrg?.data?.status === 404) {
        toast.warning(addUserToOrg?.data?.message);
        return;
      } else if (addUserToOrg?.data?.status === 200) {
        const pushToDb = await api.post(`${dbUrl}/users`, userData);
        if (pushToDb.status === 200) {
          toast.success(`New User created with ID: ${userData._id}`);
          fetchUsers();
          setIsPopupOpen(false);
        }
      }
    } catch (error) {
      toast.error(error);
      fetchUsers();
      setIsPopupOpen(false);
    }
  };

  const handleUserEdit = async (userId) => {
    setEditMode(true);
    setIsPopupOpen(true);
    setId(userId);
    try {
      if (userId === user._id) {
        setCompanyName(user.companyName);
        setEmailAddress(user.emailAddress);
        setLastEmailAddress(user.emailAddress);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setLocation(user.location);
        setPhoneNumber(user.phoneNumber);
        setRole(user.role);
      } else {
        const fetchUserData = await api.get(`${dbUrl}/users/${userId}`);
        let userData = fetchUserData.data.data;
        setCompanyName(userData.companyName);
        setEmailAddress(userData.emailAddress);
        setLastEmailAddress(userData.emailAddress);
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setLocation(userData.location);
        setPhoneNumber(userData.phoneNumber);
        setRole(userData.role);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateUser = async () => {
    if (!validateInputs(emailAddress, userPassword)) {
      return;
    }
    const hashedPassword = await api.post(`${dbUrl}/get_hashed_password`, {
      password: userPassword,
    });
    setIsPopupOpen(false);
    const updatedData = {
      username: emailAddress.split("@")[0],
      password: hashedPassword.data.data,
      firstName: firstName,
      lastName: lastName,
      companyName: companyName,
      location: location,
      emailAddress: emailAddress,
      phoneNumber: phoneNumber,
      role: role,
    };
    try {
      const emailExists = users.some(
        (user) => user.emailAddress === emailAddress
      );
      if (editMode && lastEmailAddress === emailAddress) {
        const updateUser = await api.put(`${dbUrl}/users/${id}`, updatedData);
        if (updateUser.status === 200) {
          toast.success("User Updated");
          fetchUsers();
        }
      } else if (emailExists) {
        toast.warning(
          "Email address is already taken. Please use another email address."
        );
        return;
      } else {
        const updateUser = await api.put(`${dbUrl}/users/${id}`, updatedData);
        if (updateUser.status === 200) {
          toast.success("User Updated");
          fetchUsers();
        }
      }
    } catch (error) {
      console.error(error);
      fetchUsers();
    }
  };
  const handleUserDelete = async (userId) => {
    try {
      console.log("Called This Function to Delete");
      if (user._id !== userId) {
        const delUserFromOrg = await api.post(`${dbUrl}/remove_user_from_org`, {
          _id: userId,
          orgId: user?.orgId,
        });
        if (delUserFromOrg?.data?.status === 200) {
          await api.delete(`${dbUrl}/users/${userId}`);
          toast.success("User Deleted Successfully");
          fetchUsers();
        } else if (delUserFromOrg?.data?.status === 404) {
          toast.warning(delUserFromOrg?.data?.message);
          return;
        }
      } else {
        toast.warning("Cannot Delete Current Logged In User");
      }
    } catch (error) {
      toast.error(error);
      fetchUsers();
    }
  };
  return (
    <div className={`userSettings `}>
      <CustomHeader heading={"Users"} />
      <div className="userSettingsHeading">
        <div
          className={`filled-btn-style`}
          onClick={() => handleCreateUser()}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "start",
          }}
        >
          <span className="createUserBtn">Create User</span>
          <AiOutlineUserAdd size={16} />
        </div>
      </div>

      <div className={`userSettingsContent `}>
        {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : ""}
        <UsersTable
          handleUserEdit={handleUserEdit}
          handleUserDelete={handleUserDelete}
          userNames={userNames}
          roles={roles}
          status={status}
          userId={userId}
          lastAccess={lastAccess}
        />
      </div>
      {isPopupOpen ? (
        <div className="userSettingsCreateUserPopup">
          <div className="userSettingsCreateUser">
            <h2>{editMode ? "Edit User Info" : "Create a User"}</h2>
            <div className="userSettingsCreateUserForm">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  createUser(e);
                }}
                method="POST"
              >
                <div className="userSettingsCreateUserFormInputs">
                  <div className="userSettingsCreateUserFormLeft">
                    <CustomFormInput
                      id="firstName"
                      label="First Name"
                      placeholder={"Enter your First Name"}
                      required={true}
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <CustomFormInput
                      id="lastName"
                      label="Last Name"
                      placeholder={"Enter your Last Name"}
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <CustomFormInput
                      id="companyName"
                      label="Company Name"
                      placeholder={"Enter your Company Name"}
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                    <CustomFormInput
                      id="location"
                      label="Location"
                      placeholder={"Enter your Location"}
                      type="text"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>

                  <div className="userSettingsCreateUserFormRight">
                    <CustomFormInput
                      id="emailAddress"
                      label="Email Address"
                      placeholder={"Enter your e-mail"}
                      required={true}
                      type="email"
                      value={emailAddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                      style={{ backgroundColor: "white" }}
                    />
                    <CustomPhoneInput
                      id="phoneNumber"
                      label="Phone Number"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      placeholder="Enter your Phone number"
                    />
                    {editMode ? (
                      <CustomFormInput
                        id="password"
                        label="Password"
                        placeholder={"Enter your Password"}
                        required={true}
                        value={userPassword}
                        onChange={(e) => setUserPassword(e.target.value)}
                      />
                    ) : (
                      <CustomFormInput
                        id="password"
                        label="Password"
                        placeholder={"Enter your Password"}
                        required={true}
                        type="password"
                        value={userPassword}
                        onChange={(e) => setUserPassword(e.target.value)}
                      />
                    )}
                    <CustomFormSelect
                      id={"roles"}
                      required={true}
                      label={"Select Role"}
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      options={userRoles.map((role) => ({
                        label: role._id,
                        value: role._id,
                      }))}
                    />
                  </div>
                </div>
                <div className="userSettingsCreateUserFormBtns">
                  <CustomButton
                    className="outline-btn-style"
                    buttonName="Cancel"
                    handleClick={() => setIsPopupOpen(false)}
                  />
                  {editMode ? (
                    <CustomButton
                      className="filled-btn-style"
                      buttonName="Update User"
                      handleClick={updateUser}
                    />
                  ) : (
                    <CustomButton
                      className="filled-btn-style"
                      buttonName="Create User"
                      type="submit"
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UserSettings;
