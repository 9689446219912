/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PreLoader from "../../../../../reusableComponents/preLoader";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const BarCharts = ({ chart }) => {
  const { config } = useMenu();
  // const [isLoading, setIsLoading] = useState(true);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [chartData, setChartData] = useState([]);
  const [yAxisDomain, setYAxisDomain] = useState([0, 0]);
  // const [loading, setLoading] = useState(true);

  const fetchLiveDeviceData = async () => {
    try {
      const couchdbUrl = dbUrl;
      const point = chart?.data?.points[0];
      const pointId = point.value;

      if (chart?.data?.pointType === "currentVal") {
        const fetchedData = await Promise.all(
          chart.data.points.map(async (point) => {
            const response = await api.get(
              `${couchdbUrl}/points/${point.value}`
            );
            return response.data.data;
          })
        );

        let data = fetchedData.map((value) => ({
          name: value?.pointName,
          value: value?.pointPresentValue,
        }));

        if (data.length > 200) {
          data = data.slice(-200);
        }

        setChartData(data);
        setYAxisDomain([
          Math.min(...data.map((d) => d.value)) - 5,
          Math.max(...data.map((d) => d.value)) + 5,
        ]);
      } else if (chart?.data?.pointType === "trendVal") {
        let filteredData = [];
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        if (chart?.data?.dateRange === "1Day") {
          const currentDateString = new Date().toISOString().split("T")[0];
          const response = await api.get(`${couchdbUrl}/trends/${pointId}`);

          filteredData = response?.data?.data?.trends?.filter((doc) =>
            doc.timestamp.startsWith(currentDateString)
          );
        } else if (chart?.data?.dateRange === "2Day") {
          const twoDaysAgo = new Date(currentDate);
          twoDaysAgo.setDate(currentDate.getDate() - 2);
          const response = await api.get(`${couchdbUrl}/trends/${pointId}`);
          filteredData = response.data.data.trends.filter(
            (doc) => new Date(doc.timestamp) >= twoDaysAgo
          );
        } else if (chart?.data?.dateRange === "custom") {
          const startDate = new Date(chart.data.min);
          const endDate = new Date(chart.data.max);
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(23, 59, 59, 999);
          const response = await api.get(`${couchdbUrl}/trends/${pointId}`);
          filteredData = response.data.data.trends.filter((doc) => {
            const timestamp = new Date(doc.timestamp);
            return timestamp >= startDate && timestamp <= endDate;
          });
        }
        if (filteredData.length > 200) {
          filteredData = filteredData.slice(-200);
        }
        const data = filteredData.map((doc) => ({
          name: doc.timestamp,
          value: doc?.presentValue?.toFixed(1),
        }));
        setChartData(data);
        setYAxisDomain([
          Math.min(...data.map((d) => d.value)),
          Math.max(...data.map((d) => d.value)) + 0.5,
        ]);
      }
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // setLoading(false);
    }
  };

  useEffect(() => {
    const intervalInSeconds = chart?.data?.pollFrequency;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchLiveDeviceData();
    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart]);

  return (
    <>
      <div
        id="chartContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
          height: "92%",
          overflow: "scroll",
          margin: "auto",
        }}
      >
        <span className="chartLabel">
          {chart?.data?.title
            ? chart?.data?.title
            : chart?.data?.pointType === "trendVal"
            ? chart?.data?.points[0]?.label
            : "Label"}
        </span>
        {chartData.length > 0 ? (
          <ResponsiveContainer width="100%" height={"100%"}>
            <BarChart
              width={600}
              height={300}
              data={chartData}
              className="barChart"
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <XAxis
                dataKey="name"
                tickFormatter={(name) =>
                  name.length > 10 ? `${name.substring(0, 10)}...` : name
                }
                stroke="#8884d8"
                tickLine={false}
                axisLine={{ stroke: "var(--font-color)", strokeWidth: 1.5 }}
                tick={{ fontSize: 12, fill: "var(--font-color)" }}
              />
              <YAxis
                domain={yAxisDomain}
                stroke="#8884d8"
                tickLine={false}
                axisLine={{ stroke: "var(--font-color)", strokeWidth: 1.5 }}
                tick={{ fontSize: 12, fill: "var(--font-color)" }}
              />
              <Bar
                dataKey="value"
                fill="var(--primary-color"
                radius={16}
                style={{
                  filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2))",
                }}
              />

              <Tooltip
                contentStyle={{
                  backgroundColor: "var(--tooltip-background-color)",
                  border: "var(--container-border)",
                  borderRadius: "15px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                labelStyle={{
                  fontSize: "var(--heading-font-size)",
                  color: "var(--black-color)",
                  fontWeight: "bold",
                }}
                itemStyle={{
                  color: "var(--black-color)",
                  fontWeight: "bold",
                }}
                cursor={{ fill: "transparent" }}
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <p className="noData">No Data Available</p>
        )}
      </div>
    </>
  );
};

export default BarCharts;
