/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../../../../common/styles/liveDataWidgetStyle.css";
import { useMenu } from "../../../../../common/utils/menuContext";
import { toast } from "react-toastify";
import CustomFormSelect from "../../../../reusableComponents/customFormSelect";
import TruncateText from "../../../../../common/utils/truncateText";
import { Tooltip } from "react-tooltip";
import api from "../../../../../common/utils/axiosRequest";

const LiveDataWidget = ({
  pointProps,
  permissions,
  alarmHigh,
  alarmLow,
  setAlarmHigh,
  setAlarmLow,
  deviceProps,
}) => {
  const { config, reloadData, setReloadData } = useMenu();
  const [liveValue, setLiveValue] = useState(0);
  const [mode, setMode] = useState();
  const [binaryValue, setBinaryValue] = useState();
  const [presentValue, setPresentValue] = useState(
    pointProps?.pointPresentValue ? pointProps?.pointPresentValue : 0
  );
  const serverEndpoint = config?.REACT_APP_POLLING_ENDPOINT;
  const [loading, setLoading] = useState(false);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const fetchPointsFromDB = async () => {
    setLoading(true);
    try {
      const getPoint = await api.get(`${dbUrl}/points/${pointProps?._id}`);
      setPresentValue(getPoint.data.data.pointPresentValue);
      setBinaryValue(getPoint.data.data.pointPresentValue);
    } catch (error) {
      console.error("Error Fetching Point Information:", error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchPointsFromDB();
    setMode(pointProps?.pointMode || "Auto");
  }, [pointProps]);

  const fetchLiveData = async () => {
    try {
      const getPoint = await api.get(`${dbUrl}/points/${pointProps?._id}`);
      const liveVal = getPoint.data.data.pointPresentValue;
      pointProps["pointPresentValue"] = liveVal;
      if (
        liveVal >= parseInt(getPoint?.data?.data?.alarmHighLimit) &&
        getPoint?.data?.data?.alarmHighLimitEnable
      ) {
        setAlarmHigh(true);
        setAlarmLow(false);
      } else if (
        liveVal <= parseInt(getPoint?.data?.data?.alarmLowLimit) &&
        getPoint?.data?.data?.alarmLowLimitEnable
      ) {
        setAlarmLow(true);
        setAlarmHigh(false);
      } else {
        setAlarmHigh(false);
        setAlarmLow(false);
      }
      setPresentValue(liveVal);
      setReloadData(false);
    } catch (error) {
      console.error("Error fetching last uploaded record:", error.message);
      setReloadData(false);
    }
  };
  useEffect(() => {
    const intervalInSeconds = 5;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    if (pointProps?.pointLastPollStatus === "Success" || reloadData === true) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveData();
      }, intervalInMilliseconds);
      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      setReloadData(false);
    }
  }, [pointProps, reloadData, setReloadData]);

  useEffect(() => {
    if (presentValue !== liveValue) {
      setLiveValue(presentValue);
    }
  }, [presentValue]);

  const onBinaryValueChange = async (value) => {
    setLoading(true);
    setBinaryValue(value);
    try {
      const updatedData = {
        previousPresentValue: presentValue,
      };
      await api.put(`${dbUrl}/points/${pointProps?._id}`, updatedData);
      const updateValue = await api.post(
        `${serverEndpoint}/write/${pointProps?._id}`,
        {
          value: parseFloat(value ? value : 0),
          priority: 10,
          topic: pointProps?.networkTopic ? pointProps?.networkTopic : "",
        }
      );
      if (updateValue.status === 200) {
        toast.success("Updated Point Successfully");
      } else {
        toast.warning("Unable to Write Point, Reason - Don't have Permission");
        setBinaryValue(presentValue);
      }
    } catch (error) {
      toast.error(error);
      setBinaryValue(presentValue);
    }
    setLoading(false);
  };

  const handleBlur = async () => {
    setLoading(true);
    try {
      const updatedData = {
        previousPresentValue: presentValue,
      };
      await api.put(`${dbUrl}/points/${pointProps?._id}`, updatedData);
      const updateValue = await api.post(
        `${serverEndpoint}/write/${pointProps?._id}`,
        {
          value: parseFloat(liveValue ? liveValue : 0),
          priority: 10,
          topic: pointProps?.networkTopic ? pointProps?.networkTopic : "",
        }
      );
      if (updateValue.status === 200) {
        toast.success("Updated Point Successfully");
      } else {
        toast.warning("Unable to Write Point, Reason - Don't have Permission");
        setLiveValue(presentValue);
      }
    } catch (error) {
      toast.error(`Unable to Write Point: ${error}`);
      setLiveValue(presentValue);
    }
    setLoading(false);
  };

  const onModeChange = async (mode) => {
    const pointData = {
      pointMode: mode,
    };
    try {
      await api.put(`${dbUrl}/points/${pointProps?._id}`, pointData);
    } catch (error) {
      toast.error(`Unable to Change Mode: ${error}`);
    }
    if (mode === "Auto") {
      setMode(mode);
      try {
        const getUpdatedPoint = await api.get(
          `${dbUrl}/points/${pointProps?._id}`
        );
        const updatedData = {
          pointMode: mode,
          previousPresentValue: presentValue,
        };
        await api.put(`${dbUrl}/points/${pointProps?._id}`, updatedData);
        const priorityArray = Array.isArray(pointProps?.priorityArray)
          ? pointProps?.priorityArray
          : JSON.parse(pointProps?.priorityArray || "[]");
        const updateValue = await api.post(
          `${serverEndpoint}/write/${pointProps?._id}`,
          {
            value: parseFloat(
              getUpdatedPoint?.data?.data?.previousPresentValue
                ? getUpdatedPoint?.data?.data?.previousPresentValue
                : 0
            ),
            priority: 10,
            mode: "auto",
            priorityArray: priorityArray,
            topic: pointProps?.networkTopic ? pointProps?.networkTopic : "",
          }
        );
        if (updateValue.status === 200) {
          toast.success("Enabled Auto Mode");
          setLiveValue(getUpdatedPoint?.data?.previousPresentValue);
          setBinaryValue(getUpdatedPoint?.data?.previousPresentValue);
        } else {
          toast.warning("Unable to Enable Auto Mode,");
        }
      } catch (error) {
        toast.error(`Unable to Enable Auto Mode: ${error}`);
      }
    } else {
      setMode(mode);
    }
  };

  return (
    <div className="pointDetailsSummaryLiveDataContainer">
      {/* <div className="pointDetailsSummaryLiveDataHeading">Live Data</div> */}
      <div className="pointDetailsSummaryLiveDataContents">
        <span className="pointDetailsLiveDataHeading">Point Name</span>
        <span className="pointDetailsSummaryLiveDataContent">
          {pointProps?.pointName}
        </span>
        <span className="pointDetailsLiveDataHeading">Point Description</span>
        <span className="pointDetailsSummaryLiveDataContent">
          {pointProps?.pointDetails}
        </span>
        <span className="pointDetailsLiveDataHeading">Unit</span>
        <span className="pointDetailsSummaryLiveDataContent">
          {pointProps?.pointUnits ? pointProps?.pointUnits : "--"}
        </span>
        <span className="pointDetailsLiveDataHeading">Input Type</span>
        <span className="pointDetailsSummaryLiveDataContent">
          {pointProps?.pointType}
        </span>
        <div
          className={`liveValueContainer ${
            alarmHigh ||
            !pointProps?.pointEnable ||
            pointProps?.pointPollStatus === "Failed"
              ? "red"
              : alarmLow
              ? "yellow"
              : "green"
          }`}
        >
          {!pointProps?.pointEnable
            ? "null"
            : pointProps?.pointType.includes("binaryValue") ||
              pointProps?.pointType.includes("binaryInput") ||
              pointProps?.pointType.includes("binaryOutput") ||
              pointProps?.pointType.includes("digitalInput") ||
              pointProps?.pointType.includes("digitalOutput") ||
              pointProps?.pointType.includes("digitalValue") ||
              pointProps?.pointType.includes("CoilStatus") ||
              pointProps?.pointType.includes("InputStatus")
            ? presentValue === 0
              ? "OFF"
              : "ON"
            : presentValue?.toFixed(2)}
          <span
            className="liveDataUnit"
            data-tooltip-id="liveWidgetTooltip"
            data-tooltip-content={
              pointProps?.pointUnitSymbol?.length > 2
                ? pointProps?.pointUnitSymbol
                : ""
            }
          >
            {TruncateText(
              pointProps?.pointUnitSymbol ? pointProps?.pointUnitSymbol : "",
              2
            )}
          </span>
        </div>
        <Tooltip place="top" className="tooltipStyle" id="liveWidgetTooltip" />

        {typeof presentValue === "string" ? null : (
          <div className="liveWidgetLastPart">
            <div className="pointDetailsSummaryLiveDataInputGroup">
              <div className="pointDetailsSummaryLiveDataInputGroupLabel">
                <div className="labelInputContainer">
                  <label htmlFor="mode" style={{ textAlign: "center" }}>
                    MODE
                  </label>
                  <div className="modeToggle">
                    <button
                      type="button"
                      className={`toggleButton ${
                        mode === "Auto" ? "active" : "notactive"
                      }`}
                      onClick={
                        permissions?.devices.write &&
                        (pointProps?.pointType?.includes("Value") ||
                          pointProps?.pointType?.includes("Output") ||
                          pointProps?.pointType?.includes("Coil"))
                          ? () => onModeChange("Auto")
                          : undefined
                      }
                      disabled={
                        !(
                          pointProps?.pointType?.includes("Value") ||
                          pointProps?.pointType?.includes("Output") ||
                          pointProps?.pointType?.includes("Coil")
                        )
                      }
                      style={{
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        cursor: "pointer",
                      }}
                    >
                      AUTO
                    </button>
                    <button
                      type="button"
                      className={`toggleButton ${
                        mode === "Manual" ? "active" : ""
                      }`}
                      onClick={
                        permissions.devices.write &&
                        (pointProps?.pointType?.includes("Value") ||
                          pointProps?.pointType?.includes("Output") ||
                          pointProps?.pointType?.includes("Coil"))
                          ? () => onModeChange("Manual")
                          : undefined
                      }
                      disabled={
                        !(
                          pointProps?.pointType?.includes("Value") ||
                          pointProps?.pointType?.includes("Output") ||
                          pointProps?.pointType?.includes("Coil")
                        )
                      }
                      style={{
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                        cursor: "pointer",
                      }}
                    >
                      MANUAL
                    </button>
                  </div>
                </div>

                {pointProps?.pointType?.includes("binaryValue") ||
                pointProps?.pointType?.includes("binaryInput") ||
                pointProps?.pointType?.includes("CoilStatus") ||
                pointProps?.pointType?.includes("InputStatus") ? (
                  <CustomFormSelect
                    id={"binaryValue"}
                    label={"Select Value"}
                    onChange={(e) => onBinaryValueChange(e.target.value)}
                    options={[
                      {
                        label: pointProps?.statusTextOne
                          ? pointProps?.statusTextOne
                          : "True",
                        value: "active",
                      },
                      {
                        label: pointProps?.statusTextZero
                          ? pointProps?.statusTextZero
                          : "False",
                        value: "inactive",
                      },
                    ]}
                    value={binaryValue}
                    disabled={
                      mode === "Auto" ||
                      pointProps?.pointType?.includes("InputRegister") ||
                      pointProps?.pointType?.includes("Input") ||
                      pointProps?.pointStatus === "Inactive"
                    }
                  />
                ) : (
                  <div className="labelInputContainer">
                    <label htmlFor="outputVal" style={{ textAlign: "center" }}>
                      OUTPUT VAL
                    </label>
                    <input
                      id="outputVal"
                      className="input-style"
                      type={!pointProps?.pointEnable ? "text" : "number"}
                      value={
                        !pointProps?.pointEnable
                          ? "null"
                          : presentValue === null
                          ? "null"
                          : liveValue
                      }
                      disabled={
                        mode === "Auto" ||
                        pointProps?.pointType?.includes("InputRegister") ||
                        pointProps?.pointType?.includes("Input") ||
                        pointProps?.pointStatus === "Inactive"
                      }
                      onBlur={handleBlur}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleBlur();
                        }
                      }}
                      onChange={(e) => setLiveValue(e.target.value)}
                      style={{
                        color: "var(--font-color)",
                        cursor: !pointProps?.pointEnable
                          ? "not-allowed"
                          : "auto",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            {pointProps?.pointType?.includes("Value") ||
            pointProps?.pointType?.includes("Output") ||
            pointProps?.pointType?.includes("Coil") ? (
              <div className="Writableflag input-style">Read/Write</div>
            ) : (
              <div className="Writableflag input-style">Read Only</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveDataWidget;
