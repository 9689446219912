import React from "react";
import "../../../../../common/styles/miniWidgetsStyle.css";
import WidgetsTwoToneIcon from "@mui/icons-material/WidgetsTwoTone";
import CalculateIcon from "@mui/icons-material/Calculate";
import { TbLogicXnor } from "react-icons/tb";
import { AiOutlineFunction } from "react-icons/ai";
import { LuTextCursorInput } from "react-icons/lu";
import { useMenu } from "../../../../../common/utils/menuContext";

import { useLocation } from "react-router-dom";
import getPermissions from "../../../../../configuration/localStorage/getPermissions";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import { GiMaze } from "react-icons/gi";
import { TbTemplate } from "react-icons/tb";

const MiniWidgets = () => {
  const location = useLocation();
  const [permissions] = useState(getPermissions);

  const {
    setWidgetOpen,
    setPreBuiltDashboard,
    setMathBlockOpen,
    setLogicBlockOpen,
    setFunctionBlockOpen,
    setConstantsBlockOpen,
    setPreBuiltDashboardEnable,
    setFloorLayout,
    setWidgetLibraryOpen,
  } = useMenu();
  return (
    <div className={`miniWidgets container-style`}>
      <div className={`miniWidgetMenu `}>
        {location.pathname.startsWith("/displays") ? (
          permissions.displays.write && (
            <>
              <WidgetsTwoToneIcon
                className={`miniWidgetIcon selected-nav`}
                onClick={() => {
                  setWidgetOpen((prevOpen) => !prevOpen) ||
                    setPreBuiltDashboard(false) ||
                    setPreBuiltDashboardEnable(false);
                  setWidgetLibraryOpen(false);
                  setFloorLayout(false);
                }}
                data-tooltip-id="miniWidgetToolTip"
                data-tooltip-content="Display Widgets"
              />
              <TbTemplate
                className={`miniWidgetIcon selected-nav`}
                onClick={() => {
                  setWidgetLibraryOpen((prevOpen) => !prevOpen) ||
                    setPreBuiltDashboard(false) ||
                    setPreBuiltDashboardEnable(false);
                  setFloorLayout(false);
                  setWidgetOpen(false);
                }}
                data-tooltip-id="miniWidgetToolTip"
                size={23}
                data-tooltip-content="Prebuilt Templates"
              />
              <span
                className="miniWidgetIcon selected-nav"
                onClick={() => {
                  setFloorLayout((prevOpen) => !prevOpen);
                  setWidgetOpen(false);
                  setWidgetLibraryOpen(false);
                }}
                data-tooltip-id="miniWidgetToolTip"
                data-tooltip-content="Floor Widgets"
              >
                <GiMaze color="var(--font-color)" size={20} />
              </span>
            </>
          )
        ) : // <>

        // </>
        location.pathname === "/rules" && permissions.rules.write ? (
          <>
            <CalculateIcon
              className={`miniWidgetIcon `}
              onClick={() =>
                setMathBlockOpen((prevOpen) => !prevOpen) ||
                setLogicBlockOpen(false) ||
                setFunctionBlockOpen(false) ||
                setConstantsBlockOpen(false)
              }
              data-tooltip-id="miniWidgetToolTip"
              data-tooltip-content="Math Blocks"
            />
            Math
            <TbLogicXnor
              size={24}
              className={`miniWidgetIcon `}
              onClick={() =>
                setLogicBlockOpen((prevOpen) => !prevOpen) ||
                setMathBlockOpen(false) ||
                setFunctionBlockOpen(false) ||
                setConstantsBlockOpen(false)
              }
              data-tooltip-id="miniWidgetToolTip"
              data-tooltip-content="Logic Blocks"
            />
            Logic
            <AiOutlineFunction
              size={24}
              className={`miniWidgetIcon `}
              onClick={() =>
                setFunctionBlockOpen((prevOpen) => !prevOpen) ||
                setMathBlockOpen(false) ||
                setLogicBlockOpen(false) ||
                setConstantsBlockOpen(false)
              }
              data-tooltip-id="miniWidgetToolTip"
              data-tooltip-content="Function Blocks"
            />
            Func
            <LuTextCursorInput
              size={24}
              className={`miniWidgetIcon `}
              onClick={() =>
                setConstantsBlockOpen((prevOpen) => !prevOpen) ||
                setMathBlockOpen(false) ||
                setLogicBlockOpen(false) ||
                setFunctionBlockOpen(false)
              }
              data-tooltip-id="miniWidgetToolTip"
              data-tooltip-content="Constant Blocks"
            />
            Consts
          </>
        ) : null}
      </div>
      <Tooltip place="left" className="tooltipStyle" id="miniWidgetToolTip" />
    </div>
  );
};

export default MiniWidgets;
