/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../../../../../common/styles/pointsDetailsPropertiesStyle.css";

import PreLoader from "../../../../reusableComponents/preLoader";
import { useMenu } from "../../../../../common/utils/menuContext";
import { isEmpty } from "../../../../../common/utils/isEmpty";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import CustomSearchableDropDown from "../../../../reusableComponents/customSearchableDropDown";
import api from "../../../../../common/utils/axiosRequest";

const PointDetailsProperties = ({
  pointProps,
  setSelectedPoint,
  permissions,
}) => {
  const { setReloadData, config } = useMenu();
  const [loading, setLoading] = useState(false);
  const [pointName, setPointName] = useState(pointProps?.pointName);
  const [pointDetails, setPointDetails] = useState(pointProps?.pointDetails);
  const [pointAddress, setPointAddress] = useState(
    pointProps?.pointInstanceOriginal
  );
  const [selectedUnit, setSelectedUnit] = useState();
  const [units, setUnits] = useState([]);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  useEffect(() => {
    const getUnits = async () => {
      const unitz = await api.get(`${dbUrl}/units/units`, {
        params: {
          include_docs: true,
        },
      });
      setUnits(unitz?.data?.data?.units);
    };
    getUnits();
  }, []);

  const handleSave = async (option) => {
    try {
      setSelectedUnit(option._id);
      if (!option) {
        toast.warning("Units is Empty");
      } else {
        const updatedData = {
          ...pointProps,
          pointUnits: option?._id,
          pointUnitSymbol: option?.symbol,
        };
        await api.put(`${dbUrl}/points/${pointProps?._id}`, updatedData);
        setSelectedPoint(updatedData);
        toast.success("Units Updated Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setPointName(pointProps?.pointName);
    setPointDetails(pointProps?.pointDetails);
    setPointAddress(pointProps?.pointInstanceOriginal);
    setSelectedUnit(pointProps?.pointUnits);
  }, [pointProps]);

  const savePointInfo = async () => {
    if (!isEmpty(pointName)) {
      setLoading(true);
      const data = {
        pointName,
        pointDetails,
      };
      try {
        const addToDb = await api.put(
          `${dbUrl}/points/${pointProps?._id}`,
          data
        );
        if (addToDb.status === 200) {
          toast.success("Point Info Updated");
        }
        setLoading(false);
        setReloadData(true);
      } catch (error) {
        setLoading(false);
        console.error("Error updating point info:", error.message);
      }
    } else {
      setLoading(false);
      toast.warning("Please Fill the Required Fields");
    }
  };
  return (
    <div className="pointsDetailsProperties">
      {loading ? <PreLoader size={80} color="var(--primary-color)" /> : ""}
      <div className={`pointsDetailsPropertiesContent `}>
        <div className="pointsDetailsPropertiesWrapper">
          <div className="pointsDetailsPropertiesGroup">
            <CustomFormInput
              required={true}
              id={"pointName"}
              label={"Point Name"}
              type={"text"}
              value={pointName}
              onChange={(e) => setPointName(e.target.value)}
            />
          </div>
          <div className="pointsDetailsPropertiesGroup">
            <CustomFormInput
              id={"pointDesc"}
              label={"Point Description"}
              type={"text"}
              value={pointDetails}
              onChange={(e) => setPointDetails(e.target.value)}
            />
          </div>
          {(pointProps?.pointType === "analogInput" ||
            pointProps?.pointType === "analogOutput" ||
            pointProps?.pointType === "HoldingRegister" ||
            pointProps?.pointType === "InputRegister" ||
            pointProps?.pointType === "analogValue") && (
            <CustomSearchableDropDown
              options={units}
              id={"units"}
              onChange={handleSave}
              placeholder="Search Units"
              selectedValue={selectedUnit}
              setSelectedValue={setSelectedUnit}
              label={"Point Unit"}
              disabled={permissions?.devices?.write ? false : true}
            />
          )}
          {pointProps?.networkType === "BacnetTCP" ? (
            <div className="pointsDetailsPropertiesGroup">
              <CustomFormInput
                id={"pointDataType"}
                label={"Point Data Type"}
                type={"text"}
                disabled={true}
                value={pointProps?.pointDataType.toLowerCase()}
              />
            </div>
          ) : (
            <div className="pointsDetailsPropertiesGroup">
              <CustomFormInput
                id={"pointDataType"}
                label={"Point Data Type"}
                type={"text"}
                disabled={true}
                value={`${pointProps?.pointDataType} (${
                  pointProps?.msw ? "msw" : "lsw"
                })`}
              />
            </div>
          )}

          <div className="pointsDetailsPropertiesGroup">
            <CustomFormInput
              id={"pointAddress"}
              label={"Point Address"}
              type={"text"}
              disabled={true}
              value={
                pointProps?.networkType === "BacnetTCP"
                  ? pointProps?.pointId
                  : pointAddress
              }
            />
          </div>

          <div className="pointsDetailsPropertiesGroup">
            <CustomFormInput
              id={"pointEnable"}
              label={"Point Poll Status"}
              type={"text"}
              disabled={true}
              value={
                pointProps?.pointLastPollStatus === "Failed" ? "Failed" : "OK"
              }
            />
          </div>

          <div className="pointsDetailsPropertiesGroup">
            <CustomFormInput
              id={"lastPollTime"}
              label={"Last Poll Time"}
              type={"text"}
              disabled={true}
              value={pointProps?.lastPollTime}
            />
          </div>
        </div>
      </div>

      {permissions.devices.write && (
        <div className="pointsDetailsPropertiesBtnGroup">
          <div className="filled-btn-style" onClick={savePointInfo}>
            Save
          </div>
        </div>
      )}
    </div>
  );
};

export default PointDetailsProperties;
