import React from "react";
import "../../../../../common/styles/displayDataConfigurationFormContentStyle.css";
import CustomButton from "../../../../reusableComponents/customButton";
import { useState } from "react";
import { useEffect } from "react";
import Slide from "@mui/material/Slide";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import CustomFormSelect from "../../../../reusableComponents/customFormSelect";
import { isEmpty } from "../../../../../common/utils/isEmpty";
import { toast } from "react-toastify";
import ColorPicker from "../../../../../common/utils/colorPicker";
import CustomFormMultiSelect from "../../../../reusableComponents/customFormMultiSelect";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";
const DisplayDataConfigurationForm = ({
  chartId,
  setConfigure,
  droppedItems,
  handleSave,
  configure,
  setSelectedWidget,
  onClick,
  setHoveredPin,
  setHovered,
  setWidgets,
  setSelectedFloorWidget,
  selectedWidget,
}) => {
  const [selectedItem] = useState(
    droppedItems?.find(
      (item) => item?.id === chartId || item?.id?.includes("energyTemplate")
    )?.data
  );
  const { config } = useMenu();
  const [title, setTitle] = useState(() => {
    if (selectedItem?.length > 0) {
      const matchingItem = selectedItem?.find((item) => item?.id === chartId);
      return matchingItem?.title || "";
    }
    return selectedItem?.title;
  });

  const [trendData, setTrendData] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [btnColor, setBtnColor] = useState();
  const [selectedDisplay, setSelectedDisplay] = useState({});
  const [pollFrequency, setPollFrequency] = useState("");
  const serverEndpoint = config?.REACT_APP_DB_ENDPOINT;
  const [imageData, setImageData] = useState(null);
  const [points, setPoints] = useState([]);
  const [startDate, setStartDate] = useState();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);
  const [endDate, setEndDate] = useState();
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [dateRange, setDateRange] = useState("1Day");
  const [kpi, setKpi] = useState("1Day");
  const [customKpiValue, setCustomKpiValue] = useState();
  const [pointType, setPointType] = useState("currentVal");
  const [enableWriteField, setEnableWriteField] = useState(false);
  const handleClose = () => {
    setConfigure((prev) => !prev);
    setSelectedWidget(null);
  };

  useEffect(() => {
    const fetchDashboardsFromDB = async () => {
      try {
        const getDashboards = await api.get(
          `${serverEndpoint}/displays/_all_docs`,
          {
            params: {
              include_docs: true,
            },
          }
        );
        if (getDashboards.status === 200) {
          setDashboards(getDashboards.data.data.rows.map((row) => row.doc));
        }
      } catch (error) {
        console.error("Error fetching dashboards");
      }
    };
    const fetchPoints = async () => {
      try {
        const pointsDatabase = "points";
        const networksResponse = await api.get(
          `${dbUrl}/${pointsDatabase}/_all_docs`,
          {
            params: {
              include_docs: true,
            },
          }
        );
        setPoints(networksResponse.data.data.rows.map((row) => row.doc));
      } catch (error) {
        console.log(
          `Error Occurred While fetching Output/Value Points: ${error}`
        );
      }
    };
    fetchPoints();
    const fetchTrendPoints = async () => {
      try {
        const couchdbUrl = dbUrl;
        const trendsDatabase = "trends";
        const networksResponse = await api.get(
          `${couchdbUrl}/${trendsDatabase}/_all_docs`,
          {
            params: {
              include_docs: true,
            },
          }
        );
        setTrendData(networksResponse.data.data.rows.map((row) => row.doc));
        const itemSelected = droppedItems?.find((item) => item?.id === chartId);
        if (itemSelected) {
          const {
            points,
            max,
            min,
            pollFrequency,
            pointType,
            dateRange,
            kpi,
            customKpiValue,
            enableWriteField,
          } = itemSelected?.data;
          setShowCustomRange(dateRange || kpi === "custom" ? true : false);
          setSelectedOptions(points);
          setDateRange(dateRange);
          setKpi(kpi);
          setPointType(pointType);
          setCustomKpiValue(customKpiValue);
          setStartDate(min);
          setEndDate(max);
          setPollFrequency(pollFrequency);
          setEnableWriteField(enableWriteField ? enableWriteField : false);
        } else {
          if (!chartId?.includes("Template")) {
            setSelectedOptions([]);
          }
          setPollFrequency();
        }
      } catch (error) {
        console.log("Enable Trend for points to view it here.");
      }
    };

    if (chartId.includes("button") || chartId.includes("hyperlink")) {
      const selectedItem = droppedItems?.find((item) => item?.id === chartId);
      if (selectedItem?.data) {
        const { display, buttonColor } = selectedItem?.data;
        setSelectedDisplay(display);
        setBtnColor(buttonColor);
      } else {
        setSelectedDisplay();
        setBtnColor();
      }
      fetchDashboardsFromDB();
    } else if (chartId.includes("image")) {
      const selectedItem = droppedItems?.find((item) => item?.id === chartId);
      if (selectedItem?.data) {
        const { imageUrl } = selectedItem?.data;
        setImageData(imageUrl);
      } else {
        setImageData();
      }
    } else if (chartId.includes("command")) {
      const selectedItem = droppedItems?.find((item) => item?.id === chartId);
      if (selectedItem?.data) {
        const { points } = selectedItem?.data;
        setSelectedOptions(points);
      } else {
        setSelectedOptions([]);
      }
    } else if (chartId?.includes("energyTemplate")) {
      fetchTrendPoints();
      const selectedTemplate = droppedItems?.find((item) =>
        item?.id?.includes("energyTemplate")
      );
      if (selectedTemplate?.data?.length > 0) {
        for (const widget of selectedTemplate?.data) {
          if (widget?.id === chartId) {
            // setTitle(widget?.title);
            setSelectedOptions(widget?.points);
          }
        }
      }
    } else {
      fetchTrendPoints();
    }
  }, [
    base64Credentials,
    chartId,
    dashboards.length,
    dbUrl,
    droppedItems,
    serverEndpoint,
    title,
  ]);

  const handleSelectedDate = (value) => {
    setDateRange(value);
    switch (value) {
      case "custom":
        setShowCustomRange(true);
        break;
      case "1Day":
        setShowCustomRange(false);
        break;
      case "2Days":
        setShowCustomRange(false);
        break;
      default:
        setShowCustomRange(false);
        break;
    }
  };
  const handleSelectedKpi = (value) => {
    setKpi(value);
    switch (value) {
      case "1Day":
        setShowCustomRange(false);
        break;
      case "1Week":
        setShowCustomRange(false);
        break;
      case "custom":
        setShowCustomRange(true);
        break;
      default:
        setShowCustomRange(false);
        break;
    }
  };
  const options = trendData.map((item) => ({
    value: item._id,
    label: `${item.deviceInstance}-${item.pointName}`,
  }));
  const pointOptions = points.map((item) => ({
    value: item._id,
    label: `${item.pointDeviceInstance}-${item.pointName}`,
    pointEnable: item.pointEnable,
  }));
  const allowedValues = [
    "analogValue",
    "analogOutput",
    "binaryValue",
    "binaryOutput",
    "HoldingRegister",
    "CoilStatus",
  ];

  const filteredOptions = pointOptions.filter((option) => {
    return allowedValues.some((value) => option?.value?.includes(value));
  });

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected.map((option) => option));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result;
        localStorage.setItem("uploadedImage", dataURL);
        setImageData(dataURL);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileBrowse = (e) => {
    e.preventDefault();
    const { files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result;
        localStorage.setItem("uploadedImage", dataURL);
        setImageData(dataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    localStorage.removeItem("uploadedImage");
    setHoveredPin({});
    setWidgets((prevWidgets) => {
      const updatedWidgets = { ...prevWidgets };
      delete updatedWidgets[chartId];
      return updatedWidgets;
    });
    setHovered(false);
    setSelectedFloorWidget({});
    setImageData(null);
  };

  const handlePointTypeChange = (newPointType) => {
    setSelectedOptions([]);
    setPointType(newPointType);
  };
  const dashboardsList = dashboards.map((display) => ({
    value: display.name,
    label: display.name,
  }));

  const sendChartData = async () => {
    if (chartId.includes("button") || chartId.includes("hyperlink")) {
      if (!isEmpty(selectedDisplay) && title) {
        const newData = {
          id: chartId,
          title: title,
          display: selectedDisplay,
          buttonColor: btnColor,
          enableWriteField: enableWriteField,
        };
        handleSave(chartId, newData);
        setConfigure(false);
      } else {
        toast.warning("Please Fill the Required Fields");
      }
    } else if (chartId.includes("image")) {
      const newData = {
        id: chartId,
        imageUrl: imageData,
        enableWriteField: enableWriteField,
      };
      handleSave(chartId, newData);
      setConfigure(false);
    } else if (chartId.includes("command")) {
      const newData = {
        id: chartId,
        title: `${selectedOptions[0].label}`,
        points: selectedOptions,
        enableWriteField: enableWriteField,
      };
      handleSave(chartId, newData);
      setConfigure(false);
    } else if (chartId.includes("switch")) {
      const newData = {
        id: chartId,
        title: title,
        pointType: pointType,
        points: selectedOptions,
        pollFrequency: pollFrequency,
        enableWriteField: enableWriteField,
      };
      handleSave(chartId, newData);
      setConfigure(false);
    } else if (chartId?.includes("energyTemplate")) {
      const typeMap = ["ec", "eu", "maxd", "ect", "te", "mindkw", "maxdkw"];
      const suffix = chartId.split("-").pop();
      const parentId = chartId.replace(/-\w+$/, "");
      const selectedTemplate = droppedItems?.find((item) =>
        item?.id?.includes("energyTemplate")
      );
      if (typeMap.includes(suffix)) {
        let updatedData;
        if (selectedTemplate?.data?.some((entry) => entry.type === suffix)) {
          updatedData = selectedTemplate?.data.map((entry) =>
            entry.type === suffix
              ? {
                  ...entry,
                  id: chartId,
                  points: selectedOptions,
                  title: title,
                }
              : entry
          );
        } else {
          updatedData = [
            ...(selectedTemplate?.data || []),
            {
              id: chartId,
              points: selectedOptions,
              title: title,
              type: suffix,
            },
          ];
        }
        handleSave(parentId, updatedData);
        setConfigure(false);
      } else {
        console.error("Invalid chartId suffix!");
      }
    } else {
      if (
        !isEmpty(kpi, dateRange) &&
        selectedOptions.length > 0 &&
        pointType &&
        pollFrequency &&
        (dateRange !== "custom" || (startDate && endDate)) &&
        (kpi !== "custom" || customKpiValue)
      ) {
        const newData = {
          id: chartId,
          title: title,
          pointType: pointType,
          points: selectedOptions,
          dateRange: dateRange,
          min: startDate,
          max: endDate,
          kpi: kpi,
          customKpiValue: customKpiValue,
          pollFrequency: pollFrequency,
          enableWriteField: enableWriteField,
        };
        handleSave(chartId, newData);
        setConfigure(false);
      } else if (chartId.includes("label")) {
        const newData = {
          id: chartId,
          title: title,
          pointType: pointType,
          points: selectedOptions,
          dateRange: dateRange,
          min: startDate,
          max: endDate,
          kpi: kpi,
          customKpiValue: customKpiValue,
          pollFrequency: pollFrequency,
          enableWriteField: enableWriteField,
        };
        handleSave(chartId, newData);
        toast.success("Configuration Saved");
        setConfigure(false);
      } else {
        toast.warning("Please Fill the Required fields");
      }
    }
  };
  return (
    <Slide
      direction={"left"}
      in={configure}
      mountOnEnter
      unmountOnExit
      onExited={() => {
        setConfigure(false);
      }}
    >
      <div
        className={`displayConfigurationForm container-style`}
        onClick={onClick}
      >
        <div className="displayDataConfigurationFormContentHeading">
          Configure Data
        </div>
        <div className="displayDataConfigurationFormContents ">
          {chartId.includes("button") || chartId.includes("hyperlink") ? (
            <>
              <CustomFormInput
                id="linkButton"
                label="Button/Link Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Enter the Title for Button/Link"
                required={true}
                type="text"
              />
              <CustomFormSelect
                id="selectDisplay"
                label="Select a Display"
                onChange={(e) => {
                  const selectedDisplayObj = dashboards.find(
                    (display) => display.name === e.target.value
                  );
                  setSelectedDisplay(selectedDisplayObj || {});
                }}
                value={
                  droppedItems?.data?.selectedDisplay
                    ? droppedItems?.data?.selectedDisplay?.name
                    : selectedDisplay?.name
                }
                options={[
                  {
                    value: "",
                    label: "Select a Display",
                  },
                  ...dashboardsList,
                ]}
                required={true}
              />
              {chartId.includes("button") ? (
                <ColorPicker
                  initialColor={btnColor}
                  onColorChange={setBtnColor}
                />
              ) : null}
            </>
          ) : chartId.includes("label") ? (
            <>
              <CustomFormInput
                id="label"
                label="Label"
                onChange={(e) => setTitle(e.target.value)}
                value={
                  droppedItems?.data?.title ? droppedItems?.data?.title : title
                }
                placeholder="Enter a label"
                type="text"
              />
            </>
          ) : chartId.includes("image") ? (
            <div
              className="displayDataConfigurationFormImageContainer"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              {imageData ? (
                <>
                  <img
                    src={imageData}
                    alt="Uploaded"
                    className="displayDataConfigurationFormImage"
                  />
                  <MdDelete
                    className="imageWidgetImageDeleteBtn"
                    onClick={handleDeleteImage}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="imageUploadInput" className="imageUploadBtn">
                    <div className="imageUploadImage">
                      <FaCloudUploadAlt />
                    </div>
                    <div className="imageDragUploadText">
                      Drag and Drop the Image
                    </div>
                    <div className="imageOr">(OR)</div>
                    <span className="">Browse Image</span>
                  </label>
                  <input
                    type="file"
                    id="imageUploadInput"
                    style={{ display: "none" }}
                    onChange={handleFileBrowse}
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                  />
                </>
              )}
            </div>
          ) : chartId.includes("command") ? (
            <CustomFormMultiSelect
              id={"selectPoints"}
              label={"Select Point"}
              max={1}
              options={filteredOptions}
              placeholder={"Select Max 1 Point"}
              selectedValues={
                droppedItems?.data?.selectedOptions
                  ? droppedItems?.data?.selectedOptions.label
                  : selectedOptions
              }
              required={true}
              onChange={handleSelectChange}
            />
          ) : chartId.includes("bar") ||
            chartId.includes("line") ||
            chartId.includes("pie") ? (
            <>
              <CustomFormInput
                id="title"
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Enter the Title for the Chart"
                type="text"
              />
              <CustomFormSelect
                id="selectType"
                label="Select Type"
                onChange={(e) => handlePointTypeChange(e.target.value)}
                value={pointType}
                options={[
                  {
                    value: "currentVal",
                    label: "Instantaneous Value",
                  },
                  {
                    value: "trendVal",
                    label: "Trend Value",
                  },
                ]}
                required={true}
              />
              <CustomFormMultiSelect
                id={"selectPoints"}
                label={"Select Points"}
                max={pointType === "trendVal" ? 1 : 5}
                options={pointType === "trendVal" ? options : pointOptions}
                placeholder={
                  pointType === "trendVal"
                    ? "Select Max 1 Point"
                    : "Select Max 5 Points"
                }
                selectedValues={selectedOptions}
                required={true}
                onChange={handleSelectChange}
              />
              {pointType === "currentVal" ? null : (
                <>
                  <CustomFormSelect
                    id="selectDateRanges"
                    label="Select Date Ranges"
                    onChange={(e) => handleSelectedDate(e.target.value)}
                    value={dateRange}
                    options={[
                      {
                        value: "",
                        label: "Select Date Ranges",
                      },
                      {
                        value: "1Day",
                        label: "Last 1 Day",
                      },
                      {
                        value: "2Day",
                        label: "Last 2 Days",
                      },
                      {
                        value: "custom",
                        label: "Custom Date Range",
                      },
                    ]}
                    required={true}
                  />

                  {dateRange === "custom" && (
                    <>
                      <CustomFormInput
                        id="startDate"
                        label="Start Date"
                        onChange={(e) => setStartDate(e.target.value)}
                        value={startDate}
                        placeholder="Select Start Date"
                        type="date"
                        required
                      />
                      <CustomFormInput
                        id="endDate"
                        label="End Date"
                        onChange={(e) => setEndDate(e.target.value)}
                        value={endDate}
                        placeholder="Select End Date"
                        type="date"
                        required
                      />
                    </>
                  )}
                </>
              )}
              <CustomFormInput
                id="pollFrequency"
                label="Poll Frequency (Seconds)"
                onChange={(e) => setPollFrequency(e.target.value)}
                value={pollFrequency}
                placeholder="Enter the Poll Frequency"
                type="number"
                required
              />
            </>
          ) : chartId.includes("kpi") ? (
            <>
              <CustomFormInput
                id="title"
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Enter the Title for the Chart"
                type="text"
              />
              <CustomFormMultiSelect
                id={"selectPoints"}
                label={"Select Point"}
                max={1}
                options={options}
                placeholder={"Select Max 1 Point"}
                selectedValues={
                  droppedItems?.data?.selectedOptions
                    ? droppedItems?.data?.selectedOptions.label
                    : selectedOptions
                }
                required={true}
                onChange={handleSelectChange}
              />

              {/* <CustomFormSelect
                id="selectPoint"
                label="Select Point"
                onChange={(e) => setSelectedOptions(e.target.value)}
                value={
                  droppedItems?.data?.selectedOptions
                    ? droppedItems?.data?.selectedOptions.label
                    : selectedOptions
                }
                options={[
                  {
                    value: "",
                    label: "Select a Point",
                  },
                  ...options,
                ]}
                required={true}
              /> */}
              <CustomFormSelect
                id="selectKpiValue"
                label="Select KPI Value"
                onChange={(e) => handleSelectedKpi(e.target.value)}
                value={kpi}
                options={[
                  {
                    value: "1Day",
                    label: "Average of 1 Day",
                  },
                  {
                    value: "1Week",
                    label: "Average of 1 Week",
                  },
                  {
                    value: "custom",
                    label: "Custom Value",
                  },
                ]}
                required={true}
              />

              {kpi === "custom" && (
                <>
                  <CustomFormInput
                    id="setCustomKpiValue"
                    label="Set Custom Kpi Value"
                    onChange={(e) => setCustomKpiValue(e.target.value)}
                    value={
                      droppedItems?.data?.customKpiValue
                        ? droppedItems?.data?.customKpiValue
                        : customKpiValue
                    }
                    placeholder="Enter a Kpi value"
                    type="number"
                    required
                  />
                </>
              )}
              <CustomFormInput
                id="pollFrequency"
                label="Poll Frequency (Seconds)"
                onChange={(e) => setPollFrequency(e.target.value)}
                value={pollFrequency}
                placeholder="Enter the Poll Frequency"
                type="number"
                required
              />
            </>
          ) : chartId.includes("table") ? (
            <>
              <CustomFormInput
                id="title"
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Enter the Title for the Chart"
                type="text"
              />
              <CustomFormMultiSelect
                id={"selectPoints"}
                label={"Select Points"}
                max={100}
                options={pointOptions}
                placeholder={"Select Points"}
                selectedValues={selectedOptions}
                required={true}
                onChange={handleSelectChange}
              />

              <CustomFormInput
                id="pollFrequency"
                label="Poll Frequency (Seconds)"
                onChange={(e) => setPollFrequency(e.target.value)}
                value={pollFrequency}
                placeholder="Enter the Poll Frequency"
                type="number"
                required
              />
              <CustomFormInput
                id="hideWrite"
                label="Hide Write Points Field"
                onChange={(e) => setEnableWriteField(e.target.checked)}
                checked={enableWriteField === true ? true : false}
                type="checkbox"
                required
              />
            </>
          ) : chartId.includes("tree") ||
            chartId?.includes("radar") ||
            chartId?.includes("radial") ||
            chartId?.includes("composed") ? (
            <>
              <CustomFormInput
                id="title"
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Enter the Title for the Chart"
                type="text"
              />
              <CustomFormMultiSelect
                id={"selectPoints"}
                label={"Select Points"}
                max={
                  chartId?.includes("radar") || chartId?.includes("radial")
                    ? 10
                    : chartId?.includes("composed")
                    ? 3
                    : 20
                }
                options={chartId?.includes("composed") ? options : pointOptions}
                placeholder={
                  chartId?.includes("radar") || chartId?.includes("radial")
                    ? "Select Max 10 Points"
                    : chartId?.includes("composed")
                    ? "Select Max 3 Points"
                    : "Select Max 20 Points"
                }
                selectedValues={selectedOptions}
                required={true}
                onChange={handleSelectChange}
              />

              <CustomFormInput
                id="pollFrequency"
                label="Poll Frequency (Seconds)"
                onChange={(e) => setPollFrequency(e.target.value)}
                value={pollFrequency}
                placeholder="Enter the Poll Frequency"
                type="number"
                required
              />
            </>
          ) : chartId?.includes("Template") ? (
            <>
              {chartId?.endsWith("-ec") && (
                <>
                  <CustomFormInput
                    id="title"
                    label="Title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    placeholder="Enter the Title for the Chart"
                    type="text"
                  />
                  <CustomFormMultiSelect
                    id={"selectPoints"}
                    label={"Select Point"}
                    max={1}
                    options={options}
                    placeholder={"Select Max 1 Point"}
                    selectedValues={selectedOptions}
                    required={true}
                    onChange={handleSelectChange}
                  />
                </>
              )}

              {chartId?.endsWith("-eu") && (
                <>
                  <CustomFormInput
                    id="title"
                    label="Title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    placeholder="Enter the Title for the Chart"
                    type="text"
                  />
                  <CustomFormMultiSelect
                    id={"selectPoints"}
                    label={"Select Point"}
                    max={1}
                    options={options}
                    placeholder={"Select Max 1 Point"}
                    selectedValues={selectedOptions}
                    required={true}
                    onChange={handleSelectChange}
                  />
                </>
              )}

              {chartId?.endsWith("-maxd") && (
                <>
                  <CustomFormInput
                    id="title"
                    label="Title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    placeholder="Enter the Title for the Chart"
                    type="text"
                  />
                  <CustomFormMultiSelect
                    id={"selectPoints"}
                    label={"Select Point"}
                    max={1}
                    options={options}
                    placeholder={"Select Max 1 Point"}
                    selectedValues={selectedOptions}
                    required={true}
                    onChange={handleSelectChange}
                  />
                </>
              )}

              {chartId?.endsWith("-ect") && (
                <>
                  <CustomFormInput
                    id="title"
                    label="Title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    placeholder="Enter the Title for the Chart"
                    type="text"
                  />
                  <CustomFormMultiSelect
                    id={"selectPoints"}
                    label={"Select Point"}
                    max={1}
                    options={options}
                    placeholder={"Select Max 1 Point"}
                    selectedValues={selectedOptions}
                    required={true}
                    onChange={handleSelectChange}
                  />
                </>
              )}

              {chartId?.endsWith("-te") && (
                <>
                  <CustomFormInput
                    id="title"
                    label="Title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    placeholder="Enter the Title for the Chart"
                    type="text"
                  />
                  <CustomFormMultiSelect
                    id={"selectPoints"}
                    label={"Select Point"}
                    max={1}
                    options={pointOptions}
                    placeholder={"Select Max 1 Point"}
                    selectedValues={selectedOptions}
                    required={true}
                    onChange={handleSelectChange}
                  />
                </>
              )}

              {chartId?.endsWith("-mindkw") && (
                <>
                  <CustomFormInput
                    id="title"
                    label="Title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    placeholder="Enter the Title for the Chart"
                    type="text"
                  />
                  <CustomFormMultiSelect
                    id={"selectPoints"}
                    label={"Select Point"}
                    max={1}
                    options={pointOptions}
                    placeholder={"Select Max 1 Point"}
                    selectedValues={selectedOptions}
                    required={true}
                    onChange={handleSelectChange}
                  />
                </>
              )}

              {chartId?.endsWith("-maxdkw") && (
                <>
                  <CustomFormInput
                    id="title"
                    label="Title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    placeholder="Enter the Title for the Chart"
                    type="text"
                  />
                  <CustomFormMultiSelect
                    id={"selectPoints"}
                    label={"Select Point"}
                    max={1}
                    options={pointOptions}
                    placeholder={"Select Max 1 Point"}
                    selectedValues={selectedOptions}
                    required={true}
                    onChange={handleSelectChange}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <CustomFormInput
                id="title"
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Enter the Title for the Chart"
                type="text"
              />
              <CustomFormMultiSelect
                id={"selectPoints"}
                label={"Select Point"}
                max={1}
                options={pointOptions}
                placeholder={"Select Max 1 Point"}
                selectedValues={selectedOptions}
                required={true}
                onChange={handleSelectChange}
              />

              <CustomFormInput
                id="pollFrequency"
                label="Poll Frequency (Seconds)"
                onChange={(e) => setPollFrequency(e.target.value)}
                value={
                  droppedItems?.data?.pollFrequency
                    ? droppedItems?.data?.pollFrequency
                    : pollFrequency
                }
                placeholder="Enter the Poll Frequency"
                type="number"
                required
              />
            </>
          )}
        </div>
        <div className="displayDataConfigurationFormButtons">
          <CustomButton
            buttonName="Close"
            className="outline-btn-style"
            handleClick={() => handleClose()}
          />
          <CustomButton
            buttonName="Save"
            className="filled-btn-style"
            handleClick={sendChartData}
          />
        </div>
      </div>
    </Slide>
  );
};

export default DisplayDataConfigurationForm;
