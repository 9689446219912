/* eslint-disable react-hooks/exhaustive-deps */
import { Slide } from "@mui/material";
import React from "react";
import { useState } from "react";
import CustomButton from "../../../../reusableComponents/customButton";
import DiscoveringGif from "../../../../../assets/discovering.gif";
import "../../../../../common/styles/addDeviceFormStyle.css";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useMenu } from "../../../../../common/utils/menuContext";
import formatCurrentDateTime from "../../../../../common/utils/formattedDateTime";
import { FiRefreshCcw } from "react-icons/fi";
import { IoIosSearch } from "react-icons/io";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import PreLoader from "../../../../reusableComponents/preLoader";
import AddDeviceManualForm from "./addDeviceManualForm";
import TruncateText from "../../../../../common/utils/truncateText";
import api from "../../../../../common/utils/axiosRequest";
import { v4 as uuid } from "uuid";
import getUser from "../../../../../configuration/localStorage/getUser";

const AddDeviceForm = ({ isFormOpen, setIsFormOpen, networkProps }) => {
  const user = getUser();
  const { setReloadData, config } = useMenu();
  const [isDiscovering, setIsDiscovering] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [devicesArray, setDevicesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dbUrl = config?.REACT_APP_POLLING_ENDPOINT;
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [search, setSearch] = useState();
  const [selectedOption, setSelectedOption] = useState("discover");
  const [deviceInfo, setDeviceInfo] = useState({
    _id: "",
    deviceId: "",
    deviceDetails: "Virtual Device for 3rd Party Points",
    deviceEnable: true,
    deviceInstance: "",
    deviceMacAddress: "",
    deviceName: "Virtual Device",
    deviceNetwork: 1,
    deviceStatus: "Active",
    deviceSubnet: "255.0.0.0",
    deviceType: "MQTT",
    lastPollStatus: "Success",
    lastPollTime: formatCurrentDateTime(),
    networkAddress: networkProps?.networkAddress,
    networkId: networkProps?._id,
    networkPort: networkProps?.networkPort,
    orgId: "",
    pollFrequency: 5,
    networkTopic: networkProps?.topic,
    isVirtual: true,
  });

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setIsFormOpen(false);
    }
  };
  const fetchDevices = async () => {
    setIsDiscovering(true);
    try {
      const response = await api.post(`${dbUrl}/discover_mqtt_devices`, {
        topic: networkProps?.topic,
        orgId: user?.orgId,
      });
      if (response.status === 200) {
        const devices = response.data.data;
        setDevicesArray(devices);
        setFilteredDevices(devices);
      }
    } catch (error) {
      console.error("Error fetching devices:", error);
    } finally {
      setIsDiscovering(false);
    }
  };
  const fetchBacnetDevices = async () => {
    console.log(networkProps?.networkType);
    setIsDiscovering(true);
    try {
      const response = await api.post(`${dbUrl}/discover_devices`, {
        networkAddress: networkProps?.networkAddress,
        networkPort: networkProps?.networkPort,
      });
      if (response.data.data.length > 0) {
        setDevicesArray(response.data.data);
        setFilteredDevices(response.data.data);
      }
      setIsDiscovering(false);
    } catch (error) {
      console.error("Error fetching devices:", error);
      setIsDiscovering(false);
    }
  };

  const generateUniqueId = () => {
    return parseInt(
      uuid()
        .replace(/[^0-9]/g, "")
        .slice(0, 5),
      10
    );
  };

  useEffect(() => {
    if (isFormOpen) {
      if (networkProps?.networkType === "BacnetTCP") {
        fetchBacnetDevices();
      } else {
        const deviceId = generateUniqueId();
        setDeviceInfo({
          ...deviceInfo,
          deviceId: deviceId,
          deviceInstance: deviceId,
          _id: `${deviceId}_${networkProps?.networkAddress}_${networkProps?.networkPort}`,
        });
        fetchDevices();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFormOpen]);

  const handleToggleDevice = (device) => {
    if (selectedDevices.includes(device)) {
      setSelectedDevices((prev) => prev.filter((item) => item !== device));
    } else {
      setSelectedDevices((prev) => [...prev, device]);
    }
  };

  const handleToggleSelectAll = () => {
    if (selectAll) {
      setSelectedDevices([]);
    } else {
      setSelectedDevices(filteredDevices);
    }
    setSelectAll((prev) => !prev);
  };

  async function addDevice() {
    try {
      setIsLoading(true);
      for (let j = 0; j < selectedDevices.length; j++) {
        const selectedDevice = selectedDevices[j];
        const updatedDevice = {
          ...selectedDevice,
          networkTopic: networkProps?.topic,
          deviceType: "MQTT",
          networkId: networkProps?._id,
        };
        try {
          const createDeviceResponse = await api.post(
            `${dbUrl}/devices`,
            updatedDevice
          );

          if (createDeviceResponse.status === 200) {
            await api.post(`${dbUrl}/add_id_to_mqtt`, {
              ids: selectedDevices.map((device) => device._id),
            });
            setReloadData(true);
            setIsLoading(false);
            setIsFormOpen((prev) => !prev);
          } else {
            console.error(
              "Device creation failed:",
              createDeviceResponse.status
            );
            toast.error("Device creation failed");
          }
        } catch (error) {
          console.log("Error creating device:", error);
        }
      }
      setReloadData(true);
      setIsLoading(false);
      toast.success("Devices Imported Successfully");
    } catch (error) {
      toast.error(
        "Error Importing Devices: Device Already Exists with the same Address"
      );
      setReloadData(true);
      setIsLoading(false);
      setIsFormOpen((prev) => !prev);
      console.log(error);
    }
  }

  async function addDataToDeviceArray() {
    try {
      const database = "networks";
      const documentId = networkProps?._id;
      const getResponse = await api.get(`${dbUrl}/${database}/${documentId}`);
      const existingDocument = getResponse.data.data;

      const updatedDevices = selectedDevices.map(
        ([deviceID, deviceInstance]) => ({
          deviceID,
          deviceInstance,
        })
      );

      for (let i = 0; i < existingDocument.devices.length; i++) {
        const device = existingDocument.devices[i];
        const foundIndex = updatedDevices.findIndex(
          (updatedDevice) =>
            updatedDevice.deviceID === device.deviceID &&
            updatedDevice.deviceInstance === device.deviceInstance
        );
        if (foundIndex !== -1) {
          device.someField = updatedDevices[foundIndex].someField;

          updatedDevices.splice(foundIndex, 1);
        }
      }
      existingDocument.devices.push(...updatedDevices);

      const putResponse = await api.put(
        `${dbUrl}/${database}/${documentId}`,
        existingDocument
      );
      setReloadData(true);
      if (putResponse.status === 200) {
        toast.success("Devices Imported Successfully");
      }
      for (const [deviceID, deviceInstance] of selectedDevices) {
        try {
          const response = await api.post(`${dbUrl}/get_device_details`, {
            deviceInstance: deviceInstance,
            deviceMac: deviceID,
            networkAddress: networkProps?.networkAddress,
            networkPort: networkProps?.networkPort,
          });
          const newDevice = {
            _id: `${deviceInstance}_${networkProps?.networkAddress}_${networkProps?.networkPort}`,
            deviceId: deviceInstance,
            deviceName: response.data.data.objectName,
            deviceDetails: response.data.data.objectDesc,
            deviceNetwork: networkProps?.networkInstance,
            deviceType: networkProps?.networkType,
            deviceStatus: "Active",
            deviceEnable: true,
            lastPollStatus: "Success",
            lastPollTime: formatCurrentDateTime(),
            devicePollFrequency: networkProps?.networkPollFrequency,
            deviceInstance: deviceInstance,
            deviceMacAddress: deviceID,
            networkAddress: networkProps?.networkAddress,
            deviceSubnet: networkProps?.networkSubnet,
            deviceGateway: networkProps?.networkGateway,
            networkId: networkProps?._id,
            networkPort: networkProps?.networkPort,
          };

          const createDocumentResponse = await api.post(
            `${dbUrl}/devices`,
            newDevice
          );
          if (createDocumentResponse.status === 200) {
            setReloadData(true);
            // toast.success(`New Device Created Successfully`);
          }
        } catch (error) {
          console.error("Error creating device:", error);
          console.error(
            "Error creating device, Possibly Devices Already Exists"
          );
        }
      }
    } catch (error) {
      toast.error("Error Importing Devices:", error);
      setReloadData(true);
    }
  }

  async function addVirtualDevice() {
    try {
      const createDevice = await api.post(`${dbUrl}/devices`, deviceInfo);
      if (createDevice?.status === 200) {
        toast.success(
          `Device Created Successfully with ID: ${deviceInfo?._id}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleSave = () => {
    setIsLoading(true);
    setIsFormOpen((prev) => !prev);
    if (networkProps?.networkType === "BacnetTCP") {
      addDataToDeviceArray();
      setIsFormOpen((prev) => !prev);
      setReloadData(true);
    } else if (networkProps?.networkType === "MQTT") {
      if (selectedOption === "virtual") {
        addVirtualDevice();
      } else {
        addDevice();
      }
    }

    setReloadData(true);
  };

  const handleClose = () => {
    setIsFormOpen((prev) => !prev);
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);

    const filtered = devicesArray.filter((device) =>
      `${device.deviceInstance}-${device.deviceName}`
        .toString()
        .includes(searchTerm)
    );
    console.log(filtered);
    setFilteredDevices(filtered);
  };

  return (
    <Slide direction={"left"} in={isFormOpen} mountOnEnter unmountOnExit>
      <div className={`addDeviceForm container-style`}>
        {isLoading && (
          <PreLoader
            size={80}
            color="var(--primary-color)"
            loadingText="Adding Points from Device! Please Wait!"
          />
        )}
        <div className="addPointsFormHeading">Add Devices</div>
        <div className="custom-tabs">
          <CustomButton
            className={`  ${
              selectedOption === "discover"
                ? "custom-tab-selected "
                : "custom-tab"
            }`}
            buttonName="Discover"
            handleClick={() => setSelectedOption("discover")}
          />
          <CustomButton
            className={`  ${
              selectedOption === "virtual"
                ? "custom-tab-selected"
                : "custom-tab"
            }`}
            buttonName="Virtual Device"
            handleClick={() => setSelectedOption("virtual")}
          />
        </div>
        {networkProps?.networkType === "BacnetTCP" ? (
          selectedOption === "discover" ? (
            <div className="addDeviceFormContents">
              {isDiscovering ? (
                <div className="addDeviceFormDiscoveringImg">
                  <img src={DiscoveringGif} alt="" />
                  Discovering Devices
                </div>
              ) : (
                <>
                  <div className="addDeviceFormContentsDeviceList">
                    <div className="addDeviceContentsDeviceListSearch input-style">
                      <input
                        type="text"
                        placeholder="Search Device ID"
                        onChange={(e) => handleSearch(e)}
                        value={search}
                      />
                      <IoIosSearch
                        size={24}
                        style={{ margin: "auto", marginRight: "10px" }}
                      />
                    </div>
                    <div className="addDeviceFormContentsDeviceListItem selectAll">
                      <input
                        type="checkbox"
                        id="select-all"
                        onChange={handleToggleSelectAll}
                        checked={selectAll}
                      />
                      <label htmlFor="select-all">Select All Devices</label>
                    </div>
                    <div
                      className="scanAgainBtn filled-btn-style"
                      onClick={() => {
                        networkProps?.networkType === "MQTT"
                          ? fetchDevices()
                          : fetchBacnetDevices();
                      }}
                    >
                      <FiRefreshCcw className="scanAgainIcon" />
                      <p>Discover</p>
                    </div>
                    <div className="addDeviceFormContentsDeviceListContents">
                      {Array.isArray(filteredDevices) ? (
                        filteredDevices.length !== 0 ? (
                          filteredDevices.map((device, index) => (
                            <div
                              key={device}
                              className="addDeviceFormContentsDeviceListItem"
                            >
                              <input
                                type="checkbox"
                                id={device}
                                onChange={() => handleToggleDevice(device)}
                                checked={selectedDevices.includes(device)}
                              />
                              <label htmlFor={device}>Device-{device[1]}</label>
                            </div>
                          ))
                        ) : (
                          <div>No devices found</div>
                        )
                      ) : (
                        <div>No devices found</div>
                      )}
                    </div>
                  </div>
                  <div className="addDeviceFormContentsBtns">
                    <CustomButton
                      buttonName="Add"
                      className="addDeviceFormContentsSaveBtn"
                      handleClick={handleSave}
                    />
                    <CustomButton
                      buttonName="Cancel"
                      className="addDeviceFormContentsCancelBtn"
                      handleClick={handleClose}
                    />
                  </div>
                </>
              )}
            </div>
          ) : (
            <AddDeviceManualForm
              networkProps={networkProps}
              handleClose={handleClose}
            />
          )
        ) : networkProps?.networkType === "MQTT" ? (
          selectedOption === "discover" ? (
            <div className="addDeviceFormContents">
              {isDiscovering ? (
                <div className="addDeviceFormDiscoveringImg">
                  <img src={DiscoveringGif} alt="" />
                  Discovering Devices
                </div>
              ) : (
                <>
                  <div className="addDeviceFormContentsDeviceList">
                    <div className="addDeviceContentsDeviceListSearch input-style">
                      <input
                        type="text"
                        placeholder="Search Device "
                        onChange={(e) => handleSearch(e)}
                        value={search}
                      />
                      <IoIosSearch
                        size={24}
                        style={{ margin: "auto", marginRight: "10px" }}
                      />
                    </div>
                    <div className="addDeviceFormContentsDeviceListItem selectAll">
                      <input
                        type="checkbox"
                        id="select-all"
                        onChange={handleToggleSelectAll}
                        checked={selectAll}
                      />
                      <label htmlFor="select-all">Select All Devices</label>
                    </div>
                    <div
                      className="scanAgainBtn filled-btn-style"
                      onClick={() => {
                        networkProps?.networkType === "MQTT"
                          ? fetchDevices()
                          : fetchBacnetDevices();
                      }}
                    >
                      <FiRefreshCcw className="scanAgainIcon" />
                      <p>Discover</p>
                    </div>
                    <div className="addDeviceFormContentsDeviceListContents">
                      {Array.isArray(filteredDevices) ? (
                        filteredDevices.length !== 0 ? (
                          filteredDevices.map((device, index) => (
                            <div
                              key={device._id}
                              className="addDeviceFormContentsDeviceListItem"
                            >
                              <input
                                type="checkbox"
                                id={device._id}
                                onChange={() => handleToggleDevice(device)}
                                checked={selectedDevices.includes(device)}
                              />
                              <label htmlFor={device._id}>
                                {TruncateText(
                                  `${device.deviceInstance}-${device.deviceName}`,
                                  20
                                )}
                              </label>
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              justifyContent: "center",
                              margin: "auto",
                              fontSize: "16px",
                              fontWeight: 700,
                            }}
                          >
                            No devices found
                          </div>
                        )
                      ) : (
                        <div
                          style={{
                            justifyContent: "center",
                            margin: "auto",
                            fontSize: "16px",
                            fontWeight: 700,
                          }}
                        >
                          No devices found
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="addDeviceFormContentsBtns">
                    <CustomButton
                      buttonName="Cancel"
                      className="outline-btn-style"
                      handleClick={handleClose}
                    />
                    <CustomButton
                      buttonName="Add"
                      className="filled-btn-style"
                      handleClick={handleSave}
                    />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="addDeviceFormContents">
              <CustomFormInput
                id={"deviceName"}
                label="Device Name"
                placeholder={"Enter the Device Name"}
                required={true}
                onChange={(e) =>
                  deviceInfo({ ...deviceInfo, deviceName: e.target.value })
                }
                value={deviceInfo.deviceName}
                type="text"
              />
              <CustomFormInput
                id={"deviceDetails"}
                label="Device Details"
                placeholder={"Enter the Device Details"}
                required={true}
                onChange={(e) =>
                  deviceInfo({ ...deviceInfo, deviceDetails: e.target.value })
                }
                value={deviceInfo.deviceDetails}
                type="text"
              />
              <CustomFormInput
                id={"deviceId"}
                label="Device Id"
                placeholder={"Device Id"}
                required={true}
                disabled
                value={deviceInfo.deviceInstance}
                type="text"
              />
              <div className="addDeviceFormContentsBtns">
                <CustomButton
                  buttonName="Cancel"
                  className="outline-btn-style"
                  handleClick={handleClose}
                />
                <CustomButton
                  buttonName="Add"
                  className="filled-btn-style"
                  handleClick={handleSave}
                />
              </div>
            </div>
          )
        ) : null}
      </div>
    </Slide>
  );
};

export default AddDeviceForm;
