import { useMenu } from "../../../../../common/utils/menuContext";
import { useState } from "react";

import "../../../../../common/styles/trendNavigatorStyle.css";
import { Tooltip } from "react-tooltip";
import TruncateText from "../../../../../common/utils/truncateText";
import { MdDelete } from "react-icons/md";
import getPermissions from "../../../../../configuration/localStorage/getPermissions";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomNavigator from "../../../../reusableComponents/customNavigator";
import api from "../../../../../common/utils/axiosRequest";

const TrendNavigator = ({
  handleItemClick,
  trendData,
  setSelectedItem,
  setSelectedOption,
  fetchTrendPoints,
  selectedTrendId,
}) => {
  const navigate = useNavigate();

  const { open, config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [permissions] = useState(getPermissions);

  const handleDeleteTrend = async (trendId, pointId) => {
    try {
      await api.delete(`${dbUrl}/trends/${trendId}`);
      setSelectedOption("");
      setSelectedItem(null);
      await api.put(`${dbUrl}/points/${pointId}`, { trendEnable: false });
      fetchTrendPoints();
      toast.success("Trend Deleted and Disabled Successfully");
      navigate("/trends");
    } catch (error) {
      console.log("Document Not Found");
      setSelectedOption("");
      setSelectedItem(null);
      fetchTrendPoints();
    }
  };
  const renderTrendList = () => {
    return (
      <>
        {trendData.map((trend) => (
          <li key={trend._id} onClick={() => handleItemClick(trend)}>
            <span
              className="trendName"
              data-tooltip-id="trendNavigatorToolTip"
              data-tooltip-content={trend.pointName}
            >
              <Link className="link" to={`/trends/${trend._id}`}>
                {TruncateText(`${trend.deviceInstance}-${trend.pointName}`, 20)}
              </Link>
            </span>
            {permissions.trends.write && (
              <MdDelete
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteTrend(trend._id, trend.trendId);
                }}
                style={{
                  cursor: "pointer",
                  color: "var(--white-color)",
                  margin: "auto",
                  padding: "3px",
                  borderRadius: "5px",
                  backgroundColor: "var(--light-red-color)",
                }}
              />
            )}
          </li>
        ))}
      </>
    );
  };

  return open ? (
    <>
      <CustomNavigator className="customTrendNavigator">
        <div className={`trendNavigator`}>
          <div className={`trendNavigatorHeading`}>
            <span>Trends Navigator</span>
          </div>
          <div className="trendNavigatorContent">{renderTrendList()}</div>
        </div>
      </CustomNavigator>
      <Tooltip
        place="right"
        className="tooltipStyle"
        id="trendNavigatorToolTip"
      />
    </>
  ) : null;
};

export default TrendNavigator;
