import CryptoJS from "crypto-js";

const SECRET_KEY = "thisisthesupersecretkeyofbacsys";

export const encryptEmail = (email) => {
  return CryptoJS.AES.encrypt(email, SECRET_KEY).toString();
};

export const decryptToken = (token) => {
  const bytes = CryptoJS.AES.decrypt(token, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const extractDetailsFromToken = (token) => {
  const decodedToken = decodeURIComponent(token);
  const decryptedPayload = decryptToken(decodedToken);
  const [email, orgId, orgName] = decryptedPayload.split("|");

  return { email, orgId, orgName };
};
