import React from "react";

import { useMenu } from "../../../../../common/utils/menuContext";
import { MdDelete } from "react-icons/md";
import TruncateText from "../../../../../common/utils/truncateText";
import { Tooltip } from "react-tooltip";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import CustomNavigator from "../../../../reusableComponents/customNavigator";

const ScheduleBrowser = ({
  handleNewSchedule,
  schedules,
  handleLoadSchedule,
  handleDeleteSchedule,
  selectedSchedule,
  permissions,
}) => {
  const { open } = useMenu();
  return (
    open && (
      <>
        <CustomNavigator>
          <div className={`scheduleBrowser `}>
            <div className={`scheduleBrowserHeader `}>
              <span>Schedule Browser</span>
              {permissions.schedules.write && (
                <FiPlus
                  className="addScheduleNetworksBtn"
                  data-tooltip-id="displayNavigatorToolTip"
                  data-tooltip-content="Add Schedule"
                  onClick={() => handleNewSchedule()}
                  size={20}
                />
              )}
            </div>

            <div className="scheduleBrowserContent">
              <div className="scheduleBrowserContentList">
                {schedules?.map((schedule) => (
                  <p>
                    <span
                      onClick={() =>
                        handleLoadSchedule(schedule._id, schedule.scheduleTitle)
                      }
                      data-tooltip-id="scheduleBrowserToolTip"
                      data-tooltip-content={schedule.scheduleTitle}
                    >
                      <Link
                        key={schedule._id}
                        to={`/schedule/${schedule._id}`}
                        className="link"
                      >
                        {TruncateText(schedule.scheduleTitle, 20)}
                      </Link>
                    </span>
                    {permissions.schedules.write && (
                      <MdDelete
                        onClick={() => handleDeleteSchedule(schedule._id)}
                        style={{
                          cursor: "pointer",
                          color: "var(--white-color)",
                          margin: "auto",
                          padding: "3px",
                          borderRadius: "5px",
                          backgroundColor: "var(--light-red-color)",
                        }}
                      />
                    )}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </CustomNavigator>
        <Tooltip
          place="right"
          className="tooltipStyle"
          id="scheduleBrowserToolTip"
        />
      </>
    )
  );
};

export default ScheduleBrowser;
