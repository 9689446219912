import React from "react";
import "../../../../../common/styles/roleStyle.css";

const Role = ({ roleName, roles, handleWritePermissionChange }) => {
  const role = roles.find((role) => role._id === roleName);

  if (!role) {
    return null;
  }

  const onWritePermissionChange = (property, permissionType, newValue) => {
    const updatedRole = {
      ...role,
      [property]: {
        ...role[property],
        [permissionType]: newValue,
      },
    };
    console.log(updatedRole);
    handleWritePermissionChange(roleName, updatedRole);
  };

  return (
    <div className="role">
      <div className="roleName">{roleName}</div>
      <div className="roleCheckBoxes">
        {Object.keys(role).map((key) => {
          if (key === "_id" || key === "_rev" || key === "settings") {
            return null;
          }
          return (
            <div className="roleReadWrite" key={key}>
              <input
                type="checkbox"
                checked={role[key].read}
                onChange={() =>
                  onWritePermissionChange(key, "read", !role[key].read)
                }
              />
              <input
                type="checkbox"
                checked={role[key].write}
                onChange={() =>
                  onWritePermissionChange(key, "write", !role[key].write)
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Role;
