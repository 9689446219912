/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import GaugeChart from "react-gauge-chart";
import "../../../../../../common/styles/guageChartStyle.css";
import { useState } from "react";
import { useEffect } from "react";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const GuageChart = ({ chart, selectedDevice }) => {
  const { config } = useMenu();
  // const [isLoading, setIsLoading] = useState(true);
  const [presentValue, setPresentValue] = useState();
  const [percentValue, setPercentValue] = useState();
  const [pointName, setPointName] = useState();
  const [pointUnitSymbol, setPointUnitSymbol] = useState();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const fetchLiveDeviceData = async () => {
    try {
      const pointName = chart?.data?.points;
      let pointId = "";

      const parentDeviceResponse = await api.get(
        `${dbUrl}/devices/${selectedDevice.parentId}`,
        {
          params: {
            points: true,
          },
        }
      );

      if (parentDeviceResponse.status === 200) {
        const device = parentDeviceResponse?.data?.data;

        if (!device) {
          console.log("Device not found in parent device data");
          // setIsLoading(false);
          setPresentValue([]);
          return;
        }

        const point = device.points.find((point) =>
          point?.objectName?.toLowerCase()?.includes(pointName.toLowerCase())
        );

        if (!point) {
          console.log("Point not found");
          // setIsLoading(false);
          setPresentValue([]);
          return;
        }

        pointId = point.pointId;
        const response = await api.get(`${dbUrl}/points/${pointId}`);
        const liveVal = response.data.data.pointPresentValue;

        setPresentValue(liveVal);
        setPointName(response.data.data.pointName);
        setPercentValue(liveVal?.toFixed(0));
        setPointUnitSymbol(
          response?.data?.data?.pointUnitSymbol
            ? response?.data?.data?.pointUnitSymbol
            : ""
        );
      } else if (parentDeviceResponse.status === 404) {
        return;
      }

      // setIsLoading(false);
    } catch (error) {
      console.log();
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    const intervalInSeconds = 5;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchLiveDeviceData();
    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart]);

  return (
    <div
      id="chartContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
        overflow: "scroll",
        height: "92%",
        margin: "auto",
      }}
    >
      {/* {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null} */}

      <span className="chartLabel">
        {chart?.data?.title
          ? chart?.data?.title
          : pointName
          ? pointName
          : "Analog/Binary Point"}
      </span>
      <GaugeChart
        nrOfLevels={5}
        arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
        colors={["#00C49F", "#FFBB28", "#FF8042", "#FF4F4F", "#D4397F"]}
        percent={`${presentValue ? `0.${percentValue}` : "0.0"}`}
        formatTextValue={(value) =>
          `${
            presentValue
              ? `${presentValue} ${pointUnitSymbol ? pointUnitSymbol : ""}`
              : "0"
          }`
        }
        textColor={"var(--font-color"}
        arcPadding={0.02}
        animate={false}
        className="guageChartChart"
      />
    </div>
  );
};

export default GuageChart;
