import React, { useState } from "react";
import "../../../../../common/styles/configurationSettingsStyle.css";

import ConfigurationSettingsMAIL from "./configurationSettingsMAIL.jsx";
import ConfigurationSettingsNavigator from "./configurationSettingsNavigator.jsx";
import MQTTCertificate from "./mqttCertificate.jsx";
import ConfigurationSettingsSMS from "./configurationSettingsSMS.jsx";
import CustomHeader from "../../../../reusableComponents/customHeader.jsx";

const ConfigurationSettings = () => {
  const [selectedOption, setSelectedOption] = useState("MQTT Certificate");
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  return (
    <div className={`configurationSettings`}>
      <CustomHeader heading={"Configurations"} />
      <div className="configurationSettingsContent container-style">
        <ConfigurationSettingsNavigator onOptionClick={handleOptionClick} />
        {selectedOption === "MQTT Certificate" && <MQTTCertificate />}
        {selectedOption === "MAIL" && <ConfigurationSettingsMAIL />}
        {selectedOption === "SMS" && <ConfigurationSettingsSMS />}
      </div>
    </div>
  );
};

export default ConfigurationSettings;
