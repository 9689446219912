/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  MarkerType,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "../../../../common/styles/rulesStyle.css";
import { useMenu } from "../../../../common/utils/menuContext";
import MiniWidgets from "../../display/presentation/components/miniWidgets";
import ConstantsBlock from "./components/constantsBlock";
import FunctionBlock from "./components/functionBlock";
import LogicBlock from "./components/logicBlock";
import MathBlock from "./components/mathBlock";
import ObjectBrowser from "./components/objectBrowser.jsx";
import RuleCanvas from "./components/ruleCanvas.jsx";
import RuleList from "./components/ruleList.jsx";
import nodeTypes from "./nodes/nodeTypes";
import edgeOptions from "./nodes/edgeOptions";
import { Slide } from "@mui/material";
import { toast } from "react-toastify";
import OperatorNodeForm from "./components/operatorNodeForm";
import PreLoader from "../../../reusableComponents/preLoader";
import getPermissions from "../../../../configuration/localStorage/getPermissions";
import formatCurrentDateTime from "../../../../common/utils/formattedDateTime";
import CustomFormInput from "../../../reusableComponents/customFormInput";
import api from "../../../../common/utils/axiosRequest.js";
const connectionLineStyle = {
  stroke: "var(--font-color)",
};

const Rules = () => {
  const [permissions] = useState(getPermissions);
  const { open, config } = useMenu();
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [draggedOperator, setDraggedOperator] = useState(null);
  const [draggedConstantLabel, setDraggedConstantLabel] = useState("");
  const [dragged4Operator, setdragged4Operator] = useState(null);
  const [dragged10Operator, setdragged10Operator] = useState(null);
  const [draggedOutput, setDraggedOutput] = useState(null);
  const [nodeCount, setNodeCount] = useState(0);
  const [nodeCounts, setNodeCounts] = useState({});
  const [expandedDevices, setExpandedDevices] = useState([]);
  const [isSavePopupOpen, setIsSavePopupOpen] = useState(false);
  const [constantValue, setConstantValue] = useState("");
  const [saveValue, setSaveValue] = useState("");
  const [constantType, setConstantType] = useState("");
  const [data, setData] = useState({});
  const [operatorNode, setOperatorNode] = useState(null);
  const [operatorNodeFormOpen, setOperatorNodeFormOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [savedRules, setSavedRules] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRule, setSelectedRule] = useState();
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [editingNodeId, setEditingNodeId] = useState(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [ruleState, setRuleState] = useState(false);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);
  const serverEndpoint = config?.REACT_APP_SERVER_ENDPOINT;

  const fetchRules = async () => {
    try {
      const couchdbUrl = dbUrl;
      const rulesDatabase = "rules";
      const rulesResponse = await api.get(
        `${couchdbUrl}/${rulesDatabase}/_all_docs`,
        {
          params: {
            include_docs: true,
          },
        }
      );
      const rules = rulesResponse.data.data.rows.map((row) => row.doc);
      setSavedRules(rules);
    } catch (error) {
      console.error("Error fetching Rules:", error.message);
    }
  };

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const couchdbUrl = dbUrl;
        const devicesDatabase = "devices";
        const devicesResponse = await api.get(
          `${couchdbUrl}/${devicesDatabase}/_all_docs`,
          {
            params: {
              include_docs: true,
            },
          }
        );
        const devices = devicesResponse.data.data.rows.map((row) => row.doc);

        const devicesMap = {};
        devices.forEach((device) => {
          devicesMap[`device${device.deviceInstance}`] = device;
        });

        setData(devicesMap);
      } catch (error) {
        console.error("Error fetching devices:", error.message);
      }
    };

    fetchDevices();
    fetchRules();
  }, [dbUrl, serverEndpoint, setSavedRules]);

  const onSave = async () => {
    setLoading(true);
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      const calculationData = {
        ruleName: selectedRule,
        networkAddress: "",
        networkPort: "",
        msw: "",
        lsw: "",
        dataType: "",
        objectInstance: "",
        nodes: [],
        edges: [],
        networkType: "",
        objectType: "",
      };

      const nodePromises = flow.nodes.map(async (node) => {
        let nodeData = node.data;
        console.log(node.type);
        if (node.type === "customConstantNode") {
          try {
            const idString = node.id;
            let digits = "";
            for (let i = 0; i < idString.length; i++) {
              let char = idString.charAt(i);
              if (!isNaN(char)) {
                digits += char;
              }
            }
            const data = {
              _id: node.id,
              pointName: `${node.data.type}.${parseInt(digits)}`,
              pointDetails: "Software Point",
              pointId: "",
              pointPollId: "",
              pointType:
                node.data.type === "Analog"
                  ? "analogValue"
                  : node.data.type === "Binary"
                  ? "binaryValue"
                  : "characterstringValue",
              pointInstance: parseInt(digits),
              pointDeviceInstance: 12345,
              pointDeviceMac: "",
              pointUnits: "noUnits",
              networkType: "BacnetTCP",
              networkInstance: 1,
              pointPollFrequency: 5,
              pointStatus: "Active",
              pointLastPollStatus: "Success",
              networkAddress: "",
              networkPort: "",
              pointEnable: true,
              lastPollTime: formatCurrentDateTime(),
              pointDataType: "",
              pointPresentValue: node.data.value,
            };
            const createPoint = await api.post(`${dbUrl}/points`, data, {
              headers: {
                Authorization: `Basic ${base64Credentials}`,
              },
            });
            if (createPoint.status === 200 || createPoint.status === 201) {
              console.log("Point Created");
            }
          } catch (error) {
            console.log(error);
          }
        } else if (
          node.id.includes("Value") ||
          node.id.includes("Output") ||
          node.id.includes("HoldingRegister") ||
          node.id.includes("CoilStatus")
        ) {
          console.log(nodeData.label);
          const fetchPoint = await api.get(
            `${dbUrl}/points/${nodeData.label}`,
            {
              headers: {
                Authorization: `Basic ${base64Credentials}`,
              },
            }
          );
          calculationData.networkAddress = fetchPoint.data.data.networkAddress;
          calculationData.networkPort = fetchPoint.data.data.networkPort;
          calculationData.dataType = fetchPoint.data.data?.pointDataType;
          calculationData.lsw = fetchPoint.data.data?.lsw;
          calculationData.msw = fetchPoint.data.data?.msw;
          calculationData.objectInstance = fetchPoint.data.data?.pointInstance;
          calculationData.networkType = fetchPoint.data.data?.networkType;
          calculationData.objectType = fetchPoint.data.data?.pointType;
        }
        calculationData.nodes.push({
          id: node.id,
          type: node.type,
          data: nodeData,
        });
      });
      await Promise.all(nodePromises);

      const ruleExists = savedRules.some((rule) => rule._id === selectedRule);
      if (ruleExists) {
        await api.post(`${serverEndpoint}/update_document`, {
          collection: "rules",
          document_id: selectedRule,
          updated_data: {
            data: flow,
            status: true,
            name: saveValue ? saveValue : "Untitled Rule",
          },
        });
        fetchRules();
        setSaveValue(saveValue ? saveValue : "Untitled Rule");
        setSelectedRule(selectedRule);
        toast.success(
          `Rule Updated as: ${saveValue ? saveValue : "Untitled Rule"}`
        );
      } else {
        const newRule = {
          name: saveValue ? saveValue : "Untitled Rule",
          data: flow,
          status: ruleState,
        };
        const createRule = await api.post(`${dbUrl}/rules`, newRule, {
          headers: {
            Authorization: `Basic ${base64Credentials}`,
          },
        });
        fetchRules();
        setRuleState(true);
        toast.success(
          `Rule Saved as: ${saveValue ? saveValue : "Untitled Rule"}`
        );
        setSelectedRule(createRule.data.data.id);
        calculationData.ruleName = createRule.data.data.id;
        setSaveValue(saveValue ? saveValue : "Untitled Rule");
      }
      setIsSavePopupOpen(false);

      flow.edges.forEach((edge) => {
        calculationData.edges.push({
          id: edge.id,
          source: edge.source,
          target: edge.target,
        });
      });
      // console.log(calculationData);
      api
        .post(`${serverEndpoint}/calculate`, calculationData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (
            response.data.data &&
            response.data.data.hasOwnProperty("result")
          ) {
            const result = response.data.data.result;
            console.log(result);
            if (result.length === 2) {
              console.log(result);
              const outputNodes = flow.nodes.filter(
                (node) => node.type === "customOutputNode"
              );
              console.log(outputNodes);
              if (outputNodes.length === 2) {
                outputNodes[0].data = {
                  ...outputNodes[0].data,
                  value: result[0],
                };
                outputNodes[1].data = {
                  ...outputNodes[1].data,
                  value: result[1],
                };
                setNodes([...nodes, ...outputNodes]);
              }
            } else {
              const outputNode = flow.nodes.find(
                (node) => node.type === "customOutputNode"
              );
              const pointOutput = flow.nodes.find(
                (node) =>
                  node.data.label.toLowerCase().includes("value") ||
                  node.data.label.toLowerCase().includes("output")
              );

              console.log(pointOutput);
              if (outputNode) {
                outputNode.data = {
                  ...outputNode.data,
                  value: result,
                };
                setNodes([...nodes, outputNode]);
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error sending data to the backend:", error);
        });
    }

    setLoading(false);
  };

  const deleteRule = async (rule) => {
    setConfirmDelete(false);
    try {
      const getRule = await api.get(`${dbUrl}/rules/${rule}`, {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });
      console.log(getRule.data.data);
      getRule.data.data.nodes.map(async (node) => {
        try {
          await api.post(`${serverEndpoint}/delete_document`, {
            collection: "points",
            document_id: node.id,
          });
        } catch (error) {
          console.error(error);
        }
      });
      const deleteRule = await api.post(`${serverEndpoint}/delete_document`, {
        collection: "rules",
        document_id: rule,
      });
      const deleteRuleThread = await api.post(`${serverEndpoint}/delete_rule`, {
        thread_id: rule,
      });
      if (deleteRule.status === 200 && deleteRuleThread.status === 200) {
        toast.success(`Rule:${getRule.data.data.name} Deleted Successfully`);
      }
      fetchRules();
      setNodes([]);
      setEdges([]);
      setSaveValue("");
      setSelectedRule();
      setNodeCount(0);
      setNodeCounts({});
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };

  const stopRule = async (rule) => {
    setLoading(true);
    try {
      const getRule = await api.get(`${dbUrl}/rules/${rule}`, {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });
      const data = {
        ...getRule.data.data,
        status: false,
      };
      await api.put(`${dbUrl}/rules/${rule}`, data, {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });
      const deleteRuleThread = await api.post(`${serverEndpoint}/delete_rule`, {
        thread_id: rule,
      });
      if (deleteRuleThread.status === 200) {
        fetchRules();
        toast.success(`Rule:${rule} Stopped Successfully`);
      }
      setLoading(false);
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };

  useEffect(() => {
    setRuleState(true);
  }, []);

  const logicsOnOff = (checked) => {
    if (checked) {
      onSave();
      setRuleState(true);
    } else {
      console.log(checked);
      setRuleState(false);
      stopRule(selectedRule);
    }
  };

  const createNewRule = () => {
    setNodes([]);
    setEdges([]);
    setSelectedRule();
    setSaveValue("");
  };
  const onRuleClick = (rule) => {
    const { data } = rule;
    setSaveValue(rule.name);
    setRuleState(rule.status);
    setSelectedRule(rule._id);
    const updatedNodes = (data.nodes || []).map((node) => {
      const updatedNode = { ...node };
      updatedNode.data.handleDuplicateClick = () =>
        handleDuplicateClick(updatedNode.id, updatedNode.data.type);
      updatedNode.data.handleEditClick = () => handleEditClick(updatedNode.id);

      return updatedNode;
    });

    setNodes(updatedNodes || []);
    // setNodes(data.nodes || []);
    setEdges(data.edges || []);
  };

  const openSavePopup = useCallback(() => {
    setIsSavePopupOpen(true);
  }, []);

  const closePopup = useCallback(() => {
    setConstantValue("");
    setDraggedConstantLabel("");
    setSaveValue("");
    setIsSavePopupOpen(false);
  }, []);

  const closeDataConfigPopup = useCallback(() => {
    setConstantValue("");
    setDraggedConstantLabel("");
    setIsSavePopupOpen(false);
  });

  // const handleConstantInputChange = (event) => {
  //   setConstantValue(event.target.value);
  // };
  const handleSaveInputChange = (event) => {
    setSaveValue(event.target.value);
  };

  const onNodeDoubleClick = useCallback(
    (event, node) => {
      event.preventDefault();

      console.log("Node double-clicked:", node);
      if (
        (node.type === "customOperatorNode" ||
          node.type === "custom4OperatorNode") &&
        (node.data.label === "ADEMUX2" ||
          node.data.label === "ASW" ||
          node.data.label === "BSW" ||
          node.data.label === "PID" ||
          node.data.label === "ASW4" ||
          node.data.label === "Limiter" ||
          node.data.label === "SRLatch" ||
          node.data.label === "Count" ||
          node.data.label === "Ramp" ||
          node.data.label === "TickTock")
      ) {
        setOperatorNode(node);
        setOperatorNodeFormOpen(true);
      }
    },
    [setContextMenu]
  );

  const addNodeValues = (operatorNode, data) => {
    let newNode;
    if (operatorNode && data) {
      if (operatorNode.data.label === "PID") {
        newNode = {
          ...operatorNode,
          data: {
            ...operatorNode.data,
            kp: data.kp,
            ki: data.ki,
            kd: data.kd,
            min: data.min,
            max: data.max,
          },
        };
        setNodes((prevNodes) => [...prevNodes, newNode]);
      } else if (
        operatorNode.data.label === "ADEMUX2" ||
        operatorNode.data.label === "ASW" ||
        operatorNode.data.label === "BSW"
      ) {
        newNode = {
          ...operatorNode,
          data: {
            ...operatorNode.data,
            selector: data.selector,
          },
        };
      } else if (operatorNode.data.label === "ASW4") {
        newNode = {
          ...operatorNode,
          data: {
            ...operatorNode.data,
            selectorValue: data.selectorValue,
            startsAt: data.startsAt,
          },
        };
      } else if (operatorNode.data.label === "Limiter") {
        newNode = {
          ...operatorNode,
          data: {
            ...operatorNode.data,
            highLimit: data.highLimit,
            lowLimit: data.lowLimit,
          },
        };
      } else if (operatorNode.data.label === "SRLatch") {
        newNode = {
          ...operatorNode,
          data: {
            ...operatorNode.data,
            reset: data.reset,
          },
        };
      } else if (operatorNode.data.label === "Count") {
        newNode = {
          ...operatorNode,
          data: {
            ...operatorNode.data,
            direction: data.direction,
            reset: data.reset,
          },
        };
      } else if (operatorNode.data.label === "Ramp") {
        newNode = {
          ...operatorNode,
          data: {
            ...operatorNode.data,
            min: data.min,
            max: data.max,
            rampPeriod: data.rampPeriod,
          },
        };
      } else if (operatorNode.data.label === "TickTock") {
        newNode = {
          ...operatorNode,
          data: {
            ...operatorNode.data,
            ticks: data.ticks,
          },
        };
      }

      setNodes((prevNodes) => [...prevNodes, newNode]);
    }
  };

  const onNodeContextMenu = useCallback(
    (event, node) => {
      event.preventDefault();
      const pane = reactFlowWrapper.current.getBoundingClientRect();
      const menuX = event.clientX - pane.left;
      const menuY = event.clientY - pane.top;
      // console.log(node);
      const deviceId = node?.id?.split("-")[0];
      setContextMenu({
        id: node.id,
        name: `${deviceId}-${node.data.pointName}`,
        top: menuY,
        left: menuX,
      });
    },
    [setContextMenu]
  );

  const onEdgeContextMenu = useCallback(
    (event, edge) => {
      event.preventDefault();
      const pane = reactFlowWrapper.current.getBoundingClientRect();
      const menuX = event.clientX - pane.left;
      const menuY = event.clientY - pane.top;
      setContextMenu({
        id: edge.id,
        name: "Edge",
        top: menuY,
        left: menuX,
      });
    },
    [reactFlowWrapper, setContextMenu]
  );

  const closeContextMenu = () => {
    setContextMenu(null);
  };
  const onPaneClick = useCallback(() => setContextMenu(null), [setContextMenu]);

  const handleEditClick = (nodeId) => {
    console.log(nodeId);
    setEditingNodeId(nodeId);
    setIsOverlayOpen(true);
    setIsEditPopupOpen(true);
  };

  const handleDuplicateClick = useCallback(
    (nodeId, constantType) => {
      const nodeIdParts = nodeId.split("-");
      const originalNodeId = nodeIdParts.slice(0, -1).join("-");

      if (!nodeCounts[originalNodeId]) {
        nodeCounts[originalNodeId] = 1;
      }
      nodeCounts[originalNodeId]++;
      const newCount = nodeCounts[originalNodeId];

      const newId = `${originalNodeId}-${newCount}`;
      const newLabel = `${constantType} Constant ${newCount}`;
      const handleDuplicateClickClosure = () => {
        handleDuplicateClick(newId, constantType);
      };

      const handleEditClickClosure = () => {
        handleEditClick(newId);
      };
      const duplicatedNode = {
        id: newId,
        type: "customConstantNode",
        position: { x: 0, y: 0 },
        data: {
          id: newId,
          value: 0,
          label: newLabel,
          type: constantType,
          handleDuplicateClick: handleDuplicateClickClosure,
          handleEditClick: handleEditClickClosure,
        },
      };
      setNodes((prevNodes) => [...prevNodes, duplicatedNode]);
    },
    [nodeCounts, setNodes]
  );

  const createCustomNodeWithConstant = useCallback(
    (position, constantType) => {
      if (editingNodeId) {
        console.log(editingNodeId);
        if (
          editingNodeId.includes("Binary") &&
          constantValue !== "0" &&
          constantValue !== "1"
        ) {
          toast.warning("Only 0 and 1 are allowed for a Binary Constant");
          return;
        }
        const updatedNodes = nodes.map((node) => {
          if (node.id === editingNodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                label: `${
                  draggedConstantLabel ? draggedConstantLabel : `Constant Node`
                } `,
                value: parseFloat(constantValue ? constantValue : 0),
              },
            };
          }
          return node;
        });
        setNodes(updatedNodes);
        setEditingNodeId(null);
        setIsOverlayOpen(false);
        setIsEditPopupOpen(false);
      } else {
        const newNodeId = `${constantType}-${nodeCount + 1}`;
        const handleDuplicateClickClosure = () => {
          handleDuplicateClick(newNodeId, constantType);
        };

        const handleEditClickClosure = () => {
          handleEditClick(newNodeId);
        };

        const customConstantNode = {
          id: newNodeId,
          type: "customConstantNode",
          position: position,
          data: {
            value: 0,
            label: `${constantType} Constant ${nodeCount + 1}`,
            type: constantType,
            handleDuplicateClick: handleDuplicateClickClosure,
            handleEditClick: handleEditClickClosure,
          },
        };
        setNodes((prevNodes) => [...prevNodes, customConstantNode]);
      }
      setConstantType("");
      setNodeCount(nodeCount + 1);
      closeDataConfigPopup();
    },
    [
      editingNodeId,
      nodeCount,
      closePopup,
      nodes,
      setNodes,
      constantValue,
      handleDuplicateClick,
    ]
  );
  const toggleDevice = (deviceId) => {
    if (expandedDevices.includes(deviceId)) {
      setExpandedDevices(expandedDevices.filter((id) => id !== deviceId));
    } else {
      setExpandedDevices([...expandedDevices, deviceId]);
    }
  };
  const clearNodes = () => {
    setNodes([]);
    setEdges([]);
    setNodeCount(0);
    setNodeCounts({});
  };
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (params) => {
      setNodes([...nodes]);
      setEdges((eds) => addEdge(params, eds));
    },
    [nodes, setNodes, setEdges]
  );
  const onDragConstantNodeStart = (event, label) => {
    setDraggedConstantLabel(label);
  };
  const onDragOperatorStart = (event, operatorType) => {
    setDraggedOperator(operatorType);
  };
  const onDrag4OperatorStart = (event, operatorType) => {
    setdragged4Operator(operatorType);
  };
  const onDrag10OperatorStart = (event, operatorType) => {
    setdragged10Operator(operatorType);
  };
  const onDragOutputStart = (event, output) => {
    setDraggedOutput(output);
  };
  const onDragStart = (event, objectName, device, pointName) => {
    console.log(device);
    const deviceData = data[`device${device.deviceInstance}`];
    console.log(deviceData);
    if (deviceData) {
      event.dataTransfer.setData("application/objectName", objectName);
      event.dataTransfer.setData("application/macAddress", device.deviceID);
      event.dataTransfer.setData("application/pointName", pointName);
      event.dataTransfer.effectAllowed = "move";
    } else {
      console.error("Device data not found in data.");
    }
  };
  const createCustomOperatorNode = (position, operatorType) => {
    const newNodeId = `${operatorType}-${nodeCount + 1}`;
    const operatorLabel = operatorType;
    const customOperatorNode = {
      id: newNodeId,
      type: "customOperatorNode",
      position: position,
      data: {
        label: operatorLabel,
        value: 0,
      },
    };

    setNodes([...nodes, customOperatorNode]);
    setNodeCount(nodeCount + 1);
  };

  const create4OperatorNode = (position, operatorType) => {
    const newNodeId = `${operatorType}-${nodeCount + 1}`;
    const operatorLabel = operatorType;
    const customOperatorNode = {
      id: newNodeId,
      type: "custom4OperatorNode",
      position: position,
      data: {
        label: operatorLabel,
        value: 0,
      },
    };

    setNodes([...nodes, customOperatorNode]);
    setNodeCount(nodeCount + 1);
  };

  const create10OperatorNode = (position, operatorType) => {
    const newNodeId = `${operatorType}-${nodeCount + 1}`;
    const operatorLabel = operatorType;
    const customOperatorNode = {
      id: newNodeId,
      type: "custom10OperatorNode",
      position: position,
      data: {
        label: operatorLabel,
        value: 0,
      },
    };

    setNodes([...nodes, customOperatorNode]);
    setNodeCount(nodeCount + 1);
  };
  let newOutputNodeId = "";
  const createCustomOutputNode = (position, output) => {
    newOutputNodeId = `output-${nodeCount + 1}`;
    const customOutputNode = {
      id: newOutputNodeId,
      type: "customOutputNode",
      position: position,
      data: {
        label: "Output",
        value: 0,
      },
    };

    setNodes([...nodes, customOutputNode]);
    setNodeCount(nodeCount + 1);
  };
  const createCustomNode = async (
    position,
    objectName,
    mac_address,
    pointName
  ) => {
    const newNodeId = `${objectName}-${mac_address}`;
    const label = objectName;
    const getPoint = await api.get(`${dbUrl}/points/${objectName}`, {
      headers: {
        Authorization: `Basic ${base64Credentials}`,
      },
    });
    const liveVal = getPoint.data.data.pointPresentValue;
    console.log(liveVal);
    const customNode = {
      id: newNodeId,
      type: "customNode",
      position: position,
      data: {
        value: liveVal,
        label: label,
        objectName,
        pointName,
      },
      markerEnd: {
        type: MarkerType.Arrow,
      },
      isSelected: false,
    };
    console.log(newNodeId);
    if (getPoint.status === 200) {
      const presentValue = getPoint.data.data.pointPresentValue;
      customNode.data.value = presentValue;
    } else {
      const liveVal = 0;
      const presentValue = liveVal;
      customNode.data.value = presentValue;
    }
    setNodes([...nodes, customNode]);
    setNodeCount(nodeCount + 1);
    setExpandedDevices([...expandedDevices, objectName]);
  };
  const onDrop = (e, mac_address) => {
    e.preventDefault();
    if (!reactFlowWrapper.current) {
      console.error("reactFlowWrapper ref is not assigned.");
      return;
    }

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const objectName = e.dataTransfer.getData("application/objectName");
    const macAddress = e.dataTransfer.getData("application/macAddress");
    const pointName = e.dataTransfer.getData("application/pointName");

    const position = reactFlowInstance.project({
      x: e.clientX - reactFlowBounds.left,
      y: e.clientY - reactFlowBounds.top,
    });
    console.log(draggedOperator);

    if (draggedOperator) {
      createCustomOperatorNode(position, draggedOperator);
      setDraggedOperator(null);
    } else if (draggedConstantLabel) {
      createCustomNodeWithConstant(position, draggedConstantLabel);
      setSaveValue(saveValue);
      setDraggedConstantLabel(null);
    } else if (dragged4Operator) {
      create4OperatorNode(position, dragged4Operator);
      setdragged4Operator(null);
    } else if (dragged10Operator) {
      create10OperatorNode(position, dragged10Operator);
      setdragged10Operator(null);
    } else if (draggedOutput) {
      createCustomOutputNode(position, draggedOutput);
      setDraggedOutput(null);
    } else if (objectName) {
      createCustomNode(position, objectName, macAddress, pointName);
    }
  };
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  return (
    <div className="rules">
      {open ? (
        <div className="rulesLeft">
          <RuleList
            ruleList={savedRules}
            onRuleClick={onRuleClick}
            deleteRule={deleteRule}
            setConfirmDelete={setConfirmDelete}
            confirmDelete={confirmDelete}
            createNewRule={createNewRule}
            permissions={permissions}
            selectedRule={selectedRule}
          />
          <ObjectBrowser
            onDragStart={onDragStart}
            toggleDevice={toggleDevice}
            data={data}
            expandedDevices={expandedDevices}
            permissions={permissions}
          />
        </div>
      ) : null}
      <RuleCanvas
        saveValue={saveValue}
        onSave={onSave}
        nodes={nodes}
        edges={edges}
        defaultEdgeOptions={edgeOptions}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={setReactFlowInstance}
        nodeTypes={nodeTypes}
        onDrop={onDrop}
        onDragOver={onDragOver}
        connectionLineStyle={connectionLineStyle}
        onPaneClick={onPaneClick}
        onNodeContextMenu={onNodeContextMenu}
        onEdgeContextMenu={onEdgeContextMenu}
        onNodeDoubleClick={onNodeDoubleClick}
        fitView
        clearNodes={clearNodes}
        openSavePopup={openSavePopup}
        reactFlowWrapper={reactFlowWrapper}
        contextMenu={contextMenu}
        nodeCount={nodeCount}
        closeContextMenu={closeContextMenu}
        permissions={permissions}
        logicsOnOff={logicsOnOff}
        setRuleState={setRuleState}
        ruleState={ruleState}
        selectedRule={selectedRule}
        setSaveValue={setSaveValue}
      />
      <MathBlock
        onDrag4OperatorStart={onDrag4OperatorStart}
        onDrag10OperatorStart={onDrag10OperatorStart}
        onDragOperatorStart={onDragOperatorStart}
      />
      <LogicBlock
        onDrag4OperatorStart={onDrag4OperatorStart}
        onDragOperatorStart={onDragOperatorStart}
      />
      <FunctionBlock onDragOperatorStart={onDragOperatorStart} />
      <ConstantsBlock
        onDragConstantNodeStart={onDragConstantNodeStart}
        onDragOutputStart={onDragOutputStart}
      />
      <MiniWidgets />
      <div className="rulesPopup">
        {loading ? <PreLoader size={80} color="var(--primary-color)" /> : ""}
        {isOverlayOpen && (
          <div className="overlay">
            <Slide
              direction={"left"}
              in={isEditPopupOpen}
              mountOnEnter
              onExited={() => {
                setIsOverlayOpen(false);
              }}
              unmountOnExit
            >
              <div className="rulesConstantPopup">
                <h3>{constantType} Constant</h3>
                <CustomFormInput
                  type="text"
                  label={"Constant Label"}
                  value={draggedConstantLabel}
                  onChange={(e) => setDraggedConstantLabel(e.target.value)}
                  placeholder="Enter Constant Label"
                />
                <CustomFormInput
                  type="text"
                  label={"Constant Value"}
                  value={constantValue}
                  onChange={(e) => setConstantValue(e.target.value)}
                  placeholder="Enter Constant Value"
                />
                <button
                  className="rulesPopupBtn save"
                  onClick={createCustomNodeWithConstant}
                >
                  Update
                </button>
                <button
                  className="rulesPopupBtn cancel"
                  onClick={() => {
                    setIsEditPopupOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Slide>
          </div>
        )}

        {isSavePopupOpen && (
          <div className="rulesSavePopup">
            <h3>Save Rule</h3>
            <input
              type="text"
              value={saveValue}
              onChange={handleSaveInputChange}
              placeholder="Enter Rule Name"
              required
            />
            <button
              onClick={() => {
                saveValue === ""
                  ? toast.warning("Please enter a rule name to save")
                  : onSave();
              }}
            >
              Save
            </button>
            <button onClick={closePopup}>Cancel</button>
          </div>
        )}
        {operatorNodeFormOpen && operatorNode && (
          <OperatorNodeForm
            operatorNodeFormOpen={operatorNodeFormOpen}
            setOperatorNodeFormOpen={setOperatorNodeFormOpen}
            operatorNode={operatorNode}
            addNodeValues={addNodeValues}
          />
        )}
      </div>
    </div>
  );
};

export default Rules;
