import React from "react";

import "../../../../../common/styles/usersTableStyle.css";

import CustomTable from "../../../../reusableComponents/customTable";

export default function UsersTable({
  userNames,
  roles,
  userId,
  status,
  lastAccess,
  handleUserEdit,
  handleUserDelete,
}) {
  if (!userNames || !roles || !status || !lastAccess || !userId) {
    return null;
  }

  if (
    userNames.length !== roles.length ||
    userNames.length !== status.length ||
    userNames.length !== lastAccess.length ||
    userNames.length !== userId.length
  ) {
    return null;
  }
  const rows = createData(userNames, roles, status, lastAccess, userId);

  function createData(userNames, roles, status, lastAccess, userId) {
    const data = [];
    for (let i = 0; i < userNames.length; i++) {
      data.push({
        userName: userNames[i],
        roles: roles[i],
        status: status[i],
        lastAccess: lastAccess[i],
        action: userId[i],
      });
    }
    return data;
  }

  const headings = [
    {
      id: "userName",
      label: "Full Name",
    },
    {
      id: "roles",
      label: "Role",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "lastAccess",
      label: "Last Access Time",
    },
    {
      id: "action",
      label: "Action",
    },
  ];
  return (
    <CustomTable
      data={rows}
      headings={headings}
      key={"usersTable"}
      totalRows={rows.length}
      totalPlaceholder={"Users"}
      action={true}
      deleteFunc={handleUserDelete}
      editFunc={handleUserEdit}
    />
  );
}
