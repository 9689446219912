import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, Text } from "recharts";
import PreLoader from "../../../../../reusableComponents/preLoader";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FFBA86"];

const PieCharts = ({ chart }) => {
  const { config } = useMenu();
  // const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const fetchLiveDeviceData = async () => {
    // setIsLoading(true);
    try {
      const couchdbUrl = dbUrl;
      const point = chart?.data?.points[0];
      const pointId = point.value;

      if (chart?.data?.pointType === "currentVal") {
        const fetchedData = await Promise.all(
          chart.data.points.map(async (point) => {
            const response = await api.get(
              `${couchdbUrl}/points/${point.value}`
            );
            return response.data.data;
          })
        );

        const data = fetchedData.map((value) => ({
          name: value.pointName,
          value: value.pointPresentValue,
        }));

        setData(data);
      } else if (chart?.data?.pointType === "trendVal") {
        let filteredData = [];
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        if (chart?.data?.dateRange === "1Day") {
          const currentDateString = new Date().toISOString().split("T")[0];
          const response = await api.get(`${couchdbUrl}/trends/${pointId}`);
          filteredData = response.data.data.trends.filter((doc) =>
            doc.timestamp.startsWith(currentDateString)
          );
        } else if (chart?.data?.dateRange === "2Days") {
          const twoDaysAgo = new Date(currentDate);
          twoDaysAgo.setDate(currentDate.getDate() - 2);
          const response = await api.get(`${couchdbUrl}/trends/${pointId}`);
          filteredData = response.data.data.trends.filter(
            (doc) => new Date(doc.timestamp) >= twoDaysAgo
          );
        } else if (chart?.data?.dateRange === "custom") {
          const startDate = new Date(chart.data.min);
          const endDate = new Date(chart.data.max);
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(23, 59, 59, 999);
          const response = await api.get(`${couchdbUrl}/trends/${pointId}`);
          filteredData = response.data.data.trends.filter((doc) => {
            const timestamp = new Date(doc.timestamp);
            return timestamp >= startDate && timestamp <= endDate;
          });
        }

        const trendData = filteredData.map((doc) => ({
          name: doc.timestamp,
          value: parseFloat(doc.presentValue.toFixed(2)),
        }));

        setData(trendData);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
    // setIsLoading(false);
  };

  useEffect(() => {
    const intervalInSeconds = chart?.data?.pollFrequency;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchLiveDeviceData();

    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart]);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <Text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {value}
      </Text>
    );
  };

  return (
    <>
      {/* {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null} */}
      <div
        id="chartContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
          overflow: "scroll",
          margin: "auto",
          height: "92%",
        }}
      >
        <span className="chartLabel">
          {chart?.data?.title
            ? chart?.data?.title
            : chart?.data?.pointType === "trendVal"
            ? chart?.data?.points[0]?.label
            : "Label"}
        </span>
        {data.length > 0 ? (
          <PieChart width={200} height={200}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              // label={
              //   chart?.data?.pointType === "trendVal"
              //     ? null
              //     : renderCustomizedLabel
              // }
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip
              contentStyle={{
                backgroundColor: "var(--tooltip-background-color)",
                border: "var(--container-border)",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              labelStyle={{
                fontSize: "var(--heading-font-size)",
                color: "var(--black-color)",
                fontWeight: "bold",
              }}
              itemStyle={{
                color: "var(--black-color)",
                fontWeight: "bold",
              }}
            />
            <Legend layout="vertical" align="right" verticalAlign="middle" />
          </PieChart>
        ) : (
          <p className="noData">No Data Available</p>
        )}
      </div>
    </>
  );
};

export default PieCharts;
