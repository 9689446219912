/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import CustomButton from "../../../../reusableComponents/customButton";
import { useState } from "react";
import { toast } from "react-toastify";
import PreLoader from "../../../../reusableComponents/preLoader";
import { useEffect } from "react";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";

const ConfigureSmtp = () => {
  const [senderMail, setSenderMail] = useState();
  const [appPassword, setAppPassword] = useState();
  const { config } = useMenu();
  const serverEndpoint = config?.REACT_APP_USER_ENDPOINT;
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSMTPConfiguration = async () => {
    try {
      const response = await api.get(`${dbUrl}/connections/smtp_con`, {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });
      const data = response.data.data;
      if (response.status === 200) {
        setSenderMail(data.senderMail);
        setAppPassword(data.appPassword);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchSMTPConfiguration();
  }, []);

  const handleSaveSMTPConfig = async () => {
    try {
      setIsLoading(true);
      const config = {
        senderMail: senderMail,
        appPassword: appPassword,
      };
      const configureSmtp = await api.post(
        `${serverEndpoint}/configure_smtp`,
        config
      );
      if (configureSmtp.data.error) {
        toast.error(configureSmtp.data.error);
        setIsLoading(false);
      } else {
        toast.success(configureSmtp.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error configuring SMTP:", error);
      toast.error(`An error occurred while configuring SMTP: ${error}`);
      setIsLoading(false);
    }
  };
  const handleSendTestMessage = async () => {
    try {
      setIsLoading(true);
      const sendTestMail = await api.get(`${serverEndpoint}/send_test_mail`);

      if (sendTestMail.data.error) {
        toast.error(sendTestMail.data.error);
        setIsLoading(false);
      } else {
        toast.success(sendTestMail.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      console.error(`An Unexpected Error Occurred: ${error}`);
      setIsLoading(false);
    }
  };
  return (
    <div className="configureNotifications">
      {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null}
      <div className="configurationNotificationsForm">
        <div className="configurationSmtpFormContent">
          <label htmlFor="mailId">Sender's Mail Id</label>
          <input
            type="text"
            id="mailId"
            placeholder="Enter Sender's Email Address"
            value={senderMail}
            onChange={(e) => setSenderMail(e.target.value)}
          />
          <label htmlFor="password">SMTP App Password</label>
          <div className="configureSmtpAppPasswordGroup">
            <input
              className="configureSmtpAppPassword"
              type="password"
              id="password"
              placeholder="Enter your 16 Character Password"
              value={appPassword}
              onChange={(e) => setAppPassword(e.target.value)}
            />
            <a
              target="_blank"
              href="https://support.google.com/accounts/answer/185833?hl=en"
              rel="noreferrer"
            >
              Get Password
            </a>
          </div>
        </div>
        <div className="configurationNotificationsFormBtns">
          <CustomButton
            className="configurationNotificationsFormBtn"
            buttonName="Save"
            handleClick={() => handleSaveSMTPConfig()}
          />
          <CustomButton
            className="configurationNotificationsFormBtn"
            buttonName="Send Test Mail"
            handleClick={() => handleSendTestMessage()}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigureSmtp;
