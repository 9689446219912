import React, { useState } from "react";
import "../../../../../../common/styles/imageWidgetStyle.css";
import PinChart from "./pinChart";
import ButtonPinChart from "./buttonPinChart";

const ImageWidget = ({
  chart,
  widgets,
  hovered,
  setHovered,
  setHoveredPin,
  hoveredPin,
  setSelectedFloorWidget,
  handleDragWidgetInsideCanvasStart,
  handleDragInsideCanvasEnd,
  handleDragInsideCanvas,
  handleDragOver,
  handleDrop,
  view,
  handleLoadDashboard,
  deleteWidget,
}) => {
  const [hoverPosition, setHoverPosition] = useState({});
  const handlePinHover = (e, widget) => {
    setHoveredPin(widget);
    setHovered(true);

    const imageContainer = e.target.closest(".floorImageWidget");
    const containerRect = imageContainer.getBoundingClientRect();
    const pinRect = e.target.getBoundingClientRect();

    const hoverDetailsWidth = 200; // Adjust width as necessary
    const hoverDetailsHeight = 100; // Adjust height as necessary
    const offset = 10;

    let hoverX, hoverY;
    if (pinRect.right + hoverDetailsWidth > containerRect.right) {
      hoverX = pinRect.left - containerRect.left - offset - hoverDetailsWidth;
    } else {
      hoverX = pinRect.right - containerRect.left + offset;
    }
    if (pinRect.bottom + hoverDetailsHeight > containerRect.bottom) {
      hoverY = pinRect.top - containerRect.top - offset - hoverDetailsHeight;
    } else {
      hoverY = pinRect.bottom - containerRect.top + offset;
    }
    setHoverPosition({ left: hoverX, top: hoverY });
  };

  return (
    <div className="imageWidget" id={chart?.id}>
      {chart?.data?.imageUrl ? (
        <div
          className="floorImageWidget"
          onDrop={view === "Edit" ? handleDrop : null}
          onDragOver={view === "Edit" ? handleDragOver : null}
        >
          <img
            src={chart?.data?.imageUrl}
            alt="Uploaded"
            className="imageWidgetImage"
          />

          <div className="widgetPinContainer">
            {widgets[chart?.id]?.map((widget) =>
              widget.type === "point" ? (
                <PinChart
                  key={widget.id}
                  handleDragWidgetInsideCanvasStart={
                    handleDragWidgetInsideCanvasStart
                  }
                  handleDragInsideCanvas={handleDragInsideCanvas}
                  handleDragOver={handleDragOver}
                  handleDragInsideCanvasEnd={handleDragInsideCanvasEnd}
                  handlePinHover={handlePinHover}
                  setHoveredPin={setHoveredPin}
                  setHovered={setHovered}
                  setSelectedFloorWidget={setSelectedFloorWidget}
                  widget={widget}
                  hoverPosition={hoverPosition}
                  hovered={hovered}
                  hoveredPin={hoveredPin}
                  view={view}
                />
              ) : (
                <ButtonPinChart
                  key={widget.id}
                  handleDragWidgetInsideCanvasStart={
                    handleDragWidgetInsideCanvasStart
                  }
                  handleDragInsideCanvas={handleDragInsideCanvas}
                  handleDragOver={handleDragOver}
                  handleDragInsideCanvasEnd={handleDragInsideCanvasEnd}
                  handlePinHover={handlePinHover}
                  setHoveredPin={setHoveredPin}
                  setHovered={setHovered}
                  setSelectedFloorWidget={setSelectedFloorWidget}
                  handleLoadDashboard={handleLoadDashboard}
                  widget={widget}
                  hoverPosition={hoverPosition}
                  hovered={hovered}
                  hoveredPin={hoveredPin}
                  view={view}
                  deleteWidget={deleteWidget}
                />
              )
            )}
          </div>
        </div>
      ) : (
        <p className="imageWidgetLabel">Image Displayed here</p>
      )}
    </div>
  );
};

export default ImageWidget;
