/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "../../../../../common/styles/deviceBrowserStyle.css";

import { useMenu } from "../../../../../common/utils/menuContext";
import { toast } from "react-toastify";
import PreLoader from "../../../../reusableComponents/preLoader";
import ContextMenu from "./contextMenu";
import { FiPlus } from "react-icons/fi";
import { FiRefreshCw } from "react-icons/fi";
import { Link, useParams, useNavigate } from "react-router-dom";
import CustomNavigator from "../../../../reusableComponents/customNavigator";
import api from "../../../../../common/utils/axiosRequest";

const DeviceBrowser = ({
  handleSelectedNetwork,
  handleSelectedDevice,
  handleSelectedPoint,
  handleOpen,
  permissions,
  setPointDetails,
  selectedItem,
  setSelectedItem,
}) => {
  const navigate = useNavigate();
  const { networkId, pointId, deviceId } = useParams();

  const { open, reloadData, setReloadData, config } = useMenu();
  const [dataBrowserData, setDataBrowserData] = useState([]);
  const [expandedNetworks, setExpandedNetworks] = useState([]);
  const [expandedDevices, setExpandedDevices] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPoints, setSelectedPoints] = useState([]);
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [loading, setLoading] = useState(false);

  const [rightClickSelected, setRightClickSelected] = useState(null);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [showContextMenu, setShowContextMenu] = useState(false);
  const contextMenuRef = useRef(null);

  useEffect(() => {
    const getRouteData = async () => {
      if (networkId) {
        setExpandedNetworks((prevState) => [...prevState, networkId]);
        handleSelectItem(networkId);
      } else if (deviceId) {
        const getDevice = await api.get(`${dbUrl}/devices/${deviceId}`);
        const networkId = getDevice.data.data.networkId;
        setExpandedNetworks((prevState) => [...prevState, networkId]);
        setExpandedDevices((prevState) => [...prevState, deviceId]);
        handleSelectItem(deviceId);
      } else if (pointId) {
        const getPoint = await api.get(`${dbUrl}/points/${pointId}`);
        const deviceId = getPoint?.data?.data?.deviceId;
        const networkId = getPoint?.data?.data?.networkId;

        setExpandedNetworks((prevState) => [...prevState, networkId]);
        setExpandedDevices((prevState) => [...prevState, deviceId]);
        handleSelectItem(pointId);
      }
    };
    getRouteData();
  }, [deviceId, networkId, pointId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target)
      ) {
        setShowContextMenu(false);
        setRightClickSelected(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleContextMenu = (event, item) => {
    event.preventDefault();
    const posX = event.clientX + window.pageXOffset;
    const posY = event.clientY + window.pageYOffset;
    setContextMenuPosition({ x: posX, y: posY });
    if (typeof item === "string") {
      setRightClickSelected(item);
    } else {
      setRightClickSelected(item);
    }
    setShowContextMenu(true);
  };

  const handleCloseContextMenu = () => {
    setShowContextMenu(false);
  };
  const handleSelectItem = (id) => {
    setSelectedItem(id);
  };

  const fetchNetworksAndDevices = async () => {
    // setLoading(true);
    try {
      const couchdbUrl = dbUrl;
      const networksDatabase = "networks";
      const devicesDatabase = "devices";
      const networksResponse = await api.get(
        `${couchdbUrl}/${networksDatabase}/_all_docs`,
        {
          params: {
            include_docs: true,
            devices: true,
          },
        }
      );

      const networks = networksResponse.data.data.rows.map((row) => row.doc);
      const devicesResponse = await api.get(
        `${couchdbUrl}/${devicesDatabase}/_all_docs`,
        {
          params: {
            include_docs: true,
            points: true,
          },
        }
      );

      const devices = devicesResponse.data.data.rows.map((row) => row.doc);
      const devicesMap = {};

      devices.forEach((device) => {
        devicesMap[device._id] = device;
      });
      networks.forEach((network) => {
        network.devices.forEach((device) => {
          const deviceInstance = device._id;
          if (devicesMap[deviceInstance]) {
            device.points = devicesMap[deviceInstance].points;
            device.deviceType = devicesMap[deviceInstance].deviceType;
            device.deviceDetails = devicesMap[deviceInstance];
            device.deviceName = devicesMap[deviceInstance].deviceName;
            device._id = devicesMap[deviceInstance]._id;
          }
        });
      });

      setReloadData(false);
      setDataBrowserData(networks);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching networks and devices:", error);
      setReloadData(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open || reloadData === true) {
      fetchNetworksAndDevices();
    } else {
      setReloadData(false);
    }
  }, [dbUrl, open, reloadData, setReloadData]);

  const toggleNetwork = (network) => {
    if (expandedNetworks.includes(network._id)) {
      setExpandedNetworks((prevState) =>
        prevState.filter((id) => id !== network._id)
      );
    } else {
      setExpandedNetworks((prevState) => [...prevState, network._id]);
    }
  };
  const toggleDevice = (device) => {
    if (expandedDevices.includes(device._id)) {
      setExpandedDevices((prevState) =>
        prevState.filter((id) => id !== device._id)
      );
    } else {
      setExpandedDevices((prevState) => [...prevState, device._id]);
    }
  };
  const togglePointSelection = (point, device, network) => {
    const pointKey = point.pointId;
    const isSelected = selectedPoints.includes(pointKey);

    if (isSelected) {
      setSelectedPoints((prevSelected) =>
        prevSelected.filter((selected) => selected !== pointKey)
      );
    } else {
      setSelectedPoints((prevSelected) => [...prevSelected, pointKey]);
    }
  };

  const handleDelete = async () => {
    try {
      const deletePoint = await api.delete(
        `${dbUrl}/points/${rightClickSelected}`
      );
      if (deletePoint.status === 200) {
        await api.post(`${dbUrl}/remove_id_from_mqtt`, {
          ids: [rightClickSelected],
        });
        setLoading(false);
        setShowContextMenu(false);
        setPointDetails(false);
        toast.success("Point Deleted");
        fetchNetworksAndDevices();
        setReloadData(true);
        navigate("/devices");
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Error deleting point: ${error}`);
      setShowContextMenu(false);
      setReloadData(true);
    }
  };

  return open ? (
    <>
      <CustomNavigator>
        <div className={`deviceBrowser `}>
          {loading ? (
            <PreLoader size={80} color="var(--primary-color)" />
          ) : null}
          <div className={`deviceBrowserHeader `}>
            <span>Device Browser</span>
            <span className="deviceBrowserHeaderBtns">
              {permissions.devices.write && (
                <FiPlus
                  className="addNetworksBtn"
                  data-tooltip-id="tooltipStyle"
                  data-tooltip-content="Add Network"
                  onClick={() => handleOpen()}
                />
              )}
              <FiRefreshCw
                data-tooltip-id="tooltipStyle"
                data-tooltip-content="Refresh List"
                className="addNetworksBtn"
                onClick={() => setReloadData(true)}
              />
            </span>
          </div>

          <div className="deviceBrowserContent">
            <ul className="deviceBrowserNetworks">
              {dataBrowserData.map((network) => (
                <li className="objectBrowserList" key={network._id}>
                  <div
                    className={`deviceBrowserNetworkhead ${
                      selectedItem === network._id ? "selected" : ""
                    }`}
                  >
                    {expandedNetworks.includes(network._id) ? (
                      <FaChevronDown
                        className="expandCollapseIcon"
                        onClick={() => toggleNetwork(network)}
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          fontSize: "smaller",
                        }}
                      />
                    ) : (
                      <FaChevronRight
                        className="expandCollapseIcon"
                        onClick={() => toggleNetwork(network)}
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      />
                    )}
                    <Link
                      to={`/devices/network/${network._id}`}
                      className="link"
                    >
                      <span
                        onClick={() => {
                          handleSelectedNetwork(network);
                          handleSelectItem(network._id);
                        }}
                      >
                        {network.networkName}
                      </span>
                    </Link>
                  </div>
                  {expandedNetworks.includes(network._id) && (
                    <ul className="deviceBrowserDevices">
                      {network.devices.map((device) => (
                        <li
                          className="deviceBrowserDeviceList"
                          key={device._id}
                        >
                          <div
                            className={`deviceBrowserhead ${
                              selectedItem === device._id ||
                              rightClickSelected === device._id
                                ? "selected"
                                : ""
                            }`}
                          >
                            {expandedDevices.includes(device._id) ? (
                              <FaChevronDown
                                className="expandCollapseIcon"
                                size={13}
                                onClick={() => toggleDevice(device)}
                                style={{
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                  fontSize: "smaller",
                                }}
                              />
                            ) : (
                              <FaChevronRight
                                className="expandCollapseIcon"
                                size={13}
                                onClick={() => toggleDevice(device)}
                                style={{
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              />
                            )}
                            <Link
                              to={`/devices/device/${device._id}`}
                              className="link"
                            >
                              <span
                                data-tooltip-id="tooltipStyle"
                                data-tooltip-content={`${
                                  device?.deviceName
                                    ? `${device?.deviceName}-${device?.deviceInstance}`
                                    : `${device?.deviceInstance}`
                                }`}
                                onClick={() => {
                                  handleSelectedDevice(device, network);
                                  handleSelectItem(device._id);
                                }}
                                // onContextMenu={(event) =>
                                //   handleContextMenu(
                                //     event,
                                //     parseInt(device.deviceInstance)
                                //   )
                                // }
                              >
                                {device.deviceName
                                  ? device.deviceName
                                  : `${device.deviceInstance}`}
                              </span>
                            </Link>
                          </div>
                          {expandedDevices.includes(device._id) &&
                            device.points && (
                              <ul className="deviceBrowserPoints">
                                {device.points.map((point, index) => (
                                  <li
                                    className="deviceBrowserPointList"
                                    key={index}
                                    onContextMenu={(event) =>
                                      handleContextMenu(
                                        event,
                                        point.pointId.toString(),
                                        true
                                      )
                                    }
                                  >
                                    {isEditMode && (
                                      <input
                                        type="checkbox"
                                        checked={selectedPoints.includes(
                                          point.pointId
                                        )}
                                        onChange={() =>
                                          togglePointSelection(
                                            point,
                                            device,
                                            network
                                          )
                                        }
                                      />
                                    )}
                                    <Link
                                      to={`/devices/point/${point.pointId}`}
                                      className="link"
                                    >
                                      <span
                                        className={
                                          selectedItem === point.pointId ||
                                          rightClickSelected === point.pointId
                                            ? "selected"
                                            : ""
                                        }
                                        data-tooltip-id="tooltipStyle"
                                        data-tooltip-content={point.objectName}
                                        onClick={() => {
                                          handleSelectedPoint(
                                            point,
                                            device,
                                            network
                                          );
                                          handleSelectItem(point.pointId);
                                        }}
                                      >
                                        {point.objectName}
                                      </span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div ref={contextMenuRef}>
            {showContextMenu && (
              <ContextMenu
                items={[
                  {
                    label: "Delete",
                    action: () => handleDelete(),
                  },
                ]}
                position={{
                  top: contextMenuPosition.y - 80,
                  left: contextMenuPosition.x - 180,
                }}
                onClose={handleCloseContextMenu}
              />
            )}
          </div>
        </div>
      </CustomNavigator>
      <Tooltip
        place="right"
        className="tooltipStyle"
        id="tooltipStyle"
        noArrow={true}
        // wrapper="span"
        style={{ zIndex: 3000 }}
      />
    </>
  ) : null;
};

export default DeviceBrowser;
