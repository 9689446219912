import React, { useState } from "react";
import ScheduleReports from "./scheduleReports";
import makeAnimated from "react-select/animated";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import CustomFormSelect from "../../../../reusableComponents/customFormSelect";
import Select from "react-select";
import { useEffect } from "react";
import MatrixReportChart from "./matrixReportChart";
import { toast } from "react-toastify";
import getPermissions from "../../../../../configuration/localStorage/getPermissions";
import CustomHeader from "../../../../reusableComponents/customHeader";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";

const MatrixReport = ({
  setReportData,
  reportData,
  handleSaveReport,
  selectedReportTemplate,
  deviceOptions,
  pointOptions,
  handleSelectChange,
}) => {
  const [selectedOption, setSelectedOption] = useState("Configuration");
  const { config } = useMenu();
  const animatedComponents = makeAnimated();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [permissions] = useState(getPermissions);

  useEffect(() => {
    setColumns(reportData?.generatedReport?.columns);
    setRows(reportData?.generatedReport?.rows);
  }, [
    columns,
    reportData?.generatedReport?.columns,
    reportData?.generatedReport?.rows,
    rows,
  ]);

  const downloadCSV = () => {
    if (!rows || rows.length === 0) {
      toast.error("No data available to export");
      return;
    }

    const headers = ["Points", ...columns.map((column) => column.label)];
    const devicesHeader = ["Devices", ...columns.map(() => "")];
    const data = [
      headers.join(","),
      devicesHeader.join(","),
      ...rows.map((row) =>
        [
          row.deviceId,
          ...columns.map((column) =>
            row[column.id] !== undefined ? row[column.id] : "N/A"
          ),
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "matrix_report.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const generateReport = async () => {
    try {
      const res = await api.post(`${dbUrl}/generate_report`, {
        ...reportData,
        reportTemplate: selectedReportTemplate,
      });
      if (res.status === 200) {
        toast.success("Report Generated Successfully");
        setColumns(res.data.columns);
        setRows(res.data.rows);
        setSelectedOption("Reports");
        setReportData({
          ...reportData,
          generatedReport: res.data,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="reportDialog">
      <CustomHeader heading={"Matrix Report"} />
      <div className="reportDialogContent container-style">
        <div className="custom-tabs">
          <span
            onClick={() => setSelectedOption("Configuration")}
            className={`${
              selectedOption === "Configuration"
                ? "custom-tab-selected"
                : "custom-tab"
            }`}
          >
            Configuration
          </span>
          <span
            onClick={() => setSelectedOption("Schedule")}
            className={`${
              selectedOption === "Schedule"
                ? "custom-tab-selected"
                : "custom-tab"
            }`}
          >
            Schedule
          </span>
          <span
            onClick={() => setSelectedOption("Reports")}
            className={`${
              selectedOption === "Reports"
                ? "custom-tab-selected"
                : "custom-tab"
            }`}
          >
            Generated Report
          </span>
        </div>
        <div className="reportDialogContentGenerate">
          {selectedOption === "Configuration" ? (
            <div className="reportDialogForm">
              <div className="reportDialogFormContents">
                <div className="MatrixReportDialogFormLeft">
                  <div
                    className="matrixReportDialogFormHeading"
                    style={{ marginLeft: "15%" }}
                  >
                    Report Details
                  </div>
                  <div className="matrixReportDialogFormContent">
                    <div
                      className="matrixReportDialogFormContentLeft"
                      style={{ marginLeft: "20%" }}
                    >
                      <CustomFormInput
                        label="Report Name"
                        id="reportName"
                        type="text"
                        value={reportData.reportName}
                        onChange={(e) =>
                          setReportData({
                            ...reportData,
                            reportName: e.target.value,
                          })
                        }
                        placeholder="Enter Report Name"
                      />
                      <CustomFormInput
                        label="Report Description"
                        id="reportDesc"
                        type="text"
                        value={reportData.reportDesc}
                        onChange={(e) =>
                          setReportData({
                            ...reportData,
                            reportDesc: e.target.value,
                          })
                        }
                        placeholder="Enter Report Description"
                      />

                      <CustomFormSelect
                        id={"interval"}
                        label="Interval"
                        value={reportData.interval}
                        onChange={(e) =>
                          setReportData({
                            ...reportData,
                            interval: e.target.value,
                          })
                        }
                        options={[
                          {
                            label: "Last 1 Hour",
                            value: "1hour",
                          },
                          {
                            label: "Last 1 Day",
                            value: "1day",
                          },
                          {
                            label: "Last 1 Week",
                            value: "1week",
                          },
                          {
                            label: "Last 1 Month",
                            value: "1month",
                          },
                          {
                            label: "Custom Range",
                            value: "custom",
                          },
                        ]}
                      />
                    </div>
                    <div className="reportDialogFormContentRight">
                      {reportData.interval === "custom" && (
                        <>
                          <CustomFormInput
                            label="Start Date"
                            id="startDate"
                            type="date"
                            value={reportData.startDate}
                            onChange={(e) =>
                              setReportData({
                                ...reportData,
                                startDate: e.target.value,
                              })
                            }
                          />
                          <CustomFormInput
                            label="End Date"
                            id="endDate"
                            type="date"
                            value={reportData.endDate}
                            onChange={(e) =>
                              setReportData({
                                ...reportData,
                                endDate: e.target.value,
                              })
                            }
                          />
                          <CustomFormInput
                            label="Start Time"
                            id="timeInterval"
                            type="time"
                            value={reportData.startTime}
                            onChange={(e) =>
                              setReportData({
                                ...reportData,
                                startTime: e.target.value,
                              })
                            }
                          />
                          <CustomFormInput
                            label="End Time"
                            id="timeInterval"
                            type="time"
                            value={reportData.endTime}
                            onChange={(e) =>
                              setReportData({
                                ...reportData,
                                endTime: e.target.value,
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="matrixReportDialogFormRight">
                  <div className="reportDialogFormRightHeader">
                    <div className="enableDisableCheck">
                      <input
                        type="checkbox"
                        id="enableDisable"
                        name="enableDisable"
                      />
                    </div>
                    <div className="filterReportDialogFormHeader">Filters</div>
                  </div>
                  <div className="reportDialogRightFormContent">
                    <div className="xAxisConfig">
                      <div className="reportDialogFormContentInputGroup">
                        <label htmlFor="network">Select Devices</label>
                        <Select
                          className="reportDialogFormContentSelect"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti={true}
                          onChange={(selected) =>
                            handleSelectChange(
                              selected,
                              "selectedDevices",
                              deviceOptions
                            )
                          }
                          options={deviceOptions}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                          value={deviceOptions.filter((option) =>
                            reportData?.selectedDevices?.includes(option.value)
                          )}
                        />
                      </div>
                    </div>
                    <div className="yAxisConfig">
                      <div className="reportDialogFormContentInputGroup">
                        <label htmlFor="points">Select Points</label>
                        <Select
                          className="reportDialogFormContentSelect"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti={true}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                          onChange={(selected) =>
                            handleSelectChange(
                              selected,
                              "selectedPoints",
                              pointOptions
                            )
                          }
                          options={pointOptions}
                          value={pointOptions.filter((option) =>
                            reportData?.selectedPoints?.includes(option.value)
                          )}
                        />
                      </div>

                      {/* <div className="reportDialogFormContentInputGroup">
                      <label htmlFor="network">Select Tags</label>
                      <Select
                        className="reportDialogFormContentSelect"
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={networkOptions}
                      />
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : selectedOption === "Schedule" ? (
            <div className="reportDialogForm">
              <ScheduleReports
                reportData={reportData}
                setReportData={setReportData}
              />
            </div>
          ) : selectedOption === "Reports" ? (
            <div className="reportDialogForm">
              <MatrixReportChart rows={rows} columns={columns} />
            </div>
          ) : null}
          <div className="reportDialogFormBtns">
            {permissions.reports.write && (
              <>
                <span
                  onClick={() => handleSaveReport()}
                  className="filled-btn-style"
                >
                  Save
                </span>
                <span
                  onClick={() => generateReport()}
                  className="filled-btn-style"
                >
                  Generate
                </span>
              </>
            )}
            <span onClick={downloadCSV} className="filled-btn-style">
              Export
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatrixReport;
