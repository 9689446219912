/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import "../../../../../../common/styles/numericChartStyles.css";
import { useState } from "react";
import "../../../../../../common/styles/energyTemplate.css";
import LineTemplateChart from "./charts/lineTemplateChart";
import BarTemplateChart from "./charts/barTemplateChart";
import { FaBoltLightning } from "react-icons/fa6";
import VerticalBarTemplateChart from "./charts/verticalBarTemplateChart";
import { IoMdSettings } from "react-icons/io";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const EnergyTemplate = ({ configureData, setSelectedWidget, chart, view }) => {
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [selectedOptionEC, setSelectedOptionEC] = useState("lastHour");
  const [selectedOptionEU, setSelectedOptionEU] = useState("hourWise");
  const [selectedOptionMD, setSelectedOptionMD] = useState("hourly");
  const [maxDChart, setMaxDChart] = useState(null);
  const [teChart, setTeChart] = useState(null);
  const [minDChart, setMinDChart] = useState(null);

  const [te, setTe] = useState(null);
  const [minkw, setMinkw] = useState(null);
  const [maxdkw, setMaxdkw] = useState(null);

  const previousMaxDValue = useRef(null);
  const previousMinDValue = useRef(null);

  useEffect(() => {
    if (chart?.data) {
      setMaxDChart(chart.data.find((item) => item?.type === "maxdkw"));
      setTeChart(chart.data.find((item) => item?.type === "te"));
      setMinDChart(chart.data.find((item) => item?.type === "mindkw"));
    }
  }, [chart?.data]);

  const fetchData = async () => {
    try {
      if (maxDChart?.type === "maxdkw") {
        const pointId = maxDChart?.points[0]?.value;
        const getData = await api.get(`${dbUrl}/points/${pointId}`);
        const currentValue = getData?.data?.data?.pointPresentValue;

        const valueToDisplay =
          previousMaxDValue.current !== null
            ? Math.max(previousMaxDValue.current, currentValue)
            : currentValue;

        previousMaxDValue.current = currentValue;
        setMaxdkw(valueToDisplay);
      }

      if (teChart?.type === "te") {
        const pointId = teChart?.points[0]?.value;
        const getData = await api.get(`${dbUrl}/points/${pointId}`);
        setTe(getData?.data?.data?.pointPresentValue);
      }

      if (minDChart?.type === "mindkw") {
        const pointId = minDChart?.points[0]?.value;
        const getData = await api.get(`${dbUrl}/points/${pointId}`);
        const currentValue = getData?.data?.data?.pointPresentValue;

        const valueToDisplay =
          previousMinDValue.current !== null
            ? Math.min(previousMinDValue.current, currentValue)
            : currentValue;

        previousMinDValue.current = currentValue;
        setMinkw(valueToDisplay);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [maxDChart, teChart, minDChart]);

  return (
    <div className="energyTemplateContainer">
      <div className="templateHeading">
        <span className="templateHomeBtn">Go Home</span>
        <span className="templateLabel">{chart?.label}</span>
      </div>
      <div className="energyTemplateMainContainer">
        <div className="energyTemplateLeft">
          <div className="mediumCard">
            <div className="cardHeading">
              <span>Energy Consumption</span>

              <div className="cardBtnContainer">
                <div
                  className={`cardBtn ${
                    selectedOptionEC === "lastHour" ? "selected" : ""
                  }`}
                  onClick={() => setSelectedOptionEC("lastHour")}
                >
                  Last Hour
                </div>
                <div
                  className={`cardBtn ${
                    selectedOptionEC === "today" ? "selected" : ""
                  }`}
                  onClick={() => setSelectedOptionEC("today")}
                >
                  Today
                </div>
                <div
                  className={`cardBtn ${
                    selectedOptionEC === "yesterday" ? "selected" : ""
                  }`}
                  onClick={() => setSelectedOptionEC("yesterday")}
                >
                  Yesterday
                </div>
                <div
                  className={`cardBtn ${
                    selectedOptionEC === "mtd" ? "selected" : ""
                  }`}
                  onClick={() => setSelectedOptionEC("mtd")}
                >
                  MTD
                </div>
                {view === "Edit" && (
                  <IoMdSettings
                    size={20}
                    color={`var(--font-color)`}
                    className="displayTemplateSettingsWidget"
                    onClick={() => {
                      configureData(`${chart.id}-ec`);
                      setSelectedWidget(chart.id);
                    }}
                  />
                )}
              </div>
            </div>
            <span className="cardSubHeading">
              (
              {chart?.data?.find((item) => item?.type === "ec")?.title ||
                chart?.data?.find((item) => item?.type === "ec")?.points?.[0]
                  ?.label ||
                "Sub Title"}
              )
            </span>

            <div className="cardContent">
              <LineTemplateChart
                chart={{
                  option: selectedOptionEC,
                  ...chart?.data?.find((item) => item?.type === "ec"),
                }}
              />
            </div>
          </div>

          <div className="mediumCard">
            <div className="cardHeading">
              <span>Energy Usage</span>
              <div className="cardBtnContainer">
                <div
                  className={`cardBtn ${
                    selectedOptionEU === "hourWise" ? "selected" : ""
                  }`}
                  onClick={() => setSelectedOptionEU("hourWise")}
                >
                  Hour Wise
                </div>
                <div
                  className={`cardBtn ${
                    selectedOptionEU === "dayWise" ? "selected" : ""
                  }`}
                  onClick={() => setSelectedOptionEU("dayWise")}
                >
                  Day Wise
                </div>
                <div
                  className={`cardBtn ${
                    selectedOptionEU === "monthWise" ? "selected" : ""
                  }`}
                  onClick={() => setSelectedOptionEU("monthWise")}
                >
                  Month Wise
                </div>
                {view === "Edit" && (
                  <IoMdSettings
                    size={20}
                    color={`var(--font-color)`}
                    className="displayTemplateSettingsWidget"
                    onClick={() => {
                      configureData(`${chart.id}-eu`);
                      setSelectedWidget(chart.id);
                    }}
                  />
                )}
              </div>
            </div>
            <span className="cardSubHeading">
              (
              {chart?.data?.find((item) => item?.type === "eu")?.title ||
                chart?.data?.find((item) => item?.type === "eu")?.points?.[0]
                  ?.label ||
                "Sub Title"}
              )
            </span>
            <div className="cardContent">
              <BarTemplateChart
                chart={{
                  option: selectedOptionEU,
                  ...chart?.data?.find((item) => item?.type === "eu"),
                }}
              />
            </div>
          </div>

          <div className="mediumCard">
            <div className="cardHeading">
              <span>Maximum Demand</span>
              <div className="cardBtnContainer">
                <div
                  className={`cardBtn ${
                    selectedOptionMD === "hourly" ? "selected" : ""
                  }`}
                  onClick={() => setSelectedOptionMD("hourly")}
                >
                  Hourly
                </div>
                <div
                  className={`cardBtn ${
                    selectedOptionMD === "daily" ? "selected" : ""
                  }`}
                  onClick={() => setSelectedOptionMD("daily")}
                >
                  Daily
                </div>
                {view === "Edit" && (
                  <IoMdSettings
                    size={20}
                    color={`var(--font-color)`}
                    className="displayTemplateSettingsWidget"
                    onClick={() => {
                      configureData(`${chart.id}-maxd`);
                      setSelectedWidget(chart.id);
                    }}
                  />
                )}
              </div>
            </div>
            <span className="cardSubHeading">
              (
              {chart?.data?.find((item) => item?.type === "maxd")?.title ||
                chart?.data?.find((item) => item?.type === "maxd")?.points?.[0]
                  ?.label ||
                "Sub Title"}
              )
            </span>
            <div className="cardContent">
              <VerticalBarTemplateChart
                chart={{
                  option: selectedOptionMD,
                  ...chart?.data?.find((item) => item?.type === "maxd"),
                }}
              />
            </div>
          </div>

          <div className="mediumCard">
            <div className="cardHeading">
              <span>Energy Consumption Trend</span>
              {view === "Edit" && (
                <IoMdSettings
                  size={20}
                  color={`var(--font-color)`}
                  className="displayTemplateSettingsWidget"
                  onClick={() => {
                    configureData(`${chart.id}-ect`);
                    setSelectedWidget(chart.id);
                  }}
                />
              )}
            </div>
            <span className="cardSubHeading">
              (
              {chart?.data?.find((item) => item?.type === "ect")?.title ||
                chart?.data?.find((item) => item?.type === "ect")?.points?.[0]
                  ?.label ||
                "Sub Title"}
              )
            </span>

            <div className="cardContent">
              <LineTemplateChart
                chart={{
                  ...chart?.data?.find((item) => item?.type === "ect"),
                }}
              />
            </div>
          </div>
          {/* <div className="largeCard">.</div> */}
        </div>
        <div className="energyTemplateRight">
          <div className="smallCard">
            <div className="cardHeading small">
              <span>Total Energy (KWH)</span>
              {view === "Edit" && (
                <IoMdSettings
                  size={20}
                  color={`var(--font-color)`}
                  className="displayTemplateSettingsWidget"
                  onClick={() => {
                    configureData(`${chart.id}-te`);
                    setSelectedWidget(chart.id);
                  }}
                />
              )}
            </div>
            <span className="cardSubHeading">
              (
              {chart?.data?.find((item) => item?.type === "te")?.title ||
                chart?.data?.find((item) => item?.type === "te")?.points?.[0]
                  ?.label ||
                "Sub Title"}
              )
            </span>
            <div className="smallCardContent">
              <FaBoltLightning color="yellow" size={70} />
              <span>{te ? te : ""}</span>
            </div>
          </div>

          <div className="smallCard">
            <div className="cardHeading small">
              <span>Min Demand (KW)</span>
              {view === "Edit" && (
                <IoMdSettings
                  size={20}
                  color={`var(--font-color)`}
                  className="displayTemplateSettingsWidget"
                  onClick={() => {
                    configureData(`${chart.id}-mindkw`);
                    setSelectedWidget(chart.id);
                  }}
                />
              )}
            </div>
            <span className="cardSubHeading">
              (
              {chart?.data?.find((item) => item?.type === "mindkw")?.title ||
                chart?.data?.find((item) => item?.type === "mindkw")
                  ?.points?.[0]?.label ||
                "Sub Title"}
              )
            </span>
            <div className="smallCardContent">
              <FaBoltLightning color="yellow" size={70} />
              <span>{minkw ? minkw : ""}</span>
            </div>
          </div>

          <div className="smallCard">
            <div className="cardHeading small">
              <span>Max Demand (KW)</span>
              {view === "Edit" && (
                <IoMdSettings
                  size={20}
                  color={`var(--font-color)`}
                  className="displayTemplateSettingsWidget"
                  onClick={() => {
                    configureData(`${chart.id}-maxdkw`);
                    setSelectedWidget(chart.id);
                  }}
                />
              )}
            </div>
            <span className="cardSubHeading">
              (
              {chart?.data?.find((item) => item?.type === "maxdkw")?.title ||
                chart?.data?.find((item) => item?.type === "maxdkw")
                  ?.points?.[0]?.label ||
                "Sub Title"}
              )
            </span>
            <div className="smallCardContent">
              <FaBoltLightning color="yellow" size={70} />
              <span>{maxdkw ? maxdkw : ""}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyTemplate;
