import { useEffect, useState } from "react";
import "./app/common/styles/App.css";
import { useAuth } from "./app/common/utils/authContext";
import Header from "./app/features/core/header/header";
import SideNav from "./app/features/core/sidebar/sidebar";
import Login from "./app/features/pages/login/presentation/login";
import CustomAlert from "./app/features/reusableComponents/customAlert";
import AppRoutes from "./app/routes/routes";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { logoutUser } from "./app/configuration/reducers/userReducer";
import { useDispatch } from "react-redux";
import { useMenu } from "./app/common/utils/menuContext";
import PreLoader from "./app/features/reusableComponents/preLoader";
import Register from "./app/features/pages/register/presentation/register";
import InviteUser from "./app/features/pages/register/presentation/inviteUser";
import ErrorBoundary from "./app/common/utils/errorBoundary";

function App() {
  const { isLoggedIn } = useAuth();
  const { theme, loading, setConfig, setConfigError, config } = useMenu();
  const { setIsLoggedIn } = useAuth();
  const dispatch = useDispatch();
  const [configLoaded, setConfigLoaded] = useState(false);
  const version = config?.REACT_APP_VERSION;

  // Fetch the config on initial load
  useEffect(() => {
    async function fetchConfig() {
      try {
        const response = await fetch("/config.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setConfig(data);
        setConfigLoaded(true);
      } catch (err) {
        setConfigError("Our System Found that Config File is Missing");
      }
    }
    fetchConfig();
  }, []); // Empty dependency array to run this effect only once on mount

  useEffect(() => {
    let logoutTimer;
    const sessionTimeout = 3600000; // 1 hour
    const resetLogoutTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        dispatch(logoutUser(setIsLoggedIn));
        console.log("Session timeout, logging out...");
      }, sessionTimeout);
    };
    const clearLogoutTimer = () => {
      clearTimeout(logoutTimer);
    };
    resetLogoutTimer();
    const handleUserActivity = () => {
      resetLogoutTimer();
    };

    ["mousemove", "keydown", "mousedown", "touchstart"].forEach((event) => {
      document.addEventListener(event, handleUserActivity);
    });

    return () => {
      clearLogoutTimer();
      ["mousemove", "keydown", "mousedown", "touchstart"].forEach((event) => {
        document.removeEventListener(event, handleUserActivity);
      });
    };
  }, [dispatch, setIsLoggedIn]);

  useEffect(() => {
    document.body.className = theme === "dark" ? "dark" : "light";
  }, [theme]);

  if (!configLoaded) {
    // Show loading spinner until the config is loaded
    return <PreLoader size={80} color="var(--primary-color)" />;
  }

  return (
    <div>
      {loading && <PreLoader size={80} color="var(--primary-color)" />}
      <CustomAlert />
      <Router>
        {/* <ErrorBoundary> */}
        <RoutesComponent isLoggedIn={isLoggedIn} version={version} />
        {/* </ErrorBoundary> */}
      </Router>
    </div>
  );
}

function RoutesComponent({ isLoggedIn, version }) {
  const location = useLocation();

  return isLoggedIn ? (
    <div className="App">
      <div className="headerContent">
        <Header />
      </div>
      <div className="content">
        <SideNav />
        <AppRoutes />
        <div className="version">{version}</div>
      </div>
    </div>
  ) : (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/invite" element={<InviteUser />} />
        <Route path="/invite/:id" element={<InviteUser />} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
      {location.pathname !== "/register" &&
        !location.pathname.startsWith("/invite") && <Navigate to="/login" />}
    </>
  );
}

export default App;
