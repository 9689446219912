/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../../../common/styles/trendsStyle.css";
import TrendNavigator from "./components/trendNavigator.jsx";
import TrendCanvas from "./components/trendCanvas.jsx";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import TrendsComparator from "./components/trendsComparator.jsx";
import TrendComparator from "./components/trendComparator";
import { useMenu } from "../../../../common/utils/menuContext";
import getPermissions from "../../../../configuration/localStorage/getPermissions";
import PreLoader from "../../../reusableComponents/preLoader.js";
import { useParams, useNavigate } from "react-router-dom";
import CustomHeader from "../../../reusableComponents/customHeader.jsx";
import api from "../../../../common/utils/axiosRequest.js";

const Trends = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { open, config } = useMenu();
  const navigate = useNavigate();
  const { loadedTrendId, trendComparatorId } = useParams();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [trendData, setTrendData] = useState([]);
  const [newTrend, setNewTrend] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Table");
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [data7, setData7] = useState([]);
  const [data8, setData8] = useState([]);
  const [data9, setData9] = useState([]);
  const [data10, setData10] = useState([]);
  const [trendId, setTrendId] = useState();
  const [trendName, setTrendName] = useState("");
  const [valuesToShow, setValuesToShow] = useState("today");
  const [trendComparisions, setTrendComparisions] = useState([]);
  const [valueQuality, setValueQuality] = useState();
  const [permissions] = useState(getPermissions);
  const [loading, setLoading] = useState(false);
  const [selectedTrendId, setSelectedTrendId] = useState(null);

  useEffect(() => {
    const loadTrend = async () => {
      setLoading(true);
      if (loadedTrendId) {
        const trendDoc = await api.get(`${dbUrl}/trends/${loadedTrendId}`);
        handleItemClick(trendDoc.data.data);
      } else if (trendComparatorId) {
        onTrendComparisionLoad(trendComparatorId);
      }
    };
    loadTrend();
  }, [loadedTrendId]);
  const fetchTrendPoints = async () => {
    // setLoading(true);
    try {
      const trendsResponse = await api.get(`${dbUrl}/trends/_all_docs`, {
        params: {
          include_docs: true,
          except: ["trends"],
        },
        paramsSerializer: (params) => {
          return Object.keys(params)
            .map((key) =>
              Array.isArray(params[key])
                ? params[key].map((val) => `${key}[]=${val}`).join("&")
                : `${key}=${params[key]}`
            )
            .join("&");
        },
      });
      setTrendData(trendsResponse.data.data.rows.map((row) => row.doc));
      setLoading(false);
    } catch (error) {
      toast.warning("Enable Trend for points to view it here.");
      setLoading(false);
    }
  };

  const handleItemClick = async (item) => {
    setNewTrend(false);
    setSelectedOption("Table");
    setSelectedItem(item);
    setSelectedTrendId(item._id);
    try {
      const checkDevice = await api.get(`${dbUrl}/points/${item.trendId}`);
      const { pointStatus, pointLastPollStatus } = checkDevice.data.data;
      if (pointStatus === "Inactive" || pointLastPollStatus === "Failed") {
        setValueQuality("Bad");
      } else {
        setValueQuality("Good");
      }
    } catch (error) {
      setValueQuality("Bad");
    }
  };

  const getTrendComparisions = async () => {
    try {
      const trendComparision = await api.get(
        `${dbUrl}/trendcomparators/_all_docs`,
        {
          params: {
            include_docs: true,
          },
        }
      );
      setTrendComparisions(
        trendComparision.data.data.rows.map((trend) => trend.doc)
      );
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  const onTrendComparisionLoad = async (trendComparision) => {
    setNewTrend(true);
    setTrendId(trendComparision);
    try {
      const getComparision = await api.get(
        `${dbUrl}/trendcomparators/${trendComparision}`
      );
      setTrendName(getComparision.data.data.name);
      setSelectedOptions(getComparision.data.data.selectedOptions);
      setValuesToShow(getComparision.data.data.valuesToShow);
      setData1(getComparision.data.data.data1);
      setData2(getComparision.data.data.data2);
      setData3(getComparision.data.data.data3);
      setData4(getComparision.data.data.data4);
      setData5(getComparision.data.data.data5);
      setData6(getComparision.data.data.data6);
      setData7(getComparision.data.data.data7);
      setData8(getComparision.data.data.data8);
      setData9(getComparision.data.data.data9);
      setData10(getComparision.data.data.data10);
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  const handleDeleteComparision = async (trendComparisionId) => {
    try {
      const getTrendComparision = await api.get(
        `${dbUrl}/trendcomparators/${trendComparisionId}`
      );
      const trendComparision = getTrendComparision.data.data;
      await api.delete(`${dbUrl}/trendcomparators/${trendComparision._id}`);
      getTrendComparisions();
      setConfirmDelete(false);
      setNewTrend(true);
      setSelectedOptions([]);
      setValuesToShow("today");
      setTrendName("");
      setData1([]);
      setData2([]);
      setData3([]);
      setData4([]);
      setData5([]);
      setData6([]);
      setData7([]);
      setData8([]);
      setData9([]);
      setData10([]);
      toast.success("Successfully Deleted Trend Comparision");
      navigate("/trends");
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchTrendPoints();
    getTrendComparisions();
  }, []);

  return (
    <div className="trends">
      {loading ? <PreLoader size={80} color="var(--primary-color)" /> : null}
      {open ? (
        <div className="trendsSidebar">
          <TrendNavigator
            handleItemClick={handleItemClick}
            trendData={trendData}
            fetchTrendPoints={fetchTrendPoints}
            setSelectedOption={setSelectedOption}
            setSelectedItem={setSelectedItem}
            selectedTrendId={selectedTrendId}
          />
          <TrendsComparator
            setNewTrend={setNewTrend}
            trendComparisions={trendComparisions}
            onTrendComparisionLoad={onTrendComparisionLoad}
            setSelectedOptions={setSelectedOptions}
            setValuesToShow={setValuesToShow}
            setData1={setData1}
            setData2={setData2}
            setData3={setData3}
            setData4={setData4}
            setData5={setData5}
            setData6={setData6}
            setData7={setData7}
            setData8={setData8}
            setData9={setData9}
            setData10={setData10}
            setConfirmDelete={setConfirmDelete}
            confirmDelete={confirmDelete}
            setTrendName={setTrendName}
            setTrendId={setTrendId}
            handleDeleteComparision={handleDeleteComparision}
            permissions={permissions}
          />
        </div>
      ) : null}
      {newTrend ? (
        <TrendComparator
          getTrendComparisions={getTrendComparisions}
          fetchTrendPoints={fetchTrendPoints}
          setSelectedOptions={setSelectedOptions}
          setValuesToShow={setValuesToShow}
          setData1={setData1}
          setData2={setData2}
          setData3={setData3}
          setData4={setData4}
          setData5={setData5}
          setData6={setData6}
          setData7={setData7}
          setData8={setData8}
          setData9={setData9}
          setData10={setData10}
          valuesToShow={valuesToShow}
          selectedOptions={selectedOptions}
          trendName={trendName}
          data1={data1}
          data2={data2}
          data3={data3}
          data4={data4}
          data5={data5}
          data6={data6}
          data7={data7}
          data8={data8}
          data9={data9}
          data10={data10}
          setTrendId={setTrendId}
          trendId={trendId}
          setTrendName={setTrendName}
          permissions={permissions}
        />
      ) : (
        <div className="trendsCanvas">
          <CustomHeader heading={"Trends"} />
          <TrendCanvas
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
            permissions={permissions}
            valueQuality={valueQuality}
            fetchTrendPoints={fetchTrendPoints}
          />
        </div>
      )}
    </div>
  );
};

export default Trends;
