import React, { useEffect } from "react";
import "../../../../common/styles/settingsStyle.css";

import SettingsNavigator from "./components/settingsNavigator";
import UserSettings from "./components/userSettings.jsx";
import RolesSettings from "./components/rolesSettings.jsx";
// import ServicesSettings from "./components/servicesSettings.jsx";
import ConfigurationSettings from "./components/configurationSettings.jsx";
import BackupRestoreSettings from "./components/backupRestoreSettings.jsx";
import LicenseSettings from "./components/licenseSettings.jsx";
// import MQTTConfiguration from "./components/mqttConfiguration.jsx";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import Logs from "./components/logs.jsx";

const Settings = () => {
  const location = useLocation();

  const [selectedOption, setSelectedOption] = useState("Users");

  useEffect(() => {
    if (location.pathname === "/settings/users") {
      setSelectedOption("Users");
    } else if (location.pathname === "/settings/roles") {
      setSelectedOption("Roles");
    } else if (location.pathname === "/settings/configuration") {
      setSelectedOption("Configuration");
    } else if (location.pathname === "/settings/backupRestore") {
      setSelectedOption("BackupRestore");
    } else if (location.pathname === "/settings/logs") {
      setSelectedOption("Logs");
    } else if (location.pathname === "/settings/license") {
      setSelectedOption("License");
    }
  }, [location.pathname]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  return (
    <div className={`settings `}>
      <SettingsNavigator
        onOptionClick={handleOptionClick}
        location={location}
      />
      {selectedOption === "Users" && <UserSettings />}
      {selectedOption === "Roles" && <RolesSettings />}
      {selectedOption === "Configuration" && <ConfigurationSettings />}
      {selectedOption === "BackupRestore" && <BackupRestoreSettings />}
      {selectedOption === "Logs" && <Logs />}
      {selectedOption === "License" && <LicenseSettings />}
    </div>
  );
};

export default Settings;
