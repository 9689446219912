import React, { useEffect, useState } from "react";
import TrendCanvasChart from "../../../trends/presentation/components/trendCanvasChart";
import "../../../../../common/styles/pointDetailsTableStyle.css";
import CustomTable from "../../../../reusableComponents/customTable";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";
const PointDetailsTrendsTable = ({ pointProps }) => {
  const [trends, setTrends] = useState([]);
  const [selectedTab, setSelectedTab] = useState("table");
  const { config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [trendData, setTrendData] = useState(null);
  const labels = trendData?.map((trend) => trend.timestamp);

  useEffect(() => {
    const getAllTrends = async () => {
      try {
        const getTrend = await api.get(
          `${dbUrl}/trends/trend-${pointProps?._id}`
        );

        // Add valueQuality to each trend
        const trendsWithQuality = getTrend.data.data.trends.map((trend) => ({
          ...trend,
          valueQuality:
            pointProps?.lastPollStatus === "Failed" ? "Bad" : "Good",
        }));

        setTrends(trendsWithQuality);
        setTrendData(trendsWithQuality);
      } catch (error) {
        console.log(error);
      }
    };

    getAllTrends();
  }, [dbUrl, pointProps?._id, pointProps?.lastPollStatus]);

  const data = {
    labels,
    datasets: [
      {
        label: `${pointProps?.pointDeviceInstance}-${pointProps?.pointName}`,
        data: trendData?.map((trend) => trend.presentValue),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
  const headings = [
    { id: "timestamp", label: "Timestamp" },
    { id: "presentValue", label: "Recorded Value" },
    {
      id: "valueQuality",
      label: "Value Quality",
    },
  ];

  return (
    <>
      <div className="custom-tabs">
        <span
          onClick={() => setSelectedTab("table")}
          className={`${
            selectedTab === "table" ? "custom-tab-selected" : "custom-tab"
          }`}
        >
          Table
        </span>
        <span
          className={`${
            selectedTab === "chart" ? "custom-tab-selected" : "custom-tab"
          }`}
          onClick={() => setSelectedTab("chart")}
        >
          Chart
        </span>
      </div>
      {selectedTab === "chart" && (
        <TrendCanvasChart data={data} />
        // <div className="pointDetailsAlarmsChart">
        // </div>
      )}
      {selectedTab === "table" && (
        <CustomTable
          data={trends}
          headings={headings}
          totalPlaceholder={"Trends"}
          totalRows={trends.length}
          key={"pointDetailsAlarmTable"}
        />
      )}
    </>
  );
};

export default PointDetailsTrendsTable;
