/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import GaugeChart from "react-gauge-chart";
import "../../../../../../common/styles/guageChartStyle.css";
import { useState } from "react";
import { useEffect } from "react";
import { useMenu } from "../../../../../../common/utils/menuContext";
import { Tooltip } from "react-tooltip";
import api from "../../../../../../common/utils/axiosRequest";

const GuageChart = ({ chart }) => {
  // const [isLoading, setIsLoading] = useState(true);
  const { config } = useMenu();
  const [presentValue, setPresentValue] = useState();
  const [percentValue, setPercentValue] = useState();
  const [pointName, setPointName] = useState();
  const [deviceId, setDeviceId] = useState();
  const [pointUnitSymbol, setPointUnitSymbol] = useState();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const fetchLiveDeviceData = async () => {
    // setIsLoading(false);
    try {
      const couchdbUrl = dbUrl;
      const database = chart.data.points[0].value;
      // const database = chart.data.points;
      const response = await api.get(`${couchdbUrl}/points/${database}`);

      const liveVal = response?.data?.data?.pointPresentValue;
      setPresentValue(liveVal);
      setPercentValue(liveVal?.toFixed(0));
      setPointName(response?.data?.data?.pointName);
      setDeviceId(response?.data?.data?.pointDeviceInstance);
      setPointUnitSymbol(
        response?.data?.data?.pointUnitSymbol
          ? response?.data?.data?.pointUnitSymbol
          : ""
      );
    } catch (error) {
      console.error("Error fetching last uploaded record:", error.message);
    }
  };

  useEffect(() => {
    const intervalInSeconds = chart?.data?.pollFrequency;
    const intervalInMilliseconds = intervalInSeconds * 1000;
    fetchLiveDeviceData();
    if (intervalInSeconds) {
      const fetchDataInterval = setInterval(() => {
        fetchLiveDeviceData();
      }, intervalInMilliseconds);

      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart]);

  return (
    <div
      id="chartContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
        overflow: "scroll",
        height: "92%",
        margin: "auto",
      }}
    >
      {/* {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null} */}

      <span className="chartLabel">
        {chart?.data?.title
          ? chart?.data?.title
          : deviceId && pointName
          ? `${deviceId}-${pointName}`
          : deviceId
          ? deviceId
          : pointName
          ? pointName
          : "Analog/Binary Point"}
      </span>
      <GaugeChart
        nrOfLevels={5}
        arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
        colors={["#00C49F", "#FFBB28", "#FF8042", "#FF4F4F", "#D4397F"]}
        percent={`${presentValue ? `0.${percentValue}` : "0.0"}`}
        formatTextValue={(value) =>
          `${
            presentValue
              ? `${presentValue} ${pointUnitSymbol ? pointUnitSymbol : ""}`
              : "0"
          }`
        }
        textColor={"var(--font-color)"}
        arcPadding={0.02}
        animate={false}
        className="guageChartChart"
      />
      <Tooltip place="bottom" className="tooltipStyle" id="liveWidgetTooltip" />
    </div>
  );
};

export default GuageChart;
