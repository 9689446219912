import React from "react";
import ReactDOM from "react-dom/client";
import "./app/common/styles/index.css";
import App from "./App";
import { MenuProvider } from "./app/common/utils/menuContext";
import { AuthProvider } from "./app/common/utils/authContext";
import { Provider } from "react-redux";
import store from "./app/configuration/redux/store";
import ErrorBoundary from "./app/common/utils/errorBoundary";
// Suppress React Dev Mode Red Overlay
// window.onerror = (message, source, lineno, colno, error) => {
//   console.error("Suppressed Error:", { message, source, lineno, colno, error });
//   // Prevent React's error overlay
//   return true; // Suppresses the default browser and React overlay
// };

// window.addEventListener("unhandledrejection", (event) => {
//   console.error("Suppressed Unhandled Rejection:", event.reason);
//   // Prevent React's error overlay
//   event.preventDefault(); // This stops the overlay in dev mode
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <AuthProvider>
        <MenuProvider>
          {/* <ErrorBoundary> */}
          <App />
          {/* </ErrorBoundary> */}
        </MenuProvider>
      </AuthProvider>
    </React.StrictMode>
  </Provider>
);
