import React, { useEffect, useState } from "react";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import CustomFormSelect from "../../../../reusableComponents/customFormSelect";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ScheduleReports from "./scheduleReports";
import Report from "./report";
import { toast } from "react-toastify";
import getPermissions from "../../../../../configuration/localStorage/getPermissions";
import CustomHeader from "../../../../reusableComponents/customHeader";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";

const StandardAlarmReport = ({
  setReportData,
  reportData,
  handleSaveReport,
  networkOptions,
  deviceOptions,
  pointOptions,
  handleSelectChange,
}) => {
  const [selectedOption, setSelectedOption] = useState("Configuration");
  const { config } = useMenu();
  const animatedComponents = makeAnimated();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const [rows, setRows] = useState([]);
  const [permissions] = useState(getPermissions);

  const columns = [
    { id: "alarmPriority", label: "Priority", minWidth: "auto" },
    { id: "alarmStartTime", label: "Date Time", minWidth: "auto" },
    { id: "source", label: "Source", minWidth: "auto" },
    { id: "sourceState", label: "Source State", minWidth: "auto" },
    { id: "ackStatus", label: "Ack Status", minWidth: "auto" },
    { id: "eventDescription", label: "Event Description", minWidth: "auto" },
    { id: "message", label: "Message", minWidth: "auto" },
  ];

  useEffect(() => {
    const getDataFromDB = async () => {
      try {
        const response = await api.get(`${dbUrl}/alarms/_all_docs`, {
          params: {
            include_docs: true,
          },
        });
        const alarmData = response.data.data.rows.map((row) => row.doc);
        setRows(
          reportData?.generatedReport?.rows
            ? reportData?.generatedReport?.rows
            : alarmData
        );
      } catch (error) {
        console.error("Error fetching data from DB:", error);
        setRows([]);
      }
    };
    getDataFromDB();
  }, [dbUrl, reportData]);

  const generateReport = async () => {
    try {
      const res = await api.post(`${dbUrl}/generate_report`, reportData);
      if (res.status === 200) {
        toast.success("Report Generated Successfully");
        setSelectedOption("Reports");
        setReportData({
          ...reportData,
          generatedReport: res.data,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const exportToCSV = () => {
    if (!rows || rows.length === 0) {
      toast.error("No data available to export");
      return;
    }

    const headers = columns.map((col) => col.label);
    const csvRows = [
      headers.join(","), // header row
      ...rows.map((row) =>
        columns
          .map((col) => {
            if (col.id === "ackStatus" && !row[col.id]) {
              return JSON.stringify("Pending");
            }
            if (col.id === "eventDescription") {
              return JSON.stringify(
                row["highLimitMessage"]
                  ? row["highLimitMessage"]
                  : row["lowLimitMessage"]
              );
            }
            return JSON.stringify(row[col.id] || "");
          })
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvRows], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${reportData?.reportName ? reportData.reportName : "alarm_report"}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="reportDialog">
      <CustomHeader heading={"Standard Alarm Report"} />
      <div className="reportDialogContent container-style">
        <div className="custom-tabs">
          <span
            onClick={() => setSelectedOption("Configuration")}
            className={`${
              selectedOption === "Configuration"
                ? "custom-tab-selected"
                : "custom-tab"
            }`}
          >
            Configuration
          </span>
          <span
            onClick={() => setSelectedOption("Schedule")}
            className={`${
              selectedOption === "Schedule"
                ? "custom-tab-selected"
                : "custom-tab"
            }`}
          >
            Schedule
          </span>
          <span
            onClick={() => setSelectedOption("Reports")}
            className={`${
              selectedOption === "Reports"
                ? "custom-tab-selected"
                : "custom-tab"
            }`}
          >
            Generated Report
          </span>
        </div>
        <div className="reportDialogContentGenerate">
          {selectedOption === "Configuration" ? (
            <div className="reportDialogForm">
              <div className="reportDialogFormContents">
                <div
                  className="reportDialogFormLeft"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    className="reportDialogFormHeader"
                    style={{ marginBottom: "10px" }}
                  >
                    Report Details
                  </div>
                  <div
                    className="reportDialogFormContent"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    {/* Row 1 */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <div style={{ flex: "1" }}>
                        <CustomFormInput
                          label="Report Name"
                          id="reportName"
                          type="text"
                          value={reportData.reportName}
                          onChange={(e) =>
                            setReportData({
                              ...reportData,
                              reportName: e.target.value,
                            })
                          }
                          placeholder="Enter Report Name"
                        />
                      </div>
                      <div style={{ flex: "1" }}>
                        <CustomFormInput
                          label="Tags"
                          id="tags"
                          type="text"
                          value={reportData.tags}
                          onChange={(e) =>
                            setReportData({
                              ...reportData,
                              tags: e.target.value,
                            })
                          }
                          placeholder="Enter Tags with comma separated"
                        />
                      </div>
                    </div>
                    {/* Row 2 */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <div style={{ flex: "1" }}>
                        <CustomFormInput
                          label="Report Description"
                          id="reportDesc"
                          type="text"
                          value={reportData.reportDesc}
                          onChange={(e) =>
                            setReportData({
                              ...reportData,
                              reportDesc: e.target.value,
                            })
                          }
                          placeholder="Enter Report Description"
                        />
                      </div>
                      <div style={{ flex: "1" }}>
                        <CustomFormSelect
                          id="interval"
                          label="Interval"
                          value={reportData.interval}
                          onChange={(e) =>
                            setReportData({
                              ...reportData,
                              interval: e.target.value,
                            })
                          }
                          options={[
                            { label: "Last 1 Hour", value: "1hour" },
                            { label: "Last 1 Day", value: "1day" },
                            { label: "Last 1 Week", value: "1week" },
                            { label: "Last 1 Month", value: "1month" },
                            { label: "Custom Range", value: "custom" },
                          ]}
                        />
                      </div>
                    </div>
                    {/* Conditional Custom Date/Time Inputs */}
                    <div className="reportDialogFormContentRight">
                      {reportData.interval === "custom" && (
                        <>
                          <CustomFormInput
                            label="Start Date"
                            id="startDate"
                            type="date"
                            value={reportData.startDate}
                            onChange={(e) =>
                              setReportData({
                                ...reportData,
                                startDate: e.target.value,
                              })
                            }
                          />
                          <CustomFormInput
                            label="End Date"
                            id="endDate"
                            type="date"
                            value={reportData.endDate}
                            onChange={(e) =>
                              setReportData({
                                ...reportData,
                                endDate: e.target.value,
                              })
                            }
                          />
                          <CustomFormInput
                            label="Start Time"
                            id="timeInterval"
                            type="time"
                            value={reportData.startTime}
                            onChange={(e) =>
                              setReportData({
                                ...reportData,
                                startTime: e.target.value,
                              })
                            }
                          />
                          <CustomFormInput
                            label="End Time"
                            id="timeInterval"
                            type="time"
                            value={reportData.endTime}
                            onChange={(e) =>
                              setReportData({
                                ...reportData,
                                endTime: e.target.value,
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="reportDialogFormRight">
                  <div className="reportDialogFormRightHeader">
                    <div className="enableDisableCheck">
                      <input
                        type="checkbox"
                        id="enableDisable"
                        name="enableDisable"
                      />
                    </div>
                    <div className="reportDialogFormHeaderFilter">Filters</div>
                  </div>
                  <div className="reportDialogRightFormContent">
                    <div className="xAxisConfig">
                      <div className="reportDialogFormContentInputGroup">
                        <label htmlFor="network">Select Networks</label>
                        <div className="reportDialogFormContentCheckBoxes">
                          <Select
                            className="reportDialogFormContentSelect"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti={true}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            onChange={(selected) =>
                              handleSelectChange(
                                selected,
                                "selectedNetworks",
                                networkOptions
                              )
                            }
                            options={networkOptions}
                            value={networkOptions?.filter((option) =>
                              reportData?.selectedNetworks?.includes(
                                option.value
                              )
                            )}
                          />
                        </div>
                      </div>

                      <div className="reportDialogFormContentInputGroup">
                        <label htmlFor="network">Select Devices</label>
                        <Select
                          className="reportDialogFormContentSelect"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti={true}
                          onChange={(selected) =>
                            handleSelectChange(
                              selected,
                              "selectedDevices",
                              deviceOptions
                            )
                          }
                          options={deviceOptions}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                          value={deviceOptions?.filter((option) =>
                            reportData?.selectedDevices?.includes(option.value)
                          )}
                        />
                      </div>
                    </div>
                    <div className="yAxisConfig">
                      <div className="reportDialogFormContentInputGroup">
                        <label htmlFor="points">Select Points</label>
                        <Select
                          className="reportDialogFormContentSelect"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti={true}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                          onChange={(selected) =>
                            handleSelectChange(
                              selected,
                              "selectedPoints",
                              pointOptions
                            )
                          }
                          options={pointOptions}
                          value={pointOptions?.filter((option) =>
                            reportData?.selectedPoints?.includes(option.value)
                          )}
                        />
                      </div>

                      {/* <div className="reportDialogFormContentInputGroup">
                      <label htmlFor="network">Select Tags</label>
                      <Select
                        className="reportDialogFormContentSelect"
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={networkOptions}
                      />
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : selectedOption === "Schedule" ? (
            <div className="reportDialogForm">
              <ScheduleReports
                reportData={reportData}
                setReportData={setReportData}
              />
            </div>
          ) : selectedOption === "Reports" ? (
            <div className="reportDialogForm">
              <Report rows={rows} columns={columns} />
            </div>
          ) : null}

          <div className="reportDialogFormBtns">
            {permissions.reports.write && (
              <>
                <span
                  onClick={() => handleSaveReport()}
                  className="filled-btn-style"
                >
                  Save
                </span>

                <span
                  onClick={() => generateReport()}
                  className="filled-btn-style"
                >
                  Generate
                </span>
              </>
            )}
            <span onClick={exportToCSV} className="filled-btn-style">
              Export
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardAlarmReport;
