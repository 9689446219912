import React from "react";

import "../../../../../common/styles/deviceNetworkPropertiesStyle.css";
import CustomButton from "../../../../reusableComponents/customButton";
import { useState } from "react";
import AddDeviceForm from "./addDeviceForm.jsx";
import { useMenu } from "../../../../../common/utils/menuContext";
import { toast } from "react-toastify";
import PreLoader from "../../../../reusableComponents/preLoader";
import { useEffect } from "react";
import { isEmpty } from "../../../../../common/utils/isEmpty";
import CustomConfirmDelete from "../../../../reusableComponents/customConfirmDelete.jsx";
import CustomFormInput from "../../../../reusableComponents/customFormInput.jsx";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CustomHeader from "../../../../reusableComponents/customHeader.jsx";
import api from "../../../../../common/utils/axiosRequest.js";

const DeviceNetworkProperties = ({
  networkProps,
  setDeviceNetworkProperty,
  setSelectedNetwork,
  permissions,
}) => {
  const navigate = useNavigate();

  const { setReloadData, config } = useMenu();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const dbUrl = config?.REACT_APP_POLLING_ENDPOINT;
  const [loading, setLoading] = useState(false);
  const [networkName, setNetworkName] = useState();
  const [networkEnable, setNetworkEnable] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [ipAddress, setIpAddress] = useState();
  const [port, setPort] = useState();
  const serverEndpoint = config?.REACT_APP_POLLING_ENDPOINT;
  const [networkDescription, setNetworkDescription] = useState();

  useEffect(() => {
    setNetworkName(networkProps?.networkName);
    setNetworkEnable(networkProps?.networkEnable);
    setNetworkDescription(networkProps?.networkDesc);
    setIpAddress(networkProps?.networkAddress);
    setPort(networkProps?.networkPort);
  }, [networkProps]);

  const goBack = () => {
    setDeviceNetworkProperty(false);

    navigate("/devices");
  };
  const handleOpen = () => {
    setIsFormOpen(true);
    setIsOverlayOpen(true);
  };
  const handleClose = () => {
    setIsFormOpen(false);
    setIsOverlayOpen(false);
  };

  const updateNetwork = async () => {
    setLoading(true);
    if (!isEmpty(networkName, port, ipAddress)) {
      try {
        const updatedDoc = {
          ...networkProps,
          networkName: networkName,
          networkDesc: networkDescription,
          networkAddress: ipAddress,
          networkPort: port,
        };

        setSelectedNetwork(updatedDoc);

        const updatedNetwork = await api.put(
          `${dbUrl}/networks/${networkProps?._id}`,
          updatedDoc
        );

        if (updatedNetwork.status === 200) {
          try {
            await api.post(`${dbUrl}/unsubscribe`, {
              topic: `${networkProps?.topic}/#`,
            });
            await api.post(`${dbUrl}/subscribe`, {
              topic: `${networkProps?.topic}/#`,
            });
          } catch (error) {
            console.log(error);
          }
          setLoading(false);
          toast.success("Network Info Updated");
          setReloadData(true);
        }
      } catch (error) {
        setLoading(false);
        console.log("Error updating network:", error);
      }
    } else {
      setLoading(false);
      toast.warning("Please fill in the required fields.");
    }
  };

  const deleteNetwork = async () => {
    setConfirmDelete(false);
    setLoading(true);
    try {
      const deleteNetworkResponse = await api.delete(
        `${dbUrl}/networks/${networkProps?._id}`,
        {
          params: {
            points: true,
            devices: true,
            reference: "networkId",
          },
        }
      );
      if (deleteNetworkResponse.status === 200) {
        await api.post(`${serverEndpoint}/unsubscribe`, {
          topic: `${networkProps?.topic}/#`,
        });
        toast.success(`Network-${networkProps?._id} Deleted Successfully`);
        setLoading(false);
        setReloadData(true);
        goBack();
        navigate("/devices");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error deleting network:", error);
      toast.error("Error deleting network");
    }
  };

  return (
    <div className="deviceNetworkProperties">
      <CustomHeader heading={networkName} />
      <div className={`deviceNetworkProperties container-style`}>
        {loading ? <PreLoader size={80} color="var(--primary-color)" /> : ""}
        <div className={`deviceNetworkPropertiesHeaderContainerBox `}>
          <div className="headerGroup filled-btn-style" onClick={goBack}>
            <FaChevronLeft />
            <span>Networks</span>
          </div>
        </div>
        <div className={`deviceNetworkPropertiesContent `}>
          <div className="deviceNetworkPropertiesPartContent">
            <div className="deviceNetworkPropertiesGroup">
              <CustomFormInput
                required={true}
                id={"networkName"}
                label={"Network Name"}
                type={"text"}
                onChange={(e) => setNetworkName(e.target.value)}
                value={networkName}
              />
            </div>
            <div className="deviceNetworkPropertiesGroup">
              <CustomFormInput
                id={"networkDesc"}
                label={"Description"}
                type={"text"}
                onChange={(e) => setNetworkDescription(e.target.value)}
                value={networkDescription}
              />
            </div>
            <div className="deviceNetworkPropertiesGroup">
              <CustomFormInput
                required={true}
                id={"networkIp"}
                label={"Network IP"}
                type={"text"}
                onChange={(e) => setIpAddress(e.target.value)}
                value={ipAddress}
              />
            </div>
            <div className="deviceNetworkPropertiesGroup">
              <CustomFormInput
                required={true}
                id={"networkPort"}
                label={"Network Port"}
                type={"text"}
                onChange={(e) => setPort(e.target.value)}
                value={port}
              />
            </div>
          </div>
          <div className="deviceNetworkPropertiesPartContent">
            <div className="deviceNetworkPropertiesGroup">
              <CustomFormInput
                id={"networkType"}
                label={"Network Type"}
                type={"text"}
                disabled={true}
                value={networkProps?.networkType}
              />
            </div>
            <div className="deviceNetworkPropertiesGroup">
              <CustomFormInput
                id={"networkStatus"}
                label={"Network Status"}
                type={"text"}
                disabled={true}
                value={networkProps?.networkStatus}
              />
            </div>
            <div className="deviceNetworkPropertiesGroup">
              <CustomFormInput
                id={"apexTopic"}
                label={"Apex Name"}
                type={"text"}
                required={true}
                value={networkProps?.topic}
              />
            </div>
            <div className="deviceNetworkPropertiesGroup">
              <CustomFormInput
                id={"createdAt"}
                label={"Created At"}
                type={"text"}
                disabled={true}
                value={networkProps?.lastPollTime}
              />
            </div>
          </div>
        </div>
        {permissions?.devices.write && (
          <div className="deviceNetworkPropertiesBtnsGroup">
            <CustomButton
              handleClick={() => updateNetwork()}
              className={`filled-btn-style `}
              buttonName="Save"
            />
            <CustomButton
              className={`filled-btn-style `}
              buttonName="Delete Network"
              handleClick={() => setConfirmDelete(true)}
            />
            <CustomButton
              handleClick={() => handleOpen()}
              className={`filled-btn-style `}
              buttonName="Add Device"
            />
          </div>
        )}
        {confirmDelete && (
          <CustomConfirmDelete
            setConfirmDelete={setConfirmDelete}
            handleDelete={deleteNetwork}
            message="Are you sure you want to Delete this Network ?"
            note="Devices and Points Associated with this Network will be Deleted."
          />
        )}

        {isFormOpen && (
          <AddDeviceForm
            isFormOpen={isFormOpen}
            setIsFormOpen={handleClose}
            networkProps={networkProps}
          />
        )}
      </div>
    </div>
  );
};

export default DeviceNetworkProperties;
