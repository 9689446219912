import "../../../../../common/styles/trendCanvasChartStyle.css";
import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import CustomFormInput from "../../../../reusableComponents/customFormInput";

const TrendCanvasChart = ({ data }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [yAxisDomain, setYAxisDomain] = useState([0, 0]);

  useEffect(() => {
    const filterData = () => {
      if (
        !data ||
        !data.labels ||
        !data.datasets ||
        data.datasets.length === 0
      ) {
        setFilteredData([]);
        return;
      }
      const sensorName = data?.datasets[0]?.label || "sensor";
      let filtered = data?.labels?.map((label, index) => ({
        x: label,
        [sensorName]: parseFloat(
          data?.datasets[0]?.data[index] ? data?.datasets[0]?.data[index] : 0
        )?.toFixed(2),
      }));
      const today = new Date().toISOString().slice(0, 10);

      if (!startDate && !endDate && !startTime && !endTime) {
        filtered = filtered?.slice(0, 50);
      }

      if (startDate && endDate) {
        if (startDate.includes(today) && endDate.includes(today)) {
          filtered = filtered.filter((item) => item.x.includes(today));
          filtered = filtered.slice(0, 50);
        } else if (startDate === endDate) {
          filtered = filtered.filter((item) => item.x.includes(startDate));
          filtered = filtered.slice(0, 50);
        } else {
          filtered = filtered.filter(
            (item) => item.x >= startDate && item.x <= endDate
          );
          filtered = filtered.slice(0, 50);
        }
      } else if (startDate) {
        filtered = filtered.filter((item) => item.x.includes(startDate));
        filtered = filtered.slice(0, 50);
      } else if (endDate) {
        filtered = filtered.filter((item) => item.x.includes(endDate));
        filtered = filtered.slice(0, 50);
      }

      if (startTime && endTime) {
        if (startDate && endDate && startTime && endTime) {
          filtered = filtered.filter((item) => {
            const itemTimestamp = new Date(item.x);
            const startTimestamp = new Date(startDate + " " + startTime);
            const endTimestamp = new Date(endDate + " " + endTime);
            return (
              itemTimestamp >= startTimestamp && itemTimestamp <= endTimestamp
            );
          });
          filtered = filtered.slice(0, 50);
        } else if (startDate) {
          filtered = filtered.filter((item) => {
            const itemTimestamp = new Date(item.x);
            const startTimestamp = new Date(startDate + " " + startTime);
            const endTimestamp = new Date(startDate + " " + endTime);
            return (
              itemTimestamp >= startTimestamp && itemTimestamp <= endTimestamp
            );
          });
          filtered = filtered.slice(0, 50);
        } else if (endDate) {
          filtered = filtered.filter((item) => {
            const itemTimestamp = new Date(item.x);
            const startTimestamp = new Date(endDate + " " + startTime);
            const endTimestamp = new Date(endDate + " " + endTime);
            return (
              itemTimestamp >= startTimestamp && itemTimestamp <= endTimestamp
            );
          });
          filtered = filtered.slice(0, 50);
        }
      } else if (startTime) {
        if (startDate && endDate) {
          filtered = filtered.filter((item) => {
            const itemTimestamp = new Date(item.x);
            const startTimestamp = new Date(startDate + " " + startTime);
            const endTimestamp = new Date(endDate + " " + startTime);
            return (
              itemTimestamp >= startTimestamp && itemTimestamp <= endTimestamp
            );
          });
          filtered = filtered.slice(0, 50);
        } else if (startDate) {
          filtered = filtered.filter((item) => {
            const itemTimestamp = new Date(item.x);
            const startTimestamp = new Date(startDate + " " + startTime);
            return itemTimestamp >= startTimestamp;
          });
          filtered = filtered.slice(0, 50);
        } else if (endDate) {
          filtered = filtered.filter((item) => {
            const itemTimestamp = new Date(item.x);
            const startTimestamp = new Date(endDate + " " + startTime);
            return itemTimestamp >= startTimestamp;
          });
          filtered = filtered.slice(0, 50);
        }
      } else if (endTime) {
        if (startDate && endDate) {
          filtered = filtered.filter((item) => {
            const itemTimestamp = new Date(item.x);
            const startTimestamp = new Date(startDate + " " + endTime);
            const endTimestamp = new Date(endDate + " " + endTime);
            return (
              itemTimestamp >= startTimestamp && itemTimestamp <= endTimestamp
            );
          });
          filtered = filtered.slice(0, 50);
        } else if (startDate) {
          filtered = filtered.filter((item) => {
            const itemTimestamp = new Date(item.x);
            const startTimestamp = new Date(startDate + " " + endTime);
            return itemTimestamp <= startTimestamp;
          });
          filtered = filtered.slice(0, 50);
        } else if (endDate) {
          filtered = filtered.filter((item) => {
            const itemTimestamp = new Date(item.x);
            const startTimestamp = new Date(endDate + " " + endTime);
            return itemTimestamp <= startTimestamp;
          });
          filtered = filtered.slice(0, 50);
        }
      }

      setFilteredData(filtered);
      setYAxisDomain([
        Math.min(
          Math.min(...filtered.map((data) => Number(data[sensorName])))
        ) - 2,
        Math.max(
          Math.min(...filtered.map((data) => Number(data[sensorName])))
        ) + 2,
      ]);
    };

    filterData();
  }, [data, startDate, endDate, startTime, endTime]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };

  const sensorName = data?.datasets[0]?.label || "sensor";

  return data ? (
    <div className="trendCanvasChart">
      <div className="filters">
        <CustomFormInput
          id="startDate"
          label="Start Date"
          type={"date"}
          value={startDate}
          onChange={handleStartDateChange}
        />

        <CustomFormInput
          id="endDate"
          label="End Date"
          type={"date"}
          value={endDate}
          onChange={handleEndDateChange}
        />

        <hr className="hr" />
        <CustomFormInput
          id="startTime"
          label="Start Time"
          type={"time"}
          value={startTime}
          onChange={handleStartTimeChange}
        />
        <CustomFormInput
          id="endTime"
          label="End Time"
          type={"time"}
          value={endTime}
          onChange={handleEndTimeChange}
        />
      </div>
      <div className="trendCanvasChartData">
        <ResponsiveContainer width="100%" height={450}>
          <AreaChart data={filteredData}>
            <XAxis
              dataKey="x"
              tickFormatter={(name) =>
                name.length > 10 ? `${name.substring(0, 10)}...` : name
              }
              stroke="#8884d8"
              tickLine={false}
              axisLine={{ stroke: "var(--font-color)", strokeWidth: 1.5 }}
              tick={{ fontSize: 12, fill: "var(--font-color)" }}
            />
            <YAxis
              domain={yAxisDomain}
              stroke="#8884d8"
              tickLine={false}
              axisLine={{ stroke: "var(--font-color)", strokeWidth: 1.5 }}
              tick={{ fontSize: 12, fill: "var(--font-color)" }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "var(--tooltip-background-color)",
                border: "none",
                borderRadius: "10px",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
              }}
              labelStyle={{
                fontSize: "var(--heading-font-size)",
                color: "var(--black-color)",
                fontWeight: "bold",
              }}
              itemStyle={{
                color: "var(--black-color)",
                fontWeight: "bold",
              }}
            />
            <Area
              type="monotone"
              dataKey={sensorName}
              stroke="var(--line-chart-stroke-color)"
              strokeWidth={2}
              fill="var(--line-chart-fill-color)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  ) : (
    <p>No Records Found!</p>
  );
};

export default TrendCanvasChart;
