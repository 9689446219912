import React from "react";
import "../../../../../common/styles/backupRestoreStyle.css";

const BackupRestore = ({
  configType,
  handleBackupClick,
  handleRestoreClick,
}) => {
  return (
    <div className="backupRestore">
      <div className="backupRestoreConfig">{configType}</div>
      <div className="backupRestoreConfigBtns">
        <div className="filled-btn-style" onClick={handleBackupClick}>
          Backup
        </div>
        <div className="filled-btn-style" onClick={handleRestoreClick}>
          Restore
        </div>
      </div>
    </div>
  );
};

export default BackupRestore;
