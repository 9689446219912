import React, { useEffect, useState } from "react";
import PointDetailsNavigator from "./pointDetailsNavigator.jsx";
import PointDetailsProperties from "./pointDetailsProperties.jsx";
import LiveDataWidget from "./liveDataWidget";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PointDetailsConfig from "./pointDetailsConfig.jsx";
import PointDetailsAlarmsTable from "./pointDetailsAlarmsTable.jsx";
import PointDetailsTrendsTable from "./pointDetailsTrendsTable.jsx";
import CustomHeader from "../../../../reusableComponents/customHeader.jsx";

const PointDetails = ({
  networkProps,
  setPointDetails,
  pointProps,
  setSelectedPoint,
  permissions,
  alarmHigh,
  setAlarmHigh,
  setAlarmLow,
  alarmLow,
  deviceProps,
  pointDetails,
  setSelectedItem,
}) => {
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState("Properties");
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  const goBack = () => {
    setPointDetails(false);
    setSelectedItem(null);
    navigate("/devices");
  };
  useEffect(() => {
    console.log(pointDetails);
  }, [pointDetails]);
  useEffect(() => {
    return () => {
      setSelectedOption("Properties");
    };
  }, [pointProps]);

  return (
    <div className="deviceDetailsPointsStyle">
      <CustomHeader
        heading={`${pointProps?.pointName}-${pointProps?.pointDeviceInstance}`}
      />
      <div className={`deviceDetailsPointsStyle container-style`}>
        <div className="deviceDetailsContent point">
          <div className="deviceDetailsContentTabs">
            <div className="deviceDetailsContentLeft">
              <LiveDataWidget
                pointProps={pointProps}
                permissions={permissions}
                alarmHigh={alarmHigh}
                alarmLow={alarmLow}
                setAlarmHigh={setAlarmHigh}
                deviceProps={deviceProps}
                setAlarmLow={setAlarmLow}
              />
            </div>

            <div className="deviceDetailsContentRight">
              <PointDetailsNavigator
                onOptionClick={handleOptionClick}
                pointProps={pointProps}
              />
              {selectedOption === "Properties" && (
                <PointDetailsProperties
                  pointProps={pointProps}
                  setSelectedPoint={setSelectedPoint}
                  permissions={permissions}
                />
              )}
              {selectedOption === "Config" && (
                <PointDetailsConfig
                  pointProps={pointProps}
                  permissions={permissions}
                />
              )}
              {selectedOption === "Alarms" && (
                <PointDetailsAlarmsTable
                  pointProps={pointProps}
                  permissions={permissions}
                />
              )}
              {selectedOption === "Trends" && (
                <PointDetailsTrendsTable
                  pointProps={pointProps}
                  permissions={permissions}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointDetails;
