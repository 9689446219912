import React from "react";
import "../../../../../common/styles/trendTableStyle.css";
import CustomTable from "../../../../reusableComponents/customTable";

const headings = [
  {
    id: "timestamp",
    label: "Timestamp",
  },
  {
    id: "value",
    label: "Recorded Value",
  },
  {
    id: "valueQuality",
    label: "Value Quality",
  },
];

export default function TrendCanvasTable({ timestamp, valueQuality, value }) {
  const data = [];
  if (!timestamp || !valueQuality || !value) {
    return null;
  }
  if (
    timestamp.length !== valueQuality.length ||
    timestamp.length !== value.length
  ) {
    return null;
  }
  const rows = createData(timestamp, valueQuality, value);

  function createData(timestamp, valueQuality, value) {
    for (let i = 0; i < timestamp.length; i++) {
      data.push({
        timestamp: timestamp[i],
        valueQuality: valueQuality[i],
        value: value[i],
      });
    }
    return data;
  }

  return (
    <CustomTable
      data={rows}
      headings={headings}
      key={"trendTable"}
      totalRows={rows.length}
      totalPlaceholder={"Trends"}
    />
  );
}
