/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import "../../../../common/styles/display.css";

import { useMenu } from "../../../../common/utils/menuContext.js";
import getPermissions from "../../../../configuration/localStorage/getPermissions.js";
import PreLoader from "../../../reusableComponents/preLoader.js";
import Canvas from "./components/canvas.jsx";
import DisplayDataConfigurationForm from "./components/displayDataConfigurationForm.jsx";
import DisplayNavigator from "./components/displayNavigator.jsx";
import Widgets from "./components/widgets.jsx";
import PrebuiltMiniWidgets from "./components/PrebuiltminiWidgets.jsx";
import { useNavigate, useParams } from "react-router-dom";
import FloorLayoutWidgets from "../../display/presentation/components/floorLayoutWidgets.jsx";
import api from "../../../../common/utils/axiosRequest.js";

const PrebuiltDisplay = () => {
  const { displayId } = useParams();
  const navigate = useNavigate();
  const [permissions] = useState(getPermissions);
  const { setOpen, floorLayout, config } = useMenu();

  const [droppedItems, setDroppedItems] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [newDashboardName, setNewDashboardName] = useState("");
  const [selectedFloorWidget, setSelectedFloorWidget] = useState({});
  const [hovered, setHovered] = useState(false);
  const [hoveredPin, setHoveredPin] = useState({});
  const [draggingWidget, setDraggingWidget] = useState(null);
  const [draggedInsideImg, setDraggedInsideImg] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [layout, setLayout] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [view, setView] = useState("Edit");
  const [itemProperties, setItemProperties] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [configure, setConfigure] = useState(false);
  const [chartId, setChartId] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedDisplay, setSelectedDisplay] = useState("");
  const [selectedDisplayIndex, setSelectedDisplayIndex] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState({
    id: "",
    name: "",
    parentId: "",
  });
  const [addToDevicesList, setAddToDevicesList] = useState([]);
  const [searchDevices, setSearchDevices] = useState("");
  const [deviceList, setDeviceList] = useState([]);
  const [addDeviceTo, setAddDeviceTo] = useState({
    id: "",
    name: "",
    parentId: "",
  });
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const [addDevice, setAddDevice] = useState(false);
  const [id, setId] = useState();
  const [selectedWidget, setSelectedWidget] = useState(null);
  const base64Credentials = btoa(`${username}:${password}`);
  const configureData = (chartId) => {
    setConfigure(true);
    setChartId(chartId);
  };
  useEffect(() => {
    const loadDashboard = async () => {
      if (displayId) {
        handleLoadDashboard({ _id: displayId });
      }
    };
    if (id === undefined) {
      loadDashboard();
    }
  }, [displayId]);

  useEffect(() => {
    const getDevicesList = async () => {
      const response = await api.get(`${dbUrl}/devices/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      const devices = response.data.data.rows
        .map((device) => ({
          id: device.doc.deviceInstance,
          name: device.doc.deviceName,
          parentId: device.doc._id,
        }))
        .flat();

      setDeviceList(devices);
    };
    getDevicesList();
  }, []);

  const handleDeviceChange = (device) => {
    setAddToDevicesList((prevList) =>
      prevList.includes(device)
        ? prevList.filter((d) => d !== device)
        : [...prevList, device]
    );
  };

  const filteredDeviceList = deviceList.filter((device) =>
    `${device.id}-${device.name.toLowerCase()}`.includes(
      searchDevices.toLowerCase()
    )
  );
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setAddToDevicesList(filteredDeviceList);
    } else {
      setAddToDevicesList([]);
    }
  };

  const addDeviceToTemplate = async (templateId, templateName) => {
    setIsLoading(true);
    try {
      const getTemplate = await api.get(
        `${dbUrl}/prebuiltdisplays/${templateId}`
      );
      const { devices } = getTemplate.data.data;
      const newDevicesToAdd = addToDevicesList.filter(
        (newDevice) =>
          !devices.some((existingDevice) => existingDevice.id === newDevice.id)
      );

      const updatedDevices = [...devices, ...newDevicesToAdd];
      console.log(updatedDevices);
      const addDeviceToTemplate = await api.put(
        `${dbUrl}/prebuiltdisplays/${templateId}`,
        {
          devices: updatedDevices,
        }
      );

      if (addDeviceToTemplate.status === 200) {
        toast.success(`Devices added to ${templateName} successfully`);
        loadDashboards();
        setIsLoading(true);
        setAddDevice(false);
      }
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };

  const removeDeviceFromTemplate = async (
    templateId,
    templateName,
    deviceId,
    deviceName
  ) => {
    setIsLoading(true);
    try {
      const getTemplate = await api.get(
        `${dbUrl}/prebuiltdisplays/${templateId}`
      );
      const { devices } = getTemplate.data.data;
      const updatedDevices = devices.filter(
        (existingDevice) => existingDevice.id !== deviceId
      );

      const response = await api.put(
        `${dbUrl}/prebuiltdisplays/${templateId}`,
        {
          devices: updatedDevices,
        }
      );

      if (response.status === 200) {
        toast.success(
          `${deviceId}-${deviceName} removed from ${templateName} successfully`
        );
        loadDashboards();
        setAddDevice(false);
        setSelectedDevice({ id: "", name: "", parentId: "" });
      }
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = (chartId, newData) => {
    setDroppedItems((prevItems) => {
      const updatedCharts = prevItems.map((chart) =>
        chart.id === chartId ? { ...chart, data: newData } : chart
      );
      setSelectedWidget(null);
      return updatedCharts;
    });
    setConfigure(false);
  };
  const handleSaveExistingDashboard = async (existingDashboard) => {
    try {
      const updatedDashboard = {
        ...existingDashboard,
        name: newDashboardName ? newDashboardName : "Untilted Display",
        widgets: droppedItems,
        layout: layout,
        floorWidgets: widgets,
      };
      const updateDashboard = await api.put(
        `${dbUrl}/prebuiltdisplays/${existingDashboard._id}`,
        updatedDashboard,
        {
          headers: {
            Authorization: `Basic ${base64Credentials}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (updateDashboard.status === 200) {
        toast.success(`Updated Dashboard - ${newDashboardName}`);
        // setView("View");
        loadDashboards();
        // setOpen(true);
      }
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  const handleSaveAsDashboard = async () => {
    setIsPopupOpen(true);
    setId();
  };
  const handleSaveDashboard = async () => {
    // setIsLoading(true);
    try {
      const getDashboards = await api.get(
        `${dbUrl}/prebuiltdisplays/_all_docs`,
        {
          params: {
            include_docs: true,
          },
        }
      );
      const existingDashboards = getDashboards.data.data.rows.map(
        (row) => row.doc
      );
      const existingDashboard = existingDashboards.find(
        (dashboard) => dashboard._id === id
      );

      if (existingDashboard) {
        handleSaveExistingDashboard(existingDashboard);
      } else {
        const newDashboard = {
          _id: id ? id : uuidv4(),
          name: newDashboardName ? newDashboardName : "Untitled Display",
          widgets: droppedItems,
          layout: layout,
          devices: [],
          floorWidgets: widgets,
        };
        try {
          const saveDashboardtoDB = await api.post(
            `${dbUrl}/prebuiltdisplays`,
            newDashboard
          );
          if (saveDashboardtoDB.status === 200) {
            toast.success(`Saved Dashboard - ${newDashboard.name}`);
            navigate(`/prebuiltdisplays/${newDashboard._id}`, {
              state: {
                view: "Edit",
                id: newDashboard._id,
                newDashboardName: newDashboard.name,
              },
              replace: true,
            });
            setId(newDashboard._id);
            setNewDashboardName(newDashboard.name);
            setIsPopupOpen(false);
          } else {
            toast.error(`${newDashboardName} Already Exists`);
            alert("Error occcurred Here");
          }
        } catch (error) {
          console.log(error);
        }
      }
      loadDashboards();
      setIsLoading(false);
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };

  const loadDashboards = async () => {
    // setIsLoading(true);
    try {
      const database = "prebuiltdisplays";
      const getDashboards = await api.get(`${dbUrl}/${database}/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      if (getDashboards.status === 200) {
        const displays = getDashboards.data.data.rows.map((row) => row.doc);
        setDashboards(displays);
        console.log(displays);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };

  const handleLoadDashboard = async (dashboard) => {
    setId(dashboard._id);
    try {
      const getDashboard = await api.get(
        `${dbUrl}/prebuiltdisplays/${dashboard._id}`
      );
      setNewDashboardName(getDashboard.data.data.name);
      const updatedItemProperties = {};
      getDashboard?.data.data?.layout?.forEach((item) => {
        updatedItemProperties[item.i] = {
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
        };
      });
      setView("View");
      setItemProperties(updatedItemProperties);
      setLayout(getDashboard.data.data.layout);
      setDroppedItems(getDashboard.data.data.widgets);
      setWidgets(getDashboard.data.data.floorWidgets);
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    }
  };
  const handleDeleteDashboard = async (dashboard) => {
    setConfirmDelete(false);
    try {
      const getDashboard = await api.get(
        `${dbUrl}/prebuiltdisplays/${dashboard}`,
        {
          params: {
            include_docs: true,
          },
        }
      );
      setIsLoading(true);
      const deleteDashboardfromDB = await api.delete(
        `${dbUrl}/prebuiltdisplays/${getDashboard.data.data._id}`
      );
      if (deleteDashboardfromDB.status === 200) {
        toast.success(`Dashboard With ID - ${dashboard} Deleted Successfully`);
      }
      loadDashboards();
      if (dashboard === id) {
        setNewDashboardName("");
        setDroppedItems([]);
        setId();
      }
      navigate("/prebuiltdisplays");
      setIsLoading(false);
    } catch (error) {
      console.log(`Unexpected Error Occurred: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  const handleNewDashboard = () => {
    navigate("/prebuiltdisplays");
    setId("");
    setNewDashboardName("");
    setDroppedItems([]);
    setView("Edit");
    setOpen(false);
  };
  useEffect(() => {
    loadDashboards();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && (e.key === "s" || e.key === "S")) {
        e.preventDefault();
        console.log(id);
        handleSaveDashboard();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [id, droppedItems, layout, newDashboardName]);

  const handleDragStartWidgetContainer = (e, item) => {
    e.dataTransfer.setData("item", JSON.stringify(item));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const findAvailablePosition = () => {
    let maxY = 0;
    layout.forEach((item) => {
      if (item.y + item.h > maxY) {
        maxY = item.y + item.h;
      }
    });

    return { x: 0, y: maxY, w: 5, h: 6 };
  };
  const handleCanvasDrop = (e) => {
    const widgetType = e.dataTransfer?.getData("widgetType");
    console.log(widgetType);
    if (widgetType || draggedInsideImg) return;
    else {
      console.log(e);
      toast.success("Widget Added", {
        position: "top-center",
        autoClose: 100,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          width: "fit-content",
          minHeight: "10px!important",
          fontSize: "14px",
          textAlign: "center",
          borderRadius: "10px",
        },
        // transition: "Zoom",
      });
      e.preventDefault();
      const item = JSON.parse(e.dataTransfer.getData("item"));
      const newItem = { ...item, id: `${item.id}-${uuidv4()}` };

      const newLayoutItem = findAvailablePosition();

      setLayout((prevLayout) => [
        ...prevLayout,
        { i: newItem.key, ...newLayoutItem },
      ]);
      setDroppedItems((prevItems) => [...prevItems, newItem]);
    }
  };
  const handleDelete = (index, chart) => {
    const updatedDroppedItems = [...droppedItems];
    updatedDroppedItems.splice(index, 1);
    setDroppedItems(updatedDroppedItems);
  };

  return (
    <div className="display">
      {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : null}
      <DisplayNavigator
        loadDashboards={loadDashboards}
        dashboards={dashboards}
        handleLoadDashboard={handleLoadDashboard}
        handleDeleteDashboard={handleDeleteDashboard}
        handleNewDashboard={handleNewDashboard}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
        permissions={permissions}
        selectedDevice={selectedDevice}
        setSelectedDevice={setSelectedDevice}
        selectedDisplay={selectedDisplay}
        setSelectedDisplay={setSelectedDisplay}
        selectedDisplayIndex={selectedDisplayIndex}
        setSelectedDisplayIndex={setSelectedDisplayIndex}
        setAddDevice={setAddDevice}
        setAddDeviceTo={setAddDeviceTo}
        setAddToDevicesList={setAddToDevicesList}
        removeDeviceFromTemplate={removeDeviceFromTemplate}
        id={id}
      />
      {addDevice && (
        <div className="overlay">
          <div className="addDeviceToTemplate">
            <div className="addDeviceToTemplateContent custom-dialog-style">
              <div className="addDeviceToTemplateHeading">
                Add Devices to {addDeviceTo.name}
              </div>
              <div className="addDeviceToTemplateForm">
                <div className="input-style">
                  <input
                    type="text"
                    placeholder="Search Devices"
                    value={searchDevices}
                    onChange={(e) => setSearchDevices(e.target.value)}
                  />
                  <IoSearchOutline
                    size={20}
                    className="addDeviceToTemplateSearchBoxSearchIcon"
                  />
                </div>
                <div className="addDeviceToTemplateFormInputGroup">
                  <input
                    type="checkbox"
                    id="selectAll"
                    name="selectAll"
                    checked={
                      addToDevicesList.length === deviceList.length &&
                      deviceList.length > 0
                    }
                    onChange={handleSelectAll}
                  />
                  <label htmlFor="selectAll">Select All</label>
                </div>
                <div className="addDeviceToTemplateFormList">
                  {filteredDeviceList.map((device) => (
                    <div
                      className="addDeviceToTemplateFormDeviceInputGroup"
                      key={device.id}
                    >
                      <input
                        type="checkbox"
                        id={device.id}
                        name={device.id}
                        value={device.id}
                        checked={addToDevicesList.includes(device)}
                        onChange={() => handleDeviceChange(device)}
                      />
                      <label htmlFor={device.id}>
                        {device.id} - {device.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="addDeviceToTemplateBtns">
                <div
                  className="outline-btn-style"
                  onClick={() => setAddDevice(false)}
                >
                  Cancel
                </div>
                <div
                  className="filled-btn-style"
                  onClick={() =>
                    addDeviceToTemplate(addDeviceTo.id, addDeviceTo.name)
                  }
                >
                  Add
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="canvasContainer">
        <Canvas
          handleCanvasDrop={handleCanvasDrop}
          handleDragOver={handleDragOver}
          handleNewDashboard={handleNewDashboard}
          droppedItems={droppedItems}
          handleDelete={handleDelete}
          view={view}
          handleSave={handleSave}
          setView={setView}
          layout={layout}
          handleLoadDashboard={handleLoadDashboard}
          setLayout={setLayout}
          configureData={configureData}
          setItemProperties={setItemProperties}
          itemProperties={itemProperties}
          handleSaveExistingDashboard={handleSaveExistingDashboard}
          newDashboardName={newDashboardName}
          handleSaveAsDashboard={handleSaveAsDashboard}
          handleSaveDashboard={handleSaveDashboard}
          setNewDashboardName={setNewDashboardName}
          permissions={permissions}
          selectedWidget={selectedWidget}
          setSelectedWidget={setSelectedWidget}
          draggingWidget={draggingWidget}
          setDraggingWidget={setDraggingWidget}
          selectedFloorWidget={selectedFloorWidget}
          setSelectedFloorWidget={setSelectedFloorWidget}
          widgets={widgets}
          setWidgets={setWidgets}
          setDraggedInsideImg={setDraggedInsideImg}
          setHovered={setHovered}
          setHoveredPin={setHoveredPin}
          hovered={hovered}
          hoveredPin={hoveredPin}
          selectedDevice={selectedDevice}
        />
        {/* <PreBuiltDisplayCanvas
          selectedDisplay={selectedDisplay}
          selectedDevice={selectedDevice}
        /> */}
      </div>
      {view === "Edit" && permissions.settings.write && (
        <>
          {floorLayout ? (
            <FloorLayoutWidgets
              selectedFloorWidget={selectedFloorWidget}
              widgets={widgets}
              setWidgets={setWidgets}
            />
          ) : (
            <Widgets
              handleDragStartWidgetContainer={handleDragStartWidgetContainer}
            />
          )}
          <PrebuiltMiniWidgets />
        </>
      )}
      {configure ? (
        <div className="overlay" onClick={() => setConfigure(false)}>
          <DisplayDataConfigurationForm
            configure={configure}
            chartId={chartId}
            droppedItems={droppedItems}
            handleSave={handleSave}
            setConfigure={setConfigure}
            setSelectedWidget={setSelectedWidget}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      ) : null}

      {isPopupOpen ? (
        <div className={`saveDialog `}>
          <div className="saveHeading">Save Display</div>
          <input
            type="text"
            name="saveDashboard"
            id="saveDashboard"
            placeholder="Enter Display Name"
            onChange={(e) => setNewDashboardName(e.target.value)}
          />
          <div className="saveDialogBtns">
            <div onClick={() => handleSaveDashboard()}>Save</div>
            <div onClick={() => setIsPopupOpen(false)}>Cancel</div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PrebuiltDisplay;
