import Cookies from "js-cookie";

function getUser() {
  let user = Cookies.get("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

export default getUser;
