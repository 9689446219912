import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMenu } from "../../../../../common/utils/menuContext";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import TruncateText from "../../../../../common/utils/truncateText";
import { toast } from "react-toastify";
import CustomNavigator from "../../../../reusableComponents/customNavigator";
import api from "../../../../../common/utils/axiosRequest";

const ReportsBrowser = ({
  setSelectedReportTemplate,
  selectedReportTemplate,
  setReportData,
  setSelectedReport,
  reportData,
  selectedReport,
}) => {
  const { setReloadData, reloadData, open, config } = useMenu();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const [reports, setReports] = useState([]);
  const [expandedTemplates, setExpandedTemplates] = useState({});

  const handleSelectReportData = (report) => {
    setReportData({
      _id: report._id,
      reportName: report.reportName,
      reportDesc: report.reportDesc,
      tags: report.tags,
      interval: report.interval,
      startDate: report.startDate,
      endDate: report.endDate,
      startTime: report.startTime,
      endTime: report.endTime,
      filterEnable: report.filterEnable,
      reportTemplate: report.reportTemplate,
      selectedNetworks: report.selectedNetworks,
      selectedPoints: report.selectedPoints,
      seletedDevices: report.seletedDevices,
      periodicReport: report.periodicReport,
      period: report.period,
      timeOfReport: report.timeOfReport,
      dayOfReport: report.dayOfReport,
      weekOfReport: report.weekOfReport,
      monthOfReport: report.monthOfReport,
      generatedReport: report.generatedReport,
    });
    setSelectedReportTemplate(report.reportTemplate);
  };

  const handleSelectTemplate = (template) => {
    setSelectedReportTemplate(template);
    setSelectedReport("");
    setReportData({
      _id: "",
      reportName: "",
      reportDesc: "",
      tags: "",
      interval: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      filterEnable: false,
      reportTemplate: selectedReportTemplate,
      selectedNetworks: [],
      selectedPoints: [],
      seletedDevices: [],
      periodicReport: false,
      period: "Daily",
      timeOfReport: "",
      dayOfReport: "",
      weekOfReport: "1",
      monthOfReport: "jan",
      generatedReport: {},
    });
  };

  const reportTemplates = [
    "Standard Alarm Report",
    // "Instantaneous Data Report",
    // "History Report",
    // "Energy Consumption Report",
    "Matrix Report",
  ];

  useEffect(() => {
    const getReports = async () => {
      const reports = await api.get(`${dbUrl}/reports/_all_docs`, {
        params: {
          include_docs: true,
        },
      });
      const reportsData = reports.data.data.rows.map((row) => row.doc);
      setReports(reportsData);
    };

    getReports();
    setReloadData(false);
  }, [dbUrl, reloadData, setReloadData]);

  const toggleTemplate = (template) => {
    setExpandedTemplates((prevState) => ({
      ...prevState,
      [template]: !prevState[template],
    }));
  };

  const handleDeleteReport = async (reportId) => {
    try {
      const deleteReport = await api.delete(`${dbUrl}/reports/${reportId}`);
      if (deleteReport.status === 200) {
        toast.success("Report Deleted Successfully");
        setReloadData(true);
        setSelectedReport("");
        setReportData({
          _id: "",
          reportName: "",
          reportDesc: "",
          tags: "",
          interval: "",
          startDate: "",
          endDate: "",
          startTime: "",
          endTime: "",
          filterEnable: false,
          reportTemplate: selectedReportTemplate,
          selectedNetworks: [],
          selectedPoints: [],
          seletedDevices: [],
          periodicReport: false,
          period: "Daily",
          timeOfReport: "",
          dayOfReport: "",
          weekOfReport: "1",
          monthOfReport: "jan",
          generatedReport: {},
        });
      }
    } catch (error) {
      console.error("Failed to delete report:", error);
      toast.error("Failed to delete report");
    }
  };

  return (
    open && (
      <CustomNavigator>
        <div className={`reportsBrowser `}>
          <div className={`reportsBrowserHeader `}>
            <span>Reports Browser</span>
          </div>
          <div className="reportsBrowserContent">
            <ul className="reportsList">
              {reportTemplates.map((template) => (
                <li key={template}>
                  <span
                    className={`${
                      selectedReportTemplate === template
                        ? "selectedReport"
                        : ""
                    }`}
                  >
                    {expandedTemplates[template] ? (
                      <IoIosArrowDown
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                        size={20}
                        onClick={() => toggleTemplate(template)}
                      />
                    ) : (
                      <IoIosArrowForward
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                        size={20}
                        onClick={() => toggleTemplate(template)}
                      />
                    )}
                    <div onClick={() => handleSelectTemplate(template)}>
                      {TruncateText(template, 20)}
                    </div>
                  </span>
                  {expandedTemplates[template] && (
                    <ul className="reportsInsideTemplate">
                      {reports.map(
                        (report) =>
                          report.reportTemplate === template && (
                            <li
                              style={{ fontSize: "13px", fontWeight: "600" }}
                              key={report._id}
                              // className={`${
                              //   selectedReport === report._id
                              //     ? "selectedReport"
                              //     : ""
                              // }`}
                            >
                              <span>
                                <div
                                  onClick={() => handleSelectReportData(report)}
                                >
                                  {TruncateText(report.reportName, 15)}
                                </div>
                                <MdDelete
                                  onClick={() => handleDeleteReport(report._id)}
                                  style={{
                                    cursor: "pointer",
                                    color: "var(--light-font-color)",
                                    margin: "auto",
                                    padding: "3px",
                                    borderRadius:
                                      "var(--delete-button-border-radius)",
                                    backgroundColor: "var(--light-red-color)",
                                  }}
                                />
                              </span>
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </CustomNavigator>
    )
  );
};

export default ReportsBrowser;
