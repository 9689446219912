import axios from "axios";
import getUser from "../../configuration/localStorage/getUser";
import { toast } from "react-toastify";

const api = axios.create();

api.interceptors.request.use(
  (config) => {
    const user = getUser();
    if (!user) {
      toast.info("Session Timed Out, Please Sign-in Again");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1500);

      throw new axios.Cancel("Session Timed Out");
    }

    const orgID = user?.orgId;
    const url = new URL(config.url, window.location.origin);
    const port = url.port;
    const host = config?.REACT_APP_HOST;
    const baseURLs = {
      5001: `http://${host}:5001/bacs/api/v1`,
      5002: `http://${host}:5002/bacs/api/v1`,
      5003: `http://${host}:5003/bacs/api/v1`,
      5004: `http://${host}:5004/bacs/api/v1`,
      5005: `http://${host}:5005/bacs/api/v1`,
      5006: `http://${host}:5006/bacs/api/v1`,
    };
    if (baseURLs[port]) {
      config.baseURL = baseURLs[port];
    }
    config.headers["X-Org-ID"] = orgID;
    return config;
  },
  (error) => {
    toast.error("Request setup failed!");
    return Promise.reject(error);
  }
);

// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (!axios.isCancel(error)) {
//       const status = error.response?.status;
//       if (status === 404) {
//         // toast.warn("Resource not found.");
//       } else if (status === 500) {
//         toast.error("Server error. Please try again later.");
//       } else {
//         toast.error(`Error: ${error.message}`);
//       }
//     }
//     return new Promise(() => {});
//   }
// );

export default api;
