/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "../../../../../../common/styles/commandWidgetStyle.css";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { isEmpty } from "../../../../../../common/utils/isEmpty";
import { useMenu } from "../../../../../../common/utils/menuContext";
import api from "../../../../../../common/utils/axiosRequest";

const CommandWidget = ({ chart, selectedDevice }) => {
  const [isChecked, setIsChecked] = useState(false);
  const { config } = useMenu();
  const serverEndpoint = config?.REACT_APP_POLLING_ENDPOINT;
  // const [isLoading, setIsLoading] = useState(false);
  const [pointValue, setPointValue] = useState();
  const [pointData, setPointData] = useState({});
  const [deviceName, setDeviceName] = useState("");
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;

  const fetchPoint = async () => {
    try {
      const pointName = chart?.data?.points;
      const deviceId = selectedDevice?.id ? selectedDevice?.id : "";

      // Fetch parent device data
      const parentDeviceResponse = await api.get(
        `${dbUrl}/devices/${selectedDevice.parentId}`
      );
      const parentDevice = parentDeviceResponse.data.data.devices.find(
        (deviceInstance) => deviceInstance.deviceInstance === parseInt(deviceId)
      );

      if (!parentDevice) {
        console.log("Device not found in parent device data");
        // setIsLoading(false);
        setPointValue();
        return;
      }

      // Find the point ID
      const point = parentDevice.points.find((point) =>
        point.objectName.toLowerCase().includes(pointName.toLowerCase())
      );

      if (!point) {
        console.log("Point not found");
        // setIsLoading(false);
        setPointValue();
        return;
      }

      const pointId = point.pointId;
      console.log(pointId);

      // Fetch point data
      const pointResponse = await api.get(`${dbUrl}/points/${pointId}`);
      setPointData(pointResponse.data.data);

      // Fetch device name
      const deviceResponse = await api.get(
        `${dbUrl}/devices/${selectedDevice.parentId}`
      );
      setDeviceName(deviceResponse.data.data.deviceName);
    } catch (error) {
      console.error(`Unexpected Error Occurred: ${error}`);
    } finally {
      // setIsLoading(false); // Ensure loading is set to false at the end
    }
  };

  useEffect(() => {
    if (chart?.data?.points) {
      fetchPoint();
      const fetchDataInterval = setInterval(() => {
        fetchPoint();
      }, 5000);
      return () => {
        clearInterval(fetchDataInterval);
      };
    } else {
      // setIsLoading(false);
    }
  }, [chart?.data?.points]);

  const handleWrite = async (data) => {
    try {
      if (!isEmpty(pointValue)) {
        const updateValue = await api.post(
          `${serverEndpoint}/update_point_value`,
          {
            macAddress: data.pointDeviceMac,
            deviceId: data.pointDeviceInstance,
            objectType: data.pointType,
            objectInstance: data.pointInstance,
            value: parseFloat(pointValue),
            networkType: data.networkType,
            networkAddress: data.networkAddress,
            networkPort: data.networkPort,
            dataType: data?.pointDataType,
            msw: data?.msw,
            lsw: data?.lsw,
          }
        );

        setPointValue();

        if (updateValue.status === 200 && !updateValue.data.error) {
          toast.success("Point Written Successfully");
        } else {
          toast.error("Failed to Update, Possible Cause: Point is Readonly ");
          setPointValue();
        }
      } else {
        toast.warning("Cannot Write Empty Value to a Point");
        setPointValue();
      }
    } catch (error) {
      toast.error(`Failed to Update, Possible Cause: ${error}`);
      setPointValue();
    }
  };

  return (
    <div className="commandWidget">
      <div className="pointInfos">
        <div className="pointInfo">Device : {deviceName ? deviceName : ""}</div>
        <div className="pointInfo">
          Point : {pointData ? pointData?.pointName : ""}
        </div>
        <div className="pointInfo">
          PresentValue : {pointData ? pointData?.pointPresentValue : ""}
        </div>
      </div>
      <div className="pointWriteInfo">
        <span>Write Point</span>
        <input
          type="checkbox"
          checked={chart?.data?.pointEnable}
          onChange={(e) => setIsChecked(e.target.checked)}
        />
        {isChecked && (
          <input
            className="commandWidgetInput"
            type="number"
            value={pointValue}
            onBlur={() => handleWrite(pointData)}
            onChange={(e) => setPointValue(e.target.value)}
          />
        )}
      </div>
    </div>
  );
};

export default CommandWidget;
