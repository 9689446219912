/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "../../../../../common/styles/pointDetailsAlarmsStyle.css";
import PreLoader from "../../../../reusableComponents/preLoader";
import { toast } from "react-toastify";
import { useEffect } from "react";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import CustomFormSelect from "../../../../reusableComponents/customFormSelect";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";
import getUser from "../../../../../configuration/localStorage/getUser";

const PointDetailsAlarms = ({ pointProps, permissions }) => {
  const { config } = useMenu();
  const [alarmLowLimit, setAlarmLowLimit] = useState();
  const [alarmHighLimit, setAlarmHighLimit] = useState();
  const [alarmHighLimitEnable, setAlarmHighLimitEnable] = useState(false);
  const [alarmLowLimitEnable, setAlarmLowLimitEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pollFrequency, setPollFrequency] = useState("");
  const [alarmHighPriority, setAlarmHighPriority] = useState("Critical");
  const [alarmLowPriority, setAlarmLowPriority] = useState("Critical");
  const [alarmLowLimitMessage, setAlarmLowLimitMessage] = useState("");
  const [alarmHighLimitMessage, setAlarmHighLimitMessage] = useState("");
  const serverEndpoint = config?.REACT_APP_ALARM_ENDPOINT;
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const user = getUser();

  const fetchPointsFromDB = async () => {
    // setIsLoading(true);
    try {
      const fetch = await api.get(`${dbUrl}/points/${pointProps?._id}`);
      const alarmHighPriority = fetch.data.data["alarmHighPriority"];
      const alarmLowPriority = fetch.data.data["alarmLowPriority"];
      const alarmHigh = fetch.data.data["alarmHighLimit"];
      const alarmLow = fetch.data.data["alarmLowLimit"];
      const alarmHighMsg = fetch.data.data["alarmHighLimitMessage"];
      const alarmLowMsg = fetch.data.data["alarmLowLimitMessage"];
      const alarmHighEnable = fetch.data.data["alarmHighLimitEnable"] ?? false;
      const alarmLowEnable = fetch.data.data["alarmLowLimitEnable"] ?? false;
      const pollFrequency = fetch.data.data["pointPollFrequency"];

      setAlarmHighPriority(alarmHighPriority);
      setAlarmLowPriority(alarmLowPriority);
      setAlarmHighLimit(alarmHigh);
      setAlarmLowLimit(alarmLow);
      setAlarmHighLimitEnable(alarmHighEnable);
      setAlarmLowLimitEnable(alarmLowEnable);
      setAlarmHighLimitMessage(alarmHighMsg);
      setAlarmLowLimitMessage(alarmLowMsg);
      setPollFrequency(pollFrequency);
      const data = {
        alarmHighPriority,
        alarmLowPriority,
        alarmHigh,
        alarmLow,
        alarmHighMsg,
        alarmLowMsg,
        alarmHighEnable,
        alarmLowEnable,
        pollFrequency,
      };
    } catch (error) {
      console.error("Error Fetching Point Information:", error);
    }
    // setIsLoading(false);
  };
  useEffect(() => {
    fetchPointsFromDB();
  }, []);
  const handleAlarmSave = async () => {
    try {
      if (alarmHighPriority !== null || alarmLowPriority !== null) {
        setIsLoading(true);

        const alarmData = {
          alarmId: `alarm-${pointProps?._id}`,
          alarmHighPriority: alarmHighPriority ? alarmHighPriority : "Critical",
          alarmLowPriority: alarmLowPriority ? alarmLowPriority : "Critical",
          alarmLowLimit: alarmLowLimit,
          alarmHighLimit: alarmHighLimit,
          alarmLowLimitMessage: alarmLowLimitMessage,
          alarmHighLimitMessage: alarmHighLimitMessage,
          alarmLowLimitEnable: alarmLowLimitEnable,
          alarmHighLimitEnable: alarmHighLimitEnable,
          networkInstance: pointProps?.networkInstance,
          deviceInstance: pointProps?.pointDeviceInstance,
          deviceMac: pointProps?.pointDeviceMac,
          pointId: pointProps?.pointId,
        };
        try {
          const updatedDoc = {
            ...(alarmData || {}),
            ...alarmData,
          };
          await api.put(`${dbUrl}/points/${pointProps?._id}`, updatedDoc);
        } catch (error) {
          console.error(`Unexpected Error Occurred ${error}`);
        }
        if (alarmHighLimitEnable && alarmLowLimitEnable) {
          if (
            alarmHighLimit &&
            alarmHighLimitMessage &&
            alarmLowLimit &&
            alarmLowLimitMessage
          ) {
            const enableAlarm = await api.post(
              `${serverEndpoint}/enable_alarm`,
              {
                pointId: pointProps?._id,
                alarmHighPriority: alarmHighPriority
                  ? alarmHighPriority
                  : "Critical",
                alarmLowPriority: alarmLowPriority
                  ? alarmLowPriority
                  : "Critical",
                source: `${pointProps?.pointDeviceInstance}-${pointProps?.pointName}`,
                sourceState: pointProps?.pointStatus,
                alarmHighLimit: alarmHighLimit,
                alarmLowLimit: alarmLowLimit,
                alarmHighEnable: alarmHighLimitEnable,
                alarmLowEnable: alarmLowLimitEnable,
                alarmHighLimitMessage: alarmHighLimitMessage,
                alarmLowLimitMessage: alarmLowLimitMessage,
                pollFrequency: pollFrequency,
                orgId: user?.orgId,
              }
            );
            if (enableAlarm.status === 200) {
              toast.success("Saved Changes");
            }
          } else {
            toast.warning(
              "Alarm High/Low Limit and High/Low Limit Message Cannot be Empty"
            );
          }
        } else if (alarmHighLimitEnable) {
          if (alarmHighLimit && alarmHighLimitMessage) {
            const enableAlarm = await api.post(
              `${serverEndpoint}/enable_alarm`,
              {
                pointId: pointProps?._id,
                orgId: user?.orgId,
                alarmHighPriority: alarmHighPriority
                  ? alarmHighPriority
                  : "Critical",
                alarmLowPriority: alarmLowPriority
                  ? alarmLowPriority
                  : "Critical",
                source: `${pointProps?.pointDeviceInstance}-${pointProps?.pointName}`,
                sourceState: pointProps?.pointStatus,
                alarmHighLimit: alarmHighLimit,
                alarmLowLimit: alarmLowLimit,
                alarmHighEnable: alarmHighLimitEnable,
                alarmLowEnable: alarmLowLimitEnable,
                alarmHighLimitMessage: alarmHighLimitMessage,
                alarmLowLimitMessage: alarmLowLimitMessage,
                pollFrequency: pollFrequency,
              }
            );
            if (enableAlarm.status === 200) {
              toast.success("Saved Changes");
            }
          } else {
            toast.warning(
              "Alarm High Limit and High Limit Message Cannot be Empty"
            );
          }
        } else if (alarmLowLimitEnable) {
          if (alarmLowLimit && alarmLowLimitMessage) {
            const enableAlarm = await api.post(
              `${serverEndpoint}/enable_alarm`,
              {
                pointId: pointProps?._id,
                alarmHighPriority: alarmHighPriority
                  ? alarmHighPriority
                  : "Critical",
                alarmLowPriority: alarmLowPriority
                  ? alarmLowPriority
                  : "Critical",
                source: `${pointProps?.pointDeviceInstance}-${pointProps?.pointName}`,
                sourceState: pointProps?.pointStatus,
                alarmHighLimit: alarmHighLimit,
                alarmLowLimit: alarmLowLimit,
                alarmHighEnable: alarmHighLimitEnable,
                alarmLowEnable: alarmLowLimitEnable,
                alarmHighLimitMessage: alarmHighLimitMessage,
                alarmLowLimitMessage: alarmLowLimitMessage,
                pollFrequency: pollFrequency,
                orgId: user?.orgId,
              }
            );
            if (enableAlarm.status === 200) {
              toast.success("Saved Changes");
            }
          } else {
            toast.warning(
              "Alarm Low Limit and Low Limit Message Cannot be Empty"
            );
          }
        } else {
          const disableAlarm = await api.post(
            `${serverEndpoint}/disable_alarm`,
            {
              thread_id: `alarm-${pointProps?._id}`,
            }
          );
          if (disableAlarm.status === 200) {
            toast.success("Alarm Disabled");
          }
        }
      } else {
        toast.warning("Alarm Priority Cannot be Empty");
      }
    } catch (error) {
      console.error(`Unexpected Error Occurred : ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pointDetailsAlarms">
      <div className={`pointDetailsAlarmsContent `}>
        {isLoading ? <PreLoader size={80} color="var(--primary-color)" /> : ""}
        <div className="pointDetailsAlarmsPartContent">
          <div className="pointDetailsAlarmsGroup">
            <CustomFormInput
              required={true}
              id={"alarmHighEnable"}
              label={"Alarm High Limit Enable:"}
              type={"checkbox"}
              checked={alarmHighLimitEnable ? true : false}
              onChange={
                permissions.devices.write
                  ? (e) => setAlarmHighLimitEnable(e.target.checked)
                  : undefined
              }
            />
          </div>

          <div className="pointDetailsAlarmsGroup">
            <CustomFormSelect
              required={true}
              id={"alarmPriority"}
              label={"Alarm High Priority"}
              value={alarmHighPriority ? alarmHighPriority : "Critical"}
              options={[
                {
                  label: "Critical",
                  value: "Critical",
                },
                {
                  label: "High",
                  value: "High",
                },
                {
                  label: "Low",
                  value: "Low",
                },
              ]}
              onChange={(e) => setAlarmHighPriority(e.target.value)}
            />
          </div>

          <div className="pointDetailsAlarmsGroup">
            <CustomFormInput
              required={true}
              id={"alarmHighLowEnable"}
              label={"Alarm High Limit"}
              type={"text"}
              value={alarmHighLimit}
              onChange={(e) => setAlarmHighLimit(e.target.value)}
            />
          </div>

          <div className="pointDetailsAlarmsGroup">
            <CustomFormInput
              required={true}
              id={"highLimitMessage"}
              label={"Set High Limit Message"}
              type={"text"}
              value={alarmHighLimitMessage}
              onChange={(e) => setAlarmHighLimitMessage(e.target.value)}
            />
          </div>
        </div>
        <div className="pointDetailsAlarmsPartContent">
          <div className="pointDetailsAlarmsGroup">
            <CustomFormInput
              required={true}
              id={"alarmLowEnable"}
              label={"Alarm Low Limit Enable:"}
              type={"checkbox"}
              checked={alarmLowLimitEnable ? true : false}
              onChange={
                permissions.devices.write
                  ? (e) => setAlarmLowLimitEnable(e.target.checked)
                  : undefined
              }
            />
          </div>

          <div className="pointDetailsAlarmsGroup">
            <CustomFormSelect
              required={true}
              id={"alarmPriority"}
              label={"Alarm Low Priority"}
              value={alarmLowPriority ? alarmLowPriority : "Critical"}
              options={[
                {
                  label: "Critical",
                  value: "Critical",
                },
                {
                  label: "High",
                  value: "High",
                },
                {
                  label: "Low",
                  value: "Low",
                },
              ]}
              onChange={(e) => setAlarmLowPriority(e.target.value)}
            />
          </div>

          <div className="pointDetailsAlarmsGroup">
            <CustomFormInput
              required={true}
              id={"pointDetailsAlarmsData"}
              label={"Set Low Limit"}
              type={"text"}
              value={alarmLowLimit}
              onChange={(e) => setAlarmLowLimit(e.target.value)}
            />
          </div>

          <div className="pointDetailsAlarmsGroup">
            <CustomFormInput
              required={true}
              id={"lowLimitMessage"}
              label={"Alarm Low Limit Message"}
              type={"text"}
              value={alarmLowLimitMessage}
              onChange={(e) => setAlarmLowLimitMessage(e.target.value)}
            />
          </div>
        </div>
      </div>
      {permissions.devices.write && (
        <div className="pointDetailsBtnContainer">
          <span className="filled-btn-style" onClick={handleAlarmSave}>
            Save
          </span>
        </div>
      )}
    </div>
  );
};

export default PointDetailsAlarms;
