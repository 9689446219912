import React from "react";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomSwitch = ({ checked, onChange, offText, onText }) => {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 55,
    height: 29,
    padding: 0,
    display: "flex",
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "& + .MuiSwitch-track": {
        display: "flex",
        opacity: 1,
        backgroundColor: "var(--container-background-color)",
        border: "var(--container-border)",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
      },
      "&.Mui-checked": {
        transform: "translateX(26px)",
        color: "var(--font-color)",
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 23,
      height: 23,
      cursor: "pointer",
      borderRadius: 14,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 10,
      color: "var(--primary-color)",
    },
    "& .MuiSwitch-track": {
      borderRadius: 16,
      opacity: 1,
      backgroundColor: "#ccc",
      boxSizing: "border-box",
      position: "relative",
      cursor: "pointer",
    },
    "& .MuiSwitch-track::before": {
      content: `"${offText}"`,
      position: "absolute",
      left: 27,
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: 14,
      color: "var(--font-color)",
      transition: theme.transitions.create(["opacity"], {
        duration: 200,
      }),
      opacity: checked ? 0 : 1,
    },
    "& .MuiSwitch-track::after": {
      content: `"${onText}"`,
      position: "absolute",
      right: 32,
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: 14,
      color: "var(--font-color)",
      transition: theme.transitions.create(["opacity"], {
        duration: 200,
      }),
      opacity: checked ? 1 : 0,
    },
  }));

  return (
    <AntSwitch
      className="deviceBrowserBoundSwitch"
      checked={checked}
      inputProps={{ "aria-label": "ant design" }}
      onChange={onChange}
    />
  );
};

export default CustomSwitch;
