import Slide from "@mui/material/Slide";
import React, { useEffect, useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import "../../../../../common/styles/displayDataConfigurationFormContentStyle.css";

import { isEmpty } from "../../../../../common/utils/isEmpty";
import TruncateText from "../../../../../common/utils/truncateText";
import CustomButton from "../../../../reusableComponents/customButton";
import CustomFormInput from "../../../../reusableComponents/customFormInput";
import CustomFormSelect from "../../../../reusableComponents/customFormSelect";
import ColorPicker from "../../../../../common/utils/colorPicker";
import { useMenu } from "../../../../../common/utils/menuContext";
import api from "../../../../../common/utils/axiosRequest";

const DisplayDataConfigurationForm = ({
  chartId,
  setConfigure,
  droppedItems,
  handleSave,
  configure,
  setSelectedWidget,
  onClick,
}) => {
  const { config } = useMenu();
  const [selectedItem] = useState(
    droppedItems?.find((item) => item?.id === chartId).data
  );

  const [title, setTitle] = useState(selectedItem?.title || "");
  const [trendData, setTrendData] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [btnColor, setBtnColor] = useState();
  const [selectedDisplay, setSelectedDisplay] = useState({});
  const [pollFrequency, setPollFrequency] = useState("");
  const serverEndpoint = config?.REACT_APP_SERVER_ENDPOINT;
  const [imageData, setImageData] = useState(null);
  const [points, setPoints] = useState([]);
  const [startDate, setStartDate] = useState();
  const dbUrl = config?.REACT_APP_DB_ENDPOINT;
  const username = config?.REACT_APP_DB_USERNAME;
  const password = config?.REACT_APP_DB_PASSWORD;
  const base64Credentials = btoa(`${username}:${password}`);
  const [endDate, setEndDate] = useState();
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [dateRange, setDateRange] = useState("1Day");
  const [kpi, setKpi] = useState("1Day");
  const [customKpiValue, setCustomKpiValue] = useState();
  const [pointType, setPointType] = useState("currentVal");
  const [pointToBeMapped, setPointToBeMapped] = useState(
    selectedItem?.points || ""
  );
  const [pointToBeMappedMultiple, setPointToBeMappedMultiple] = useState([]);
  const [rowsCount, setRowsCount] = useState(parseInt(selectedItem?.rows) || 1);

  const handleClose = () => {
    setConfigure((prev) => !prev);
    setSelectedWidget(null);
  };

  useEffect(() => {
    console.log(selectedItem);
    const fetchDashboardsFromDB = async () => {
      try {
        const getDashboards = await api.get(
          `${serverEndpoint}/prebuiltdisplays/_all_docs`,
          {
            params: {
              include_docs: true,
            },
          }
        );
        if (getDashboards.status === 200) {
          setDashboards(getDashboards.data.data.rows.map((row) => row.doc));
        }
      } catch (error) {
        console.error("Error fetching dashboards");
      }
    };
    const fetchPoints = async () => {
      try {
        const pointsDatabase = "points";
        const networksResponse = await api.get(
          `${dbUrl}/${pointsDatabase}/_all_docs`,
          {
            params: {
              include_docs: true,
            },
          }
        );
        setPoints(networksResponse.data.data.rows.map((row) => row.doc));
      } catch (error) {
        console.log(
          `Error Occurred While fetching Output/Value Points: ${error}`
        );
      }
    };
    fetchPoints();
    const fetchTrendPoints = async () => {
      try {
        const couchdbUrl = dbUrl;
        const trendsDatabase = "trends";
        const networksResponse = await api.get(
          `${couchdbUrl}/${trendsDatabase}/_all_docs`,
          {
            params: {
              include_docs: true,
            },
          }
        );
        setTrendData(networksResponse.data.data.rows.map((row) => row.doc));
        const itemSelected = droppedItems?.find((item) => item?.id === chartId);
        if (itemSelected) {
          const {
            points,
            max,
            min,
            pollFrequency,
            pointType,
            dateRange,
            kpi,
            customKpiValue,
            rows,
          } = itemSelected?.data;
          setShowCustomRange(dateRange || kpi === "custom" ? true : false);
          setSelectedOptions(points);
          setPointToBeMappedMultiple(points);
          setRowsCount(parseInt(rows));
          setDateRange(dateRange);
          setKpi(kpi);
          setPointType(pointType);
          setCustomKpiValue(customKpiValue);
          setStartDate(min);
          setEndDate(max);
          setPollFrequency(pollFrequency);
        } else {
          setSelectedOptions([]);
          setPollFrequency();
        }
      } catch (error) {
        console.error("Enable Trend for points to view it here.");
      }
    };

    if (chartId.includes("button") || chartId.includes("hyperlink")) {
      const selectedItem = droppedItems?.find((item) => item?.id === chartId);
      if (selectedItem?.data) {
        const { display, buttonColor } = selectedItem?.data;
        setSelectedDisplay(display);
        setBtnColor(buttonColor);
      } else {
        setSelectedDisplay();
        setBtnColor();
      }
      fetchDashboardsFromDB();
    } else if (chartId.includes("image")) {
      const selectedItem = droppedItems?.find((item) => item?.id === chartId);
      if (selectedItem?.data) {
        const { imageUrl } = selectedItem?.data;
        setImageData(imageUrl);
      } else {
        setImageData();
      }
    } else if (chartId.includes("command")) {
      const selectedItem = droppedItems?.find((item) => item?.id === chartId);
      if (selectedItem?.data) {
        const { points } = selectedItem?.data;
        setSelectedOptions(points);
      } else {
        setSelectedOptions([]);
      }
    } else {
      fetchTrendPoints();
    }
  }, [
    base64Credentials,
    chartId,
    dbUrl,
    droppedItems,
    selectedItem,
    serverEndpoint,
    title,
  ]);

  const handleSelectedDate = (value) => {
    setDateRange(value);
    switch (value) {
      case "custom":
        setShowCustomRange(true);
        break;
      case "1Day":
        setShowCustomRange(false);
        break;
      case "2Days":
        setShowCustomRange(false);
        break;
      default:
        setShowCustomRange(false);
        break;
    }
  };
  const handleSelectedKpi = (value) => {
    setKpi(value);
    switch (value) {
      case "1Day":
        setShowCustomRange(false);
        break;
      case "1Week":
        setShowCustomRange(false);
        break;
      case "custom":
        setShowCustomRange(true);
        break;
      default:
        setShowCustomRange(false);
        break;
    }
  };
  const pointOptions = points.map((item) => ({
    value: item._id,
    label: `${item.pointDeviceInstance}-${TruncateText(item.pointName, 20)}`,
    pointEnable: item.pointEnable,
  }));
  const allowedValues = [
    "analogValue",
    "analogOutput",
    "binaryValue",
    "binaryOutput",
    "HoldingRegister",
    "CoilStatus",
  ];

  const filteredOptions = pointOptions.filter((option) => {
    return allowedValues.some((value) => option.value.includes(value));
  });
  const sendChartData = async () => {
    if (chartId.includes("button") || chartId.includes("hyperlink")) {
      if (!isEmpty(selectedDisplay) && title) {
        const newData = {
          id: chartId,
          title: title,
          display: selectedDisplay,
          buttonColor: btnColor,
        };
        handleSave(chartId, newData);
        setConfigure(false);
      } else {
        toast.warning("Please Fill the Required Fields");
      }
    } else if (chartId.includes("image")) {
      const newData = {
        id: chartId,
        imageUrl: imageData,
      };
      handleSave(chartId, newData);
      setConfigure(false);
    } else if (chartId.includes("command")) {
      const newData = {
        id: chartId,
        title: pointToBeMapped,
        points: pointToBeMapped,
      };
      handleSave(chartId, newData);
      setConfigure(false);
    } else if (chartId.includes("table")) {
      const newData = {
        id: chartId,
        title: title,
        rows: rowsCount,
        points: pointToBeMappedMultiple,
      };
      handleSave(chartId, newData);
    } else {
      if (
        !isEmpty(kpi, dateRange) &&
        pointToBeMapped &&
        pointType &&
        (dateRange !== "custom" || (startDate && endDate)) &&
        (kpi !== "custom" || customKpiValue)
      ) {
        const newData = {
          id: chartId,
          title: title,
          pointType: pointType,
          points: pointToBeMapped,
          dateRange: dateRange,
          min: startDate,
          max: endDate,
          kpi: kpi,
          customKpiValue: customKpiValue,
          pollFrequency: pollFrequency,
        };
        handleSave(chartId, newData);
        setConfigure(false);
      } else if (chartId.includes("label")) {
        const newData = {
          id: chartId,
          title: title,
          pointType: pointType,
          points: pointToBeMapped,
          dateRange: dateRange,
          min: startDate,
          max: endDate,
          kpi: kpi,
          customKpiValue: customKpiValue,
          pollFrequency: pollFrequency,
        };
        console.log(newData);
        handleSave(chartId, newData);
        setConfigure(false);
      } else {
        toast.warning("Please Fill the Required fields");
        return;
      }
    }
    toast.success("Configuration Saved");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result;
        localStorage.setItem("uploadedImage", dataURL);
        setImageData(dataURL);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleFileBrowse = (e) => {
    e.preventDefault();
    const { files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result;
        localStorage.setItem("uploadedImage", dataURL);
        setImageData(dataURL);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteImage = () => {
    localStorage.removeItem("uploadedImage");
    setImageData(null);
  };
  const handlePointTypeChange = (newPointType) => {
    setSelectedOptions([]);
    setPointType(newPointType);
  };
  const dashboardsList = dashboards.map((display) => ({
    value: display.name,
    label: display.name,
  }));

  const handleInputChange = (index, value) => {
    const updatedPoints = [...pointToBeMappedMultiple];
    updatedPoints[index] = value;
    setPointToBeMappedMultiple(updatedPoints);
  };

  return (
    <Slide
      direction={"left"}
      in={configure}
      mountOnEnter
      unmountOnExit
      onExited={() => {
        setConfigure(false);
      }}
    >
      <div
        className={`displayConfigurationForm container-style`}
        onClick={onClick}
      >
        <div className="displayDataConfigurationFormContentHeading">
          Configure Data
        </div>
        <div className="displayDataConfigurationFormContents">
          {chartId.includes("button") || chartId.includes("hyperlink") ? (
            <>
              <CustomFormInput
                id="linkButton"
                label="Button/Link Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Enter the Title for Button/Link"
                required={true}
                type="text"
              />
              <CustomFormSelect
                id="selectDisplay"
                label="Select a Display"
                onChange={(e) => {
                  const selectedDisplayObj = dashboards.find(
                    (display) => display.name === e.target.value
                  );
                  setSelectedDisplay(selectedDisplayObj || {});
                }}
                value={
                  droppedItems?.data?.selectedDisplay
                    ? droppedItems?.data?.selectedDisplay?.name
                    : selectedDisplay?.name
                }
                options={[
                  {
                    value: "",
                    label: "Select a Display",
                  },
                  ...dashboardsList,
                ]}
                required={true}
              />
              {chartId.includes("button") ? (
                <ColorPicker
                  initialColor={btnColor}
                  onColorChange={setBtnColor}
                />
              ) : null}
            </>
          ) : chartId.includes("label") ? (
            <>
              <CustomFormInput
                id="label"
                label="Label"
                onChange={(e) => setTitle(e.target.value)}
                value={
                  droppedItems?.data?.title ? droppedItems?.data?.title : title
                }
                placeholder="Enter a label"
                type="text"
              />
            </>
          ) : chartId.includes("image") ? (
            <div
              className="displayDataConfigurationFormImageContainer"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              {imageData ? (
                <>
                  <img
                    src={imageData}
                    alt="Uploaded"
                    className="displayDataConfigurationFormImage"
                  />
                  <MdDelete
                    className="imageWidgetImageDeleteBtn"
                    onClick={handleDeleteImage}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="imageUploadInput" className="imageUploadBtn">
                    <div className="imageUploadImage">
                      <FaCloudUploadAlt />
                    </div>
                    <div className="imageDragUploadText">
                      Drag and Drop the Image
                    </div>
                    <div className="imageOr">(OR)</div>
                    <span className="">Browse Image</span>
                  </label>
                  <input
                    type="file"
                    id="imageUploadInput"
                    style={{ display: "none" }}
                    onChange={handleFileBrowse}
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                  />
                </>
              )}
            </div>
          ) : chartId.includes("command") ? (
            <>
              <CustomFormInput
                id={"pointToBeMapped"}
                required={true}
                type={"text"}
                label={"Point to be Mapped"}
                value={pointToBeMapped}
                placeholder={"Ex: Temperature Indoor"}
                onChange={(e) => setPointToBeMapped(e.target.value)}
              />
            </>
          ) : chartId.includes("bar") ||
            chartId.includes("line") ||
            chartId.includes("pie") ? (
            <>
              <CustomFormInput
                id="title"
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Enter the Title for the Chart"
                type="text"
              />
              <CustomFormInput
                id={"pointToBeMapped"}
                required={true}
                type={"text"}
                label={"Point to be Mapped"}
                value={pointToBeMapped}
                onChange={(e) => setPointToBeMapped(e.target.value)}
                placeholder={"Ex: Temperature Indoor"}
              />
              <CustomFormSelect
                id="selectType"
                label="Select Type"
                onChange={(e) => handlePointTypeChange(e.target.value)}
                value={pointType}
                options={[
                  {
                    value: "currentVal",
                    label: "Instantaneous Value",
                  },
                  {
                    value: "trendVal",
                    label: "Trend Value",
                  },
                ]}
                required={true}
              />
              {pointType === "currentVal" ? null : (
                <>
                  <CustomFormSelect
                    id="selectDateRanges"
                    label="Select Date Ranges"
                    onChange={(e) => handleSelectedDate(e.target.value)}
                    value={dateRange}
                    options={[
                      {
                        value: "",
                        label: "Select Date Ranges",
                      },
                      {
                        value: "1Day",
                        label: "Last 1 Day",
                      },
                      {
                        value: "2Day",
                        label: "Last 2 Days",
                      },
                      {
                        value: "custom",
                        label: "Custom Date Range",
                      },
                    ]}
                    required={true}
                  />

                  {dateRange === "custom" && (
                    <>
                      <CustomFormInput
                        id="startDate"
                        label="Start Date"
                        onChange={(e) => setStartDate(e.target.value)}
                        value={startDate}
                        placeholder="Select Start Date"
                        type="date"
                        required
                      />
                      <CustomFormInput
                        id="endDate"
                        label="End Date"
                        onChange={(e) => setEndDate(e.target.value)}
                        value={endDate}
                        placeholder="Select End Date"
                        type="date"
                        required
                      />
                    </>
                  )}
                </>
              )}
            </>
          ) : chartId.includes("kpi") ? (
            <>
              <CustomFormInput
                id="title"
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Enter the Title for the Chart"
                type="text"
              />
              <CustomFormInput
                id={"pointToBeMapped"}
                required={true}
                type={"text"}
                label={"Point to be Mapped"}
                placeholder={"Ex: Temperature Indoor"}
                value={pointToBeMapped}
                onChange={(e) => setPointToBeMapped(e.target.value)}
              />
              <CustomFormSelect
                id="selectKpiValue"
                label="Select KPI Value"
                onChange={(e) => handleSelectedKpi(e.target.value)}
                value={kpi}
                options={[
                  {
                    value: "1Day",
                    label: "Average of 1 Day",
                  },
                  {
                    value: "1Week",
                    label: "Average of 1 Week",
                  },
                  {
                    value: "custom",
                    label: "Custom Value",
                  },
                ]}
                required={true}
              />

              {kpi === "custom" && (
                <>
                  <CustomFormInput
                    id="setCustomKpiValue"
                    label="Set Custom Kpi Value"
                    onChange={(e) => setCustomKpiValue(e.target.value)}
                    value={
                      droppedItems?.data?.customKpiValue
                        ? droppedItems?.data?.customKpiValue
                        : customKpiValue
                    }
                    placeholder="Enter a Kpi value"
                    type="number"
                    required
                  />
                </>
              )}
            </>
          ) : chartId.includes("table") ? (
            <>
              <CustomFormInput
                id="title"
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Enter the Title for the Chart"
                type="text"
              />
              <CustomFormInput
                id="rows"
                label="Number of Rows"
                onChange={(e) => setRowsCount(e.target.value)}
                value={rowsCount}
                placeholder="Enter the Number of Rows for the Table"
                type="number"
              />
              {Array.from({ length: rowsCount }).map((_, index) => (
                <CustomFormInput
                  key={index}
                  id={`pointToBeMapped-${index}`}
                  required={true}
                  type={"text"}
                  label={`Point to be Mapped for Row - ${index + 1}`}
                  value={pointToBeMappedMultiple[index] || ""}
                  placeholder={"Ex: Temperature Indoor"}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              ))}
            </>
          ) : (
            <>
              <CustomFormInput
                id="title"
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Enter the Title for the Chart"
                type="text"
              />
              <CustomFormInput
                id={"pointToBeMapped"}
                required={true}
                type={"text"}
                label={"Point to be Mapped"}
                value={pointToBeMapped}
                placeholder={"Ex: Temperature Indoor"}
                onChange={(e) => setPointToBeMapped(e.target.value)}
              />
            </>
          )}
        </div>
        <div className="displayDataConfigurationFormButtons">
          <CustomButton
            buttonName="Close"
            className="outline-btn-style"
            handleClick={() => handleClose()}
          />
          <CustomButton
            buttonName="Save"
            className="filled-btn-style"
            handleClick={sendChartData}
          />
        </div>
      </div>
    </Slide>
  );
};

export default DisplayDataConfigurationForm;
